<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Агропромышленные и биотехнологии»
		</h2>
		<p class="EventsPage-date">
			с 26 сентября по 9 октября 2024
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=190" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://р38.навигатор.дети/program/25596-programma-agropromyshlennye-i-biotekhnologii" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Агропромышленные и биотехнологии» для учащихся 8-11-х
			классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Данная программа заключается в создании образовательных условий и освоение учащимися профессиональных навыков и
			умений, необходимых в отраслях АПК в области агропромышленности и биотехнологий.
		</p>

		<p class="EventsPage-text">
			Целью профильной смены: создание условий для формирования агробизнес-компетенций, личностных качеств учащихся
			агробизнес школ Иркутской области, развитие инновационного мышления и поиск бизнес-подходов к
			сельскохозяйственному производству в региональном агропромышленном комплексе.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету и выполнить задание (макет проекта) до 16 сентября 2024 года на платформе
				Образовательного центра «Персей», системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Для тех участников, которые пройдут конкурсный отбор, необходимо записаться на программу «Агропромышленные и
				биотехнологии» на ресурсе «Навигатор дополнительного образования Иркутской области».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится с 26 сентября по 9 октября 2024 года на бесплатной основе в Образовательном центре
				«Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			<b>Результаты отбора будут размещены 17 сентября на сайте Образовательного центра «Персей».</b>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Аболмасова Карина Андреевна</li>
			<li class="EventsPage-number">Архипова Татьяна Вячеславовна</li>
			<li class="EventsPage-number">Беганцова Мария Владимировна</li>
			<li class="EventsPage-number">Васильев Ятур Баирович</li>
			<li class="EventsPage-number">Ващенко Вероника Александровна</li>
			<li class="EventsPage-number">Ведерникова Алина Ивановна</li>
			<li class="EventsPage-number">Визгалова Олеся Александровна</li>
			<li class="EventsPage-number">Вилицкая Кристина Андреевна</li>
			<li class="EventsPage-number">Волчёк София Ивановна</li>
			<li class="EventsPage-number">Галдусова Екатерина Алексеевна</li>
			<li class="EventsPage-number">Дашабылова Софья Сергеевна</li>
			<li class="EventsPage-number">Дементьева Полина Геннадьевна</li>
			<li class="EventsPage-number">Дмитриева Юлия Степановна</li>
			<li class="EventsPage-number">Долгополова Дарья Александровна</li>
			<li class="EventsPage-number">Домошонкина Диана Евгеньевна</li>
			<li class="EventsPage-number">Дунцова Алина Владимировна</li>
			<li class="EventsPage-number">Дыхнова Софья Алексеевна</li>
			<li class="EventsPage-number">Ершова Дарина Андреевна</li>
			<li class="EventsPage-number">Зарубин Кирилл Ильич</li>
			<li class="EventsPage-number">Зарубина Алёна Андреевна</li>
			<li class="EventsPage-number">Зиновьев Александр Денисович</li>
			<li class="EventsPage-number">Зюбина Полина Юрьевна</li>
			<li class="EventsPage-number">Инкеева Полина Романовна</li>
			<li class="EventsPage-number">Кадочникова Маргарита Александровна</li>
			<li class="EventsPage-number">Каширин Роман Максимович</li>
			<li class="EventsPage-number">Кирьянов Данил Михайлович</li>
			<li class="EventsPage-number">Козлова Полина Алексеевна</li>
			<li class="EventsPage-number">Козырев Михаил Максимович</li>
			<li class="EventsPage-number">Колбасова Яна Игоревна</li>
			<li class="EventsPage-number">Кузнецова Вероника Евгеньевна</li>
			<li class="EventsPage-number">Кульмаер Евгения Андреевна</li>
			<li class="EventsPage-number">Ламбина Полина Максимович</li>
			<li class="EventsPage-number">Лесина Дарина Олеговна</li>
			<li class="EventsPage-number">Лопатин Родион Константинович</li>
			<li class="EventsPage-number">Магас Нелли Николаевна</li>
			<li class="EventsPage-number">Матапов Эдуард Николаевич</li>
			<li class="EventsPage-number">Медведева София Сергеевна</li>
			<li class="EventsPage-number">Мелькова Арина Павловна</li>
			<li class="EventsPage-number">Миненко Анастасия Николаевна</li>
			<li class="EventsPage-number">Мураенко Алиса Владимировна</li>
			<li class="EventsPage-number">Павленко Валерия Александровна</li>
			<li class="EventsPage-number">Перфильева Ульяна Сергеевна</li>
			<li class="EventsPage-number">Петрович Людмила Григорьевна</li>
			<li class="EventsPage-number">Рубанова Полина Романовна</li>
			<li class="EventsPage-number">Саврикова Татьяна Вячеславовна</li>
			<li class="EventsPage-number">Самсонова Алтана Михайловна</li>
			<li class="EventsPage-number">Семёнова Ирина Сергеевна</li>
			<li class="EventsPage-number">Скурлатова Анастасия Алексеевна</li>
			<li class="EventsPage-number">Смолин Роман Русланович</li>
			<li class="EventsPage-number">Сураева Динара Ильхамовна</li>
			<li class="EventsPage-number">Тарасова Анастасия Вячеславововна</li>
			<li class="EventsPage-number">Тарханова Татьяна Сергеевна</li>
			<li class="EventsPage-number">Ткаченко Анастасия</li>
			<li class="EventsPage-number">Трошина Таисия Александровна</li>
			<li class="EventsPage-number">Урбагаева Алина Олеговна</li>
			<li class="EventsPage-number">Черепкова Анастасия</li>
			<li class="EventsPage-number">Шарыпова Янина Вячеславовна</li>
			<li class="EventsPage-number">Шенцова Анастасия Евгеньевна</li>
			<li class="EventsPage-number">Штокерт Семен Валерьевич</li>
			<li class="EventsPage-number">Яковин Герман Сергеевич</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Резерв:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Моисеева Анастасия Евгеньевна</li>
			<li class="EventsPage-number">Цымбалова Маргарита Васильевна</li>
		</ol>
		<br>
		<hr>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 18 сентября 2024 г. в 20.00, <a style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1725265114">ссылка на подключение</a>
		</p>
		<hr>

		<p class="EventsPage-text">
			<a style="color:blue"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%90%D0%B3%D1%80%D0%BE%D0%BF%D1%80%D0%BE%D0%BC%D1%8B%D1%88%D0%BB%D0%B5%D0%BD%D0%BD%D1%8B%D0%B5-%D0%B8-%D0%B1%D0%B8%D0%BE%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D0%B8.pdf">Положение
				о профильной смене «Агропромышленные и биотехнологии»
			</a>
		</p>
		<hr>

		<!-- <p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="" target="_blank">
			Скачать
		</a>
		<hr> -->

		<!-- <p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="" target="_blank">
			Скачать
		</a>
		<hr> -->

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>