<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Еженедельный курс
		</h3>
		<h2 class="EventsPage-title">
			«Основы фотосъемки. Фотодни»
		</h2>
		<p class="EventsPage-date">
			с 1 июня по 31 августа 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=167" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/25064-programma-osnovy-fotosemki-fotodni"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит еженедельный курс «Основы фотосъемки. Фотодни» для учащихся 8-11-х
			классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			На программе учащиеся научатся самостоятельной работе фотографа в жанре студийной, портретной и пейзажной
			фотографии. Обучение будет проходить в формате фотодней и воркшопов, где учащиеся смогут отработать навыки работы
			со светом и моделями. Пополнить свое творческое портфолио и больше узнать о предпродакшне, продакшене и
			постпродакшене в работе фотографа.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Говорова Анастасия Андреевна, Специалист по связям с общественностью Образовательный центр «Персей»;
			</li>
			<li class="JuniorProfi-items">
				Хранивский Сергей Дмитриевич, Заведующий отделом информационно-технического Обеспечения Образовательный центр
				«Персей».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету на платформе ОЦ «Персей», до 1 июня 2024 года на платформе обучения
				Образовательного центра «Персей» системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Еженедельный курс состоится с 1 июня 2024 года на бесплатной основе в Образовательном центре «Персей» (г.
				Иркутск, ул. Рабочая штаба 19 а).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/FqNzRuZy5SETuQ" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>