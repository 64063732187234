<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Химия элементов»
		</h2>
		<p class="EventsPage-date">
			с 27 января по 24 февраля 2025 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=176 " target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href=" https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/25353-programma-khimiya-elementov" target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит дистанционный курс «Химия элементов»» для учащихся 8-11-х классов
			общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Этот курс ознакомит вас с основами химии: основными понятиями и законами, необходимыми для каждого химика;
			структурой атомов и их влиянием на свойства веществ; важными элементами вещества, такими как водород, кислород и
			вода.
		</p>

		<p class="EventsPage-text">
			Вы узнаете, как читать и писать химические уравнения, как синтезировать золото из свинца на бумаге, и почему
			водные растворы соли и сахара ведут себя по-разному в отношении проводимости электричества.
		</p>

		<p class="EventsPage-text">
			Этот курс будет полезен учащимся 8 класса, желающим расширить свои знания за пределами школьной программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Томилова Альбина Сергеевна, Старший лаборант Образовательный центр «Персей».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 27 января 2025 года на платформе обучения Образовательного центра
				«Персей» системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>