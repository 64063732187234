<template>
	<div class="WeeklyBlock _cover">
		<div class="WeeklyBlock-name-block">
			<h2 class="WeeklyBlock-title _cover-title">Дистанционные курсы</h2>
			<div class="WeeklyCourses-img-box">
				<img loading="lazy" class="WeeklyCourses-img-content" src="../assets/img/jpg/2art-1024x1024.webp"
					alt="Дистанционные курсы" />
			</div>
		</div>
		<hr />

		<!-- Актуальные -->
		<div class="WeeklyTopical">
			<h2 class="WeeklyTopical-title _cover-title">Список программ</h2>
			<ul class="grid-container">

				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage193' })">
						<EventsCard193></EventsCard193>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage189' })">
						<EventsCard189></EventsCard189>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage187' })">
						<EventsCard187></EventsCard187>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage183' })">
						<EventsCard183></EventsCard183>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage174' })">
						<EventsCard174></EventsCard174>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage176' })">
						<EventsCard176></EventsCard176>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage175' })">
						<EventsCard175></EventsCard175>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage159' })">
						<EventsCard159></EventsCard159>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage148' })">
						<EventsCard148></EventsCard148>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage144' })">
						<EventsCard144></EventsCard144>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage143' })">
						<EventsCard143></EventsCard143>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage142' })">
						<EventsCard142></EventsCard142>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage141' })">
						<EventsCard141></EventsCard141>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage140' })">
						<EventsCard140></EventsCard140>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage138' })">
						<EventsCard138></EventsCard138>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage136' })">
						<EventsCard136></EventsCard136>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage131' })">
						<EventsCard131></EventsCard131>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage121' })">
						<EventsCard121></EventsCard121>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage119' })">
						<EventsCard119></EventsCard119>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage115' })">
						<EventsCard115></EventsCard115>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage114' })">
						<EventsCard114></EventsCard114>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage109' })">
						<EventsCard109></EventsCard109>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage92' })">
						<EventsCard92></EventsCard92>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage91' })">
						<EventsCard91></EventsCard91>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage24' })">
						<EventsCard24></EventsCard24>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage17' })">
						<EventsCard17></EventsCard17>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage14' })">
						<EventsCard14></EventsCard14>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage15' })">
						<EventsCard15></EventsCard15>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage16' })">
						<EventsCard16></EventsCard16>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage1' })">
						<EventsCard1></EventsCard1>
					</a>
				</li>
			</ul>
		</div>

		<!-- Архив -->
		<div class="Period">
			<h2 class="Period-title _cover-title">Архив</h2>

			<details class="Ol_title-drop">
				<summary class="Completed-Period-hide-title">Подробнее</summary>
				<ul class="grid-container">
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage170' })">
							<EventsCard170></EventsCard170>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage169' })">
							<EventsCard169></EventsCard169>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage167' })">
							<EventsCard167></EventsCard167>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage168' })">
							<EventsCard168></EventsCard168>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/dk-okp-180423/" target="_blank">
							<EventsCard89></EventsCard89>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/dk-bohi-m2-080423/" target="_blank">
							<EventsCard88></EventsCard88>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/dk-lt-ha-1207-0108/" target="_blank">
							<EventsCard87></EventsCard87>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/dk-oma-270323-150423/" target="_blank">
							<EventsCard86></EventsCard86>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/dv-zvs-280323/" target="_blank">
							<EventsCard85></EventsCard85>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/dk-op-030623/" target="_blank">
							<EventsCard84></EventsCard84>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/dk-fg-290520223/" target="_blank">
							<EventsCard83></EventsCard83>
						</a>
					</li>
				</ul>
			</details>
		</div>
	</div>
</template>

<script>
import EventsCard1 from '../EventsCard/EventsCard1'
import EventsCard14 from '../EventsCard/EventsCard14'
import EventsCard15 from '../EventsCard/EventsCard15'
import EventsCard16 from '../EventsCard/EventsCard16'
import EventsCard17 from '../EventsCard/EventsCard17'
import EventsCard24 from '../EventsCard/EventsCard24'
import EventsCard83 from '../EventsCard/EventsCard83'
import EventsCard84 from '../EventsCard/EventsCard84'
import EventsCard85 from '../EventsCard/EventsCard85'
import EventsCard86 from '../EventsCard/EventsCard86'
import EventsCard87 from '../EventsCard/EventsCard87'
import EventsCard88 from '../EventsCard/EventsCard88'
import EventsCard89 from '../EventsCard/EventsCard89'
import EventsCard91 from '../EventsCard/EventsCard91'
import EventsCard92 from '../EventsCard/EventsCard92'
import EventsCard109 from '../EventsCard/EventsCard109'
import EventsCard114 from '../EventsCard/EventsCard114'
import EventsCard115 from '../EventsCard/EventsCard115'
import EventsCard119 from '../EventsCard/EventsCard119'
import EventsCard121 from '../EventsCard/EventsCard121'
import EventsCard131 from '../EventsCard/EventsCard131'
import EventsCard136 from '../EventsCard/EventsCard136'
import EventsCard138 from '../EventsCard/EventsCard138'
import EventsCard140 from '../EventsCard/EventsCard140'
import EventsCard141 from '../EventsCard/EventsCard141'
import EventsCard142 from '../EventsCard/EventsCard142'
import EventsCard143 from '../EventsCard/EventsCard143'
import EventsCard144 from '../EventsCard/EventsCard144'
import EventsCard148 from '../EventsCard/EventsCard148'
import EventsCard159 from '../EventsCard/EventsCard159'
import EventsCard167 from '../EventsCard/EventsCard167'
import EventsCard168 from '../EventsCard/EventsCard168'
import EventsCard169 from '../EventsCard/EventsCard169'
import EventsCard170 from '../EventsCard/EventsCard170'
import EventsCard174 from '../EventsCard/EventsCard174'
import EventsCard175 from '../EventsCard/EventsCard175'
import EventsCard176 from '../EventsCard/EventsCard176'
import EventsCard183 from '../EventsCard/EventsCard183'
import EventsCard187 from '../EventsCard/EventsCard187'
import EventsCard189 from '../EventsCard/EventsCard189'
import EventsCard193 from '../EventsCard/EventsCard193'

export default {
	components: {
		EventsCard1,
		EventsCard14,
		EventsCard15,
		EventsCard16,
		EventsCard17,
		EventsCard24,
		EventsCard83,
		EventsCard84,
		EventsCard85,
		EventsCard86,
		EventsCard87,
		EventsCard88,
		EventsCard89,
		EventsCard91,
		EventsCard92,
		EventsCard109,
		EventsCard114,
		EventsCard115,
		EventsCard119,
		EventsCard121,
		EventsCard131,
		EventsCard136,
		EventsCard138,
		EventsCard140,
		EventsCard141,
		EventsCard142,
		EventsCard143,
		EventsCard144,
		EventsCard148,
		EventsCard159,
		EventsCard167,
		EventsCard168,
		EventsCard169,
		EventsCard170,
		EventsCard174,
		EventsCard175,
		EventsCard176,
		EventsCard183,
		EventsCard187,
		EventsCard189,
		EventsCard193,
	},
}
</script>
