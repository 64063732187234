<template>
	<div class="RegulatoryDocuments _cover">
		<h2 class="RegulatoryDocuments-title _cover-title">
			Нормативно правовые документы
		</h2>

		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D1%80%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BB%D1%83%D1%87%D1%88%D0%B8%D0%B5-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D1%8B-24.pdf"
					target="_blank">
					Распоряжение лучшие программы
				</a>
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Уважаемые коллеги!
		</p>
		<p class="EventsPage-text">
			Сообщаем, что стартует муниципальный и региональный этап всероссийского конкурса "Лучшая программа организации
			отдыха детей и их оздоровления"
		</p>

		<p class="JuniorProfi-sub-title">
			Сроки проведения конкурса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				I этап - муниципальный: срок проведения до 17 сентябр я 2024 rода.
			</li>
			<li class="JuniorProfi-items">
				II этап - региональный: срок проведения с l 8 по 27 сентября 2024 rода.
			</li>
			<li class="JuniorProfi-items">
				III этап - подведение итогов и награждение победителей: срок
			</li>
		</ul>
		<p class="EventsPage-text">
			Проведения до 30 сентября2024 года
		</p>

		<p class="JuniorProfi-sub-title">
			Номинации конкурса:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Программа стационарных лагерей
			</li>
			<li class="JuniorProfi-items">
				Программа палаточных лагерей
			</li>
			<li class="JuniorProfi-items">
				Программа лагерей с дневным пребыванием
			</li>
			<li class="JuniorProfi-items">
				Программа лагерей труда и отдыха
			</li>
			<li class="JuniorProfi-items">
				Программа детских специализированных (профильных) лагерей
			</li>
			<li class="JuniorProfi-items">
				Программа детских лагерей различной тематической направленности
			</li>
			<li class="JuniorProfi-items">
				Инклюзивная программа организации отдыха и оздоровления детей
			</li>
			<li class="JuniorProfi-items">
				Информационно-методические сборники по организации отдыха детей и их оздоровления
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Самодурова Вера Геннадьевна, руководитель Центра развития конкурсного движения и детских
				инициатив
			</li>
			<li class="JuniorProfi-items">
				Телефон: 89247117835
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: v.samodurova@perseusirk.ru
			</li>
		</ul>
	</div>
</template>