<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Еженедельный курс
		</h3>
		<h2 class="EventsPage-title">
			«Основы фотосъемки: практика»
		</h2>
		<p class="EventsPage-date">
			с 4 марта оп 8 мая 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=144" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24620-programma-osnovy-fotosemki-praktika"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит еженедельный курс «Основы фотосъемки: практика» для учащихся 8-11-х
			классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа направлена на стимулирование интереса к фотографии и потребность в получении дополнительных знаний,
			сформировать способность к личностному самоопределению и самореализации как фотографа.
		</p>
		<p class="EventsPage-text">
			Еженедельный курс состоится <b>4 марта 2024 года</b> на бесплатной основе в Образовательном центре «Персей».
		</p>
		<p class="EventsPage-text">
			Участники дистанционного курса «Секреты фотосъемки» и профильной смены «Мир в объективе» могут подать заявку без
			прохождения конкурсного отбора с условиями наличия зеркального фотоаппарата.
		</p>
		<p class="EventsPage-text">
			<b>Отбор проводится с 10 февраля 2024 года по 26 февраля 2024 года.</b>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Говорова Анастасия Андреевна, Специалист по связям с общественностью Образовательный центр «Персей»;
			</li>
			<li class="JuniorProfi-items">
				Хранивский Сергей Дмитриевич, Заведующий отделом информационно-технического Обеспечения Образовательный центр
				«Персей».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету на платформе обучения Образовательного центра «Персей» в системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Необходимо прикрепить пять фотографий на платформе обучения Образовательного центра «Персей»., сделанных
				участником отбора. Каждая фотография должна соответствовать следующей тематике:
			</li>
			<li class="JuniorProfi-items">
				Зимнее настроение
			</li>
			<li class="JuniorProfi-items">
				Мой город в объективе
			</li>
			<li class="JuniorProfi-items">
				Мой лучший друг (подруга)
			</li>
			<li class="JuniorProfi-items">
				Мир глазами фотографа
			</li>
			<li class="JuniorProfi-items">
				Любимые вещи
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/Kz7L/qbZvzusuD" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/leotcHn6MIZJtQ" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>