<template>
	<div class="Education _cover">
		<h2 class="Education _cover-title">
			Образование
		</h2>

		<h3 class="Scholarships-title-box">
			Лицензия на осуществление образовательной деятельности
		</h3>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A3%D0%B2%D0%B5%D0%B4%D0%BE%D0%BC%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%B2%D1%8B%D0%B4%D0%B0%D1%87%D0%B5-%D0%BB%D0%B8%D1%86%D0%B5%D0%BD%D0%B7%D0%B8%D0%B8.pdf"
					target="_blank">
					Уведомление о выдаче лицензии
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%A3%D0%B2%D0%B5%D0%B4%D0%BE%D0%BC%D0%BB%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%B2%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B9-%D0%B2-%D1%80%D0%B5%D0%B5%D1%81%D1%82%D1%80-%D0%BB%D0%B8%D1%86%D0%B5%D0%BD%D0%B7%D0%B8%D0%B9.pdf"
					target="_blank">
					Уведомление о внесении изменений в реестр лицензий
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%92%D1%8B%D0%BF%D0%B8%D1%81%D0%BA%D0%B0-%D0%B8%D0%B7-%D1%80%D0%B5%D0%B5%D1%81%D1%82%D1%80%D0%B0-%D0%BB%D0%B8%D1%86%D0%B5%D0%BD%D0%B7%D0%B8%D0%B9-%E2%84%96-%D0%9B035-01220-38-00653029-%D0%BE%D1%82-07.08.2024.pdf"
					target="_blank">
					Выписка-из-реестра-лицензий-№-Л035-01220-38-00653029-от-07.08.2024
				</a>
			</li>
		</ul>

		<h3 class="Scholarships-title-box">
			Информация о реализуемых образовательных программах
		</h3>
		<p class="Scholarships-sub-title">
			<b>Уровни образования:</b> дополнительное образование
		</p>
		<p class="Scholarships-sub-title">
			<b>Формы обучения:</b> очная, очно-заочная, заочная (с применением дистанционных образовательных технологий).
		</p>
		<p class="Scholarships-sub-title">
			<b>Нормативные сроки обучения:</b> до 1 года.
		</p>
		<p class="Scholarships-sub-title">
			<b>Язык обучения:</b> русский.
		</p>
		<p class="Scholarships-sub-title">
			<b>Организация образовательного процесса по учебным предметам, дисциплинам(модулям)</b> осуществляется в
			соответствии с реализуемыми образовательными программами.
		</p>
		<p class="Scholarships-sub-title">
			<b>Информация о практике, предусмотренной соответствующей образовательной программой:</b> практика не
			предусмотрена
		</p>
		<p class="Scholarships-sub-title">
			<b>Информация об описании образовательных программ </b> включая учебный план, аннотации к рабочим программам
			дисциплин, календарный учебный график представлена по ссылкам:
		</p>
		<ul class="Education-list">
			<li class="Education-items">
				<a class="Education-items-link" @click="$router.push({ name: 'AdditionalProfessionalPrograms' })">Дополнительные
					профессиональные программы (повышения
					квалификации</a>)
			</li>
			<li class="Education-items">
				<a class="Education-items-link" @click="$router.push({ name: 'AdditionalGneral' })">Дополнительные
					общеобразовательные (общеразвивающие) программы</a>
			</li>
			<li class="Education-items">
				<a class="Education-items-link" href="#">Основные программы профессионального обучения (программы
					профессиональной подготовки по профессиям рабочих, должностям служащих, программы переподготовки рабочих,
					служащих, программы повышения квалификации рабочих, служащих</a>
			</li>
		</ul>

		<h3 class="Scholarships-title-box">
			Методические и иные документы
		</h3>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D1%80%D0%B0%D0%B7%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B5-%D0%B8-%D1%80%D0%B5%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%BE%D0%B1%D1%89%D0%B5%D1%80%D0%B0%D0%B7%D0%B2%D0%B8%D0%B2%D0%B0%D1%8E%D1%89%D0%B8%D1%85-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC-2.pdf"
					target="_blank">
					Положение о разработке и реализации дополнительных общеразвивающих программ
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%A3%D0%9C%D0%9A-%D0%94%D0%9E%D0%9E%D0%9F.pdf"
					target="_blank">
					Положение об учебно-методическом комплексе дополнительной общеразвивающей программе
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%92%D0%BE%D1%81%D0%BF%D0%B8%D1%82%D0%B0%D0%BD%D0%B8%D1%8F.pdf"
					target="_blank">
					Программа воспитания
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BE%D0%BA-%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8-%D0%B8-%D0%BE%D1%81%D1%83%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B9-%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8-%D0%BF%D0%BE-%D0%94%D0%9E%D0%9F.pdf"
					target="_blank">
					Приказ Министерства просвещения Российской Федерации от 27.07.2022 № 629 «Об утверждении Порядка организации
					и осуществления образовательной деятельности по дополнительным общеобразовательным программам»
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B5-%D1%80%D0%B5%D0%BA%D0%BE%D0%BC%D0%B5%D0%BD%D0%B4%D0%B0%D1%86%D0%B8%D0%B8-%D0%BF%D0%BE-%D0%BE%D0%B1%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8E-%D1%81%D0%BE%D0%B4%D0%B5%D1%80%D0%B6%D0%B0%D0%BD%D0%B8%D1%8F.pdf"
					target="_blank">
					Методические рекомендации Минпросвещения России от 29.09.2023 № АБ-3935/06 «Методические рекомендации по
					формированию механизмов обновления содержания, методов и технологий обучения в системе дополнительного
					образования детей, направленных на повышение качества дополнительного образования детей, в том числе включение
					компонентов, обеспечивающих формирование функциональной грамотности и компетентностей, связанных с
					эмоциональным, физическим, интеллектуальным, духовным развитием человека, значимых для вхождения Российской
					Федерации в число десяти ведущих стран мира по качеству общего образования, для реализации приоритетных
					направлений научно-технологического и культурного развития страны».
				</a>
			</li>
		</ul>

		<h3 class="Scholarships-title-box">
			Информация о численности обучающихся по реализуемым образовательным программам
		</h3>
		<h3 class="Scholarships-title-box">
			Общая численность обучающихся в 2024 г. - 10940 человек, в том числе:
		</h3>

		<div class="StructureAndOrgans-table">
			<table>
				<tr>
					<td><b>за счет бюджетных ассигнований федерального бюджета (в том числе с выделением численности обучающихся,
							являющихся иностранными гражданами)</b></td>
					<td><b>за счет бюджетных ассигнований бюджета Иркутской области (в том числе с выделением численности
							обучающихся, являющихся иностранными гражданами)</b></td>
					<td><b>за счет бюджетных ассигнований местного бюджета (в том числе с выделением численности обучающихся,
							являющихся иностранными гражданами)</b></td>
					<td><b>по договорам об образовании, заключаемых при приеме на обучение за счет средств физического и (или)
							юридического лица (в том числе с выделением численности обучающихся, являющихся иностранными
							гражданами)</b>
					</td>
				</tr>
				<tr>
					<td>0</td>
					<td>10940</td>
					<td>0</td>
					<td>0</td>
				</tr>
				<tr>
					<td colspan="4" style="text-align: left;"><b>По дополнительным профессиональным программам:</b></td>
				</tr>
				<tr>
					<td>0</td>
					<td>468</td>
					<td>0</td>
					<td>0</td>
				</tr>
				<tr>
					<td colspan="4" style="text-align: left;"><b>По программам профессиональной подготовки:</b></td>
				</tr>
				<tr>
					<td>0</td>
					<td>0</td>
					<td>0</td>
					<td>0</td>
				</tr>
				<tr>
					<td colspan="4" style="text-align: left;"><b>По дополнительным общеобразовательным (общеразвивающим)
							программам:</b>
					</td>
				</tr>
				<tr>
					<td>0</td>
					<td>10472</td>
					<td>0</td>
					<td>0</td>
				</tr>
			</table>
		</div>

		<h3 class="Scholarships-title-box">
			Вакантных мест для приема (перевода) нет.
		</h3>

	</div>
</template>