<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			02.09.2024
		</p>
		<h2 class="NewsPage-title">
			Гала-концертом завершилась профильная смена «Оркестровое духовое исполнительство» в «Персее»
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage79.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Региональном центре выявления и поддержки одаренных детей «Персей» подвели итоги профильной смены «Оркестровое
				духовое исполнительство» по направлению «Искусство». Программа объединила более 100 школьников из различных
				уголков Иркутской области, включая Иркутск, Ангарск, Байкальск, Усолье-Сибирское, Вихоревку, а также из сел и
				деревень, таких как Урик, Куда, Буреть и поселка Тайтурка.
			</p>
			<p class="NewsPage-sub-title">
				На протяжении двух недель юные музыканты совершенствовали навыки игры на духовых инструментах как в ансамблях,
				так и в оркестре. Большое внимание было уделено развитию музыкального слуха, ритма, музыкальной памяти и
				способности к быстрой реакции на изменения темпоритма. Как отмечают преподаватели смены: эти элементы крайне
				важны для коллективного исполнения, особенно в условиях оркестрового дефиле – исполнений произведений на ходу.
			</p>
			<p class="NewsPage-sub-title">
				– На этой смене мы развивали слаженность и четкость игры детей в оркестре. Практика показала, что не все дети
				имеют опыт игры в оркестрах и больших коллективах. Половина детей, приехавших на смену, ранее не имела такого
				опыта. Эта программа дала возможность ребятам ощутить, что значит быть частью большого музыкального коллектива.
				Они не только научились взаимодействовать друг с другом, но и приобрели уверенность в своих силах. Благодаря
				этой программе, многие из них получили стимул для дальнейшего развития своих музыкальных навыков и укрепили
				желание продолжать заниматься музыкой, – поделился Семен Фролов, преподаватель Школы-интерната музыкантских
				воспитанников г. Иркутска.
			</p>
			<p class="NewsPage-sub-title">
				Особенной частью программы стало обучение искусству оркестрового «дефиле». Ребята учились слаженно двигаться и
				исполнять музыкальные произведения в условиях сложных передвижений и выстраиваний в различные фигуры, что
				требует не только музыкальных навыков, но и высокого уровня физической подготовки и координации.
			</p>
			<p class="NewsPage-sub-title">
				Также для ребят прошли мастер-классы от преподавателей Иркутского музыкального колледжа. Эти занятия дали
				возможность юным музыкантам углубить свои знания и получить новые навыки в различных аспектах музыкального
				искусства. Специалисты колледжа делились с участниками своим опытом, раскрывая тонкости техники исполнения на
				духовых инструментах, а также секреты работы в оркестре. Благодаря этим мастер-классам, ребята смогли не только
				повысить свой уровень игры, но и получить ценные советы от профессионалов, которые помогут им в дальнейшем
				развитии на музыкально-исполнительском пути.
			</p>
			<p class="NewsPage-sub-title">
				Завершением программы стал Гала-концерт, на котором школьники исполнили произведения: Андрея Петрова — марш из
				кинофильма «Жестокий романс», Евгения Никитина — «Русская народная», Василия Беккера — «Радость Победы»,
				гимн-марш «Славься» из оперы М. Глинки «Иван Сусанин», марш «Байкал» и вальс «Волны Байкала» в обработке
				Анатолия Школяра.
			</p>
			<p class="NewsPage-sub-title">
				– В «Персее» мне очень понравилось. Мы не только занимались, но и хорошо отдыхали — было много развлечений и
				игр. Я очень рад, что приехал сюда, ведь эта смена дала мне прочный фундамент для поступления в Суворовское
				училище, – рассказал Марк Шкляр, ученик Школы-интерната музыканских воспитанников г. Иркутска, участник
				профильной смены.
			</p>
			<p class="NewsPage-sub-title">
				По словам Марка, время, проведенное в «Персее», помогло ему не только улучшить свои музыкальные навыки, но и
				обрести уверенность в себе, научиться работать в коллективе и подготовиться к новым вызовам на его пути.
			</p>
			<p class="NewsPage-sub-title">
				– Это первый опыт создания сводного оркестра Иркутской области. В будущем мы планируем создать оркестры в каждом
				муниципалитете региона. Нашей целью является развитие музыкального образования и культуры в области, а также
				предоставление детям и молодежи возможности участвовать в крупных музыкальных проектах. Мы верим, что эта смена
				даст мощный импульс для творческого роста молодых музыкантов и поможет им реализовать свой потенциал на
				региональном и даже всероссийском уровне, – отметил Арсений Володин, директор Иркутской областной детской школы
				искусств.
			</p>
			<p class="NewsPage-sub-title">
				Уже в сентябре участники программы «Оркестровое духовое исполнительство» выступят на фестивале «Байкальские
				духовые ассамблеи», где представят концертную программу, которую подготовили на профильной смене.
			</p>
			<p class="NewsPage-sub-title">
				Полная запись Гала-концерта доступна <a class="NewsPage-link" href="https://disk.yandex.com/d/2y5nSlUfU18izQ">по
					ссылке</a>
			</p>

		</div>
	</div>
</template>
