<template>
	<div class="VsoshPage _cover">
		<h2 class="VsoshPage-title _cover-title">
			Всероссийская олимпиада школьников
		</h2>

		<div class="AllRussian-img-box">
			<img loading="lazy" class="AllRussian-img" src="../assets/img/svg/ВСОШ.svg"
				alt="Всероссийская олимпиада школьников">
		</div>

		<div class="AllRussian-btn-box">
			<a class="AllRussian vsosh2024-temp-btn" @click="$router.push({ name: 'Preparation' })">
				Подготовка к олимпиаде
			</a>
			<a class="AllRussian vsosh2024-temp-btn" @click="$router.push({ name: 'ContactsofOlympiad' })">
				Контакты
			</a>
		</div>

		<h3 class="vsosh2024-sub-title">
			Документы школьного этапа
		</h3>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4.-%D1%80%D0%B5%D0%BA%D0%BE%D0%BC%D0%B5%D0%BD%D0%B4%D0%B0%D1%86%D0%B8%D0%B8-%D0%A8%D0%AD-%D0%92%D1%81%D0%9E%D0%A8.pdf"
					target="_blank">
					Метод. рекомендации ШЭ ВсОШ
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BC%D0%B8%D0%BD.-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%E2%84%9655-1105-%D0%BC%D1%80-%D0%9E%D0%B1-%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8-%D0%92%D0%A1%D0%9E%D0%A8-%D0%B2-2024-2025-%D0%B3.pdf"
					target="_blank">
					Распоряжение мин. образования №55-1105-мр Об организации ВСОШ в 2024-2025 г
				</a>
			</li>
		</ul>

		<h3 class="vsosh2024-sub-title">
			Документы муниципального этапа
		</h3>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4.-%D1%80%D0%B5%D0%BA%D0%BE%D0%BC%D0%B5%D0%BD%D0%B4%D0%B0%D1%86%D0%B8%D0%B8-%D0%9C%D0%AD-%D0%92%D1%81%D0%9E%D0%A8.pdf"
					target="_blank">
					Метод. рекомендации МЭ ВсОШ
				</a>
			</li>
		</ul>

		<h2 class="vsosh2024-title _cover-title">
			ШКОЛЬНЫЙ ЭТАП (сентябрь-октябрь)
		</h2>
		<h3 class="vsosh2024-sub-title">
			Приглашаются школьники:
		</h3>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">4-11 классов по предметам Математика и Русский язык</li>
			<li class="vsosh2024-items">5-11 классов по предметам Информатика, Иностранный язык (английский, немецкий,
				французский, испанский, китайский), Биология, Экология, География, Астрономия, Литература, История,
				Обществознание, Право, Физическая культура, Технология, Основы безопасности жизнедеятельности</li>
			<li class="vsosh2024-items">7-11 классов по предметам Искусство, Физика, Экономика, Химия</li>
		</ul>

		<h3 class="vsosh2024-sub-title">
			Как участвовать:
		</h3>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">К участию приглашаются все желающие обучающиеся.</li>
			<li class="vsosh2024-items">Участник по своему выбору выполняет олимпиадные задания для класса, программу
				которого он осваивает, или для более старших классов. Если вы учитесь в классе, для которого задания по
				выбранному вами предмету не разрабатываются, вы можете выполнить задания более старшего класса.</li>
			<li class="vsosh2024-items">При прохождении на последующие этапы сохраняется класс участия, выбранный на
				школьном этапе.</li>
			<li class="vsosh2024-items">Участвовать можно в любом количестве предметов.</li>
		</ul>

		<h3 class="vsosh2024-sub-title">
			Как проходит школьный этап:
		</h3>
		<p class="vsosh2024-text">
			По предметам физика, биология, химия, астрономия, математика и информатика формат проведения дистанционный, на
			платформе Сириус.Курсы. Для остальных предметов формат проведения традиционный.
		</p>

		<h2 class="vsosh2024-title _cover-title">
			МУНИЦИПАЛЬНЫЙ ЭТАП (ноябрь-декабрь)
		</h2>
		<h3 class="vsosh2024-sub-title">
			Приглашаются школьники:
		</h3>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">участники школьного этапа текущего учебного года, набравшие необходимое для
				участия баллы, установленные министерством образования Иркутской области;</li>
			<li class="vsosh2024-items">победители и призеры муниципального этапа олимпиады предыдущего учебного года.
			</li>
		</ul>
		<p class="vsosh2024-text">
			Задания муниципального этапа разработаны для 7-11 классов.
		</p>

		<h2 class="vsosh2024-title _cover-title">
			РЕГИОНАЛЬНЫЙ ЭТАП (январь-февраль)
		</h2>
		<h3 class="vsosh2024-sub-title">
			Приглашаются школьники:
		</h3>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">участники муниципального этапа олимпиады текущего учебного года, набравшие
				необходимые для участия баллы, установленные министерством образования Иркутской области;</li>
			<li class="vsosh2024-items">победители и призеры регионального этапа олимпиады предыдущего учебного года.</li>
		</ul>
		<p class="vsosh2024-text">
			Задания регионального этапа разработаны для 9-11 классов.
		</p>

		<h2 class="vsosh2024-title _cover-title">
			ЗАКЛЮЧИТЕЛЬНЫЙ ЭТАП (март-апрель)
		</h2>
		<h3 class="vsosh2024-sub-title">
			Приглашаются школьники:
		</h3>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">участники регионального этапа олимпиады текущего учебного года, набравшие
				необходимые для участия баллы, установленные Министерством Просвещения Российской Федерации;</li>
			<li class="vsosh2024-items">победители и призеры заключительного олимпиады предыдущего учебного года.</li>
		</ul>
		<p class="vsosh2024-text">
			Задания заключительного этапа разработаны для 9-11 классов.
		</p>

		<h2 class="vsosh2024-title _cover-title">
			ЛЬГОТЫ ПОБЕДИТЕЛЯМ И ПРИЗЕРАМ ВСОШ ПРИ ПОСТУПЛЕНИИ В ВУЗЫ
		</h2>
		<ul class="vsosh2024-list">
			<li class="vsosh2024-items">Главная льгота, которую предоставляет победа или призерство на заключительном
				этапе всероссийской олимпиады школьников – это поступление в профильные вузы без экзаменов (БВИ).</li>
			<p class="vsosh2024-text">
				Принимаются результаты с 9 по 11 класс.
			</p>
			<p class="vsosh2024-text">
				Если победитель поступает на непрофильную специальность, по решению вуза ему могут зачесть 100 баллов за ЕГЭ
				по предмету, соответствующему профилю олимпиады (соответствие направления и профиля олимпиады определяет сам
				вуз).
			</p>
			<li class="vsosh2024-items">Для победителей и призеров регионального этапа ВСОШ есть возможность получения
				баллов индивидуальных достижений, дополнительно к сумме баллов ЕГЭ. Порядок учета и перечень индивидуальных
				достижений устанавливается каждым вузом самостоятельно.</li>
		</ul>

		<h2 class="vsosh2024-title _cover-title">
			Документы
		</h2>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BE%D0%BA-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%92%D0%A1%D0%9E%D0%A8-678.pdf"
					target="_blank">
					Порядок проведения ВСОШ 678
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%BE-%D0%B2%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B9-%D0%B2-%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BE%D0%BA-%D0%BE%D1%82-16.08.21.pdf"
					target="_blank">
					Приказ о внесении изменений в Порядок от 16.08.21
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%BE-%D0%B2%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B9-%D0%B2-%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BE%D0%BA-%D0%BE%D1%82-14.02.22.pdf"
					target="_blank">
					Приказ о внесении изменений в Порядок от 14.02.22
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%BE-%D0%B2%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B9-%D0%B2-%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BE%D0%BA-%D0%BE%D1%82-26.01.23.pdf"
					target="_blank">
					Приказ о внесении изменений в Порядок от 26.01.23
				</a>
			</li>
		</ul>
	</div>


	<!-- Архив -->
	<ArchiveVsosh />

</template>

<script>
import ArchiveVsosh from '@/components/ArchiveVsosh.vue';

export default {
	components: {
		ArchiveVsosh,
	}
}

</script>