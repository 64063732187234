<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Математика»
		</h2>
		<p class="EventsPage-date">
			с 10 по 16 сентября 2024
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=185" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/25540-programma-matematika"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Математика» для учащихся 5-6-х классов
			общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			В рамках данной программы учащиеся ознакомятся со многими интересными вопросами математики, выходящими за рамки
			школьной программы. Решение математических задач, связанных с логическим мышлением, закрепит интерес детей к
			познавательной деятельности, будет способствовать развитию мыслительных операций и возможности использования
			математических знаний в различных сферах деятельности человека.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Игнатьеа Валентина Александровна, учитель математики МОУ ИРМО «Хомутовская СОШ №2»
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету и выполнить задание (тестирование) до 30 августа 2024 года на платформе
				Образовательного центра «Персей», системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Для тех участников, которые пройдут конкурсный отбор, необходимо записаться на программу «Математика» на ресурсе
				«Навигатор дополнительного образования Иркутской области».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится с 10 по 16 сентября 2024 года на бесплатной основе в Образовательном центре «Персей»
				(Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			<b>Результаты отбора будут размещены 2 сентября на сайте Образовательного центра «Персей».</b>
		</p>
		<hr>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 3 сентября 2024 г. в 12.30, <a style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1723610475">ссылка на подключение</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Анкудинов Захар Андреевич</li>
			<li class="EventsPage-number">Верзунов Савелий Вадимович</li>
			<li class="EventsPage-number">Головина Екатерина Евгеньевна</li>
			<li class="EventsPage-number">Диженин Федор Михайлович</li>
			<li class="EventsPage-number">Жданов Аркадий Николаевич</li>
			<li class="EventsPage-number">Жукович Екатерина Антоновна</li>
			<li class="EventsPage-number">Иванова Аполлинария Павловна</li>
			<li class="EventsPage-number">Каниболоцкий Максим Артёмович</li>
			<li class="EventsPage-number">Кармадонова Елена Александровна</li>
			<li class="EventsPage-number">Косарчук Александра Владимировна</li>
			<li class="EventsPage-number">Куприянов Матвей Вадимович</li>
			<li class="EventsPage-number">Лапшина Анастасия Ильинична</li>
			<li class="EventsPage-number">Мосина Кристина Дмитриевна</li>
			<li class="EventsPage-number">Мячин Вячеслав Станиславович</li>
			<li class="EventsPage-number">Рыбкин Даниил Евгеньевич</li>
			<li class="EventsPage-number">Уланова Амелия Сахибовна</li>
			<li class="EventsPage-number">Черемисин Даниил Юрьевич</li>
			<li class="EventsPage-number">Щемелева Валентина Алексеевна</li>
			<li class="EventsPage-number">Эм Ксения Олеговна</li>
			<li class="EventsPage-number">Юсупов Кирилл Евгеньевич</li>
		</ol>
		<br>

		<p class="JuniorProfi-sub-title">
			Резерв:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Карасёв Семён Игоревич</li>
			<li class="EventsPage-number">Королёв Михаил Алексеевич</li>
			<li class="EventsPage-number">Кочкин Кирилл Дмитриевич</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://drive.google.com/drive/folders/1qJZGimtZYDP2A5R71wrNDa28MJHDmw43"
			target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/80OGMJ9CerQuMQ" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>