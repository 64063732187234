<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Химия 1.0»
		</h2>
		<p class="EventsPage-date">
			с 1 по 27 марта 2024 года.
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=137" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24562-khimiya10"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Государственное автономное нетиповое учреждение дополнительного образования Иркутской области «Региональный центр
			выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого
			ребёнка» проводит дистанционный курс «Химия. Введение» с 1 марта по 27 марта 2024 года для учащихся 4-7-х классов
			общеобразовательных организаций Иркутской области.
		</p>

		<p class="EventsPage-text">
			Программа построена на идее реализации межпредметных связей химии с другими естественными дисциплинами, введёнными
			в обучение ранее или параллельно с химией, а потому позволяет актуализировать знания полученные на уроках
			природоведения, биологии, географии, физики и других наук о природе. Такая межпредметная интеграция способствует
			формированию единой естественнонаучной картины мира уже на начальном этапе изучения химии.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Томилова Альбина Сергеевна, Старший лаборант Образовательный центр «Персей».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Для прохождения обучения необходи:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 1 марта 2024 года на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 1 марта 2024 года на платформе дистанционного обучения Образовательного
				центра «Персей» системе Moodle.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/lXTxC9jxD1G_fQ" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>