<template>
	<div class="AdditionalGneral _cover">
		<h2 class="AdditionalGneral-title _cover-title">Перечень реализуемых дополнительных общеразвивающих программ 2024
			года</h2>

		<!-- <details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict"></p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items"></li>
				<li class="AdditionalGneral-summary-dict-items"></li>
				<li class="AdditionalGneral-summary-dict-items"></li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Капустина Екатерина Станиславна</p>
			<p class="AdditionalGneral-summary-sub-title">e.kapustina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link" href="" target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details> -->


		<!-- Искусство -->

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Беседы о хореографическом искусстве
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику еженедельных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно-заочная – курсы на еженедельной основе</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км. Автодороги Ангарск-Тальяны.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций, обучающиеся по дополнительным общеразвивающим программам в возрасте от 10 до 15 лет. Обучение по
				программе предполагает наличие определенных знаний:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">представление о линейной и воздушной перспективе;</li>
				<li class="AdditionalGneral-summary-dict-items">первоначальные навыки передачи солнечного освещения, изменения
					локального цвета;</li>
				<li class="AdditionalGneral-summary-dict-items">последовательность ведения зарисовки, этюда.</li>
			</ul>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">добиваться точности и завершенности в работе над этюдом,
					зарисовкой;</li>
				<li class="AdditionalGneral-summary-dict-items">проявление индивидуальности в использовании технических приемов
					и выразительных средств в изображении зарисовки;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание трудолюбия и самодисциплины учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно оценивать правильность изображения</li>
				<li class="AdditionalGneral-summary-dict-items">умение планировать работу и находить ошибки в работе;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно анализировать и применять полученные
					знания в любых видах изобразительной деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проявлять интерес к пленэру;</li>
				<li class="AdditionalGneral-summary-dict-items">усвоить основные этапы ведения работы на пленэре;</li>
				<li class="AdditionalGneral-summary-dict-items">научиться делать быстрые и длительные зарисовки пейзажа и
					передавать характерность в быстрых набросочных изображениях растений, животных, насекомых.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%91%D0%B5%D1%81%D0%B5%D0%B4%D1%8B-%D0%BE-%D1%85%D0%BE%D1%80%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%BC-%D0%B8%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%B5_1-%D0%BC%D0%BE%D0%B4%D1%83%D0%BB%D1%8C.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Наследие народного танца
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику еженедельных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно– курсы на еженедельной основе</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км. Автодороги Ангарск-Тальяны.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся организаций
				дополнительного образования (ДШИ, самодеятельные танцевальные коллективы) в возрасте от 10 до 17 лет.
				Предварительная подготовка обучающихся заключается в базовом владении основами народного танца:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">постановка корпуса, рук, ног;</li>
				<li class="AdditionalGneral-summary-dict-items">знание основных элементов народного танца.</li>
			</ul>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение исполнять основные движения женского и мужского танца
					Воронежской области,</li>
				<li class="AdditionalGneral-summary-dict-items">умение исполнять комбинации движений женского и мужского танца
					Воронежской области,</li>
				<li class="AdditionalGneral-summary-dict-items">умение двигаться в парах;</li>
				<li class="AdditionalGneral-summary-dict-items">исполнение композиций бытовых танцев Воронежской области;</li>
				<li class="AdditionalGneral-summary-dict-items">исполнение пляски Воронежской области «А я по лугу!»</li>
				<li class="AdditionalGneral-summary-dict-items">знать основные названия движений и танцевальных приёмов.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">совершенствование владения собственным телом (физическая
					культура, координация);</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">коммуникативные навыки работы в большом детском разновозрастном
					коллективе;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь выражать свою индивидуальность посредством движения и
					актёрского мастерства.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9D%D0%B0%D1%81%D0%BB%D0%B5%D0%B4%D0%B8%D0%B5-%D0%BD%D0%B0%D1%80%D0%BE%D0%B4%D0%BD%D0%BE%D0%B3%D0%BE-%D1%82%D0%B0%D0%BD%D1%86%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Танцевальная импровизация
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику еженедельных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно– курсы на еженедельной основе</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км. Автодороги Ангарск-Тальяны.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся общеобразовательных
				организаций и организаций дополнительного образования художественной направленности. Предварительная подготовка
				учащихся должна заключаться в сформированных двигательных, координационных и музыкальных навыках, полученных в
				хореографических коллективах.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Практические навыки:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">навыки работы в группе;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки самостоятельной демонстрации различных танцевальных
					элементов и комбинаций;</li>
				<li class="AdditionalGneral-summary-dict-items">координации музыкального слуха и движения во время исполнения
					танцев.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знания:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">ритмической структуры различных музыкальных произведений;</li>
				<li class="AdditionalGneral-summary-dict-items">танцевальной лексики и рисунков танца;</li>
				<li class="AdditionalGneral-summary-dict-items">о импровизации в танце;</li>
				<li class="AdditionalGneral-summary-dict-items">о сочинении танцев.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Умения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">демонстрировать выразительный и технически грамотный показ
					танцевальных движений, этюдов и танцев;</li>
				<li class="AdditionalGneral-summary-dict-items">добиваться законченности, слаженности, гармоничности и
					художественной привлекательности музыкального произведения, представляемого на сцене.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A2%D0%B0%D0%BD%D1%86%D0%B5%D0%B2%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D0%98%D0%BC%D0%BF%D1%80%D0%BE%D0%B2%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D1%8F.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Академический рисунок
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся или выпускники
				организаций дополнительного образования по направлению изобразительное искусство от 15 до 17 лет.</p>
			<p class="AdditionalGneral-summary-sub-title">Обучение по программе предполагает наличие определенных знаний:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понятия «пропорции», «симметрия»;</li>
				<li class="AdditionalGneral-summary-dict-items">законы композиции, принципы размещения изображения на листе;
				</li>
				<li class="AdditionalGneral-summary-dict-items">принципы конструктивного построения формы;</li>
				<li class="AdditionalGneral-summary-dict-items">законы линейной и воздушной перспективы;</li>
				<li class="AdditionalGneral-summary-dict-items">способы передачи пространства и моделирование формы тоном;</li>
				<li class="AdditionalGneral-summary-dict-items">основные этапы работы над длительной постановкой;</li>
			</ul>
			<p class="AdditionalGneral-summary-sub-title">наличие определенных умений:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">найти грамотное композиционное решение;</li>
				<li class="AdditionalGneral-summary-dict-items">сознательно пользоваться приемами линейной и воздушной
					перспективы;</li>
				<li class="AdditionalGneral-summary-dict-items">выполнить конструктивное построение простой формы;</li>
				<li class="AdditionalGneral-summary-dict-items">моделировать форму предметов тоном;</li>
				<li class="AdditionalGneral-summary-dict-items">передавать пространство средствами штриха и светотени;</li>
				<li class="AdditionalGneral-summary-dict-items">«ставить» предметы на плоскость;</li>
				<li class="AdditionalGneral-summary-dict-items">владеть линией, штрихом, иметь навыки линейного рисунка;</li>
				<li class="AdditionalGneral-summary-dict-items">выполнить наброски фигуры и головы живой модели;</li>
				<li class="AdditionalGneral-summary-dict-items">нарисовать по памяти несложные предметы в разных положениях.
				</li>
			</ul>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">добиваться точности и завершенности в работе над натурным
					рисунком;</li>
				<li class="AdditionalGneral-summary-dict-items">проявление индивидуальности в использовании технических приемов
					и выразительных средств в изображении человека;</li>
				<li class="AdditionalGneral-summary-dict-items"> воспитание трудолюбия и самодисциплины учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items"> умение самостоятельно оценивать правильность изображения,
					планировать работу и находить ошибки в работе;</li>
				<li class="AdditionalGneral-summary-dict-items"> умение самостоятельно анализировать и применять полученные
					знания в любых видах изобразительной деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проявлять интерес к академическому рисунку;</li>
				<li class="AdditionalGneral-summary-dict-items">усвоить основные этапы построения гипсового слепка головы
					человека;</li>
				<li class="AdditionalGneral-summary-dict-items">научиться делать быстрые и длительные зарисовки фигуры человека
					и передавать характерность в быстрых набросочных изображениях;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь создавать сложные пространственные композиции из простых
					геометрических тел в конструктивно-пространственном изображении по воображению.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%90%D0%BA%D0%B0%D0%B4%D0%B5%D0%BC%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9-%D1%80%D0%B8%D1%81%D1%83%D0%BD%D0%BE%D0%BA.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Лаборатория творчества Street dance
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся общеобразовательных
				организаций и организаций дополнительного образования в возрасте от 10 до 17 лет. Предварительная подготовка
				учащихся должна заключаться в сформированных двигательных, координационных и музыкальных навыках, полученных в
				хореографических коллективах по направлению Street Dance.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Практические навыки:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">навыки работы в коллективе, навыки коммуникативного общения;
				</li>
				<li class="AdditionalGneral-summary-dict-items">навыки импровизации в любой ритмической, музыкальной среде;</li>
				<li class="AdditionalGneral-summary-dict-items">координация и согласованность движений, синхронизация с темпом
					музыки во время исполнения танцевальных стилей street dance.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знания:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные направления и исторические особенности танцевальной
					культуры street dance;</li>
				<li class="AdditionalGneral-summary-dict-items">танцевальная лексика и характерные особенности исполнения
					направлений и стилей street dance;</li>
				<li class="AdditionalGneral-summary-dict-items">основные теоретические и практические знания по направлениям
					street dance культуры и её танцевальных направлений.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Умения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">демонстрировать выразительный и технически грамотный показ
					танцевальных стилей street dance;</li>
				<li class="AdditionalGneral-summary-dict-items">добиваться законченности, слаженности, гармоничности и
					художественной привлекательности танцевального номера, представляемого на сцене;</li>
				<li class="AdditionalGneral-summary-dict-items">отличать стили музыки различных направлений танцев;</li>
				<li class="AdditionalGneral-summary-dict-items">определять ритмическую структуру и характер музыкальной
					композиции, импровизировать под заданную музыкальную композицию.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9B%D0%B0%D0%B1%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%B8%D1%8F-%D1%82%D0%B2%D0%BE%D1%80%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Лаборатория творчества техники современного танца
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся общеобразовательных
				организаций и организаций дополнительного образования в возрасте от 10 до 15 лет, занимающихся в
				хореографических коллективах, и владеющих навыками в области современной хореографии.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Практические навыки:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">принципы взаимодействия музыкальных и хореографических средств
					выразительности;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки работы в группе;</li>
				<li class="AdditionalGneral-summary-dict-items">демонстрация танцевальных элементов различных направлений
					современной хореографии;</li>
				<li class="AdditionalGneral-summary-dict-items">координация музыкального слуха и движения во время исполнения
					танцев.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знания:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">тенденций развития современного танца, стилей современной
					хореографии;</li>
				<li class="AdditionalGneral-summary-dict-items">специальной терминологии;</li>
				<li class="AdditionalGneral-summary-dict-items">основ техники безопасности на учебных занятиях и концертной
					площадке.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Умения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">отражать в танце особенности исполнительской манеры разных
					стилей современной хореографии;</li>
				<li class="AdditionalGneral-summary-dict-items">добиваться законченности, слаженности, гармоничности и
					художественной привлекательности музыкального-хореографического произведения, представляемого на сцене.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9B%D0%B0%D0%B1%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%B8%D1%8F-%D1%82%D0%B2%D0%BE%D1%80%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B0-%D1%82%D0%B5%D1%85%D0%BD%D0%B8%D0%BA%D0%B8-%D1%81%D0%BE%D0%B2%D1%80-%D1%82%D0%B0%D0%BD%D1%86%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				История развития русского балета 17-18 век
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Заочная, с применением дистанционных образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">г. Иркутск, проезд Угольный, д. 68/1</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">В образовательной программе могут принять участие обучающиеся в
				возрасте 10-17 лет образовательных организаций Иркутской области и участники творческих хореографических
				коллективов. Освоение программы требует от обучающихся знакомства с системой начальной ступени российского
				хореографического образования.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Предметные: </p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знание основных этапов развития русского балета;</li>
				<li class="AdditionalGneral-summary-dict-items">знание видов и жанров танца и истории их формирования;</li>
				<li class="AdditionalGneral-summary-dict-items">знание видов классического танца и истории их формирования;
				</li>
				<li class="AdditionalGneral-summary-dict-items">хореографической терминологии;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">самостоятельно просматривать хореографические постановки;</li>
				<li class="AdditionalGneral-summary-dict-items">на основе просмотренного материала анализировать танец; </li>
				<li class="AdditionalGneral-summary-dict-items">первичных навыков в области теоретического и исторического
					анализа исполняемых танцев</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">уметь позитивно оценивать свои хореографические способности;
				</li>
				<li class="AdditionalGneral-summary-dict-items">добиваться законченности в выполнении заданий.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/2.-%D0%94%D0%9E%D0%A2-%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F-%D1%80%D0%B0%D0%B7%D0%B2%D0%B8%D1%82%D0%B8%D1%8F-%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%BE%D0%B3%D0%BE-%D0%B1%D0%B0%D0%BB%D0%B5%D1%82%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Реализм в русском балете первой половины 19 столетия
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Заочная, с применением дистанционных образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">г. Иркутск, проезд Угольный, д. 68/1</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">В образовательной программе могут принять участие учащиеся в
				возрасте 10-17 лет образовательных организаций Иркутской области и участники творческих хореографических
				коллективов.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Практические навыки:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">навыки работы с учебным материалом;</li>
				<li class="AdditionalGneral-summary-dict-items">первичные навыки в области теоретического и исторического
					анализа исполняемых танцев;</li>
				<li class="AdditionalGneral-summary-dict-items">комплекс навыков, позволяющих в дальнейшем осваивать
					профессиональные образовательные программы в области хореографического искусства;</li>
				<li class="AdditionalGneral-summary-dict-items">грамотное выполнение предложенных преподавателем заданий.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные этапы развития русского танца в России 19 столетия;
				</li>
				<li class="AdditionalGneral-summary-dict-items">виды и жанры танца и истории их формирования;</li>
				<li class="AdditionalGneral-summary-dict-items">хореографическую терминологию;</li>
				<li class="AdditionalGneral-summary-dict-items">образцы классического наследия балетного репертуара.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">анализировать произведение хореографического искусства с
					учетом времени его создания, стилистических особенностей, содержания, взаимодействия различных видов искусств,
					художественных средств создания хореографических образов;</li>
				<li class="AdditionalGneral-summary-dict-items">позитивно оценивать свои хореографические способности;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельно просматривать хореографические постановки;</li>
				<li class="AdditionalGneral-summary-dict-items">на основе просмотренного материала анализировать танец; </li>
				<li class="AdditionalGneral-summary-dict-items">добиваться законченности в выполнении заданий.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/1.-%D0%94%D0%9E%D0%9F-%D0%A0%D0%B5%D0%B0%D0%BB%D0%B8%D0%B7%D0%BC-%D0%B2-%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%BE%D0%BC-%D0%B1%D0%B0%D0%BB%D0%B5%D1%82%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Театральная мастрерская
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику еженедельных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно– курсы на еженедельной основе</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, Ангарский район, 8,351 км. Автодороги
				Ангарск-Тальяны.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Практические навыки:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">навыки работы в группе;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки самостоятельной демонстрации различных театральных
					элементов, этюдов и постановок;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки выразительного чтения, правильного дыхания и дикции;
				</li>
				<li class="AdditionalGneral-summary-dict-items">навыки при работе над внешним обликом героя – подбор грима,
					костюмов, прически;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знания:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знать театральную терминологию и основы сценографии;</li>
				<li class="AdditionalGneral-summary-dict-items">знать виды и жанры театрального искусства;</li>
				<li class="AdditionalGneral-summary-dict-items">знать нормы поведения на сцене и в зале.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Умения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение пользоваться средствами выразительности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение анализировать, сравнивать, сопоставлять;</li>
				<li class="AdditionalGneral-summary-dict-items">умение импровизировать во время репетиций и игры на сцене;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение строить диалог с партнером на заданную тему;</li>
				<li class="AdditionalGneral-summary-dict-items">умение управлять своими эмоциями, эффективно взаимодействовать
					со взрослыми и сверстниками, владеть культурой общения и поведения на сцене</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/1.-%D0%94%D0%9E%D0%9F-%D0%A2%D0%B5%D0%B0%D1%82%D1%80%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D0%BC%D0%B0%D1%81%D1%82%D0%B5%D1%80%D1%81%D0%BA%D0%B0%D1%8F.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Оркестровое струнно-смычковое исполнительство
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, Ангарский район, 8,351 км автодороги
				Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Программа ориентирована на школьников от 10 до 17 лет. Обучение по
				программе требует наличие основных музыкальных способностей: слуха, ритма, памяти, исполнительских навыков игры
				на струнных инструментах (скрипка, альт, виолончель, контрабас), а также начальных навыков игры в ансамбле.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Практические навыки:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">иметь навык самостоятельной настройки инструмента;</li>
				<li class="AdditionalGneral-summary-dict-items">чтение нотного материала с листа;</li>
				<li class="AdditionalGneral-summary-dict-items">владение различными приемами игры на инструменте;</li>
				<li class="AdditionalGneral-summary-dict-items">исполнение произведения в стиле и жанре, в соответствии с
					требованием композитора;</li>
				<li class="AdditionalGneral-summary-dict-items">понимание жеста дирижера.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знания:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знание основных исторических сведений о струнном оркестре;
				</li>
				<li class="AdditionalGneral-summary-dict-items">знание состава струнного оркестра, виды инструментов, группы
					оркестра;</li>
				<li class="AdditionalGneral-summary-dict-items">правила по уходу, хранению и эксплуатации инструмента: </li>
				<li class="AdditionalGneral-summary-dict-items">основных обозначений и музыкальных терминов;</li>
				<li class="AdditionalGneral-summary-dict-items">состава партитуры, роли и типа своей партии;</li>
				<li class="AdditionalGneral-summary-dict-items">основных средств музыкальной выразительности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Умения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">исполнять музыкальные произведения на достаточном художественном
					уровне в соответствии с их стилевыми особенностями;</li>
				<li class="AdditionalGneral-summary-dict-items">слушать и слышать всю партитуру оркестра;</li>
				<li class="AdditionalGneral-summary-dict-items">быстро переходить из одного эмоционального состояния в другое
					при смене жанра произведений;</li>
				<li class="AdditionalGneral-summary-dict-items">тонко понимать интонацию: мотив, фразу в строении музыкальной
					формы;</li>
				<li class="AdditionalGneral-summary-dict-items">контролировать синхронность совместной игры;</li>
				<li class="AdditionalGneral-summary-dict-items">умение понимать жест дирижёра.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/1.-%D0%94%D0%9E%D0%9F-%D0%9E%D1%80%D0%BA%D0%B5%D1%81%D1%82%D1%80%D0%BE%D0%B2%D0%BE%D0%B5-%D0%A1%D1%82%D1%80%D1%83%D0%BD%D0%BD%D0%BE-%D1%81%D0%BC%D1%8B%D1%87%D0%BA%D0%BE%D0%B2%D0%BE%D0%B5-%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Art перфоманс
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Программа ориентирована на учащихся художественных школ и
				общеобразовательных организаций в возрасте от 12 до 17 лет. Обучение по программе требует предварительной
				подготовки, а именно, знания основ построения рисунка.</p>
			<p class="AdditionalGneral-summary-sub-title">Обучение по программе предполагает:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">готовность к занятиям декоративно-прикладным искусством;</li>
				<li class="AdditionalGneral-summary-dict-items">наличие навыков и знаний в построении рисунка;</li>
				<li class="AdditionalGneral-summary-dict-items">наличие стремления обучаться навыкам в области
					театрально-сценической деятельности и актерского мастерства.</li>
			</ul>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знать особенности стилей витражей;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь наглядно представлять собственные идеи;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь анализировать объем, пропорции, форму;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь применять технические приемы и навыки в работе с
					калькой;</li>
				<li class="AdditionalGneral-summary-dict-items">освоить теоретические понятия и практические навыки в
					следующих образовательных модулях: «Актёрское мастерство», «Вокал», «Сценический костюм», «Сценография»;</li>
				<li class="AdditionalGneral-summary-dict-items">знать основы сценического поведения.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">получить навыки работы в группах;</li>
				<li class="AdditionalGneral-summary-dict-items">развить интерес к специальным знаниям по теории и истории
					театрального искусства;</li>
				<li class="AdditionalGneral-summary-dict-items">проявить творческую активность через индивидуальное раскрытие
					способностей каждого ребёнка; </li>
				<li class="AdditionalGneral-summary-dict-items">развить эстетическое восприятие, художественный вкус, творческое
					воображение.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение добиваться законченности, аккуратности и художественной
					привлекательности работы;</li>
				<li class="AdditionalGneral-summary-dict-items">умение выражать свою индивидуальную и оригинальную точку зрения;
				</li>
				<li class="AdditionalGneral-summary-dict-items">воспитывать уважительное отношение между членами коллектива в
					совместной </li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/1.-%D0%94%D0%9E%D0%9F-Art-%D0%BF%D0%B5%D1%80%D1%84%D0%BE%D0%BC%D0%B0%D0%BD%D1%81.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Классический танец
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, Ангарский район, 8,351 км автодороги
				Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">К освоению программы допускаются обучающиеся
					общеобразовательных организаций и организаций дополнительного образования от 10 до 12 лет.</li>
				<li class="AdditionalGneral-summary-dict-items">Обучение по программе предполагает:</li>
				<li class="AdditionalGneral-summary-dict-items">готовность к занятиям суставно-связочного аппарата
					обучающегося (растяжка, постановка корпуса, развитая координация);</li>
				<li class="AdditionalGneral-summary-dict-items">наличие навыков и знаний в области ОФП и классического танца;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение ориентироваться в классе и на сцене;</li>
				<li class="AdditionalGneral-summary-dict-items">наличие практического сценического опыта выступлений, а именно
					знания основ построения рисунка, танцевальную терминологию основных движений классического танца, и правильное
					их исполнение.</li>
			</ul>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">По окончании обучения учащиеся будут знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">балетную терминологию и технику классического танца;</li>
				<li class="AdditionalGneral-summary-dict-items">правила безопасности и правила поведения в хореографическом
					зале;</li>
				<li class="AdditionalGneral-summary-dict-items">особенности постановки корпуса, ног, рук, головы;</li>
				<li class="AdditionalGneral-summary-dict-items">методику исполнения основных элементов и комбинаций
					классического танца;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict"></p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">исполнять элементы и основные комбинации классического танца;
				</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельно оценивать правильность исполнения движений,
					слышать музыку, работать в коллективе;</li>
				<li class="AdditionalGneral-summary-dict-items">анализировать исполнение движений;</li>
				<li class="AdditionalGneral-summary-dict-items">свободно координировать движение рук, ног, головы, корпуса;
				</li>
				<li class="AdditionalGneral-summary-dict-items">преодолевать технические трудности при тренаже классического
					танца;</li>
				<li class="AdditionalGneral-summary-dict-items">применять полученные знания по классическому танцу при
					создании хореографических этюдов, композиций;</li>
				<li class="AdditionalGneral-summary-dict-items">умения соблюдать требования к безопасности при выполнении
					танцевальных движений;</li>
				<li class="AdditionalGneral-summary-dict-items">выполнять комплексы специальных хореографических упражнений,
					способствующих развитию профессионально необходимых физических качеств.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">a.syrovatskaya@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/1.-%D0%94%D0%9E%D0%9F-%D0%9A%D0%BB%D0%B0%D1%81%D1%81%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D0%B9-%D1%82%D0%B0%D0%BD%D0%B5%D1%86.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<!-- Наука -->
		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Гибкие навыки в проектной деятельности
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Заочная, с применением дистанционных технологий.</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Учащиеся общеобразовательных организаций 7-11 классов, проявляющие
				интерес к научно-исследовательской деятельности.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Учащиеся будут:</p>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные этапы организации проектной деятельности (выбор темы,
					сбор информации, выбор проекта, работа над ним, презентация);</li>
				<li class="AdditionalGneral-summary-dict-items">понятия цели, объекта и гипотезы исследования;</li>
				<li class="AdditionalGneral-summary-dict-items">основные источники информации;</li>
				<li class="AdditionalGneral-summary-dict-items">правила оформления списка использованной литературы;</li>
				<li class="AdditionalGneral-summary-dict-items">правила классификации и сравнения, способы познания окружающего
					мира (наблюдения, эксперименты);</li>
				<li class="AdditionalGneral-summary-dict-items">источники информации (книга, старшие товарищи и родственники,
					видео курсы, ресурсы Интернета);</li>
				<li class="AdditionalGneral-summary-dict-items">правила сохранения информации, приемы запоминания.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">выделять объект исследования;</li>
				<li class="AdditionalGneral-summary-dict-items">разделять учебно-исследовательскую деятельность на этапы;</li>
				<li class="AdditionalGneral-summary-dict-items">выдвигать гипотезы и осуществлять их проверку;</li>
				<li class="AdditionalGneral-summary-dict-items">анализировать, сравнивать, классифицировать, обобщать, выделять
					главное, формулировать выводы, выявлять закономерности,</li>
				<li class="AdditionalGneral-summary-dict-items">работать в группе;</li>
				<li class="AdditionalGneral-summary-dict-items">работать с источниками информации, представлять информацию в
					различных видах, преобразовывать из одного вида в другой;</li>
				<li class="AdditionalGneral-summary-dict-items">пользоваться словарями, энциклопедиями и другими учебными
					пособиями;</li>
				<li class="AdditionalGneral-summary-dict-items">планировать и организовывать исследовательскую деятельность,
					представлять результаты своей деятельности в различных видах;</li>
				<li class="AdditionalGneral-summary-dict-items">работать с текстовой информацией на компьютере, осуществлять
					операции с файлами и каталогами.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">уважение к своему народу, гордости за свой край, свою Родину,
					уважение государственных символов (герб, флаг, гимн);</li>
				<li class="AdditionalGneral-summary-dict-items"> сформированность мировоззрения, соответствующего современному
					уровню развития науки и общественной практики, основанного на диалоге культур, а также различных форм
					общественного сознания, осознание своего места в поликультурном мире;</li>
				<li class="AdditionalGneral-summary-dict-items"> сформированность основ саморазвития и самовоспитания в
					соответствии с общечеловеческими ценностями и идеалами гражданского общества; готовность и способность к
					самостоятельной, творческой и ответственной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items"> толерантное сознание и поведение в поликультурном мире,
					готовность и способность вести диалог с другими людьми, достигать в нём взаимопонимания, находить общие цели и
					сотрудничать для их достижения;</li>
				<li class="AdditionalGneral-summary-dict-items"> навыки сотрудничества со сверстниками, детьми младшего
					возраста, взрослыми в образовательной, общественно полезной, учебно-исследовательской, проектной и других
					видах деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items"> готовность и способность к образованию, в том числе
					самообразованию, на протяжении всей жизни; сознательное отношение к непрерывному образованию как условию
					успешной профессиональной и общественной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items"> эстетическое отношение к миру, включая эстетику быта, научного
					и технического творчества, спорта, общественных отношений;</li>
				<li class="AdditionalGneral-summary-dict-items"> принятие и реализация ценностей здорового и безопасного образа
					жизни, потребности в физическом самосовершенствовании, занятиях спортивно-оздоровительной деятельностью,
					неприятие вредных привычек: курения, употребления алкоголя, наркотиков;</li>
				<li class="AdditionalGneral-summary-dict-items"> бережное, ответственное и компетентное отношение к физическому
					и психологическому здоровью, как собственному, так и других людей, умение оказывать первую помощь;</li>
				<li class="AdditionalGneral-summary-dict-items"> осознанный выбор будущей профессии и возможностей реализации
					собственных жизненных планов;</li>
				<li class="AdditionalGneral-summary-dict-items"> отношение к профессиональной деятельности как возможности
					участия в решении личных, общественных проблем.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели деятельности и составлять
					планы деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно осуществлять, контролировать и
					корректировать деятельность;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать все возможные ресурсы для достижения поставленных
					целей и реализации планов деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">выбирать успешные стратегии в различных ситуациях;</li>
				<li class="AdditionalGneral-summary-dict-items">умение продуктивно общаться и взаимодействовать в процессе
					совместной деятельности, учитывать позиции других участников деятельности, эффективно разрешать конфликты;
				</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной, учебно-исследовательской и
					проектной деятельности, навыками разрешения проблем;</li>
				<li class="AdditionalGneral-summary-dict-items">способность и готовность к самостоятельному поиску методов
					решения практических задач, применению различных методов познания;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к самостоятельной
					информационно-познавательной деятельности, включая умение ориентироваться в различных источниках информации,
					критически оценивать и интерпретировать информацию, получаемую из различных источников;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать средства информационных и коммуникационных
					технологий (далее - ИКТ) в решении когнитивных, коммуникативных и организационных задач с соблюдением
					требований эргономики, техники безопасности, гигиены, ресурсосбережения, правовых и этических норм, норм
					информационной безопасности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение определять назначение и функции различных социальных
					институтов;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно оценивать и принимать решения,
					определяющие стратегию поведения, с учётом гражданских и нравственных ценностей;</li>
				<li class="AdditionalGneral-summary-dict-items">владение языковыми средствами - умение ясно, логично и точно
					излагать свою точку зрения, использовать адекватные языковые средства;</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной рефлексии как осознания
					совершаемых действий и мыслительных процессов, их результатов и оснований, границ своего знания и незнания,
					новых познавательных задач и средств их достижения.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты обучения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированность навыков коммуникативной,
					учебно-исследовательской деятельности, критического мышления;</li>
				<li class="AdditionalGneral-summary-dict-items">способность к инновационной, аналитической, творческой,
					интеллектуальной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность навыков проектной деятельности, а также
					самостоятельного применения приобретенных знаний и способов действий при решении различных задач, используя
					знания нескольких учебных предметов и/или предметных областей;</li>
				<li class="AdditionalGneral-summary-dict-items">способность постановки цели и формулирования гипотезы
					исследования, планирования работы, отбора и интерпретации необходимой информации, структурирования и
					аргументации результатов исследования на основе собранных данных, презентация результатов;</li>
				<li class="AdditionalGneral-summary-dict-items">владение умением излагать результаты проектной работы на
					вебинарах, семинарах, конференциях и т.п.;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность понятий проект, проектирование;</li>
				<li class="AdditionalGneral-summary-dict-items">владение знанием этапов проектной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">владение методами поиска и анализа научной информации.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%93%D0%B8%D0%B1%D0%BA%D0%B8%D0%B5-%D0%BD%D0%B0%D0%B2%D1%8B%D0%BA%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Мир профпроектов
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно-заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам и программам среднего профессионального образования в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">иметь представление о многообразии мира профессий;</li>
				<li class="AdditionalGneral-summary-dict-items">владеть начальными навыками самоанализа, формулировать свои
					интересы, потребности, выделять свои сильные и слабые стороны;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать логические значения, операции и выражения с
					ними;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыков объёмного, пространственного, логического
					мышления и конструкторские способности;</li>
				<li class="AdditionalGneral-summary-dict-items">владение формами учебно-исследовательской, проектной, игровой
					деятельности;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проявлять коммуникативные навыки;</li>
				<li class="AdditionalGneral-summary-dict-items">владеть начальными навыками проектной деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">иметь представление о ценности труда;</li>
				<li class="AdditionalGneral-summary-dict-items">уважительно относиться к представителям различных профессий.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%BC%D0%B8%D1%80-%D0%BF%D1%80%D0%BE%D1%84%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%BE%D0%B2.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Мир экспериментов
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Мобильный Персей.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам 5-7 классов.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Обучающиеся будут знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">правила техники безопасности при проведении опытов и
					экспериментов;</li>
				<li class="AdditionalGneral-summary-dict-items">названия и правила пользования приборов - помощников при
					проведении опытов;</li>
				<li class="AdditionalGneral-summary-dict-items">способы познания окружающего мира (наблюдения, эксперименты);
				</li>
				<li class="AdditionalGneral-summary-dict-items">основные физические, химические, технические, экологические
					понятия;</li>
				<li class="AdditionalGneral-summary-dict-items">свойства и явления природы.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Обучающиеся будут уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">применять на практике изученный теоретический материал и
					применять его при проведении опытов и экспериментов с объектами живой и неживой природы;</li>
				<li class="AdditionalGneral-summary-dict-items">пользоваться оборудованием для проведения опытов и
					экспериментов;</li>
				<li class="AdditionalGneral-summary-dict-items">вести наблюдения за окружающей природой;</li>
				<li class="AdditionalGneral-summary-dict-items">планировать и организовывать исследовательскую деятельность;
				</li>
				<li class="AdditionalGneral-summary-dict-items">выделять объект исследования, разделять учебно-исследовательскую
					деятельность на этапы;</li>
				<li class="AdditionalGneral-summary-dict-items">работать в группе.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование познавательных интересов, интеллектуальных и
					творческих способностей обучающихся;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование целостного мировоззрения, соответствующего
					современному уровню развития науки и технологий;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельность в приобретении новых знаний и практических
					умений; готовность к выбору жизненного пути в соответствии с собственными интересами и возможностями;</li>
				<li class="AdditionalGneral-summary-dict-items">проявление технико-технологического мышления при организации
					своей деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">мотивация образовательной деятельности обучающихся на основе
					личностно ориентированного подхода;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование ценностных отношений друг к другу, преподавателю,
					авторам открытий и изобретений, результатам обучения; формирование коммуникативной компетентности в процессе
					проектной, учебно-исследовательской, игровой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">овладение составляющими исследовательской и проектной
					деятельности (умение видеть проблему, ставить вопросы, выдвигать гипотезы, давать определения понятиям,
					классифицировать, наблюдать, проводить эксперименты, делать выводы и заключения, структурировать материал,
					объяснять, доказывать, защищать свои идеи; умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учёбе и познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение основами самоконтроля, самооценки, принятия решений и
					осуществления осознанного выбора в учебной и познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение создавать, применять и преобразовывать знаки и символы,
					модели, схемы для решения учебных и познавательных задач; развитие монологической и диалогической речи, умения
					выражать свои мысли, способности выслушивать собеседника, понимать его точку зрения, признавать право другого
					человека на иное мнение;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование умений работать в группе с выполнением различных
					социальных ролей, представлять и отстаивать свои взгляды и убеждения, вести дискуссию;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетентности в области использования
					информационно-коммуникационных технологий.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты обучения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение использовать термины области «Робототехника»;</li>
				<li class="AdditionalGneral-summary-dict-items">умение конструировать механизмы для преобразования движения;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать логические значения, операции и выражения с
					ними;</li>
				<li class="AdditionalGneral-summary-dict-items">умение формально выполнять алгоритмы, описанные с использованием
					конструкций ветвления (условные операторы) и повторения (циклы), вспомогательных алгоритмов, простых и
					табличных величин; умение создавать и выполнять программы для решения несложных алгоритмических задач в
					выбранной среде программирования;</li>
				<li class="AdditionalGneral-summary-dict-items">владение формами учебно-исследовательской, проектной, игровой
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">планирование технологического процесса в процессе создания
					роботов и робототехнических сформированность понимания общих экологических законов, особенностей влияния
					человеческой деятельности на состояние природной и социальной среды;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение опыта эколого-направленной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность экологического мышления и способности учитывать
					и оценивать экологические последствия в разных сферах деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">владение базовыми экологическими понятиями, владение
					способностями применять экологические знания в жизненных ситуациях, связанных с выполнением типичных
					социальных ролей;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность личностного отношения к экологическим
					ценностям, моральной ответственности за экологические последствия своих действий в окружающей среде;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование у обучающихся общего представления о
					феноменологических знаниях о природе важнейших физических явлений окружающего мира и качественное объяснение
					причины их возникновения;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие элементов теоретического мышления на основе
					формирования умений устанавливать факты, выделять главное в изучаемом явлении, выдвигать гипотезы,
					формулировать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">умение определять соединения неизвестного состава на основании
					их свойств и условий протекания реакций;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать физические свойства систем для решения
					химических задач.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9C%D0%B8%D1%80-%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D0%B8%D0%BC%D0%B5%D0%BD%D1%82%D0%BE%D0%B2.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Опыты и эксперименты
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Мобильный Персей.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам 5-7 классов.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Обучающиеся будут знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">правила техники безопасности при проведении опытов и
					экспериментов;</li>
				<li class="AdditionalGneral-summary-dict-items">названия и правила пользования приборов - помощников при
					проведении опытов;</li>
				<li class="AdditionalGneral-summary-dict-items">способы познания окружающего мира (наблюдения, эксперименты);
				</li>
				<li class="AdditionalGneral-summary-dict-items">основные физические, химические, технические, экологические
					понятия;</li>
				<li class="AdditionalGneral-summary-dict-items">свойства и явления природы.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Обучающиеся будут уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">применять на практике изученный теоретический материал и
					применять его при проведении опытов и экспериментов с объектами живой и неживой природы;</li>
				<li class="AdditionalGneral-summary-dict-items">пользоваться оборудованием для проведения опытов и
					экспериментов;</li>
				<li class="AdditionalGneral-summary-dict-items">вести наблюдения за окружающей природой;</li>
				<li class="AdditionalGneral-summary-dict-items">планировать и организовывать исследовательскую деятельность;
				</li>
				<li class="AdditionalGneral-summary-dict-items">выделять объект исследования, разделять учебно-исследовательскую
					деятельность на этапы;</li>
				<li class="AdditionalGneral-summary-dict-items">работать в группе.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование познавательных интересов, интеллектуальных и
					творческих способностей обучающихся;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование целостного мировоззрения, соответствующего
					современному уровню развития науки и технологий;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельность в приобретении новых знаний и практических
					умений; готовность к выбору жизненного пути в соответствии с собственными интересами и возможностями;</li>
				<li class="AdditionalGneral-summary-dict-items">проявление технико-технологического мышления при организации
					своей деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">мотивация образовательной деятельности обучающихся на основе
					личностно ориентированного подхода;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование ценностных отношений друг к другу, преподавателю,
					авторам открытий и изобретений, результатам обучения; формирование коммуникативной компетентности в процессе
					проектной, учебно-исследовательской, игровой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">овладение составляющими исследовательской и проектной
					деятельности (умение видеть проблему, ставить вопросы, выдвигать гипотезы, давать определения понятиям,
					классифицировать, наблюдать, проводить эксперименты, делать выводы и заключения, структурировать материал,
					объяснять, доказывать, защищать свои идеи; умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учёбе и познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение основами самоконтроля, самооценки, принятия решений и
					осуществления осознанного выбора в учебной и познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение создавать, применять и преобразовывать знаки и символы,
					модели, схемы для решения учебных и познавательных задач; развитие монологической и диалогической речи, умения
					выражать свои мысли, способности выслушивать собеседника, понимать его точку зрения, признавать право другого
					человека на иное мнение;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование умений работать в группе с выполнением различных
					социальных ролей, представлять и отстаивать свои взгляды и убеждения, вести дискуссию;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетентности в области использования
					информационно-коммуникационных технологий.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты обучения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение использовать термины области «Робототехника»;</li>
				<li class="AdditionalGneral-summary-dict-items">умение конструировать механизмы для преобразования движения;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать логические значения, операции и выражения с
					ними;</li>
				<li class="AdditionalGneral-summary-dict-items">умение формально выполнять алгоритмы, описанные с использованием
					конструкций ветвления (условные операторы) и повторения (циклы), вспомогательных алгоритмов, простых и
					табличных величин; умение создавать и выполнять программы для решения несложных алгоритмических задач в
					выбранной среде программирования;</li>
				<li class="AdditionalGneral-summary-dict-items">владение формами учебно-исследовательской, проектной, игровой
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">планирование технологического процесса в процессе создания
					роботов и робототехнических сформированность понимания общих экологических законов, особенностей влияния
					человеческой деятельности на состояние природной и социальной среды;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение опыта эколого-направленной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность экологического мышления и способности учитывать
					и оценивать экологические последствия в разных сферах деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">владение базовыми экологическими понятиями, владение
					способностями применять экологические знания в жизненных ситуациях, связанных с выполнением типичных
					социальных ролей;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность личностного отношения к экологическим
					ценностям, моральной ответственности за экологические последствия своих действий в окружающей среде;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование у обучающихся общего представления о
					феноменологических знаниях о природе важнейших физических явлений окружающего мира и качественное объяснение
					причины их возникновения;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие элементов теоретического мышления на основе
					формирования умений устанавливать факты, выделять главное в изучаемом явлении, выдвигать гипотезы,
					формулировать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">умение определять соединения неизвестного состава на основании
					их свойств и условий протекания реакций;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать физические свойства систем для решения
					химических задач.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9E%D0%BF%D1%8B%D1%82%D1%8B-%D0%B8-%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D0%B8%D0%BC%D0%B5%D0%BD%D1%82%D1%8B.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка к конкурсу Большие вызовы
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Учащиеся общеобразовательных организаций 8-11 классов, проявляющие
				интерес к научно-исследовательской деятельности.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения: </p>

			<p class="AdditionalGneral-summary-dict">Предметные;</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированность навыков коммуникативной,
					учебно-исследовательской деятельности, критического мышления;</li>
				<li class="AdditionalGneral-summary-dict-items">способность к инновационной, аналитической, творческой,
					интеллектуальной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность навыков проектной деятельности, а также
					самостоятельного применения приобретенных знаний и способов действий при решении различных задач, используя
					знания нескольких учебных предметов и/или предметных областей;</li>
				<li class="AdditionalGneral-summary-dict-items">способность постановки цели и формулирования гипотезы
					исследования, планирования работы, отбора и интерпретации необходимой информации, структурирования и
					аргументации результатов исследования на основе собранных данных, презентация результатов; </li>
				<li class="AdditionalGneral-summary-dict-items">владение умением излагать результаты проектной работы на
					вебинарах, семинарах, конференциях и т.п.;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность понятий проект, проектирование;</li>
				<li class="AdditionalGneral-summary-dict-items">владение знанием этапов проектной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">владение методами поиска и анализа научной информации.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели деятельности и составлять
					планы деятельности; самостоятельно осуществлять, контролировать и корректировать деятельность; использовать
					все возможные ресурсы для достижения поставленных целей и реализации планов деятельности; выбирать успешные
					стратегии в различных ситуациях;</li>
				<li class="AdditionalGneral-summary-dict-items">умение продуктивно общаться и взаимодействовать в процессе
					совместной деятельности, учитывать позиции других участников деятельности, эффективно разрешать конфликты;
				</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной, учебно-исследовательской и
					проектной деятельности, навыками разрешения проблем; способность и готовность к самостоятельному поиску
					методов решения практических задач, применению различных методов познания;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к самостоятельной
					информационно-познавательной деятельности, включая умение ориентироваться в различных источниках информации,
					критически оценивать и интерпретировать информацию, получаемую из различных источников;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать средства информационных и коммуникационных
					технологий (далее - ИКТ) в решении когнитивных, коммуникативных и организационных задач с соблюдением
					требований эргономики, техники безопасности, гигиены, ресурсосбережения, правовых и этических норм, норм
					информационной безопасности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение определять назначение и функции различных социальных
					институтов;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно оценивать и принимать решения,
					определяющие стратегию поведения, с учётом гражданских и нравственных ценностей;</li>
				<li class="AdditionalGneral-summary-dict-items">владение языковыми средствами - умение ясно, логично и точно
					излагать свою точку зрения, использовать адекватные языковые средства;</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной рефлексии как осознания
					совершаемых действий и мыслительных процессов, их результатов и оснований, границ своего знания и незнания,
					новых познавательных задач и средств их достижения.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">уважение к своему народу, гордости за свой край, свою Родину,
					уважение государственных символов (герб, флаг, гимн);</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность мировоззрения, соответствующего современному
					уровню развития науки и общественной практики, основанного на диалоге культур, а также различных форм
					общественного сознания, осознание своего места в поликультурном мире;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность основ саморазвития и самовоспитания в
					соответствии с общечеловеческими ценностями и идеалами гражданского общества; готовность и способность к
					самостоятельной, творческой и ответственной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">толерантное сознание и поведение в поликультурном мире,
					готовность и способность вести диалог с другими людьми, достигать в нём взаимопонимания, находить общие цели и
					сотрудничать для их достижения;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки сотрудничества со сверстниками, детьми младшего возраста,
					взрослыми в образовательной, общественно полезной, учебно-исследовательской, проектной и других видах
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к образованию, в том числе
					самообразованию, на протяжении всей жизни; сознательное отношение к непрерывному образованию как условию
					успешной профессиональной и общественной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">эстетическое отношение к миру, включая эстетику быта, научного и
					технического творчества, спорта, общественных отношений;</li>
				<li class="AdditionalGneral-summary-dict-items">принятие и реализация ценностей здорового и безопасного образа
					жизни, потребности в физическом самосовершенствовании, занятиях спортивно-оздоровительной деятельностью,
					неприятие вредных привычек: курения, употребления алкоголя, наркотиков;</li>
				<li class="AdditionalGneral-summary-dict-items">бережное, ответственное и компетентное отношение к физическому и
					психологическому здоровью, как собственному, так и других людей, умение оказывать первую помощь;</li>
				<li class="AdditionalGneral-summary-dict-items">осознанный выбор будущей профессии и возможностей реализации
					собственных жизненных планов; отношение к профессиональной деятельности как возможности участия в решении
					личных, общественных проблем;</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BA-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D1%83-%D0%91%D0%BE%D0%BB%D1%8C%D1%88%D0%B8%D0%B5-%D0%B2%D1%8B%D0%B7%D0%BE%D0%B2%D1%8B.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Проектная деятельность в русском языке и литературе
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">В образовательной программе могут принять участие учащиеся
				образовательных организаций Иркутской области в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения: </p>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">освоить наиболее актуальные для работы над проектами способы
					деятельности и подготовить учащихся к разработке и реализации собственных проектов;</li>
				<li class="AdditionalGneral-summary-dict-items">приобрести знания о структуре учебно-исследовательской
					деятельности, способах поиска информации, обработки результатов и его презентации.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формировать способности, позволяющие эффективно действовать в
					реальной жизненной ситуации;</li>
				<li class="AdditionalGneral-summary-dict-items">формировать представление о русском языке и литературе как
					духовной, нравственной и культурной ценности народа, осознание национального своеобразия русского языка;</li>
				<li class="AdditionalGneral-summary-dict-items">развивать творческие и интеллектуальные способности и логическое
					мышление учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">создавать мотивацию к самообразованию;</li>
				<li class="AdditionalGneral-summary-dict-items">совершенствование навыков конструктивного сотрудничества и
					публичного выступления;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь выражать свою индивидуальную и оригинальную точку зрения.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%BD%D0%B0%D1%8F-%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D1%8C-%D0%B2-%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%BE%D0%BC-%D1%8F%D0%B7%D1%8B%D0%BA%D0%B5-%D0%B8-%D0%BB%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Финансовая грамотность
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся по общеобразовательным
				программам 7-10 классов.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения: </p>

			<p class="AdditionalGneral-summary-dict">Обучающиеся будут знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">базовых понятий (финансовая грамотность, финансовое поведение,
					статистические данные, простая финансовая информация в области экономики семьи, учебное исследование
					экономических отношений в семье и обществе;</li>
				<li class="AdditionalGneral-summary-dict-items">названия и правила пользования финансовых инструментов;</li>
				<li class="AdditionalGneral-summary-dict-items">виды операций с деньгами, планирование семейного бюджета;</li>
				<li class="AdditionalGneral-summary-dict-items">виды финансового мошенничества.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Обучающиеся будут уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">применять на практике изученный теоретический материал и
					применять его при проведении финансовых операций;</li>
				<li class="AdditionalGneral-summary-dict-items">грамотно пользоваться финансовыми инструментами;</li>
				<li class="AdditionalGneral-summary-dict-items">планировать и организовывать семейный бюджет;</li>
				<li class="AdditionalGneral-summary-dict-items">работать в группе.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированность у учащегося гражданской позиции как активного и
					ответственного члена российского общества, осознающего свои конституционные права и обязанности, уважающего
					закон и правопорядок, обладающего чувством собственного достоинства;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к саморазвитию и личностному
					самоопределению;</li>
				<li class="AdditionalGneral-summary-dict-items">выявление и мотивация к раскрытию лидерских и
					предпринимательских качеств;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание мотивации к труду, умение оценивать и аргументировать
					собственную точку зрения по финансовым проблемам, стремление строить свое будущее на основе целеполагания и
					планирования;</li>
				<li class="AdditionalGneral-summary-dict-items">осознание ответственности за настоящее и будущее собственное
					финансовое благополучие, благополучие своей семьи и государства.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">овладение составляющими исследовательской и проектной
					деятельности (умение видеть проблему, ставить вопросы, выдвигать гипотезы, давать определения понятиям,
					классифицировать, наблюдать, проводить эксперименты, делать выводы и заключения, структурировать материал,
					объяснять, доказывать, защищать свои идеи; умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учёбе и познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение основами самоконтроля, самооценки, принятия решений и
					осуществления осознанного выбора в учебной и познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение создавать, применять и преобразовывать знаки и символы,
					модели, схемы для решения учебных и познавательных задач; развитие монологической и диалогической речи, умения
					выражать свои мысли, способности выслушивать собеседника, понимать его точку зрения, признавать право другого
					человека на иное мнение;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование умений работать в группе с выполнением различных
					социальных ролей, представлять и отстаивать свои взгляды и убеждения, вести дискуссию;</li>
				<li class="AdditionalGneral-summary-dict-items">способность планирования и прогнозирования будущих доходов и
					расходов личного бюджета</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетентности в области использования
					информационно-коммуникационных технологий.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты обучения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">освоение и применение системы финансовых знаний, и их
					необходимости для решения современных практических задач;</li>
				<li class="AdditionalGneral-summary-dict-items">умение классифицировать по разным признакам (в том числе
					устанавливать существенный признак классификации) финансовые объекты, явления, процессы, выявляя их
					существенные признаки, элементы и основные функции;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать полученные знания для объяснения (устного и
					письменного) сущности, взаимосвязей финансовых явлений и процессов; необходимости правомерного налогового
					поведения; осмысления личного опыта принятия финансовых решений;</li>
				<li class="AdditionalGneral-summary-dict-items">умение решать в рамках изученного материала познавательные и
					практические задачи, отражающие процессы постановки финансовых целей, управления доходами и расходами,
					формирования резервов и сбережений, принятия инвестиционных решений;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение смысловым чтением текстов финансовой тематики,
					позволяющим воспринимать, понимать и интерпретировать смысл текстов разных типов, жанров, назначений в целях
					решения различных учебных задач, в том числе извлечений из нормативных правовых актов; умение преобразовывать
					текстовую информацию в модели (таблицу, диаграмму, схему) и преобразовывать предложенные модели в текст;</li>
				<li class="AdditionalGneral-summary-dict-items">умение анализировать, обобщать, систематизировать,
					конкретизировать и критически оценивать статистическую финансовую информацию, соотносить ее с собственными
					знаниями и опытом; формулировать выводы, подкрепляя их аргументами;</li>
				<li class="AdditionalGneral-summary-dict-items">умение оценивать собственные поступки и поведение других людей с
					точки зрения финансово-экономических и правовых норм, включая вопросы, связанные с личными финансами,
					финансовыми рисками и угрозами финансового мошенничества; осознание неприемлемости всех форм антиобщественного
					поведения;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение опыта использования полученных знаний по финансовой
					грамотности в практической (индивидуальной и/или групповой) деятельности: для составления личного финансового
					плана; для принятия решений о приобретении финансовых услуг и имущества; для приобретения опыта публичного
					представления результатов своей деятельности в соответствии с темой и ситуацией общения, особенностями
					аудитории и регламентом.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A4%D0%B8%D0%BD%D0%B0%D0%BD%D1%81%D0%BE%D0%B2%D0%B0%D1%8F-%D0%B3%D1%80%D0%B0%D0%BC%D0%BE%D1%82%D0%BD%D0%BE%D1%81%D1%82%D1%8C.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Химия 1.0
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 10 до 14 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты освоения курса предполагают:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие ответственного отношения к учению, готовность и
					способность к саморазвитию и самообразованию на основе мотивации к обучению и познанию;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие целостного мировоззрение, соответствующее современному
					уровню развития науки и общественной практики.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование владение приёмами работы с информацией;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие интеллектуальных, коммуникативных, творческих
					способностей обучающихся;</li>
				<li class="AdditionalGneral-summary-dict-items">совершенствование умений и навыков вести наблюдения объектами,
					явлениями природы;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение учащимися опыта успешной самореализации в процессе
					осуществления естественно-научного исследования;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">познакомятся с агрегатными состояниями веществ, их физические
					свойства;</li>
				<li class="AdditionalGneral-summary-dict-items">познакомятся с периодической системой Д. И. Менделеева;</li>
				<li class="AdditionalGneral-summary-dict-items">изучат разнообразие химических элементов.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F-1.0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Художественное слово. Подготовка к творческой деятельности по литературе
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно - заочная, в том числе с использованием дистанционных
				технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются общеобразовательных школ в возрасте
				от 10 до 17 лет. Обучение по программе не предполагает наличие определенных знаний.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения: </p>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные термины и понятия разделов русской и мировой
					литературы, а также литературоведения как науки о литературе;</li>
				<li class="AdditionalGneral-summary-dict-items">значения всех используемых на занятии слов и выражений;</li>
				<li class="AdditionalGneral-summary-dict-items">законы чтения, комментирования, анализа и интерпретации
					художественного текста;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы единства художественной формы и содержания в
					литературном произведении;</li>
				<li class="AdditionalGneral-summary-dict-items">особенности поэтики и стилистики отечественных и зарубежных
					авторов литературных произведений;</li>
				<li class="AdditionalGneral-summary-dict-items">правила речевого этикета;</li>
				<li class="AdditionalGneral-summary-dict-items">орфоэпические, лексические, грамматические, стилистические нормы
					русского литературного языка;</li>
				<li class="AdditionalGneral-summary-dict-items">нормы построения художественного текста, его композиционные и
					стилистические особенности;</li>
				<li class="AdditionalGneral-summary-dict-items">достаточный объём словарного запаса и усвоенных грамматических
					средств</li>
				<li class="AdditionalGneral-summary-dict-items">для свободного выражения мыслей и чувств в процессе речевого
					общения.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимать русскую литературу как одну из национально-культурных
					ценностей русского народа;</li>
				<li class="AdditionalGneral-summary-dict-items">осознавать эстетическую ценность русской литературы;</li>
				<li class="AdditionalGneral-summary-dict-items">пользоваться различными алгоритмами постижения смыслов,
					заложенных в художественном тексте;</li>
				<li class="AdditionalGneral-summary-dict-items">формулировать цель своей деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">планировать свою деятельность;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь осуществлять библиографический поиск;</li>
				<li class="AdditionalGneral-summary-dict-items">находить и обрабатывать необходимую информацию из различных
					источников;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать опыт общения с произведениями художественной
					литературы в повседневной жизни и учебной деятельности, речевом совершенствовании;</li>
				<li class="AdditionalGneral-summary-dict-items">оценивать собственную и чужую речь с точки зрения точного,
					уместного и выразительного словоупотребления;</li>
				<li class="AdditionalGneral-summary-dict-items">переводить текст с древнерусского, старославянского и
					церковнославянского языков;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать различные методы работы при решении поставленных
					задач;</li>
				<li class="AdditionalGneral-summary-dict-items">решать олимпиадные задачи по литературе разных уровней
					сложности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">ориентированность на принцип персонализации;</li>
				<li class="AdditionalGneral-summary-dict-items">индивидуализацию процесса обучения (создание условий, в которых
					ребёнок свободно выбирает дополнительную общеобразовательную программу согласно своим интересам и
					склонностям);</li>
				<li class="AdditionalGneral-summary-dict-items">осуществление предпрофессиональной подготовки и раннюю
					профориентацию;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умений вступать в продуктивную коммуникацию и
					кооперацию.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владение всеми видами речевой деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">адекватное понимание информации устного и письменного сообщения;
				</li>
				<li class="AdditionalGneral-summary-dict-items">адекватное восприятие на слух текстов разных стилей и жанров;
				</li>
				<li class="AdditionalGneral-summary-dict-items">способность извлекать информацию из различных источников,
					включая средства массовой информации, компакт-диски учебного назначения, ресурсы Интернета; свободно
					пользоваться словарями различных типов, справочной литературой;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение приёмами отбора и систематизации материала на
					определённую тему; умение вести самостоятельный поиск информации, её анализ и отбор;</li>
				<li class="AdditionalGneral-summary-dict-items">способность определять цели предстоящей учебной деятельности
					(индивидуальной и коллективной), последовательность действий, оценивать достигнутые результаты и адекватно
					формулировать их в устной и письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">способность свободно, правильно излагать свои мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">умение выступать перед аудиторией сверстников с небольшими
					сообщениями, докладами;</li>
				<li class="AdditionalGneral-summary-dict-items">применение приобретённых знаний, умений и навыков в повседневной
					жизни; способность использовать родную литературу как средство получения знаний по другим учебным предметам,
					применять полученные знания, умения и навыки анализа языковых явлений на межпредметном уровне (на уроках
					русского языка, иностранного языка, истории, обществознания и др.);</li>
				<li class="AdditionalGneral-summary-dict-items">коммуникативно целесообразное взаимодействие с окружающими
					людьми в процессе речевого общения, совместного выполнения какой-либо задачи, участия в спорах, обсуждениях;
					овладение национально культурными нормами речевого поведения в различных ситуациях формального и неформального
					межличностного и межкультурного общения.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%BC%D0%B0-%D0%A5%D1%83%D0%B4%D0%BE%D0%B6%D0%B5%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D0%BE%D0%B5-%D1%81%D0%BB%D0%BE%D0%B2%D0%BE-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BA-%D1%82%D0%B2%D0%BE%D1%80%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B9%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8-%D0%BF%D0%BE-%D0%BB%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Экспериментальная физика
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">24 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам 9-11 классов.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие познавательного интереса, интеллектуальных и творческих
					способностей учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитывать уважительное и ответственное отношение к своему
					осознанному выбору;</li>
				<li class="AdditionalGneral-summary-dict-items">совершенствование способности самостоятельного приобретения
					новых знаний и практических умений;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной и учебно-исследовательской
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">формировать умение самостоятельно определять цели своего
					обучения, ставить и формулировать для себя новые задачи в учёбе познавательной деятельности,</li>
				<li class="AdditionalGneral-summary-dict-items">развивать мотивы и интересы своей познавательной деятельности;
				</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">развивать навыки эмоционального интеллекта и эмпатии, умение
					организовывать учебное сотрудничество и совместную деятельность с педагогом и сверстниками; работать
					индивидуально и в группе: находить общее решение и разрешать конфликты на основе согласования позиций и учёта
					интересов; формулировать, аргументировать и отстаивать свое мнение;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыков межличностного взаимодействия и построения
					коммуникационного процесса.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование у учащихся общего представления о
					феноменологических знаниях о природе важнейших физических явлений окружающего мира и качественное объяснение
					причины их возникновения;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие элементов теоретического мышления на основе
					формирования умений устанавливать факты, выделять главное в изучаемом явлении, выдвигать гипотезы,
					формулировать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">рассмотреть теоретический материал, необходимый для решения
					заданий различных этапов олимпиад</li>
				<li class="AdditionalGneral-summary-dict-items">углубление убеждения учащихся в закономерной связи и
					познаваемости явлений природы, в объективности научного знания, в высокой ценности науки в развитии
					материальной и духовной культуры общества.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%AD%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D0%B8%D0%BC%D0%B5%D0%BD%D1%82%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D1%84%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-7-8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Инженерная лаборатория
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">18 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся общеобразовательных
				организаций и организаций дополнительного образования в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">практические навыки конструктивного воображения;</li>
				<li class="AdditionalGneral-summary-dict-items">информационно-логические знания и умения и применять их:
					определять понятия, создавать обобщения, устанавливать аналогии, классифицировать, самостоятельно выбирать
					основания и критерии для классификации, устанавливать причинно-следственные связи, строить логическое
					рассуждение, умозаключение (индуктивное, дедуктивное и по аналогии) и делать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">правила безопасной работы с оборудованием.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">выполнять задания в соответствии с поставленной целью на
					оборудовании предметных лабораторий, не имеющих аналогов в школьной программе;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельно решать технические задачи в процессе
					конструирования.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Практические навыки:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">навыки работы в группе.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%98%D0%BD%D0%B6%D0%B5%D0%BD%D0%B5%D1%80%D0%BD%D0%B0%D1%8F-%D0%BB%D0%B0%D0%B1%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%B8%D1%8F.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Основы фотосъемки
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очно-заочная, с применением дистанционных технологий.</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по дополнительным
				общеразвивающим программам, в возрасте от 14 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование эстетического вкуса, чувства гармонии;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность учащихся к саморазвитию;</li>
				<li class="AdditionalGneral-summary-dict-items">добиваться точности и завершенности в работе над фотопроектами;
				</li>
				<li class="AdditionalGneral-summary-dict-items">проявление чувственно-эмоционального отношения к объектам
					фотосъёмки;</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание самодисциплины учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение планировать пути реализации поставленной цели;</li>
				<li class="AdditionalGneral-summary-dict-items">умение объективно оценивать правильность решения задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">умение проявлять гибкость и быть способным изменить тактику
					поведения в случае кардинальной смены ситуации;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно анализировать и применять полученные
					знания в любых видах творческой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты обучения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проявлять интерес к фотосъёмке;</li>
				<li class="AdditionalGneral-summary-dict-items">усвоить основные этапы проведения фотосъемки;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование первоначальных представлений о системе
					фотографических жанров, формирование навыка составления фотокомпозиции;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков обработки фотографий в графических
					редакторах;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков систематизации и размещения в сети Интернет
					графических файлов;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие способности презентовать достигнутые результаты,
					включая умение определять приоритеты целей с учетом ценностей и жизненных планов; самостоятельно
					реализовывать, контролировать и осуществлять коррекцию своей деятельности на основе предварительного
					планирования.</li>
				<li class="AdditionalGneral-summary-dict-items">владение методами поиска и анализа научной информации.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A1%D0%B5%D0%BA%D1%80%D0%B5%D1%82%D1%8B-%D1%84%D0%BE%D1%82%D0%BE%D1%81%D1%8A%D0%B5%D0%BC%D0%BA%D0%B8-%D0%BF%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Агенты погоды
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащихся по общеобразовательным
				программам в возрасте от 14 до 16 лет (учащихся 8-9 классов).</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">осознание значимости гидрометеорологической безопасности;</li>
				<li class="AdditionalGneral-summary-dict-items">интерес к деятельности гидрометеорологической службы;</li>
				<li class="AdditionalGneral-summary-dict-items">понимание необходимости бережного и уважительного отношения к
					окружающей среде;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение профориентационных знаний, которые по окончанию
					школы способствуют в выборе будущей профессии.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование культуры командной работы и коллективной проектной
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков трудового творческого сотрудничества.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные гидрометеорологические понятия, характеризующие
					состояние атмосферы;</li>
				<li class="AdditionalGneral-summary-dict-items">методы и способы выполнения гидрометеорологических работ;</li>
				<li class="AdditionalGneral-summary-dict-items">методы отбора проб и оборудование для проведения химического
					анализа;</li>
				<li class="AdditionalGneral-summary-dict-items">устройства компаса, азимута и румбы;</li>
				<li class="AdditionalGneral-summary-dict-items">основы схемотехники;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы работы радиоэлектронных компонентов;</li>
				<li class="AdditionalGneral-summary-dict-items">основы программирования микроконтроллеров.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проводить снегомерную съемку;</li>
				<li class="AdditionalGneral-summary-dict-items">проводить химический анализ проб воды, воздуха, почвы, снежного
					покрова;</li>
				<li class="AdditionalGneral-summary-dict-items">отбирать пробы воды, атмосферного воздуха, почвы, снежного
					покрова;</li>
				<li class="AdditionalGneral-summary-dict-items">ориентироваться на местности и по карте;</li>
				<li class="AdditionalGneral-summary-dict-items">выполнять горизонтальную съемку с построением плана местности;
				</li>
				<li class="AdditionalGneral-summary-dict-items">собирать электрические цепи;</li>
				<li class="AdditionalGneral-summary-dict-items">программировать микроконтроллеры.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%90%D0%B3%D0%B5%D0%BD%D1%82%D1%8B-%D0%BF%D0%BE%D0%B3%D0%BE%D0%B4%D1%8B.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Анатомия человека
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 14 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">узнают особенности организма человека, его строение,
					жизнедеятельности, высшей нервной деятельности и поведения;</li>
				<li class="AdditionalGneral-summary-dict-items">узнают основные термины (обмен веществ, превращение энергии,
					питание, дыхание, выделение, транспорт веществ, рост, развитие, размножение, наследственность и изменчивость,
					регуляция жизнедеятельности организма, раздражимость);</li>
				<li class="AdditionalGneral-summary-dict-items">научится проводить наблюдения за состоянием собственного
					организма.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учебе и познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно планировать пути достижения целей, в том
					числе альтернативные, осознанно выбирать наиболее эффективные способы решения учебных и познавательных задач;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение оценивать правильность выполнения учебной задачи,
					собственные возможности ее решения;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетенций в области анатомии;</li>
				<li class="AdditionalGneral-summary-dict-items">умение организовывать учебное сотрудничество и совместную
					деятельность с учителем и сверстниками; работать индивидуально и в группе: находить общее решение и разрешать
					конфликты на основе согласования позиций и учета интересов; формулировать, аргументировать и отстаивать свое
					мнение.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование ответственного отношения к учению, готовности и
					способности учащихся к саморазвитию и самообразованию на основе мотивации к обучению и познанию, осознанному
					выбору и построению дальнейшей индивидуальной траектории образования на базе ориентировки в мире профессий и
					профессиональных предпочтений, с учетом устойчивых познавательных интересов, а также на основе формирования
					уважительного отношения к труду, развития опыта участия в социально значимом труде;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование коммуникативной компетентности в общении и
					сотрудничестве со сверстниками, детьми старшего и младшего возраста, взрослыми в процессе образовательной,
					общественно полезной, учебно-исследовательской, творческой и других видов деятельности;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Воспитательные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированная этика групповой работы, работы в команде.</li>
				<li class="AdditionalGneral-summary-dict-items">умение установить отношения делового сотрудничества,
					взаимоуважение,</li>
				<li class="AdditionalGneral-summary-dict-items">ценностное отношение к своему здоровью,</li>
				<li class="AdditionalGneral-summary-dict-items">ответственное отношение к обучению, готовность к саморазвитию и
					самообразованию.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">анатомию тела человека с учетом возрастно-половых особенностей
					(уровни структурной организации; строение, топография и функции органов и функциональных систем; основы
					проекционной анатомии);</li>
				<li class="AdditionalGneral-summary-dict-items">основы анализа положений и движений тела с позиций влияния на
					него внешних и внутренних сил.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">четко и обоснованно формулировать сведения об анатомических
					особенностях тела в возрастно-половом аспекте и с учетом влияния специфических спортивных нагрузок;
					профессионально проводить анатомический анализ положений и движений тела;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь объяснить и показать основные ориентиры и проекции, оси,
					линии, плоскости, возможности движения в подвижных соединениях частей тела;</li>
				<li class="AdditionalGneral-summary-dict-items">определить тип телосложения, оценить морфологические показатели
					физического развития.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Владеть:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">представлениями об уровнях структурной организации тела (клетка
					- ткань - орган – система органов - организм в целом) и этапах формирования организма (эмбриогенез, филогенез,
					онтогенез);</li>
				<li class="AdditionalGneral-summary-dict-items">знаниями о специфике строения, топографии, функции органов и
					систем жизнеобеспечения;</li>
				<li class="AdditionalGneral-summary-dict-items">навыками анатомического анализа положений и движений тела и его
					частей с позиций взаимодействия внешних и внутренних сил, воздействующих на тело;</li>
				<li class="AdditionalGneral-summary-dict-items">знаниями о позитивном и негативном влиянии специфических
					спортивных нагрузок на анатомические структуры тела человека.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%90%D0%BD%D0%B0%D1%82%D0%BE%D0%BC%D0%B8%D1%8F-%D1%87%D0%B5%D0%BB%D0%BE%D0%B2%D0%B5%D0%BA%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Астрономия наука о звёздах и не только
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">60 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированность научного мировоззрения, соответствующего
					современному уровню развития астрономической науки;</li>
				<li class="AdditionalGneral-summary-dict-items">устойчивый интерес к истории и достижениям в области астрономии;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение анализировать последствия освоения космического
					пространства для жизни и деятельности человека.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение использовать при выполнении практических заданий по
					астрономии;</li>
				<li class="AdditionalGneral-summary-dict-items">такие мыслительные операции, как постановка задачи,
					формулирование гипотез, анализ и синтез, сравнение, обобщение, систематизация, выявление;</li>
				<li class="AdditionalGneral-summary-dict-items">причинно-следственных связей, поиск аналогов, формулирование
					выводов;</li>
				<li class="AdditionalGneral-summary-dict-items">для изучения различных сторон астрономических явлений,
					процессов, с которыми возникает необходимость сталкиваться в профессиональной сфере;</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной деятельности, навыками
					разрешения проблем, возникающих при выполнении практических заданий по астрономии;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать различные источники по астрономии для
					получения</li>
				<li class="AdditionalGneral-summary-dict-items">достоверной научной информации, умение оценить ее достоверность;
				</li>
				<li class="AdditionalGneral-summary-dict-items">владение языковыми средствами: умение ясно, логично и точно
					излагать свою;</li>
				<li class="AdditionalGneral-summary-dict-items">точку зрения по различным вопросам астрономии, использовать
					языковые;</li>
				<li class="AdditionalGneral-summary-dict-items">средства, адекватные обсуждаемой проблеме астрономического
					характера;</li>
				<li class="AdditionalGneral-summary-dict-items">включая составление текста и презентации материалов с
					использованием;</li>
				<li class="AdditionalGneral-summary-dict-items">информационных и коммуникационных технологий.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированность представлений о строении Солнечной системы,
					эволюции звезд и Вселенной, пространственно-временных масштабах Вселенной;</li>
				<li class="AdditionalGneral-summary-dict-items">понимание сущности наблюдаемых во Вселенной явлений;</li>
				<li class="AdditionalGneral-summary-dict-items">владение основополагающими астрономическими понятиями, теориями,
					законами и закономерностями, уверенное пользование астрономической терминологией и символикой;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность представлений о значении астрономии в
					практической деятельности человека и дальнейшем научно-техническом развитии;</li>
				<li class="AdditionalGneral-summary-dict-items">осознание роли отечественной науки в освоении и использовании
					космического пространства и развитии международного сотрудничества в этой области.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">астрономические приборы;</li>
				<li class="AdditionalGneral-summary-dict-items">строение Земли;</li>
				<li class="AdditionalGneral-summary-dict-items">строение Солнечной системы;</li>
				<li class="AdditionalGneral-summary-dict-items">название и расположение планет;</li>
				<li class="AdditionalGneral-summary-dict-items">условия их наблюдения;</li>
				<li class="AdditionalGneral-summary-dict-items">название основных спутников планет;</li>
				<li class="AdditionalGneral-summary-dict-items">строение Солнца;</li>
				<li class="AdditionalGneral-summary-dict-items">характеристики Солнца;</li>
				<li class="AdditionalGneral-summary-dict-items">физические условия Луны;</li>
				<li class="AdditionalGneral-summary-dict-items">основные созвездия и их положение на небе;</li>
				<li class="AdditionalGneral-summary-dict-items">Зодиакальные созвездия;</li>
				<li class="AdditionalGneral-summary-dict-items">строение галактик.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">пользоваться телескопом, биноклем;</li>
				<li class="AdditionalGneral-summary-dict-items">картой звездного неба;</li>
				<li class="AdditionalGneral-summary-dict-items">находить положение звезд, планет, созвездий на звездном небе;
				</li>
				<li class="AdditionalGneral-summary-dict-items">находить координаты звезд на карте звездного неба;</li>
				<li class="AdditionalGneral-summary-dict-items">объяснить причину движения небесных объектов, условия
					наступления затмений, падающих «звезд»;</li>
				<li class="AdditionalGneral-summary-dict-items">отличать планеты от звезд на небе.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F-%D0%BD%D0%B0%D1%83%D0%BA%D0%B0-%D0%BE-%D0%B7%D0%B2%D1%91%D0%B7%D0%B4%D0%B0%D1%85-%D0%B8-%D0%BD%D0%B5-%D1%82%D0%BE%D0%BB%D1%8C%D0%BA%D0%BE.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Байкал-природная лаборатория
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 14 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сознание российской гражданской идентичности и своей этнической
					принадлежности;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к саморазвитию и самообразованию на
					основе мотивации к обучению и познанию;</li>
				<li class="AdditionalGneral-summary-dict-items">постепенное выстраивание собственной целостной картины мира;
				</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение опыта участия в социально значимом труде;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие коммуникативной компетентности в общении и
					сотрудничестве со сверстниками в процессе образовательной, общественно полезной, учебно-исследовательской,
					творческой деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">понимание основ экологической культуры.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">навыка научно-исследовательской деятельности, анализа и
					обработки полученных научных результатов.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знаний основных понятий и терминов в области микробиологии,
					гидробиологии, молекулярной биологии;</li>
				<li class="AdditionalGneral-summary-dict-items">знаний о структуре и видовом разнообразии байкальского
					фитопланктона;</li>
				<li class="AdditionalGneral-summary-dict-items">знаний о современных методах молекулярной биологии;</li>
				<li class="AdditionalGneral-summary-dict-items">знаний о современном экологическом состоянии Байкала;</li>
				<li class="AdditionalGneral-summary-dict-items">знаний о санитарно-микробиологических методах анализа качества
					питьевой воды;</li>
				<li class="AdditionalGneral-summary-dict-items">умений планировать этапы эксперимента в области изучения
					биоразнообразия молекулярно-генетическими методами;</li>
				<li class="AdditionalGneral-summary-dict-items">умений проводить санитарно-микробиологические исследования воды;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умений проводить морфологический анализ фитопланктона Байкала.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные понятия микробиологии, гидробиологии, молекулярной
					биологии;</li>
				<li class="AdditionalGneral-summary-dict-items">методы проведения анализа воды.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проводить морфологический анализ фитопланктона Байкала;</li>
				<li class="AdditionalGneral-summary-dict-items">проводить санитарно-микробиологические исследования воды;</li>
				<li class="AdditionalGneral-summary-dict-items">планировать этапы эксперимента.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%91%D0%B0%D0%B9%D0%BA%D0%B0%D0%BB-%D0%BF%D1%80%D0%B8%D1%80%D0%BE%D0%B4%D0%BD%D0%B0%D1%8F-%D0%BB%D0%B0%D0%B1%D0%BE%D1%80%D0%B0%D1%82%D0%BE%D1%80%D0%B8%D1%8F.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Весенняя интеллектуальная общеразвивающая школа
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">повышение общего культурного уровня обучающихся;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие интереса к изучению исторического прошлого;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие интеллектуальных и практических умений по
					самостоятельному приобретению и применению на практике полученные знания по истории и праву.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной и учебно-исследовательской
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыков межличностного взаимодействия и построения
					коммуникационного процесса.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование культуры работы с историческими источниками и
					литературой, выступлений на семинарах, ведения дискуссий;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие интерес к изучению исторического прошлого;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование общего представления об историческом прошлом;</li>
				<li class="AdditionalGneral-summary-dict-items">владение и понимание основных понятий и концепций юридической
					теории;</li>
				<li class="AdditionalGneral-summary-dict-items">углубление знаний в сфере права и способность к их применению на
					практике;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие основ логического мышления для формирования навыка
					юридического мышления.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">химическую символику: знаки химических элементов, формулы
					химических веществ и уравнения химических реакций;</li>
				<li class="AdditionalGneral-summary-dict-items">важнейшие химические понятия: химический элемент, атом,
					молекула, относительные атомная и молекулярная массы, ион, химическая связь, вещество, классификация веществ,
					моль, молярная масса, молярный объем, химическая реакция, классификация реакций, электролит и неэлектролит,
					электролитическая диссоциация, окислитель и восстановитель, окисление и восстановление;</li>
				<li class="AdditionalGneral-summary-dict-items">основные законы химии: сохранения массы веществ, постоянства
					состава, периодический закон;</li>
				<li class="AdditionalGneral-summary-dict-items">виды передаточных механизмов;</li>
				<li class="AdditionalGneral-summary-dict-items">способы передвижения мобильных машин;</li>
				<li class="AdditionalGneral-summary-dict-items">требования к проекту;</li>
				<li class="AdditionalGneral-summary-dict-items">алгоритм подготовки к выступлению;</li>
				<li class="AdditionalGneral-summary-dict-items">названия некоторых бактерии, грибов, а также растений из их
					основных групп: мхов, папоротников, голосеменных и цветковых; строение и общие биологические особенности
					цветковых растений; разницу цветков и соцветий;</li>
				<li class="AdditionalGneral-summary-dict-items">разницу ядовитых и съедобных грибов; знать вред бактерий и
					способы предохранения от заражения ими.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">называть: химические элементы, соединения изученных классов;
				</li>
				<li class="AdditionalGneral-summary-dict-items">объяснять: физический смысл атомного (порядкового) номера
					химического элемента, номеров группы и периода, к которым элемент принадлежит в периодической системе Д.И.
					Менделеева; закономерности изменения свойств элементов в пределах малых периодов и главных подгрупп; сущность
					реакций ионного обмена;</li>
				<li class="AdditionalGneral-summary-dict-items">характеризовать: химические элементы (от водорода до кальция) на
					основе их положения в периодической системе Д.И. Менделеева и особенностей строения их атомов; связь между
					составом, строением и свойствами веществ; химические свойства основных классов неорганических веществ;</li>
				<li class="AdditionalGneral-summary-dict-items">определять: состав веществ по их формулам, принадлежность
					веществ к определенному классу соединений, типы химических реакций, валентность и степень окисления элемента в
					соединениях, тип химической связи в соединениях, возможность протекания реакций ионного обмена;</li>
				<li class="AdditionalGneral-summary-dict-items">формулировать цели и проектировать меры по их достижению;</li>
				<li class="AdditionalGneral-summary-dict-items">работать с информацией (поиск, обработка, анализ);</li>
				<li class="AdditionalGneral-summary-dict-items">оценивать свою работу и корректировать деятельность с целью
					исправления недочетов;</li>
				<li class="AdditionalGneral-summary-dict-items">работать индивидуально, в группе, в коллективе;</li>
				<li class="AdditionalGneral-summary-dict-items">отличать цветковые растения от других групп (мхов, папоротников,
					голосеменных);</li>
				<li class="AdditionalGneral-summary-dict-items">приводить примеры растений некоторых групп (бобовых,
					розоцветных, сложноцветных);</li>
				<li class="AdditionalGneral-summary-dict-items">различать органы у цветкового растения (цветок, лист, стебель,
					корень);</li>
				<li class="AdditionalGneral-summary-dict-items">различать однодольные и двудольные растения по строению корней,
					листьев (жилкование), плодов и семян; приводить примеры однодольных и двудольных растений;</li>
				<li class="AdditionalGneral-summary-dict-items">выращивать некоторые цветочно-декоративные растения (в саду и
					дома) различать грибы и растения.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%92%D0%B5%D1%81%D0%B5%D0%BD%D0%BD%D1%8F%D1%8F-%D0%B8%D0%BD%D1%82%D0%B5%D0%BB%D0%BB%D0%B5%D0%BA%D1%82%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D0%B0%D1%8F-%D0%BE%D0%B1%D1%89%D0%B5%D1%80%D0%B0%D0%B7%D0%B2%D0%B8%D0%B2%D0%B0%D1%8E%D1%89%D0%B0%D1%8F-%D1%88%D0%BA%D0%BE%D0%BB%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				География. Регионоведение
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">60 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Косвоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 14 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные: </p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование ответственного отношения к учению, готовности и
					способности учащихся к саморазвитию и самообразованию на основе мотивации к обучению и познанию, осознанному
					выбору и построению дальнейшей индивидуальной траектории образования на базе ориентировки в мире профессий и
					профессиональных предпочтений, с учетом устойчивых познавательных интересов, а также на основе формирования
					уважительного отношения к труду, развития опыта участия в социально значимом труде;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование коммуникативной компетентности в общении и
					сотрудничестве со сверстниками, детьми старшего и младшего возраста, взрослыми в процессе образовательной,
					общественно полезной, учебно-исследовательской, творческой и других видов деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Воспитательные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированная этика групповой работы, работы в команде;</li>
				<li class="AdditionalGneral-summary-dict-items">умение установить отношения делового сотрудничества,
					взаимоуважение;</li>
				<li class="AdditionalGneral-summary-dict-items">ценностное отношение к своему здоровью;</li>
				<li class="AdditionalGneral-summary-dict-items">ответственное отношение к обучению, готовность к саморазвитию и
					самообразованию.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели своего обучения, ставить и
					формулировать для себя новые задачи в учебе и познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно планировать пути достижения целей, в том
					числе альтернативные, осознанно выбирать наиболее эффективные способы решения учебных и познавательных задач;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение оценивать правильность выполнения учебной задачи,
					собственные возможности ее решения;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетенций в области применения
					географического мышления для вычленения и оценивания географических факторов, определяющих сущность и динамику
					важнейших природных, социально-экономических, экологических и дипломатических процессов;</li>
				<li class="AdditionalGneral-summary-dict-items">умение организовывать учебное сотрудничество и совместную
					деятельность с учителем и сверстниками; </li>
				<li class="AdditionalGneral-summary-dict-items">работать индивидуально и в группе: находить общее решение и
					разрешать конфликты на основе согласования позиций и учета интересов;</li>
				<li class="AdditionalGneral-summary-dict-items">формулировать, аргументировать и отстаивать свое мнение.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">ознакомить с такими профессиями как специалист в области
					лингвистического обеспечения региональных исследований России и стран АТР, переводчик, преподаватель двух
					иностранных языков, журналист, специалист в области теории и методики преподавания иностранных языков и
					культур, преподаватель русского языка как иностранного;</li>
				<li class="AdditionalGneral-summary-dict-items">познакомиться со странами Азиатско- Тихоокеанского региона;</li>
				<li class="AdditionalGneral-summary-dict-items">овладеть базовыми знаниями китайского, корейского, японского
					языков;</li>
				<li class="AdditionalGneral-summary-dict-items">овладеть базовыми знаниями европейских языков;</li>
				<li class="AdditionalGneral-summary-dict-items">ознакомиться с основными аспектами Российско-Китайских
					отношений;</li>
				<li class="AdditionalGneral-summary-dict-items">познакомиться с историей, культурой, философией Китая;</li>
				<li class="AdditionalGneral-summary-dict-items">познакомиться с этническим составом Китая;</li>
				<li class="AdditionalGneral-summary-dict-items">ознакомиться с основными направлениями сотрудничества Иркутской
					области и Китая.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">культурные, этнонациональные, конфессиональные,
					нормативно-ценностные, социально исторические факторы развития общества в контексте формирования и развития
					систем региональной и национальной безопасности;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы отбора современных исследований в области лингвистики,
					межкультурной коммуникации и переводоведения в контексте формирования и развития навыков теории перевода;</li>
				<li class="AdditionalGneral-summary-dict-items">место России в мировом сообществе, ее взаимосвязи с Западом и
					Востоком, вклад в мировую цивилизацию, специфические особенности ее развития;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы социально исторического, этического и философского
					развития современного общества в соответствии с многообразием специфических форм регионального устройства в
					современном мире.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">преобразовывать информацию в знание, осмысливать процессы,
					события и явления в истории России и мировом сообществе в их динамике и взаимосвязи;</li>
				<li class="AdditionalGneral-summary-dict-items">позиционировать себя представителем исторически сложившегося
					гражданского, этнокультурного, конфессионального сообщества;</li>
				<li class="AdditionalGneral-summary-dict-items">учитывать особенности национально-языковой картины мира
					представителей иной лингвокультуры, отраженной в лексике и грамматике языка, в профессиональной деятельности.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Владеть:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">навыками самостоятельного использования результатов современных
					исследований решения профессиональных задач в области теории перевода;</li>
				<li class="AdditionalGneral-summary-dict-items">представлениями о событиях российской и всемирной истории,
					основанными на принципе историзма;</li>
				<li class="AdditionalGneral-summary-dict-items">навыками социального и профессионального взаимодействия с учетом
					особенностей основных форм научного и религиозного сознания, деловой и общей культуры представителей других
					этносов и конфессий, различных социальных групп;</li>
				<li class="AdditionalGneral-summary-dict-items">навыками коммуникации в общей и профессиональной сферах,
					учитывающими специфику национально языковой картины мира.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F.-%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию в региональном этапе ВсОШ по английскому языку
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 15 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения: </p>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">этические нормы и ценности иноязычной культуры; основные
					фонетические, лексические, грамматические, словообразовательные явления и закономерности функционирования
					английского языка; композиционные элементы текста;</li>
				<li class="AdditionalGneral-summary-dict-items">особенности официального, нейтрального и неофициального
					регистров общения.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">свободно выражать свои мысли, адекватно используя разнообразные
					языковые средства с целью выделения релевантной информации;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать этикетные формулы в устной и письменной
					коммуникации.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Владеть:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">навыками социокультурной и межкультурной коммуникации; культурой
					мышления, способностью к анализу, обобщению информации, постановке целей и выбору путей их достижения;
					системой лингвистических знаний;</li>
				<li class="AdditionalGneral-summary-dict-items">основными дискурсивными способами реализации коммуникативных
					целей высказывания.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D1%81%D0%9E%D0%A8-%D0%BF%D0%BE-%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%BE%D0%BC%D1%83-%D1%8F%D0%B7%D1%8B%D0%BA%D1%83.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию в региональном этапе ВсОШ по литературе
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 15 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">российская гражданская идентичность, патриотизм, уважение к
					своему народу, чувства ответственности перед Родиной;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность мировоззрения, соответствующего современному
					уровню развития науки и общественной практики, основанного на диалоге культур, а также различных форм
					общественного сознания, осознание своего места в поликультурном мире;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность основ саморазвития и самовоспитания в
					соответствии с общечеловеческими ценностями и идеалами гражданского общества; готовность и способность к
					самостоятельной, творческой и ответственной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">толерантное сознание и поведение в поликультурном мире,
					готовность и способность вести диалог с другими людьми, достигать в нем взаимопонимания, находить общие цели и
					сотрудничать для их достижения;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки сотрудничества со сверстниками, взрослыми в
					образовательной, учебно исследовательской и других видах деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">нравственное сознание и поведение на основе усвоения
					общечеловеческих ценностей;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к образованию, в том числе
					самообразованию;</li>
				<li class="AdditionalGneral-summary-dict-items">эстетическое отношение к миру;</li>
				<li class="AdditionalGneral-summary-dict-items">принятие и реализация ценностей здорового и безопасного образа
					жизни;</li>
				<li class="AdditionalGneral-summary-dict-items">осознанный выбор будущей профессии и возможностей реализации
					собственных жизненных планов.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели деятельности и составлять
					планы деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельно осуществлять, контролировать и корректировать
					деятельность; использовать все</li>
				<li class="AdditionalGneral-summary-dict-items">возможные ресурсы для достижения поставленных целей и реализации
					планов деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">выбирать успешные стратегии в различных ситуациях;</li>
				<li class="AdditionalGneral-summary-dict-items">умение продуктивно общаться и взаимодействовать в процессе
					совместной деятельности, учитывать позиции других участников деятельности, эффективно разрешать конфликты;
				</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной, учебно-исследовательской и
					проектной деятельности, навыками разрешения проблем; способность и готовность к самостоятельному поиску
					методов решения практических задач, применению различных методов познания;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к самостоятельной
					информационно-познавательной деятельности, включая умение ориентироваться в различных источниках информации,
					критически оценивать и интерпретировать информацию, получаемую из различных источников;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать средства информационных и коммуникационных
					технологий (далее – ИКТ) в решении когнитивных, коммуникативных и организационных задач с соблюдением
					требований эргономики, техники безопасности, гигиены, ресурсосбережения, правовых и этических норм, норм
					информационной безопасности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно оценивать и принимать решения,
					определяющие стратегию поведения, с учётом гражданских и нравственных ценностей;</li>
				<li class="AdditionalGneral-summary-dict-items">владение языковыми средствами – умение ясно, логично и точно
					излагать свою точку зрения, использовать адекватные языковые средства;</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной рефлексии как осознания
					совершаемых действий и мыслительных процессов, их результатов и оснований, границ своего знания и незнания,
					новых познавательных задач и средств их достижения.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">совершенствование умений формализации и структурирования
					информации, умения выбирать способ представления данных в соответствии с поставленной задачей.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">образную природу словесного искусства;</li>
				<li class="AdditionalGneral-summary-dict-items">содержание изученных литературных произведений;</li>
				<li class="AdditionalGneral-summary-dict-items">основные факты жизни и творчества писателей-классиков</li>
				<li class="AdditionalGneral-summary-dict-items">основные закономерности историко-литературного процесса и черты
					литературных направлений.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">воспроизводить содержание литературного произведения;</li>
				<li class="AdditionalGneral-summary-dict-items">анализировать и интерпретировать художественное произведение,
					используя сведения по истории и теории литературы (тематика, проблематика, нравственный пафос, система
					образов, особенности композиции, изобразительно-выразительные средства языка, художественная деталь);
					анализировать эпизод (сцену) изученного произведения, объяснять его связь с проблематикой произведения;</li>
				<li class="AdditionalGneral-summary-dict-items">выявлять «сквозные темы» и ключевые проблемы русской литературы;
					соотносить произведение с литературным направлением эпохи;</li>
				<li class="AdditionalGneral-summary-dict-items">определять род и жанр произведения;</li>
				<li class="AdditionalGneral-summary-dict-items">выявлять авторскую позицию;</li>
				<li class="AdditionalGneral-summary-dict-items">выразительно читать изученные произведения (или их фрагменты),
					соблюдая нормы литературного произношения;</li>
				<li class="AdditionalGneral-summary-dict-items">аргументировано формулировать своё отношение к прочитанному
					произведению;</li>
				<li class="AdditionalGneral-summary-dict-items">писать эссе на прочитанные произведения и сочинения разных
					жанров на литературные темы.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D1%81%D0%9E%D0%A8-%D0%BF%D0%BE-%D0%BB%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию в региональном этапе ВсОШ по математике
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 15 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие самостоятельности суждений, независимости и
					нестандартности мышления;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие любознательности, сообразительности при выполнении
					разнообразных заданий проблемного и эвристического характера;</li>
				<li class="AdditionalGneral-summary-dict-items">совершенствование способности самостоятельного приобретения
					новых знаний и практических умений.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">осуществление развернутых действий контроля и самоконтроля:
					сравнивание построенной конструкции с образцом;</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной и учебно-исследовательской
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умения формулировать собственные мысли в устной и
					письменной форме.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование основы для дальнейшего математического развития;
				</li>
				<li class="AdditionalGneral-summary-dict-items">развитие механизмов мышления, характерных для математической
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">совершенствование умений формализации и структурирования
					информации, умения выбирать способ представления данных в соответствии с поставленной задачей.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">теоремы о пределах, раскрывать неопределенности; вычислять
					некоторые пределы функций;</li>
				<li class="AdditionalGneral-summary-dict-items">тождественные преобразования рациональных, иррациональных,
					показательных, логарифмических и тригонометрических выражений;</li>
				<li class="AdditionalGneral-summary-dict-items">рациональные, иррациональные, показательные, логарифмические и
					тригонометрические уравнения и неравенства, уравнения и неравенства с параметрами, доказывать неравенства.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">делить многочлен на многочлен с остатком, применять алгоритм
					Евклида для многочленов, пользоваться схемой Горнера;</li>
				<li class="AdditionalGneral-summary-dict-items">строить графики некоторых элементарных функций элементарными
					методами и проводить преобразования графиков;</li>
				<li class="AdditionalGneral-summary-dict-items">доказывать изученные в курсе теоремы;</li>
				<li class="AdditionalGneral-summary-dict-items">вычислять значения геометрических величин (длин, углов,
					площадей, объемов), используя изученные формулы, а также аппарат алгебры, тригонометрии, математического
					анализа;</li>
				<li class="AdditionalGneral-summary-dict-items">применять основные методы геометрии (проецирование,
					преобразований, векторный, координатный) к решению геометрических задач.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D1%81%D0%9E%D0%A8-%D0%BF%D0%BE-%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию в региональном этапе ВсОШ по ОБЖ
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 15 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование активной жизненной позиции, умений и навыков
					личного участия в обеспечении мер безопасности личности, общества и государства;</li>
				<li class="AdditionalGneral-summary-dict-items">повышение общего культурного уровня учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной и учебно-исследовательской
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыков работы с информацией, поиск, отбор источников,
					с учётом предложенной учебной задачи и заданных критериев.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные составляющие здорового образа жизни и их влияние на
					безопасность жизнедеятельности личности; репродуктивное здоровье и факторы, влияющие на него;</li>
				<li class="AdditionalGneral-summary-dict-items">потенциальные опасности природного, техногенного и социального
					происхождения, характерные для региона проживания;</li>
				<li class="AdditionalGneral-summary-dict-items">основные задачи государственных служб по защите населения и
					территорий от чрезвычайных ситуаций природного и техногенного характера;</li>
				<li class="AdditionalGneral-summary-dict-items">основы российского законодательства об обороне государства и
					воинской обязанности граждан;</li>
				<li class="AdditionalGneral-summary-dict-items">порядок первоначальной постановки на воинский учет,</li>
				<li class="AdditionalGneral-summary-dict-items">медицинского освидетельствования, призыва на военную службу;
				</li>
				<li class="AdditionalGneral-summary-dict-items">состав и предназначение Вооруженных Сил Российской Федерации;
				</li>
				<li class="AdditionalGneral-summary-dict-items">основные права и обязанности граждан до призыва на военную
					службу, во время прохождения военной службы и пребывания в запасе;</li>
				<li class="AdditionalGneral-summary-dict-items">основные виды военно-профессиональной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">особенности прохождения военной службы по призыву и контракту;
				</li>
				<li class="AdditionalGneral-summary-dict-items">альтернативной гражданской службы;</li>
				<li class="AdditionalGneral-summary-dict-items">требования, предъявляемые военной службой к уровню
					подготовленности призывника;</li>
				<li class="AdditionalGneral-summary-dict-items">предназначение, структуру и задачи РСЧС;</li>
				<li class="AdditionalGneral-summary-dict-items">предназначение, структуру и задачи гражданской обороны.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владеть способами защиты населения от чрезвычайных ситуаций
					природного и техногенного характера;</li>
				<li class="AdditionalGneral-summary-dict-items">пользоваться средствами индивидуальной и коллективной защиты;
				</li>
				<li class="AdditionalGneral-summary-dict-items">оценивать уровень своей подготовленности и осуществлять
					осознанное самоопределение по отношению к военной службе.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D1%81%D0%9E%D0%A8-%D0%BF%D0%BE-%D0%9E%D0%91%D0%96.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию в региональном этапе ВсОШ по праву
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 15 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">воспитание высокого уровня правовой культуры, правового
					сознания, уважение государственных символов (герба, флага, гимна);</li>
				<li class="AdditionalGneral-summary-dict-items">формирование гражданской позиции активного и ответственного
					гражданина,</li>
				<li class="AdditionalGneral-summary-dict-items">осознающего свои конституционные права и обязанности, уважающего
					закон</li>
				<li class="AdditionalGneral-summary-dict-items">и правопорядок, обладающего чувством собственного достоинства,
					осознанно</li>
				<li class="AdditionalGneral-summary-dict-items">принимающего традиционные национальные и общечеловеческие,
					гуманистические и демократические ценности;</li>
				<li class="AdditionalGneral-summary-dict-items">формированность правового осмысления окружающей жизни,
					соответствующего современному уровню развития правовой науки и практики, а также правового сознания;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к самостоятельной ответственной
					деятельности в сфере права;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность вести коммуникацию с другими людьми,
					сотрудничать для достижения поставленных целей;</li>
				<li class="AdditionalGneral-summary-dict-items">нравственное сознание и поведение на основе усвоения
					общечеловеческих ценностей;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к самообразованию на протяжении всей
					жизни.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">выбор успешных стратегий поведения в различных правовых
					ситуациях;</li>
				<li class="AdditionalGneral-summary-dict-items">умение продуктивно общаться и взаимодействовать в процессе
					совместной деятельности, предотвращать и эффективно разрешать возможные правовые конфликты;</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной, учебно-исследовательской и
					проектной деятельности в сфере права, способность и готовность к самостоятельному поиску методов решения
					практических задач, применению различных методов познания;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к самостоятельной
					информационно-познавательной деятельности в сфере права, включая умение ориентироваться в различных источниках
					правовой информации;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно оценивать и принимать решения,
					определяющие стратегию правового поведения с учетом гражданских и нравственных ценностей;</li>
				<li class="AdditionalGneral-summary-dict-items">владение языковыми средствами: умение ясно, логично и точно
					излагать свою точку зрения, использовать адекватные языковые средства;</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной рефлексии в сфере права как
					осознания совершаемых действий и мыслительных процессов, их результатов и</li>
				<li class="AdditionalGneral-summary-dict-items">оснований, границ своего знания и незнания, новых познавательных
					задачи средств их достижения;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированность представлений о понятии государства, его
					функциях,</li>
				<li class="AdditionalGneral-summary-dict-items">механизме и формах;</li>
				<li class="AdditionalGneral-summary-dict-items">владение знаниями о понятии права, источниках и нормах права,
					законности, правоотношениях;</li>
				<li class="AdditionalGneral-summary-dict-items">владение знаниями о правонарушениях и юридической
					ответственности; сформированность представлений о Конституции РФ как основном законе</li>
				<li class="AdditionalGneral-summary-dict-items">государства, владение знаниями об основах правового статуса
					личности в</li>
				<li class="AdditionalGneral-summary-dict-items">Российской Федерации;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность общих представлений о разных видах
					судопроизводства, правилах применения права, разрешения конфликтов правовыми способами;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность основ правового мышления;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность знаний об основах административного,
					гражданского,</li>
				<li class="AdditionalGneral-summary-dict-items">трудового, уголовного права;</li>
				<li class="AdditionalGneral-summary-dict-items">понимание юридической деятельности; ознакомление со спецификой
					основных юридических профессий;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность умений применять правовые знания для оценивания
					конкретных правовых норм с точки зрения их соответствия законодательству</li>
				<li class="AdditionalGneral-summary-dict-items">Российской Федерации;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность навыков самостоятельного поиска правовой
					информации,</li>
				<li class="AdditionalGneral-summary-dict-items">умений использовать результаты в конкретных жизненных ситуациях.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">о праве в целом, взаимовлиянии права и государства, генезисе
					права;</li>
				<li class="AdditionalGneral-summary-dict-items">основные правовые термины и понятия, отрасли права и их
					институты, основные правовые доктрины и системы права.</li>
				<li class="AdditionalGneral-summary-dict-items"></li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">применять правовые знания в решении практических проблем;</li>
				<li class="AdditionalGneral-summary-dict-items">выявлять и анализировать взаимодействие правовых явлений, видеть
					их взаимосвязь в целостной системе знаний для реализации права.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Владеть:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">обладать гражданской зрелостью,</li>
				<li class="AdditionalGneral-summary-dict-items">высокой общественной культурой и активностью в правовой,
					политической и культурной жизни.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D1%81%D0%9E%D0%A8-%D0%BF%D0%BE-%D0%BF%D1%80%D0%B0%D0%B2%D1%83.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию в региональном этапе ВсОШ по физике
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 12 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие познавательного интереса, интеллектуальных и творческих
					способностей учащихся;</li>
				<li class="AdditionalGneral-summary-dict-items">совершенствование способности самостоятельного приобретения
					новых знаний и практических умений;</li>
				<li class="AdditionalGneral-summary-dict-items">способствование приобретению положительного эмоционального
					отношения к окружающей природе и самому себе как части природы у учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">использование умений и навыков различных видов познавательной
					деятельности, применение основных методов познания (системноинформационный анализ, моделирование и т.д.) для
					изучения различных сторон окружающей действительности;</li>
				<li class="AdditionalGneral-summary-dict-items">использование основных интеллектуальных операций: формулирование
					гипотез, анализ и синтез, сравнение, обобщение, систематизация, выявление причинно-следственных связей, поиск
					аналогов;</li>
				<li class="AdditionalGneral-summary-dict-items">умение генерировать идеи и определять средства, необходимые для
					их реализации;</li>
				<li class="AdditionalGneral-summary-dict-items">умение определять цели и задачи деятельности, выбирать средства
					реализации целей и применять их на практике;</li>
				<li class="AdditionalGneral-summary-dict-items">использование различных источников для получения физической
					информации, понимание зависимости содержания и формы представления информации от целей коммуникации и
					адресата,</li>
				<li class="AdditionalGneral-summary-dict-items">овладение навыками самостоятельного приобретения новых знаний,
					организации учебной деятельности, постановки целей, планирования, самоконтроля и оценки результатов своей
					деятельности, умения предвидеть возможные результаты своих действий;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие монологической и диалогической речи, умение выражать
					свои мысли и выслушивать собеседника, понимать его точку зрения;</li>
				<li class="AdditionalGneral-summary-dict-items">умение работать в группе с выполнением различных социальных
					ролей, отстаивать свои взгляды, вести дискуссию.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование у учащихся общего представления о
					феноменологических знаниях о природе важнейших физических явлений окружающего мира и качественное объяснение
					причины их возникновения;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие элементов теоретического мышления на основе
					формирования умений устанавливать факты, выделять главное в изучаемом явлении, выдвигать гипотезы,
					формулировать выводы;</li>
				<li class="AdditionalGneral-summary-dict-items">углубление убеждения учащихся в закономерной связи и
					познаваемости явлений природы, в объективности научного знания, в высокой ценности науки в развитии
					материальной и духовной культуры общества.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">смысл понятий: физическое явление, физическая величина, модель,
					гипотеза, принцип,</li>
				<li class="AdditionalGneral-summary-dict-items">постулат, закон, теория, пространство, время, инерциальная
					система отсчета, материальная</li>
				<li class="AdditionalGneral-summary-dict-items">точка, вещество, взаимодействие, идеальный газ, резонанс,
					точечный заряд,</li>
				<li class="AdditionalGneral-summary-dict-items">электромагнитные колебания, электромагнитное поле,
					электромагнитная волна, атом, квант,</li>
				<li class="AdditionalGneral-summary-dict-items">фотон, атомное ядро, дефект массы, энергия связи,
					радиоактивность, ионизирующее</li>
				<li class="AdditionalGneral-summary-dict-items">излучение, планета, звезда, галактика, Вселенная;</li>
				<li class="AdditionalGneral-summary-dict-items">смысл физических величин: магнитный поток, индукция магнитного
					поля,</li>
				<li class="AdditionalGneral-summary-dict-items">индуктивность, энергия магнитного поля, показатель преломления,
					оптическая сила линзы;</li>
				<li class="AdditionalGneral-summary-dict-items">смысл физических законов, принципов и постулатов (формулировка,
					границы применимости):, закон электромагнитной индукции, законы отражения и преломления</li>
				<li class="AdditionalGneral-summary-dict-items">света, постулаты специальной теории относительности, закон связи
					массы и энергии,</li>
				<li class="AdditionalGneral-summary-dict-items">законы фотоэффекта, постулаты Бора, закон радиоактивного
					распада;</li>
				<li class="AdditionalGneral-summary-dict-items">вклад российских и зарубежных ученых, оказавших наибольшее
					влияние на развитие физики.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">описывать и объяснять результаты наблюдений и экспериментов:
					взаимодействие проводников с током; действие магнитного поля на проводник с током; зависимость сопротивления
					полупроводников от температуры и освещения; электромагнитная индукция; распространение электромагнитных волн;
					дисперсия, интерференция и дифракция света; излучение и поглощение света атомами, линейчатые спектры;
					фотоэффект; радиоактивность;</li>
				<li class="AdditionalGneral-summary-dict-items">приводить примеры опытов, иллюстрирующих, что: наблюдения и
					эксперимент служат основой для выдвижения гипотез и построения научных теорий; эксперимент позволяет проверить
					истинность теоретических выводов; физическая теория дает возможность объяснять явления природы и научные
					факты; физическая теория позволяет предсказывать еще неизвестные явления и их особенности; при объяснении
					природных явлений используются физические модели; один и тот же природный объект или явление можно исследовать
					на основе использования разных моделей; законы физики и физические теории имеют свои определенные границы
					применимости;</li>
				<li class="AdditionalGneral-summary-dict-items">описывать фундаментальные опыты, оказавшие существенное влияние
					на развитие физики;</li>
				<li class="AdditionalGneral-summary-dict-items">применять полученные знания для решения физических задач;</li>
				<li class="AdditionalGneral-summary-dict-items">определять: характер физического процесса по графику, таблице,
					формуле; продукты ядерных реакций на основе законов сохранения электрического заряда и массового числа;</li>
				<li class="AdditionalGneral-summary-dict-items">измерять: показатель преломления вещества, оптическую силу
					линзы, длину световой волны; представлять результаты измерений с учетом их погрешностей;</li>
				<li class="AdditionalGneral-summary-dict-items">приводить примеры практического применения физических знаний:
					законов электродинамики в энергетике; различных видов электромагнитных излучений для развития радио- и
					телекоммуникаций; квантовой физики в создании ядерной энергетики, лазеров;</li>
				<li class="AdditionalGneral-summary-dict-items">воспринимать и на основе полученных знаний самостоятельно
					оценивать информацию, содержащуюся в сообщениях СМИ, научно-популярных статьях; использовать новые
					информационные технологии для поиска, обработки и предъявления информации по физике в компьютерных базах
					данных и сетях (сети Интернет).</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D1%81%D0%9E%D0%A8-%D0%BF%D0%BE-%D1%84%D0%B8%D0%B7%D0%B8%D0%BA%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Подготовка обучающихся к участию в региональном этапе ВсОШ по экологии
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">36 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 15 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие компетенций сотрудничества со сверстниками, детьми
					младшего возраста, взрослыми в образовательной, общественно полезной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">экологическая культура, бережное отношение к родной земле,
					природным богатствам России и мира;</li>
				<li class="AdditionalGneral-summary-dict-items">понимание влияния социально-экономических процессов на состояние
					природной и социальной среды, ответственность за состояние природных ресурсов;</li>
				<li class="AdditionalGneral-summary-dict-items">умения и навыки разумного природопользования, нетерпимое
					отношение к действиям, приносящим вред экологии; приобретение опыта эколого-направленной деятельности.</li>
				<li class="AdditionalGneral-summary-dict-items">повышение общего культурного уровня учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной и учебно-исследовательской
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умения формулировать собственные мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие навыков межличностного взаимодействия и построения
					коммуникационного процесса.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">изучение основных понятий и принципов экологии, такие как
					взаимодействие организмов с окружающей средой, циклы веществ и энергии, биологическое разнообразие и его
					сохранение.;</li>
				<li class="AdditionalGneral-summary-dict-items">развития умения решать сложные экологические задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование представлений о экологической науке и ее основных
					разделах.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные понятия и закономерности теоретической экологии;</li>
				<li class="AdditionalGneral-summary-dict-items">различные классификации экологических факторов;</li>
				<li class="AdditionalGneral-summary-dict-items">адаптации организмов к действию экологических фактов;</li>
				<li class="AdditionalGneral-summary-dict-items">экологическую структуру и принципы функционирования
					экологических систем разного уровня (популяций, биоценозов, биогеоценозов);</li>
				<li class="AdditionalGneral-summary-dict-items">экологические стратегии выживания организмов;</li>
				<li class="AdditionalGneral-summary-dict-items">основы экологии человека;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы рационального природопользования;</li>
				<li class="AdditionalGneral-summary-dict-items">классификацию природных ресурсов;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы устойчивого развития человечества;</li>
				<li class="AdditionalGneral-summary-dict-items">современные экологические проблемы;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы охраны окружающей среды.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">характеризовать условия обитания организмов и связанные с ними
					приспособления;</li>
				<li class="AdditionalGneral-summary-dict-items">работать с графиками и пользоваться формулами, характеризующими
					состояние популяций и сообществ организмов;</li>
				<li class="AdditionalGneral-summary-dict-items">составлять цепи питания;</li>
				<li class="AdditionalGneral-summary-dict-items">находить решения экологических ситуаций;</li>
				<li class="AdditionalGneral-summary-dict-items">составлять план исследования экологической проблемы.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BA-%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D1%8E-%D0%B2-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC-%D1%8D%D1%82%D0%B0%D0%BF%D0%B5-%D0%92%D1%81%D0%9E%D0%A8-%D0%BF%D0%BE-%D1%8D%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Ракетостроение
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 14 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные: </p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">осознание значимости космической отрасли;</li>
				<li class="AdditionalGneral-summary-dict-items">интерес к деятельности предприятий космической отрасли;</li>
				<li class="AdditionalGneral-summary-dict-items">осознание значимости и возможностей космической съемки;</li>
				<li class="AdditionalGneral-summary-dict-items">интерес к региональным проблемам;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки оформления и подачи проектных работ;</li>
				<li class="AdditionalGneral-summary-dict-items">приобретение профориентационных знаний, которые по окончанию
					школы способствуют в выборе будущей профессии.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">историю астрономии и космонавтики;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы расчёта полёта ракеты;</li>
				<li class="AdditionalGneral-summary-dict-items">основы ракетомоделирования;</li>
				<li class="AdditionalGneral-summary-dict-items">основы схемотехники;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы работы радиоэлектронных компонентов;</li>
				<li class="AdditionalGneral-summary-dict-items">основы программирования микроконтроллеров</li>
				<li class="AdditionalGneral-summary-dict-items">основы дистанционного зондирования Земли;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки поиска и подбора космической съёмки;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки составления синтезов космического изображения;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки распознавания основных объектов на космических снимках;
				</li>
				<li class="AdditionalGneral-summary-dict-items">навыки анализа данных, полученных с помощью космической съемки;
				</li>
				<li class="AdditionalGneral-summary-dict-items">основы проведения экологического мониторинга с помощью
					космической съемки;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки работы с базами данных в программе Qgis;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки создания картографического материала в программе Qgis.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">рассчитывать параметры ракеты и траекторию полета;</li>
				<li class="AdditionalGneral-summary-dict-items">конструировать пневмоводяные ракеты с системой спасения;</li>
				<li class="AdditionalGneral-summary-dict-items">запускать пневмоводяные ракеты;</li>
				<li class="AdditionalGneral-summary-dict-items">собирать электрические цепи;</li>
				<li class="AdditionalGneral-summary-dict-items">программировать микроконтроллеры.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование культуры командной работы и коллективной проектной
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">основные принципы рубрикации и представления
					научно–исследовательских проектных работ.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A0%D0%B0%D0%BA%D0%B5%D1%82%D0%BE%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BD%D0%B8%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Сириус. Лето начни свой проект
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Учащиеся общеобразовательных организаций 13-17 лет, проявляющие
				интерес к научно-исследовательской деятельности.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Предметные;</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированность навыков коммуникативной,
					учебно-исследовательской деятельности, критического мышления;</li>
				<li class="AdditionalGneral-summary-dict-items">способность к инновационной, аналитической, творческой,
					интеллектуальной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность навыков проектной деятельности, а также
					самостоятельного применения приобретенных знаний и способов действий при решении различных задач, используя
					знания нескольких учебных предметов и/или предметных областей;</li>
				<li class="AdditionalGneral-summary-dict-items">способность постановки цели и формулирования гипотезы
					исследования, планирования работы, отбора и интерпретации необходимой информации, структурирования и
					аргументации результатов исследования на основе собранных данных, презентация результатов;</li>
				<li class="AdditionalGneral-summary-dict-items">владение умением излагать результаты проектной работы на
					вебинарах, семинарах, конференциях и т.п.;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность понятий проект, проектирование;</li>
				<li class="AdditionalGneral-summary-dict-items">владение знанием этапов проектной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">владение методами поиска и анализа научной информации.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели деятельности и составлять
					планы деятельности; самостоятельно осуществлять, контролировать и корректировать деятельность; использовать
					все возможные ресурсы для достижения поставленных целей и реализации планов деятельности; выбирать успешные
					стратегии в различных ситуациях;</li>
				<li class="AdditionalGneral-summary-dict-items">умение продуктивно общаться и взаимодействовать в процессе
					совместной деятельности, учитывать позиции других участников деятельности, эффективно разрешать конфликты;
				</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной, учебно-исследовательской и
					проектной деятельности, навыками разрешения проблем; способность и готовность к самостоятельному поиску
					методов решения практических задач, применению различных методов познания;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к самостоятельной
					информационно-познавательной деятельности, включая умение ориентироваться в различных источниках информации,
					критически оценивать и интерпретировать информацию, получаемую из различных источников;</li>
				<li class="AdditionalGneral-summary-dict-items">умение использовать средства информационных и коммуникационных
					технологий (далее - ИКТ) в решении когнитивных, коммуникативных и организационных задач с соблюдением
					требований эргономики, техники безопасности, гигиены, ресурсосбережения, правовых и этических норм, норм
					информационной безопасности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение определять назначение и функции различных социальных
					институтов;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно оценивать и принимать решения,
					определяющие стратегию поведения, с учётом гражданских и нравственных ценностей;</li>
				<li class="AdditionalGneral-summary-dict-items">владение языковыми средствами - умение ясно, логично и точно
					излагать свою точку зрения, использовать адекватные языковые средства;</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками познавательной рефлексии как осознания
					совершаемых действий и мыслительных процессов, их результатов и оснований, границ своего знания и незнания,
					новых познавательных задач и средств их достижения.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">уважение к своему народу, гордости за свой край, свою Родину,
					уважение государственных символов (герб, флаг, гимн);</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность мировоззрения, соответствующего современному
					уровню развития науки и общественной практики, основанного на диалоге культур, а также различных форм
					общественного сознания, осознание своего места в поликультурном мире;</li>
				<li class="AdditionalGneral-summary-dict-items">сформированность основ саморазвития и самовоспитания в
					соответствии с общечеловеческими ценностями и идеалами гражданского общества; готовность и способность к
					самостоятельной, творческой и ответственной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">толерантное сознание и поведение в поликультурном мире,
					готовность и способность вести диалог с другими людьми, достигать в нём взаимопонимания, находить общие цели и
					сотрудничать для их достижения;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки сотрудничества со сверстниками, детьми младшего возраста,
					взрослыми в образовательной, общественно полезной, учебно-исследовательской, проектной и других видах
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность к образованию, в том числе
					самообразованию, на протяжении всей жизни; сознательное отношение к непрерывному образованию как условию
					успешной профессиональной и общественной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">эстетическое отношение к миру, включая эстетику быта, научного и
					технического творчества, спорта, общественных отношений;</li>
				<li class="AdditionalGneral-summary-dict-items">принятие и реализация ценностей здорового и безопасного образа
					жизни, потребности в физическом самосовершенствовании, занятиях спортивно-оздоровительной деятельностью,
					неприятие вредных привычек: курения, употребления алкоголя, наркотиков;</li>
				<li class="AdditionalGneral-summary-dict-items">бережное, ответственное и компетентное отношение к физическому и
					психологическому здоровью, как собственному, так и других людей, умение оказывать первую помощь;</li>
				<li class="AdditionalGneral-summary-dict-items">осознанный выбор будущей профессии и возможностей реализации
					собственных жизненных планов; отношение к профессиональной деятельности как возможности участия в решении
					личных, общественных проблем.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A1%D0%B8%D1%80%D0%B8%D1%83%D1%81.-%D0%9B%D0%B5%D1%82%D0%BE-%D0%BD%D0%B0%D1%87%D0%BD%D0%B8-%D1%81%D0%B2%D0%BE%D0%B9-%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Современная энергетика
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по общеобразовательным
				программам в возрасте от 14 до 17 лет (обучающиеся 8-11 классов).</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">осознание значимости энергетики в целом;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие интереса к деятельности энергетических предприятий
					Иркутской области;</li>
				<li class="AdditionalGneral-summary-dict-items">понимание необходимости бережного отношения к энергоресурсам;
				</li>
				<li class="AdditionalGneral-summary-dict-items"></li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Формируемые знания:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные понятия о физических объектах и процессах;</li>
				<li class="AdditionalGneral-summary-dict-items">взаимосвязь естественно-научных явлений и основных физических
					моделей для их описания и объяснения;</li>
				<li class="AdditionalGneral-summary-dict-items">методы научного познания (наблюдение, описание, измерение,
					эксперимент, выдвижение гипотезы, моделирование и т.д.) и формы научного познания (факты, законы, теории);
				</li>
				<li class="AdditionalGneral-summary-dict-items">глобальные проблемы, стоящие перед человечеством энергетические,
					сырьевые, экологические;</li>
				<li class="AdditionalGneral-summary-dict-items">роль физики в решении этих проблем, необходимость освоения:
					основ термодинамики и электротехники;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы работы электрических станций и альтернативных
					источников энергии;</li>
				<li class="AdditionalGneral-summary-dict-items">технология соединения проводников и элементов схемы;</li>
				<li class="AdditionalGneral-summary-dict-items">алгоритмы программирования автоматизированных технологических
					процессов;</li>
				<li class="AdditionalGneral-summary-dict-items">состояние и перспективы развития энергетики в Иркутской области.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Формируемые умения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">демонстрация на примерах взаимосвязи между физикой и профильными
					предметами энергетической направленности;</li>
				<li class="AdditionalGneral-summary-dict-items">проведение прямых и косвенных измерений физических величин,
					выбор измерительных приборов с учетом необходимой точности измерений, планирование хода измерений, получение
					значений измеряемой величины и оценка относительной погрешности;</li>
				<li class="AdditionalGneral-summary-dict-items">решение задач (в том числе и межпредметного характера) с
					использованием моделей, физических величин и законов, выстраивание логических цепочек объяснения
					(доказательств), предложенных в задачах процессов (явлений);</li>
				<li class="AdditionalGneral-summary-dict-items">использование информации и применение знаний о принципах работы
					энергетического оборудования, приборов и других технических устройств для решения практических,
					учебно-исследовательских и проектных задач;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельное конструирование экспериментальных установок,
					планирование и проведение физических экспериментов;</li>
				<li class="AdditionalGneral-summary-dict-items">решение практико-ориентированных физических задач с опорой на
					известные физические законы, закономерности и модели;</li>
				<li class="AdditionalGneral-summary-dict-items">выполнение схем с использованием элементов электрических цепей
					для проведения экспериментов в цепях постоянного и переменного тока;</li>
				<li class="AdditionalGneral-summary-dict-items">составление программ для технологических процессов.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование культуры командной работы и коллективной
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков трудового творческого сотрудничества.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A1%D0%BE%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD%D0%BD%D0%B0%D1%8F-%D1%8D%D0%BD%D0%B5%D1%80%D0%B3%D0%B5%D1%82%D0%B8%D0%BA%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Страна железных дорог
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">60 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 14 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Воспитательные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированная этика групповой работы, работы в команде,
					спортивная этика,</li>
				<li class="AdditionalGneral-summary-dict-items">умение установить отношения делового сотрудничества,
					взаимоуважение,</li>
				<li class="AdditionalGneral-summary-dict-items">ценностное отношение к своему здоровью,</li>
				<li class="AdditionalGneral-summary-dict-items">ответственное отношение к обучению, готовность к саморазвитию и
					самообразованию.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимание особой роли технического развития в жизни общества и
					каждого отдельного человека;</li>
				<li class="AdditionalGneral-summary-dict-items">эстетические чувства, техническо-творческого мышления,
					наблюдательности и фантазии, потребностей в творческом отношении к окружающему миру, в самостоятельной
					практической технической деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки коллективной деятельности в процессе совместной
					технической работы в команде одноклассников под руководством педагога;</li>
				<li class="AdditionalGneral-summary-dict-items">умение обсуждать и анализировать собственную техническую
					деятельность и работу других учащихся с позиций задач данной темы, с точки зрения содержания и средств его
					выражения;</li>
				<li class="AdditionalGneral-summary-dict-items">потребность в технической самореализации в социально- полезной
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">патриотические чувства и качества;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки здорового образа жизни.</li>
				<li class="AdditionalGneral-summary-dict-items">устойчивый интерес к технике и технологиям, более развитые
					технические способности,</li>
				<li class="AdditionalGneral-summary-dict-items">познавательная активность и способность к самообразованию,</li>
				<li class="AdditionalGneral-summary-dict-items">устойчивый интерес к профессиональной сфере применения БЛА,
					профессии оператора БЛА, профориентация на специальности, связанные с применением БЛА.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение сотрудничать, выполняя различные роли в группе, в
					совместном решении проблемы (задачи);</li>
				<li class="AdditionalGneral-summary-dict-items">умения применять знания об окружающем мире из таких учебных
					предметов (окружающий мир, технология, литература и другие) для мотивации в работе по авиамоделированию;</li>
				<li class="AdditionalGneral-summary-dict-items">развивать мелкую моторику, пластичность, гибкость рук и точность
					глазомера;</li>
				<li class="AdditionalGneral-summary-dict-items">развить мотивацию к овладению техническими процессами
					изготовления моделей любой сложности;</li>
				<li class="AdditionalGneral-summary-dict-items">реализовать технические и творческие способности воспитанников;
				</li>
				<li class="AdditionalGneral-summary-dict-items">развить самостоятельность, ответственность, активность,
					аккуратность, трудолюбие, умение работать в группе в процессе выполнения технической работы;</li>
				<li class="AdditionalGneral-summary-dict-items">развивать потребность в саморазвитии.</li>
				<li class="AdditionalGneral-summary-dict-items">умение ориентироваться в своей системе знаний: отличать новое
					знание от известного;</li>
				<li class="AdditionalGneral-summary-dict-items">умение работать по предложенным инструкциям и самостоятельно;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение излагать мысли в четкой логической последовательности,
					отстаивать свою точку зрения, анализировать ситуацию и самостоятельно находить ответы на вопросы путем
					логических рассуждений;</li>
				<li class="AdditionalGneral-summary-dict-items">умение работать в команде, эффективно распределять обязанности.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знание основ авиамоделирования, основными понятиями и базовыми
					элементами моделирования;</li>
				<li class="AdditionalGneral-summary-dict-items">знание формообразование материаловедения, образное,
					пространственное мышление и умение выразить свою мысль с помощью чертежа, рисунка, авиамодели;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки работы нужными инструментами и приспособлениями при
					обработке различных материалов;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки учебно-исследовательской работы, создавать чертежи и
					шаблоны моделей;</li>
				<li class="AdditionalGneral-summary-dict-items">знание правил техники безопасности при управлении БЛА;</li>
				<li class="AdditionalGneral-summary-dict-items">знание истории, сфер применения БЛА;</li>
				<li class="AdditionalGneral-summary-dict-items">знание устройства БЛА, основных элементов, материалов, из
					которых они изготавливаются;</li>
				<li class="AdditionalGneral-summary-dict-items">знание основ аэродинамики;</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками настройки БЛА;</li>
				<li class="AdditionalGneral-summary-dict-items">владение техниками управления БЛА от «третьего лица», в режиме
					FPV;</li>
				<li class="AdditionalGneral-summary-dict-items">знание правил авиамодельного спорта в классе F3U, их
					практическое применение;</li>
				<li class="AdditionalGneral-summary-dict-items">спортивные навыки, спортивная техника в авиамодельном спорте в
					классе F3U.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понятие FPV пилотирование;</li>
				<li class="AdditionalGneral-summary-dict-items">историю и перспективы пилотирования БЛА в режиме FPV;</li>
				<li class="AdditionalGneral-summary-dict-items">состав FPV комплекта;</li>
				<li class="AdditionalGneral-summary-dict-items">основные авиасимуляторы.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">подключать и настраивать аппаратуру управления для пилотирования
					в авиасимуляторе; </li>
				<li class="AdditionalGneral-summary-dict-items">пилотировать FPV квадрокоптер в акро режиме;</li>
				<li class="AdditionalGneral-summary-dict-items">настраивать квадрокоптер в программе Betaflight Configurator.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Владеть:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">пилотирования в FPV режиме;</li>
				<li class="AdditionalGneral-summary-dict-items">подбора комплектующих для FPV полетов;</li>
				<li class="AdditionalGneral-summary-dict-items">прохождения гоночных трасс в симуляторе на время;</li>
				<li class="AdditionalGneral-summary-dict-items">настройки аппаратуры управления для FPV полетов.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A1%D1%82%D1%80%D0%B0%D0%BD%D0%B0-%D0%B6%D0%B5%D0%BB%D0%B5%D0%B7%D0%BD%D1%8B%D1%85-%D0%B4%D0%BE%D1%80%D0%BE%D0%B3.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Экология
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 12 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие творческих способностей;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие логического мышления;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие интереса к изучению природы, работе в лесу, участию в
					опытных и исследовательских работах;</li>
				<li class="AdditionalGneral-summary-dict-items">приобщение к участию в различных конкурсах, олимпиадах,
					конференциях.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование бережного отношения к природе и использованию ее
					ресурсов;</li>
				<li class="AdditionalGneral-summary-dict-items">выработка нравственных качеств;</li>
				<li class="AdditionalGneral-summary-dict-items">стремление заботиться о тех, кто зависит от человека,
					препятствовать насилию над природой, по мере сил облагораживать ее;</li>
				<li class="AdditionalGneral-summary-dict-items">развивать экологическую культуру и экологическое мышление.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">изучение зависимостей структуры и функций популяций древесных
					растений и экосистем в целом от факторов абиотической и биотической среды с выявлением их относительной
					значимости;</li>
				<li class="AdditionalGneral-summary-dict-items">изучение условия существования природных систем различной
					сложности от организма до экосистемы;</li>
				<li class="AdditionalGneral-summary-dict-items">изучение жизненных форм растений и животных;</li>
				<li class="AdditionalGneral-summary-dict-items">изучение средообразующих функций лесных экосистем;</li>
				<li class="AdditionalGneral-summary-dict-items">знакомство с направлениями работы в лесном комплексе.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">методы поиска, сбора и обработки экологической информации;</li>
				<li class="AdditionalGneral-summary-dict-items">методы исследования в области экологии и природопользования;
				</li>
				<li class="AdditionalGneral-summary-dict-items">методы определения и расчета загрязнения атмосферного воздуха
					отработанными газами автотранспорта.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">осуществлять критический анализ и синтез экологической
					информации, полученной из разных источников;</li>
				<li class="AdditionalGneral-summary-dict-items">применять методы исследования в области экологии и в сфере
					управления природопользования;</li>
				<li class="AdditionalGneral-summary-dict-items">применять методы определения и расчета загрязнения атмосферного
					воздуха отработанными газами автотранспорта.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/05/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				SMM Маркетинг в социальных сетях
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, с использованием дистанционных образовательных
				технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Мобильный Персей.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся образовательных
				организаций в возрасте от 14 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты предполагают:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование эстетического вкуса, чувства гармонии; </li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность учащихся к саморазвитию; </li>
				<li class="AdditionalGneral-summary-dict-items">добиваться точности и завершенности в работе над контентом;
				</li>
				<li class="AdditionalGneral-summary-dict-items">воспитание самодисциплины учащихся; </li>
				<li class="AdditionalGneral-summary-dict-items">уметь оценивать личные и групповые ключевые показатели
					эффективности. </li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение планировать пути реализации поставленной цели; </li>
				<li class="AdditionalGneral-summary-dict-items">развитие надсмотренности; </li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно анализировать и применять полученные
					знания в любых видах творческой деятельности. </li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владеть навыками поиска информации в глобальной сети
					Интернет;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь создавать и продвигать аккаунт в социальной сети;</li>
				<li class="AdditionalGneral-summary-dict-items">создавать материалы в социальных сетях;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать программные средства и работу в компьютерных
					сетях;</li>
				<li class="AdditionalGneral-summary-dict-items">владеть инструментарием работы smm-менеджера;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать в работе навыки правовой культурой.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные этапы развития SMM-маркетинга;</li>
				<li class="AdditionalGneral-summary-dict-items">основные отличия платформ для размещения контента; </li>
				<li class="AdditionalGneral-summary-dict-items">принципы сегментирования целевой аудитории;</li>
				<li class="AdditionalGneral-summary-dict-items">способы продвижения в социальных сетях; </li>
				<li class="AdditionalGneral-summary-dict-items">особенности подготовки и производства контента для
					социальных сетей;</li>
				<li class="AdditionalGneral-summary-dict-items">основные формы и способы предоставления информации в
					социальных сетях;</li>
				<li class="AdditionalGneral-summary-dict-items">грамотное оформление социальных сетей.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">разрабатывать портреты целевой аудитории; </li>
				<li class="AdditionalGneral-summary-dict-items">планировать контент на долгосрочную и краткосрочную
					перспективу; </li>
				<li class="AdditionalGneral-summary-dict-items">создать текстовые и визуальные посты; </li>
				<li class="AdditionalGneral-summary-dict-items">размещать посты в социальных сетях;</li>
				<li class="AdditionalGneral-summary-dict-items">находить информационные поводы;</li>
				<li class="AdditionalGneral-summary-dict-items">создавать различный контент для социальной сети;</li>
				<li class="AdditionalGneral-summary-dict-items">создавать, вести и продвигать страницы, группы, аккаунты;</li>
				<li class="AdditionalGneral-summary-dict-items">пользоваться техническими средствами для полной подготовки
					материалов smm;</li>
				<li class="AdditionalGneral-summary-dict-items">аргументировать собственную точку зрения, вести диалог или
					полемику;</li>
				<li class="AdditionalGneral-summary-dict-items">руководствоваться правовыми нормами.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-SMM-%D0%9C%D0%B0%D1%80%D0%BA%D0%B5%D1%82%D0%B8%D0%BD%D0%B3-%D0%B2-%D1%81%D0%BE%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D1%81%D0%B5%D1%82%D1%8F%D1%85.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам
				дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Литературное редактирование
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				образовательных технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1. </p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся общеобразовательных школ в
				возрасте от 14 до 17 лет. Обучение по программе не предполагает наличие определенных знаний. </p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">По итогам освоения программы обучающиеся будут </p>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные термины и понятия литературного редактирования;</li>
				<li class="AdditionalGneral-summary-dict-items">значения всех используемых на занятии слов и выражений;</li>
				<li class="AdditionalGneral-summary-dict-items">законы комментирования, анализа и исправления текста;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы единства формы и содержания в тексте;</li>
				<li class="AdditionalGneral-summary-dict-items">особенности композиции и стилистики текстов разных стилей;
				</li>
				<li class="AdditionalGneral-summary-dict-items">правила речевого этикета;</li>
				<li class="AdditionalGneral-summary-dict-items">орфоэпические, лексические, грамматические, стилистические
					нормы русского литературного языка;</li>
				<li class="AdditionalGneral-summary-dict-items">достаточный объём словарного запаса и усвоенных грамматических
					средств для свободного выражения мыслей и чувств в процессе речевого общения.
					Уметь
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">работать с особыми зонами текста;</li>
				<li class="AdditionalGneral-summary-dict-items">пользоваться различными алгоритмами редактирования текста;
				</li>
				<li class="AdditionalGneral-summary-dict-items">формулировать цель своей деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">планировать свою деятельность;</li>
				<li class="AdditionalGneral-summary-dict-items">находить и обрабатывать необходимую информацию из различных
					источников;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать опыт общения с текстами разных стилей в
					повседневной жизни и учебной деятельности, речевом совершенствовании;</li>
				<li class="AdditionalGneral-summary-dict-items">оценивать собственную и чужую речь с точки зрения точного,
					уместного и выразительного словоупотребления;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать различные методы работы при решении поставленных
					задач.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">работать с особыми зонами текста;</li>
				<li class="AdditionalGneral-summary-dict-items">пользоваться различными алгоритмами редактирования текста;
				</li>
				<li class="AdditionalGneral-summary-dict-items">формулировать цель своей деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">планировать свою деятельность;</li>
				<li class="AdditionalGneral-summary-dict-items">находить и обрабатывать необходимую информацию из различных
					источников;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать опыт общения с текстами разных стилей в
					повседневной жизни и учебной деятельности, речевом совершенствовании;</li>
				<li class="AdditionalGneral-summary-dict-items">оценивать собственную и чужую речь с точки зрения точного,
					уместного и выразительного словоупотребления;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать различные методы работы при решении поставленных
					задач.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владение всеми видами речевой деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">адекватное понимание информации устного и письменного
					сообщения;</li>
				<li class="AdditionalGneral-summary-dict-items">адекватное восприятие текстов разных стилей и жанров;</li>
				<li class="AdditionalGneral-summary-dict-items">способность извлекать информацию из различных источников,
					включая средства массовой информации, компакт-диски учебного назначения, ресурсы Интернета; свободно
					пользоваться словарями различных типов, справочной литературой;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение приёмами отбора и систематизации материала на
					определённую тему; </li>
				<li class="AdditionalGneral-summary-dict-items">умение вести самостоятельный поиск информации, её анализ и
					отбор;</li>
				<li class="AdditionalGneral-summary-dict-items">способность определять цели предстоящей учебной деятельности
					(индивидуальной и коллективной), последовательность действий, оценивать достигнутые результаты и адекватно
					формулировать их в устной и письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">способность свободно, правильно излагать свои мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">способность применять полученные знания, умения и навыки
					анализа языковых явлений на межпредметном уровне (на уроках иностранного языка, истории, обществознания и
					др.);</li>
				<li class="AdditionalGneral-summary-dict-items">коммуникативно целесообразное взаимодействие с окружающими
					людьми в процессе речевого общения, совместного выполнения какой-либо задачи, участия в спорах, обсуждениях;
				</li>
				<li class="AdditionalGneral-summary-dict-items">овладение национально культурными нормами речевого поведения в
					различных ситуациях формального и неформального межличностного и межкультурного общения.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные образовательные результаты</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">ориентированность на принцип персонализации;</li>
				<li class="AdditionalGneral-summary-dict-items">индивидуализацию процесса обучения (создание условий, в
					которых ребёнок свободно выбирает дополнительную общеобразовательную программу согласно своим интересам и
					склонностям);</li>
				<li class="AdditionalGneral-summary-dict-items">осуществление предпрофессиональной подготовки и раннюю
					профориентацию;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умений вступать в продуктивную коммуникацию и
					кооперацию.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%BD%D0%BE%D0%B5-%D1%80%D0%B5%D0%B4%D0%B0%D0%BA%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Программирование и анализ данных
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, с использованием дистанционных образовательных
				технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Мобильный Персей.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся образовательных
				организаций в возрасте от 14 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты предполагают:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование ответственного отношения к учению, готовности и
					способности учащихся к саморазвитию и самообразованию на основе мотивации к обучению и познанию, осознанному
					выбору и построению дальнейшей индивидуальной траектории образования на базе ориентировки в мире профессий и
					профессиональных предпочтений, с учетом устойчивых познавательных интересов, а также на основе формирования
					уважительного отношения к труду, развития опыта участия в социально значимом труде;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование коммуникативной компетентности в общении и
					сотрудничестве со сверстниками, детьми старшего и младшего возраста, взрослыми в процессе образовательной,
					общественно полезной, учебно-исследовательской, творческой и других видов деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">повышение интереса к научно-исследовательской, познавательной
					деятельности и современным информационным технологиям у учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять цели своего обучения, ставить
					и формулировать для себя новые задачи в учебе и познавательной деятельности, развивать мотивы и интересы своей
					познавательной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно планировать пути достижения целей, в том
					числе альтернативные, осознанно выбирать наиболее эффективные способы решения учебных и познавательных задач;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение оценивать правильность выполнения учебной задачи,
					собственные возможности ее решения;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетенций в области информационных
					технологий и математической статистики;</li>
				<li class="AdditionalGneral-summary-dict-items">умение организовывать учебное сотрудничество и совместную
					деятельность с учителем и сверстниками; работать индивидуально и в группе: находить общее решение и разрешать
					конфликты на основе согласования позиций и учета интересов; формулировать, аргументировать и отстаивать свое
					мнение;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">узнают особенности современных вычислений, технологий, их
					архитектуру;</li>
				<li class="AdditionalGneral-summary-dict-items">узнают методы анализа набора данных, получаемыми из реального
					мира;</li>
				<li class="AdditionalGneral-summary-dict-items">узнают инструменты, которые помогают обрабатывать,
					анализировать и визуализировать большие объемы данных;</li>
				<li class="AdditionalGneral-summary-dict-items">узнают об основных терминах, использующихся при аналитике
					данных (кластер, вектор, временной ряд, неслучайная составляющая временного ряда и др.)</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">язык программирования Python как средство разработки
					аналитических средств;</li>
				<li class="AdditionalGneral-summary-dict-items">методы, инструменты, средства анализа и обработки данных;</li>
				<li class="AdditionalGneral-summary-dict-items">современные технологии для работы с большим объемом данных.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">писать программный код;</li>
				<li class="AdditionalGneral-summary-dict-items">решать задачи обработки временных рядов и их отображения на
					языке программирования Python.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D0%B8-%D0%B0%D0%BD%D0%B0%D0%BB%D0%B8%D0%B7-%D0%B4%D0%B0%D0%BD%D0%BD%D1%8B%D1%85.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Художественное слово (июнь)
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, в том числе с использованием дистанционных
				технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, г. Иркутск, Угольный проезд, 68/1. </p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются общеобразовательных школ в возрасте
				от 10 до 17 лет. Обучение по программе не предполагает наличие определенных знаний. </p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения: </p>

			<p class="AdditionalGneral-summary-dict">знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные термины и понятия разделов русской и мировой
					литературы, а также литературоведения как науки о литературе;</li>
				<li class="AdditionalGneral-summary-dict-items">значения всех используемых на занятии слов и выражений;</li>
				<li class="AdditionalGneral-summary-dict-items">законы чтения, комментирования, анализа и интерпретации
					художественного текста;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы единства художественной формы и содержания в
					литературном произведении;</li>
				<li class="AdditionalGneral-summary-dict-items">особенности поэтики и стилистики отечественных и зарубежных
					авторов литературных произведений;</li>
				<li class="AdditionalGneral-summary-dict-items">правила речевого этикета;</li>
				<li class="AdditionalGneral-summary-dict-items">орфоэпические, лексические, грамматические, стилистические
					нормы русского литературного языка;</li>
				<li class="AdditionalGneral-summary-dict-items">нормы построения художественного текста, его композиционные и
					стилистические особенности;</li>
				<li class="AdditionalGneral-summary-dict-items">достаточный объём словарного запаса и усвоенных грамматических
					средств</li>
				<li class="AdditionalGneral-summary-dict-items">для свободного выражения мыслей и чувств в процессе речевого
					общения.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимать русскую литературу как одну из национально-культурных
					ценностей русского народа;</li>
				<li class="AdditionalGneral-summary-dict-items">осознавать эстетическую ценность русской литературы;</li>
				<li class="AdditionalGneral-summary-dict-items">пользоваться различными алгоритмами постижения смыслов,
					заложенных в художественном тексте;</li>
				<li class="AdditionalGneral-summary-dict-items">формулировать цель своей деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">планировать свою деятельность;</li>
				<li class="AdditionalGneral-summary-dict-items">уметь осуществлять библиографический поиск;</li>
				<li class="AdditionalGneral-summary-dict-items">находить и обрабатывать необходимую информацию из различных
					источников;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать опыт общения с произведениями художественной
					литературы в повседневной жизни и учебной деятельности, речевом совершенствовании;</li>
				<li class="AdditionalGneral-summary-dict-items">оценивать собственную и чужую речь с точки зрения точного,
					уместного и выразительного словоупотребления;</li>
				<li class="AdditionalGneral-summary-dict-items">переводить текст с древнерусского, старославянского и
					церковнославянского языков;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать различные методы работы при решении поставленных
					задач;</li>
				<li class="AdditionalGneral-summary-dict-items">решать олимпиадные задачи по литературе разных уровней
					сложности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">ориентированность на принцип персонализации;</li>
				<li class="AdditionalGneral-summary-dict-items">индивидуализацию процесса обучения (создание условий, в
					которых ребёнок свободно выбирает дополнительную общеобразовательную программу согласно своим интересам и
					склонностям);</li>
				<li class="AdditionalGneral-summary-dict-items">осуществление предпрофессиональной подготовки и раннюю
					профориентацию;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие умений вступать в продуктивную коммуникацию и
					кооперацию.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владение всеми видами речевой деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">адекватное понимание информации устного и письменного
					сообщения;</li>
				<li class="AdditionalGneral-summary-dict-items">адекватное восприятие на слух текстов разных стилей и жанров;
				</li>
				<li class="AdditionalGneral-summary-dict-items">способность извлекать информацию из различных источников,
					включая средства массовой информации, компакт-диски учебного назначения, ресурсы Интернета; свободно
					пользоваться словарями различных типов, справочной литературой;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение приёмами отбора и систематизации материала на
					определённую тему; умение вести самостоятельный поиск информации, её анализ и отбор;</li>
				<li class="AdditionalGneral-summary-dict-items">способность определять цели предстоящей учебной деятельности
					(индивидуальной и коллективной), последовательность действий, оценивать достигнутые результаты и адекватно
					формулировать их в устной и письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">способность свободно, правильно излагать свои мысли в устной и
					письменной форме;</li>
				<li class="AdditionalGneral-summary-dict-items">умение выступать перед аудиторией сверстников с небольшими
					сообщениями, докладами;</li>
				<li class="AdditionalGneral-summary-dict-items">применение приобретённых знаний, умений и навыков в
					повседневной жизни; способность использовать родную литературу как средство получения знаний по другим учебным
					предметам, применять полученные знания, умения и навыки анализа языковых явлений на межпредметном уровне (на
					уроках русского языка, иностранного языка, истории, обществознания и др.);</li>
				<li class="AdditionalGneral-summary-dict-items">коммуникативно целесообразное взаимодействие с окружающими
					людьми в процессе речевого общения, совместного выполнения какой-либо задачи, участия в спорах, обсуждениях;
					овладение национально культурными нормами речевого поведения в различных ситуациях формального и неформального
					межличностного и межкультурного общения.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%BC%D0%B0-%D0%A5%D1%83%D0%B4%D0%BE%D0%B6%D0%B5%D1%81%D1%82%D0%B2%D0%B5%D0%BD%D0%BD%D0%BE%D0%B5-%D1%81%D0%BB%D0%BE%D0%B2%D0%BE.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Умники и умницы
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">очно - заочная, с использованием дистанционных образовательных
				технологий</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">32 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Мобильный Персей.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся образовательных
				организаций в возрасте от 15 до 17 лет. </p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты предполагают:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">осознание литературы родного края как части мирового
					культурного наследия;</li>
				<li class="AdditionalGneral-summary-dict-items">реализация потребности самовыражения через слово </li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимание ключевых проблем изучаемых произведений и их связи с
					исторической эпохой;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">виды заданий и правила ответов (вопросы, требующие быстрого
					устного ответа, и др.);</li>
				<li class="AdditionalGneral-summary-dict-items">правила и формы проведения олимпиадной викторины «Умники и
					умницы» в формате интеллектуального телемарафона;</li>
				<li class="AdditionalGneral-summary-dict-items">историю Иркутской области, историю жизни и быта декабристов в
					Сибири.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">создавать собственные речевые высказывания в устной форме,
					содержащие литературоведческий разбор заданий;</li>
				<li class="AdditionalGneral-summary-dict-items">владеть культурой устной и письменной речи, навыками научного
					исследования;</li>
				<li class="AdditionalGneral-summary-dict-items">нести ответственность за участие в состязаниях;</li>
				<li class="AdditionalGneral-summary-dict-items">воспринимать и осмысливать полученную информацию, владеть
					способами обработки данной информации;</li>
				<li class="AdditionalGneral-summary-dict-items">определять учебную задачу;</li>
				<li class="AdditionalGneral-summary-dict-items">ясно и последовательно излагать свои мысли, аргументировано
					доказывать свою точку зрения;</li>
				<li class="AdditionalGneral-summary-dict-items">владеть своим вниманием;</li>
				<li class="AdditionalGneral-summary-dict-items">сознательно управлять своей памятью и регулировать ее
					проявления, владеть рациональными приемами запоминания;</li>
				<li class="AdditionalGneral-summary-dict-items">владеть навыками поисковой деятельности; </li>
				<li class="AdditionalGneral-summary-dict-items">использовать основные приемы мыслительной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельно мыслить.</li>
				<li class="AdditionalGneral-summary-dict-items">владеть мотивацией личностного успеха.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A3%D0%BC%D0%BD%D0%B8%D0%BA%D0%B8-%D0%B8-%D1%83%D0%BC%D0%BD%D0%B8%D1%86%D1%8B.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Региональные сборы обучающихся по подготовке к программме Сириус. ИИ
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная, с использованием дистанционных образовательных технологий
			</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">54 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются обучающиеся образовательных
				организаций в возрасте от 13 до 17 лет. </p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование ответственного отношения к учению, готовности и
					способности учащихся к саморазвитию и самообразованию на основе мотивации к обучению и познанию, осознанному
					выбору и построению дальнейшей индивидуальной траектории образования на базе ориентировки в мире профессий и
					профессиональных предпочтений, с учетом устойчивых познавательных интересов, а также на основе формирования
					уважительного отношения к труду, развития опыта участия в социально значимом труде;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование коммуникативной компетентности в общении и
					сотрудничестве со сверстниками, взрослыми в процессе образовательной, учебно-исследовательской, проектной и
					других видов деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">повышение интереса к научно-исследовательской, познавательной
					деятельности и современным информационным технологиям у учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно определять ставить цели своей
					деятельности в решении проектных задач; формулировать задачи для её реализации, развивать мотивы и интересы
					своей познавательной деятельности в рамках решения поставленных задач; </li>
				<li class="AdditionalGneral-summary-dict-items">умение найти решение задачи с помощью ИИ, планировать этапы
					процесса решения учебных и реальных задач, собственные возможности ее решения;</li>
				<li class="AdditionalGneral-summary-dict-items">самому реализовать каждый из этих этапов и решить поставленную
					задачу;</li>
				<li class="AdditionalGneral-summary-dict-items">умение организовывать учебное сотрудничество и совместную
					деятельность, формировать команду и привлекать партнёров и сторонние ресурсы для решения задач;</li>
				<li class="AdditionalGneral-summary-dict-items">находить общее решение и разрешать конфликты на основе
					согласования позиций и учета интересов; формулировать, аргументировать и отстаивать свое мнение;</li>
				<li class="AdditionalGneral-summary-dict-items">умение формулировать и проверять собственные исследовательские
					гипотезы;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование и развитие компетенций в области информационных
					технологий и машинного обучения;</li>
				<li class="AdditionalGneral-summary-dict-items">умение решать реальные проектные задачи с помощью машинного
					обучения.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты обучения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">узнают особенности современных вычислений, технологий ИИ;</li>
				<li class="AdditionalGneral-summary-dict-items">узнают методы анализа набора данных, получаемыми из реального
					мира;</li>
				<li class="AdditionalGneral-summary-dict-items">узнают инструменты, которые помогают обрабатывать,
					анализировать и визуализировать большие объемы данных;</li>
				<li class="AdditionalGneral-summary-dict-items">понимание создания интеллектуальных помощников; </li>
				<li class="AdditionalGneral-summary-dict-items">основ машинного обучения и компьютерной лингвистики. </li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать: </p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">откуда берутся и как хранятся данные;</li>
				<li class="AdditionalGneral-summary-dict-items">как отвечать на вопросы с помощью данных;</li>
				<li class="AdditionalGneral-summary-dict-items">язык программирования Python как средство разработки
					аналитических средств;</li>
				<li class="AdditionalGneral-summary-dict-items">какие существуют современные инструменты для анализа данных и
					как ими пользоваться;</li>
				<li class="AdditionalGneral-summary-dict-items">основные принципы создания моделей ИИ;</li>
				<li class="AdditionalGneral-summary-dict-items">как работают и обучаются нейронные сети;</li>
				<li class="AdditionalGneral-summary-dict-items">методы, инструменты, средства анализа и обработки данных;</li>
				<li class="AdditionalGneral-summary-dict-items">методы формирования запросов к большим языковым моделям
					(промты); </li>
				<li class="AdditionalGneral-summary-dict-items">принципы создания систем, связанных с обработкой естественного
					языка — умных колонок, поисковиков и голосовых ассистентов;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы работы генеративного ИИ.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">писать программный код;</li>
				<li class="AdditionalGneral-summary-dict-items">решать задачи обработки и анализа данных на языке
					программирования Python;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать нейросети для решения специализированных задач;
				</li>
				<li class="AdditionalGneral-summary-dict-items">обрабатывать данные перед анализом или обучением нейронных
					сетей;</li>
				<li class="AdditionalGneral-summary-dict-items">работать с GPT-чатами;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать генеративный искусственный интеллект в решении
					практических и учебных задач.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<!-- <a class="Documents-list-items-link" href="" target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a> -->
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Основы фотосъемки фотодни
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">108 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по дополнительным
				общеразвивающим программам, в возрасте от 14 до 17 лет. </p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование эстетического вкуса, чувства гармонии; </li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность учащихся к саморазвитию; </li>
				<li class="AdditionalGneral-summary-dict-items">добиваться точности и завершенности в работе над
					фотопроектами;</li>
				<li class="AdditionalGneral-summary-dict-items">проявление чувственно-эмоционального отношения к объектам
					фотосъёмки; </li>
				<li class="AdditionalGneral-summary-dict-items">воспитание самодисциплины учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение планировать пути реализации поставленной цели;</li>
				<li class="AdditionalGneral-summary-dict-items">умение объективно оценивать результат своей работы; </li>
				<li class="AdditionalGneral-summary-dict-items">умение слушать, понимать других людей при совместной работе;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно анализировать и применять полученные
					знания в любых видах творческой деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты обучения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">усвоить основные этапы проведения фотосъемки;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыка составления фотокомпозиции;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков обработки фотографий в графических
					редакторах;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков систематизации и размещения в сети
					Интернет графических файлов; </li>
				<li class="AdditionalGneral-summary-dict-items">развитие способности презентовать достигнутые результаты,
					включая умение определять приоритеты целей с учетом ценностей и жизненных планов; самостоятельно
					реализовывать, контролировать и осуществлять коррекцию своей деятельности на основе предварительного
					планирования. </li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные этапы предпродакшна, продакшена и постпродакшена
					съемки;</li>
				<li class="AdditionalGneral-summary-dict-items">основные отличия между искусственным и естественным светом;
				</li>
				<li class="AdditionalGneral-summary-dict-items">особенности подготовки и организации разножанровых съемок;
				</li>
				<li class="AdditionalGneral-summary-dict-items">способы продвижения в социальных сетях.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проводить разножанровые съемки; </li>
				<li class="AdditionalGneral-summary-dict-items">встраивать коммуникацию и работу с моделью/командой; </li>
				<li class="AdditionalGneral-summary-dict-items">организовывать и проводить работу на разных этапах съемки;
				</li>
				<li class="AdditionalGneral-summary-dict-items">обрабатывать фотографии;</li>
				<li class="AdditionalGneral-summary-dict-items">подбирать референсы;</li>
				<li class="AdditionalGneral-summary-dict-items">создавать различный контент для продвижения фотографа в
					социальных сетях.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<!-- <a class="Documents-list-items-link" href="" target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a> -->
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Региональные сборы по подготовке проектов по направлению Агропромышленные и биотехнологии
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику дистанционных курсов</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная, с использованием дистанционных образовательных технологий
			</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">12 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся по дополнительным
				общеразвивающим программам, в возрасте от 14 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">осознание о месте сельского хозяйства в экономике страны и его
					связях с другими отраслями; </li>
				<li class="AdditionalGneral-summary-dict-items">интерес к законам рыночного производства, основным элементы
					рыночного механизма,</li>
				<li class="AdditionalGneral-summary-dict-items">интерес к агропромышленным знаниям;</li>
				<li class="AdditionalGneral-summary-dict-items">способность к самопознанию, самооценке и самоорганизации;</li>
				<li class="AdditionalGneral-summary-dict-items">уважение к другому человеку, его мнению.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметныe результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно ставить новые задачи на основе развития
					познавательных мотивов и интересов;</li>
				<li class="AdditionalGneral-summary-dict-items">умение планировать пути реализации поставленной цели; </li>
				<li class="AdditionalGneral-summary-dict-items">умение объективно оценивать результат своей работы; </li>
				<li class="AdditionalGneral-summary-dict-items">умение слушать, понимать других людей при совместной работе.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные результаты обучения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">усвоить первичные знания по организации и выполнению работ по
					производству, первичной обработке, хранению и транспортировке сельскохозяйственной продукции;</li>
				<li class="AdditionalGneral-summary-dict-items">знать сельскохозяйственные культуры (сорта и гибриды), -
					семена и посадочный материал;</li>
				<li class="AdditionalGneral-summary-dict-items">иметь представление о почве и ее плодородии; </li>
				<li class="AdditionalGneral-summary-dict-items">иметь представление о удобрениях, пестицидах;
					сельскохозяйственной технике и оборудовании;</li>
				<li class="AdditionalGneral-summary-dict-items">сформировать природоохранные, природосберегающие,
					природовосстановительные навыки ведения сельскохозяйственной деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">освоить основы исследовательской деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные понятия и термины предпринимательской деятельности;
				</li>
				<li class="AdditionalGneral-summary-dict-items">об основных формах и видах предпринимательства;</li>
				<li class="AdditionalGneral-summary-dict-items">об основах целеполагания и командообразования в проекте;</li>
				<li class="AdditionalGneral-summary-dict-items">об особенностях маркетингового планирования;</li>
				<li class="AdditionalGneral-summary-dict-items">знаний о документах, используемых в предпринимательской
					деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формулировать бизнес-идею;</li>
				<li class="AdditionalGneral-summary-dict-items">анализировать рынок, целевую аудиторию;</li>
				<li class="AdditionalGneral-summary-dict-items">составлять маркетинговый план, бизнес-план;</li>
				<li class="AdditionalGneral-summary-dict-items">презентовать бизнес-проект.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<!-- <a class="Documents-list-items-link" href="" target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a> -->
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Авиамоделирование и беспилотный транспорт
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Иркутская область, Ангарский район, 8,351 км автодороги
				Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title"></p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения: </p>

			<p class="AdditionalGneral-summary-dict">Личностные: </p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">понимание особой роли технического развития в жизни общества и
					каждого отдельного человека;</li>
				<li class="AdditionalGneral-summary-dict-items">эстетические чувства, техническо-творческого мышления,
					наблюдательности и фантазии, потребностей в творческом отношении к окружающему миру, в самостоятельной
					практической технической деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки коллективной деятельности в процессе совместной
					технической работы в команде одноклассников под руководством педагога;</li>
				<li class="AdditionalGneral-summary-dict-items">умение обсуждать и анализировать собственную техническую
					деятельность и работу других учащихся с позиций задач данной темы, с точки зрения содержания и средств его
					выражения;</li>
				<li class="AdditionalGneral-summary-dict-items">потребность в технической самореализации в социально- полезной
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">патриотические чувства и качества;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки здорового образа жизни;</li>
				<li class="AdditionalGneral-summary-dict-items">устойчивый интерес к технике и технологиям, более развитые
					технические способности;</li>
				<li class="AdditionalGneral-summary-dict-items">познавательная активность и способность к самообразованию;
				</li>
				<li class="AdditionalGneral-summary-dict-items">устойчивый интерес к профессиональной сфере применения БЛА,
					профессии оператора БЛА, профориентация на специальности, связанные с применением БЛА.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знание основ авиамоделирования, основными понятиями и базовыми
					элементами моделирования;</li>
				<li class="AdditionalGneral-summary-dict-items">знание формообразование материаловедения, образное,
					пространственное мышление и умение выразить свою мысль с помощью чертежа, рисунка, авиамодели;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки работы нужными инструментами и приспособлениями при
					обработке различных материалов;</li>
				<li class="AdditionalGneral-summary-dict-items">навыки учебно-исследовательской работы, создавать чертежи и
					шаблоны моделей;</li>
				<li class="AdditionalGneral-summary-dict-items">знание правил техники безопасности при управлении БПЛА;</li>
				<li class="AdditionalGneral-summary-dict-items">знание истории, сфер применения БПЛА;</li>
				<li class="AdditionalGneral-summary-dict-items">знание устройства БПЛА, основных элементов, материалов, из
					которых они </li>
				<li class="AdditionalGneral-summary-dict-items">знание основ аэродинамики;</li>
				<li class="AdditionalGneral-summary-dict-items">владение навыками настройки БПЛА;</li>
				<li class="AdditionalGneral-summary-dict-items">владение техниками управления БПЛА от «первого лица», в режиме
					FPV;</li>
				<li class="AdditionalGneral-summary-dict-items">знание правил «дрон-рейсинга» спорта в классе F9U, их
					практическое применение;</li>
				<li class="AdditionalGneral-summary-dict-items">спортивные навыки, спортивная техника в авиамодельном спорте в
					классе F9U.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение сотрудничать, выполняя различные роли в группе, в
					совместном решении проблемы (задачи);</li>
				<li class="AdditionalGneral-summary-dict-items">умения применять знания об окружающем мире из таких учебных
					предметов (окружающий мир, технология, литература и другие) для мотивации в работе по построению и разработке
					конструкции дронов;</li>
				<li class="AdditionalGneral-summary-dict-items">развивать мелкую моторику, пластичность, гибкость рук и
					точность глазомера;</li>
				<li class="AdditionalGneral-summary-dict-items">развить мотивацию к овладению техническими процессами
					изготовления моделей любой сложности;</li>
				<li class="AdditionalGneral-summary-dict-items">реализовать технические и творческие способности
					воспитанников;</li>
				<li class="AdditionalGneral-summary-dict-items">развить самостоятельность, ответственность, активность,
					аккуратность, трудолюбие, умение работать в группе в процессе выполнения технической работы;</li>
				<li class="AdditionalGneral-summary-dict-items">развивать потребность в саморазвитии;</li>
				<li class="AdditionalGneral-summary-dict-items">умение ориентироваться в своей системе знаний: отличать новое
					знание от известного;</li>
				<li class="AdditionalGneral-summary-dict-items">умение работать по предложенным инструкциям и самостоятельно;
				</li>
				<li class="AdditionalGneral-summary-dict-items">умение излагать мысли в четкой логической последовательности,
					отстаивать свою точку зрения, анализировать ситуацию и самостоятельно находить ответы на вопросы путем
					логических рассуждений;</li>
				<li class="AdditionalGneral-summary-dict-items">умение работать в команде, эффективно распределять
					обязанности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Воспитательные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">сформированная этика групповой работы, работы в команде,
					спортивная этика;</li>
				<li class="AdditionalGneral-summary-dict-items">умение установить отношения делового сотрудничества,
					взаимоуважение,</li>
				<li class="AdditionalGneral-summary-dict-items">ценностное отношение к своему здоровью;</li>
				<li class="AdditionalGneral-summary-dict-items">ответственное отношение к обучению, готовность к саморазвитию
					и самообразованию.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные алгоритмические конструкции;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы построения блок-схем;</li>
				<li class="AdditionalGneral-summary-dict-items">что такое БПЛА и их предназначение. </li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">составлять алгоритмы для решения прикладных задач;</li>
				<li class="AdditionalGneral-summary-dict-items">настраивать БПЛА; </li>
				<li class="AdditionalGneral-summary-dict-items">представлять свой проект.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Владеть:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основной терминологией в области алгоритмизации и
					программирования;</li>
				<li class="AdditionalGneral-summary-dict-items">знаниями по устройству и применению беспилотников.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%90%D0%B2%D0%B8%D0%B0%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D0%B8-%D0%B1%D0%B5%D1%81%D0%BF%D0%B8%D0%BB%D0%BE%D1%82%D0%BD%D1%8B%D0%B9-%D1%82%D1%80%D0%B0%D0%BD%D1%81%D0%BF%D0%BE%D1%80%D1%82.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Школа безопасности
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 10 до 17 лет. </p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Ценностно-смысловые компетенции: </p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение принимать решения при оказании первой помощи и
					организации спасения пострадавших;</li>
				<li class="AdditionalGneral-summary-dict-items">выполнение обязанностей в группе под контролем педагога.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Учебно-познавательные компетенции:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владеть техникой организации спасательных работ, навыками
					преодоления естественных препятствий при помощи туристического снаряжения;</li>
				<li class="AdditionalGneral-summary-dict-items">владеть навыками самостоятельного ориентирования на местности,
					участия в соревнованиях по поисково–спасательным работам.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Социокультурные компетенции:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владеть культурными нормами и традициями родного края;</li>
				<li class="AdditionalGneral-summary-dict-items">владеть навыками позитивного поведения в группе.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Коммуникативные компетенции:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение совместно выполнять поставленные задачи в микрогруппах.
				</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Информационные компетенции:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">владеть навыками работы с источниками информации: правилами
					соревнований, регламентами организации спасательных работ, справочниками, учебниками, картами.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Природоведческие и здоровье сберегающие компетенции:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">знать и применять правила юных туристов - спасателей, основные
					правила поведения в экстремальных ситуациях в природной среде; </li>
				<li class="AdditionalGneral-summary-dict-items">знать и применять правила личной гигиены, уметь заботиться о
					личной безопасности.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A8%D0%BA%D0%BE%D0%BB%D0%B0-%D0%B1%D0%B5%D0%B7%D0%BE%D0%BF%D0%B0%D1%81%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Олимпиадная математика
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">66 часов</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 10 до 18 лет. </p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения: </p>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">свойства чисел для решения логических задач;</li>
				<li class="AdditionalGneral-summary-dict-items">правила сложения и умножения;</li>
				<li class="AdditionalGneral-summary-dict-items">понятия: масштаб и объем, площадь поверхности, площади и
					суммы;</li>
				<li class="AdditionalGneral-summary-dict-items">круги Эйлера и применять их при решении задач;</li>
				<li class="AdditionalGneral-summary-dict-items">Арабскую и Римскую нумерацию чисел. Действие с ними.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">применять свойства чисел для решения логических задач;</li>
				<li class="AdditionalGneral-summary-dict-items">пользоваться алгоритмом Евклида;</li>
				<li class="AdditionalGneral-summary-dict-items">доказывать изученные в курсе теоремы;</li>
				<li class="AdditionalGneral-summary-dict-items">решать задачи про рыцарей и лжецов;</li>
				<li class="AdditionalGneral-summary-dict-items">решать задачи с софизмами и криптограммами;</li>
				<li class="AdditionalGneral-summary-dict-items">переводить числа из десятичной в недесятичные системы
					счисления и наоборот.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<!-- <a class="Documents-list-items-link" href="" target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a> -->
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Искусство фотосъёмки
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 10 до 17 лет.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения: </p>

			<p class="AdditionalGneral-summary-dict">Личностные: </p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">формирование эстетического вкуса, чувства гармонии; </li>
				<li class="AdditionalGneral-summary-dict-items">готовность и способность учащихся к саморазвитию; </li>
				<li class="AdditionalGneral-summary-dict-items">добиваться точности и завершенности в работе над
					фотопроектами;</li>
				<li class="AdditionalGneral-summary-dict-items">проявление чувственно-эмоционального отношения к объектам
					фотосъёмки; </li>
				<li class="AdditionalGneral-summary-dict-items">воспитание самодисциплины учащихся.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">проявлять интерес к фотосъёмке;</li>
				<li class="AdditionalGneral-summary-dict-items">усвоить основные этапы проведения фотосъемки;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование первоначальных представлений о системе
					фотографических жанров, формирование навыка составления фотокомпозиции;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков обработки фотографий в графических
					редакторах; </li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыков систематизации и размещения в сети
					Интернет графических файлов; </li>
				<li class="AdditionalGneral-summary-dict-items">развитие способности презентовать достигнутые результаты,
					включая умение определять приоритеты целей с учетом ценностей и жизненных планов; самостоятельно
					реализовывать, контролировать и осуществлять коррекцию своей деятельности на основе предварительного
					планирования. </li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">умение планировать пути реализации поставленной цели; </li>
				<li class="AdditionalGneral-summary-dict-items">умение объективно оценивать правильность решения задачи; </li>
				<li class="AdditionalGneral-summary-dict-items">умение проявлять гибкость и быть способным изменить тактику
					поведения в случае кардинальной смены ситуации; </li>
				<li class="AdditionalGneral-summary-dict-items">умение самостоятельно анализировать и применять полученные
					знания в любых видах творческой деятельности. </li>
				<li class="AdditionalGneral-summary-dict-items"></li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные экспозиционные композиционные составляющие
					фотосъемки;</li>
				<li class="AdditionalGneral-summary-dict-items">жанры фотосъемкиp;</li>
				<li class="AdditionalGneral-summary-dict-items">оборудование для фотосъемки.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">уметь находить необходимый выразительный метод (с помощью
					жанров фотографии и композиционных составляющих) для передачи истории при работе над фотопроектами;</li>
				<li class="AdditionalGneral-summary-dict-items">обрабатывать фото, работать с фотооборудованием;</li>
				<li class="AdditionalGneral-summary-dict-items">работать над личным портфолио фотографа, презентовать свои
					работ.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Толстихина Татьяна Павловна</p>
			<p class="AdditionalGneral-summary-sub-title">n.tolstihina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<!-- <a class="Documents-list-items-link" href="" target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a> -->
		</details>


		<!-- Спорт -->

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Греко-римская борьба
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 10 до 18 лет, проживающие на территории Иркутской области, прошедшие отбор и попавшие
				в состав сборной команды от муниципальных образований, Иркутской области по виду спорта «Греко-римская борьба».
				Отбор проводится на основе результатов выступления на мероприятиях единого календарного плана межрегиональных,
				всероссийских и международных физкультурных мероприятий, и спортивных мероприятий на 2023 и 2024 год.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">совершенствование общих и специальных физических качеств,
					технической, тактической и психологической подготовки;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">анализировать свои ошибки, делать выводы</li>
				<li class="AdditionalGneral-summary-dict-items">участвовать в диалоге при выполнении заданий;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять взаимопроверку.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">чувство дружбы, товарищества, взаимопомощи;</li>
				<li class="AdditionalGneral-summary-dict-items">чувство ответственности, уважения, дисциплинированности,
					активности, самостоятельности, инициативности и творчества;</li>
				<li class="AdditionalGneral-summary-dict-items">смелость, стойкость, решительность, выдержка, мужество;</li>
				<li class="AdditionalGneral-summary-dict-items">уважительное отношение к чужому мнению; </li>
				<li class="AdditionalGneral-summary-dict-items">чувство уверенности в себе, вера в свои возможности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">основные показатели и закономерности физического развития,
					свойственные спортсменам его пола и возраста;</li>
				<li class="AdditionalGneral-summary-dict-items">историю и правила греко-римской борьбы;</li>
				<li class="AdditionalGneral-summary-dict-items">принципы здорового образа жизни, основанного на занятиях
					физической культурой и спортом;</li>
				<li class="AdditionalGneral-summary-dict-items">суть физиологических и биохимических изменений, происходящих
					во время нагрузки и восстановления при занятиях физической культурой и спортом, терминологию этих изменений;
				</li>
				<li class="AdditionalGneral-summary-dict-items">о существовании перечня запрещённых к использованию в спорте
					методов и веществ, порядок и правила допинг-тестирования;</li>
				<li class="AdditionalGneral-summary-dict-items">основы спортивной гигиены;</li>
				<li class="AdditionalGneral-summary-dict-items">режим дня, о закаливании организма, о здоровом образе жизни;
				</li>
				<li class="AdditionalGneral-summary-dict-items">основы спортивного питания;</li>
				<li class="AdditionalGneral-summary-dict-items">основные методики тренировки по греко-римской борьбе;</li>
				<li class="AdditionalGneral-summary-dict-items">требования техники безопасности при занятиях борьбой;</li>
				<li class="AdditionalGneral-summary-dict-items">элементы техники безопасности (страховки и самостраховки);
				</li>
				<li class="AdditionalGneral-summary-dict-items">основные качества, развиваемые в борьбе.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">быть активным участником тренировочного процесса;</li>
				<li class="AdditionalGneral-summary-dict-items">находить рациональное решение поставленной задачи, замечать и
					исправлять свои ошибки;</li>
				<li class="AdditionalGneral-summary-dict-items">выполнять упражнения согласно модулю, взаимодействовать с
					партнером на тренировочном занятии;</li>
				<li class="AdditionalGneral-summary-dict-items">применять тренировочные методы для повышения
					работоспособности;</li>
				<li class="AdditionalGneral-summary-dict-items">применять технику борьбы в стойке и партере для достижения
					побед в спортивных поединках;</li>
				<li class="AdditionalGneral-summary-dict-items">концентрировать внимание в ходе поединка;</li>
				<li class="AdditionalGneral-summary-dict-items">применять упражнения, спортивное оборудование, инвентарь,
					особенности окружающей природы, ландшафта и прочие особенности экологической среды для собственного
					физического развития, сохранения и укрепления здоровья;</li>
				<li class="AdditionalGneral-summary-dict-items">подходить в наивысшей спортивной форме к наиболее важным
					соревнованиям;</li>
				<li class="AdditionalGneral-summary-dict-items">преодолевать предсоревновательные и соревновательные факторы,
					воздействующие на психологическое состояние спортсмена;</li>
				<li class="AdditionalGneral-summary-dict-items">контролировать собственный вес, уровень своего физического
					развития и его динамику во времени.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">владеть/использовать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">всеми основными разделами техники греко-римской борьбы;</li>
				<li class="AdditionalGneral-summary-dict-items">техникой и методикой развития основных физических качеств;</li>
				<li class="AdditionalGneral-summary-dict-items">основами технических и тактических действий по греко-римской
					борьбе;</li>
				<li class="AdditionalGneral-summary-dict-items">различными алгоритмами технико-тактических действий;</li>
				<li class="AdditionalGneral-summary-dict-items">навыками анализа технико-тактических действий;</li>
				<li class="AdditionalGneral-summary-dict-items">включение занятий физической культурой и спортом в активный
					отдых и досуг;</li>
				<li class="AdditionalGneral-summary-dict-items">демонстрировать технику начальных двигательных действий
					акробатических и специально-подготовительных упражнений по греко-римской борьбе;</li>
				<li class="AdditionalGneral-summary-dict-items">выбор индивидуальной траектории физического развития
					профессионального самоопределения в области физической культуры и спорта, в частности вовлечение в систему
					систематических занятий греко-римской борьбой.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Капустина Екатерина Станиславна</p>
			<p class="AdditionalGneral-summary-sub-title">e.kapustina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/2.%D0%94%D0%9E%D0%9F-%D0%93%D1%80%D0%B5%D0%BA%D0%BE-%D1%80%D0%B8%D0%BC%D1%81%D0%BA%D0%B0%D1%8F-%D0%B1%D0%BE%D1%80%D1%8C%D0%B1%D0%B0.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Дзюдо
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 10 до 17 лет, прошедшие конкурсный отбор. Отбор проводится на основе личностных
				результатов выступления на мероприятиях по дзюдо Календарного плана физкультурных мероприятий и спортивных
				мероприятий Иркутской области на 2024 год, утвержденного распоряжением министерства спорта Иркутской области.
			</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">совершенствование общих и специальных физических качеств,
					технической, тактической и психологической подготовки;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение навыками выполнения техники бросков через спину с
					разных захватов и с разных направлений;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">анализировать свои ошибки, делать выводы</li>
				<li class="AdditionalGneral-summary-dict-items">участвовать в диалоге при выполнении заданий;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять взаимопроверку.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">чувство дружбы, товарищества, взаимопомощи;</li>
				<li class="AdditionalGneral-summary-dict-items">чувство ответственности, уважения, дисциплинированности,
					активности, самостоятельности, инициативности и творчества; </li>
				<li class="AdditionalGneral-summary-dict-items">смелость, стойкость, решительность, выдержка, мужество;</li>
				<li class="AdditionalGneral-summary-dict-items">уважительное отношение к чужому мнению; </li>
				<li class="AdditionalGneral-summary-dict-items">чувство уверенности в себе, вера в свои возможности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">особенности функционирования организма;</li>
				<li class="AdditionalGneral-summary-dict-items">иметь представление об истории и основании дзюдо.</li>
				<li class="AdditionalGneral-summary-dict-items">начальные основы стоек, передвижений, захватов в дзюдо;</li>
				<li class="AdditionalGneral-summary-dict-items">основы правил гигиены и здорового образа жизни;</li>
				<li class="AdditionalGneral-summary-dict-items">элементы техники безопасности (страховки и самостраховки);</li>
				<li class="AdditionalGneral-summary-dict-items">правила подвижных игр и упрощенные правила по дзюдо.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">быть активным участником тренировочного процесса;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать свой технический арсенал для успешного выполнения
					поставленной задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">замечать и исправлять свои ошибки;</li>
				<li class="AdditionalGneral-summary-dict-items">понимать информацию, представленную в виде текста, рисунков,
					схем;</li>
				<li class="AdditionalGneral-summary-dict-items">находить рациональное решение поставленной задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">правильно передвигаться по татами;</li>
				<li class="AdditionalGneral-summary-dict-items">выполнять упражнения согласно модулю, взаимодействовать с
					партнером на тренировочном занятии;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">владеть/использовать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">демонстрировать технику начальных двигательных действий
					акробатических и специально-подготовительных упражнений дзюдо;</li>
				<li class="AdditionalGneral-summary-dict-items">выбор индивидуальной траектории физического развития
					профессионального самоопределения в области физической культуры и спорта, в частности вовлечение в систему
					систематических занятий дзюдо.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Капустина Екатерина Станиславна</p>
			<p class="AdditionalGneral-summary-sub-title">e.kapustina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/2.-%D0%94%D0%9E%D0%9F.pdf" target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Дзюдо. Путь к успеху
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся образовательных
				организаций в возрасте от 10 до 18 лет, прошедшие конкурсный отбор. Отбор проводится на основе личностных
				результатов выступления на мероприятиях по дзюдо Календарного плана физкультурных мероприятий и спортивных
				мероприятий Иркутской области на 2024 год, утвержденного распоряжением министерства спорта Иркутской области.
			</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">совершенствование общих и специальных физических качеств,
					технической, тактической и психологической подготовки;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение навыками выполнения техники бросков через спину с
					разных захватов и с разных направлений;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">анализировать свои ошибки, делать выводы</li>
				<li class="AdditionalGneral-summary-dict-items">участвовать в диалоге при выполнении заданий;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять взаимопроверку.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">чувство дружбы, товарищества, взаимопомощи; </li>
				<li class="AdditionalGneral-summary-dict-items">чувство ответственности, уважения, дисциплинированности,
					активности, самостоятельности, инициативности и творчества; </li>
				<li class="AdditionalGneral-summary-dict-items">смелость, стойкость, решительность, выдержка, мужество;</li>
				<li class="AdditionalGneral-summary-dict-items">уважительное отношение к чужому мнению; </li>
				<li class="AdditionalGneral-summary-dict-items">чувство уверенности в себе, вера в свои возможности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">знать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">особенности функционирования организма;</li>
				<li class="AdditionalGneral-summary-dict-items">иметь представление об истории и основании дзюдо.</li>
				<li class="AdditionalGneral-summary-dict-items">начальные основы стоек, передвижений, захватов в дзюдо;</li>
				<li class="AdditionalGneral-summary-dict-items">основы правил гигиены и здорового образа жизни;</li>
				<li class="AdditionalGneral-summary-dict-items">элементы техники безопасности (страховки и самостраховки);
				</li>
				<li class="AdditionalGneral-summary-dict-items">правила подвижных игр и упрощенные правила по дзюдо.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">уметь:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">быть активным участником тренировочного процесса;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать свой технический арсенал для успешного выполнения
					поставленной задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">замечать и исправлять свои ошибки;</li>
				<li class="AdditionalGneral-summary-dict-items">понимать информацию, представленную в виде текста, рисунков,
					схем;</li>
				<li class="AdditionalGneral-summary-dict-items">находить рациональное решение поставленной задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">правильно передвигаться по татами;</li>
				<li class="AdditionalGneral-summary-dict-items">выполнять упражнения согласно модулю, взаимодействовать с
					партнером на тренировочном занятии;</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">владеть/использовать:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">демонстрировать технику начальных двигательных действий
					акробатических и специально-подготовительных упражнений дзюдо;</li>
				<li class="AdditionalGneral-summary-dict-items">выбор индивидуальной траектории физического развития
					профессионального самоопределения в области физической культуры и спорта, в частности вовлечение в систему
					систематических занятий дзюдо.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Капустина Екатерина Станиславна</p>
			<p class="AdditionalGneral-summary-sub-title">e.kapustina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/2.-%D0%94%D0%9E%D0%9F-1.pdf" target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Самбо
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся общеобразовательных
				организаций в возрасте от 10 до 17 лет, не имеющих медицинских противопоказаний к занятиям самбо, проживающие на
				территории Иркутской области, прошедшие отбор и попавшие в состав сборной команды от муниципальных образований,
				Иркутской области по виду спорта «Самбо». Отбор проводится на основе результатов выступления на соревнованиях по
				самбо в соответствии с единым календарным планом межрегиональных, всероссийских и международных физкультурных
				мероприятий, и спортивных мероприятий на 2024 год. </p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Предметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">совершенствование общих и специальных физических качеств,
					технической, тактической и психологической подготовки;</li>
				<li class="AdditionalGneral-summary-dict-items">овладение навыками выполнения техники бросков с разных
					захватов и с разных направлений.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">готовность самостоятельной информационно-познавательной
					деятельности;</li>
				<li class="AdditionalGneral-summary-dict-items">готовность к самостоятельному поиску методов решения
					практических задач, понимать своё продвижение в овладении техникой и тактикой борьбы самбо;</li>
				<li class="AdditionalGneral-summary-dict-items">замечать и исправлять свои ошибки;</li>
				<li class="AdditionalGneral-summary-dict-items">участвовать в диалоге при выполнении заданий;</li>
				<li class="AdditionalGneral-summary-dict-items">осуществлять взаимопроверку, взаимопомощь.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные результаты:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">чувство дружбы, товарищества, взаимопомощи; </li>
				<li class="AdditionalGneral-summary-dict-items">чувство ответственности, уважения, дисциплинированности,
					активности, самостоятельности, инициативности и творчества; </li>
				<li class="AdditionalGneral-summary-dict-items">смелость, стойкость, решительность, выдержка, мужество;</li>
				<li class="AdditionalGneral-summary-dict-items">уважительное отношение к чужому мнению;</li>
				<li class="AdditionalGneral-summary-dict-items">чувство уверенности в себе, вера в свои возможности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знания:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">особенностей функционирования организма;</li>
				<li class="AdditionalGneral-summary-dict-items">основ правил личной гигиены;</li>
				<li class="AdditionalGneral-summary-dict-items">основ здорового образа жизни и закаливания организма;</li>
				<li class="AdditionalGneral-summary-dict-items">по истории и основании самбо.</li>
				<li class="AdditionalGneral-summary-dict-items">начальных основ стоек, передвижений, захватов в самбо;</li>
				<li class="AdditionalGneral-summary-dict-items">элементов техники безопасности (страховки и самостраховки);
				</li>
				<li class="AdditionalGneral-summary-dict-items">правил подвижных игр и упрощенные правила по самбо.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Умения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">быть активным участником тренировочного процесса;</li>
				<li class="AdditionalGneral-summary-dict-items">использовать свой технический арсенал для успешного выполнения
					поставленной задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">самостоятельно осуществлять, контролировать и корректировать
					деятельность;</li>
				<li class="AdditionalGneral-summary-dict-items">понимать информацию, представленную в виде текста, рисунков,
					схем;</li>
				<li class="AdditionalGneral-summary-dict-items">находить рациональное решение поставленной задачи;</li>
				<li class="AdditionalGneral-summary-dict-items">правильно передвигаться по татами;</li>
				<li class="AdditionalGneral-summary-dict-items">выполнять упражнения согласно модулю, взаимодействовать с
					партнером на тренировочном занятии;</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Капустина Екатерина Станиславна</p>
			<p class="AdditionalGneral-summary-sub-title">e.kapustina@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%94%D0%9E%D0%9F-%D0%A1%D0%B0%D0%BC%D0%B1%D0%BE.pdf"
				target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				Оркестровое духовое исполнительство
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению дополнительной общеразвивающей программы допускаются
				учащиеся организаций дополнительного образования (ДМШ и ДШИ) в возрасте от 10 до 17 лет. Обучение по программе
				требует наличие основных музыкальных способностей: слуха, ритма, памяти, исполнительских навыков игры на духовых
				или ударных инструментах, а также начальных навыков игры в ансамбле.</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Предметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие исполнительских навыков игры на духовых инструментах;
				</li>
				<li class="AdditionalGneral-summary-dict-items">накопление знаний о традициях духовой музыкальной культуры;
				</li>
				<li class="AdditionalGneral-summary-dict-items">обучение комплексу практических навыков ансамблевого и
					оркестрового исполнительства;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование навыка исполнения партий оркестра различного
					уровня сложности и художественного содержания;</li>
				<li class="AdditionalGneral-summary-dict-items">освоение навыков работы во время сводных репетиций и специфики
					репетиционной работы по группам;</li>
				<li class="AdditionalGneral-summary-dict-items">умение исполнять элементы «дефиле» и фигурной маршировки.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Метапредметные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">приобщение к музыкально-творческому искусству через игру в
					оркестре;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие музыкального слуха, ритма, мелкой моторики пальцев,
					музыкальной памяти, реакции на гибко изменчивые темпоритмы;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование умения работать самостоятельно и в коллективе;
				</li>
				<li class="AdditionalGneral-summary-dict-items">формирование умения анализировать результаты собственной и
					коллективной деятельности.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Личностные:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">развитие у детей коммуникативных навыков;</li>
				<li class="AdditionalGneral-summary-dict-items">формирование у учащихся личностных качеств, эстетических
					взглядов, нравственных установок и потребности общения с духовными ценностями;</li>
				<li class="AdditionalGneral-summary-dict-items">развитие самостоятельной и личной ответственности в работе в
					коллективе.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">perseus@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%94%D0%9E%D0%9F-%D0%9E%D1%80%D0%BA%D0%B5%D1%81%D1%82%D1%80%D0%BE%D0%B2%D0%BE%D0%B5-%D0%B4%D1%83%D1%85%D0%BE%D0%B2%D0%BE%D0%B5-%D0%B8%D1%81%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE-%D0%B0%D0%B2%D0%B3%D1%83%D1%81%D1%82-72-%D1%87%D0%B0%D1%81%D0%B0.pdf" target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
					От этюда к спектаклю
			</summary>
			<p class="AdditionalGneral-summary-title">Даты проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Согласно графику профильных смен</p>
			<p class="AdditionalGneral-summary-title">Форма обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">Очная – профильная смена</p>
			<p class="AdditionalGneral-summary-title">Нормативный срок обучения:</p>
			<p class="AdditionalGneral-summary-sub-title">72 часа</p>
			<p class="AdditionalGneral-summary-title">Язык, на котором осуществляется обучение:</p>
			<p class="AdditionalGneral-summary-sub-title">Русский</p>
			<p class="AdditionalGneral-summary-title">Место проведения:</p>
			<p class="AdditionalGneral-summary-sub-title">Кампус Образовательного центра «Персей», расположенный по адресу:
				Иркутская область, Ангарский район, 8,351 км автодороги Ангарск-Тальяны</p>
			<p class="AdditionalGneral-summary-title">Адресат программы:</p>
			<p class="AdditionalGneral-summary-sub-title">К освоению программы допускаются учащиеся общеобразовательных
				организаций и организаций дополнительного образования в возрасте от 10 до 17 лет, занимающиеся в театральных
				коллективах, и владеющие навыками в области театрального творчества.</p>
			<p class="AdditionalGneral-summary-sub-title">готовность к практическим и теоретическим занятиям (беседа,
				дискуссия, лекция, видео-просмотр и обсуждение);</p>
			<p class="AdditionalGneral-summary-sub-title">знание базовых понятий актёрского мастерства и сценической речи,
				основ построения сценического действия, театральной терминологии;</p>
			<p class="AdditionalGneral-summary-sub-title">наличие практического сценического опыта выступлений, а именно,
				умение ориентироваться на сцене;</p>
			<p class="AdditionalGneral-summary-sub-title">актёрский опыт не менее 3 лет</p>
			<p class="AdditionalGneral-summary-title">Результат освоения программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Планируемые результаты освоения:</p>

			<p class="AdditionalGneral-summary-dict">Практические навыки:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">исследование психологии создания спектакля;</li>
				<li class="AdditionalGneral-summary-dict-items">приобщение к творческому поиску решения образа роли;</li>
				<li class="AdditionalGneral-summary-dict-items">коллективное взаимодействие при создании драматического
					спектакля.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Знания:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">этапы работы над драматургическим материалом;</li>
				<li class="AdditionalGneral-summary-dict-items">процесс создания драматического спектакля; </li>
				<li class="AdditionalGneral-summary-dict-items">правила создания образа персонажа;</li>
				<li class="AdditionalGneral-summary-dict-items">законы сценического воплощения замысла спектакля;</li>
				<li class="AdditionalGneral-summary-dict-items">понятия: «зерно образа», «сверхзадача персонажа», «событийный
					ряд»;</li>
				<li class="AdditionalGneral-summary-dict-items">свойства актёрской органики и эмоциональной заразительности;
				</li>
				<li class="AdditionalGneral-summary-dict-items">принципы актёрского анализа роли.</li>
			</ul>

			<p class="AdditionalGneral-summary-dict">Умения:</p>
			<ul class="AdditionalGneral-summary-dict-list">
				<li class="AdditionalGneral-summary-dict-items">держать сверхзадачу роли, достоверно и убедительно создавать
					образ персонажа;</li>
				<li class="AdditionalGneral-summary-dict-items">анализировать события, происходящие в сценическом
					пространстве;</li>
				<li class="AdditionalGneral-summary-dict-items">правильно дышать, говорить и двигаться на сцене в образе героя
					спектакля;</li>
				<li class="AdditionalGneral-summary-dict-items">взаимодействовать в актёрском ансамбле;</li>
				<li class="AdditionalGneral-summary-dict-items">грамотно и конструктивно осуществлять самоанализ и разбор
					процесса актёрской работы в спектакле.</li>
			</ul>

			<p class="AdditionalGneral-summary-title">Документ о квалификации:</p>
			<p class="AdditionalGneral-summary-sub-title">Сертификат участника</p>
			<p class="AdditionalGneral-summary-title">Руководитель программы:</p>
			<p class="AdditionalGneral-summary-sub-title">Сыроватская Ангелина Геннадьевна</p>
			<p class="AdditionalGneral-summary-sub-title">perseus@perseusirk.ru</p>
			<p class="AdditionalGneral-summary-sub-title">8(3952)546-044</p>

			<a class="Documents-list-items-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%94%D0%9E%D0%9F-%D0%9E%D1%82-%D1%8D%D1%82%D1%8E%D0%B4%D0%B0-%D0%BA-%D1%81%D0%BF%D0%B5%D0%BA%D1%82%D0%B0%D0%BA%D0%BB%D1%8E.pdf" target="_blank">
				Программа (описание образовательной программы, включая учебный план, аннотации к рабочим программам дисциплин,
				календарный учебный график)
			</a>
		</details>

	</div>
</template>