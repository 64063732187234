<template>
	<div class="AllRussian _cover">
		<h2 class="AllRussian-title _cover-title">
			Всероссийская олимпиада школьников 2024-2025г.
		</h2>
		<div class="AllRussian-img-box">
			<img loading="lazy" class="AllRussian-img" src="../assets/img/svg/ВСОШ.svg"
				alt="Всероссийская олимпиада школьников">
		</div>
		<div class="AllRussian-btn-box">
			<a class="AllRussian temp-btn" @click="$router.push({ name: 'Preparation' })">
				Подготовка к олимпиаде
			</a>
			<a class="AllRussian temp-btn" @click="$router.push({ name: 'ContactsofOlympiad' })">
				Контакты
			</a>
			<a class="AllRussian temp-btn" @click="$router.push({ name: 'SchoolStage' })">
				Школьный этап
			</a>
		</div>
		<hr>

		<ul class="AllRussian-list">
			<li class="AllRussian-items">
				<a class="AllRussian-items-link" href="https://edu.gov.ru/">
					<p class="AllRussian-items-dist">
						Организатор заключительного этапа олимпиады:
					</p>
					<img loading="lazy" class="AllRussian-items-img" src="../assets/img/svg/logo-1.webp"
						alt="Организатор заключительного этапа олимпиады">
					<p class="AllRussian-items-dist">
						Министерство образования и науки Российской Федерации
					</p>
				</a>
				<a class="AllRussian-items-link" href="https://irkobl.ru/sites/minobr/olimpiada/">
					<p class="AllRussian-items-dist">
						Организатор регионального этапа олимпиады:
					</p>
					<img loading="lazy" class="AllRussian-items-img" src="../assets/img/svg/лого-министерство.webp"
						alt="Организатор регионального этапа олимпиады">
					<p class="AllRussian-items-dist">
						Министерство образования Иркутской области
					</p>
				</a>
				<a class="AllRussian-items-link" @click="$router.push({ name: 'Main' })">
					<p class="AllRussian-items-dist">
						Оператор регионального этапа олимпиады:
					</p>
					<img loading="lazy" class="AllRussian-items-img" src="../assets/img/Logo/logo.webp"
						alt="Оператор регионального этапа олимпиады">
					<p class="AllRussian-items-dist">
						Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей"
					</p>
				</a>
			</li>
		</ul>
		<hr>

		<h2 class="AllRussian-title _cover-title">
			Нормативно-правовые документы Министерства просвещения Российской Федерации
		</h2>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%A0%D0%B5%D0%BA%D0%BE%D0%BC%D0%B5%D0%BD%D0%B4%D0%B0%D1%86%D0%B8%D0%B8-%D0%94%D0%BB%D1%8F-%D0%A8%D0%BA%D0%BE%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B8-%D0%BC%D1%83%D0%BD%D0%B8%D1%86.-%D1%8D%D1%82%D0%B0%D0%BF%D0%B0.pdf"
					target="_blank">
					Метод рекомендации для школьн. и мун. этапов ВсОШ
				</a>
			</li>
		</ul>

		<a class="AllRussian-doc" href="https://vserosolimp.edsoo.ru/" target="_blank">
			Сайт Всероссийской олимпиады школьников
		</a><br>
		<hr>

		<h2 class="AllRussian-title _cover-title">
			Документы заключительного этапа
		</h2>

		<h2 class="AllRussian-title _cover-title">
			Документы регионального этапа
		</h2>

		<h2 class="AllRussian-title _cover-title">
			Программы проведения регионального этапа ВСОШ
		</h2>

		<h2 class="AllRussian-title _cover-title">
			Документы муниципального этапа ВСОШ
		</h2>

		<h2 class="AllRussian-title _cover-title">
			Всероссийская олимпиада школьников — крупнейшее интеллектуальное соревнование, в котором ежегодно принимают
			участие более 6 млн школьников.
		</h2>
		<p class="AllRussian-dist">
			Олимпиада проводится по общеобразовательным предметам: математика, русский язык, иностранный язык (английский,
			немецкий, французский, испанский, китайский), информатика, физика, химия, биология, экология, география,
			астрономия, литература, история, обществознание, экономика, право, искусство, физическая культура, технология,
			основы безопасности жизнедеятельности.
		</p>
		<h3 class="AllRussian-sub-title">
			Регистрация на олимпиаду
		</h3>
		<p class="AllRussian-dist">
			К участию в школьном этапе Всероссийской олимпиады школьников приглашаются все желающие обучающиеся.<br>
			Для участия Вам необходимо пройти регистрацию и подать заявку на сайте <a class="AllRussian-dist-link"
				href="https://eor.vserosperseusirk.ru/">https://eor.vserosperseusirk.ru/.</a><br>
			Для получения графика олимпиады школьного этапа и инструкций обратитесь к координатору олимпиады Вашей
			школы.<br>
			<a class="AllRussian-dist-link" href="https://eor.vserosperseusirk.ru/">https://eor.vserosperseusirk.ru/</a><br>
		</p>
		<h3 class="AllRussian-sub-title">
			ШКОЛЬНЫЙ ЭТАП (сентябрь-октябрь)
		</h3>
		<ul class="AllRussian-dist-list">
			<li class="AllRussian-dist-items">
				для 5-11 классов (по всем предметам), для 4 класса (по предметам математика и русский язык);
			</li>
			<li class="AllRussian-dist-items">
				к участию приглашаются все желающие обучающиеся;
			</li>
			<li class="AllRussian-dist-items">
				формат проведения: по предметам физика, биология, химия, астрономия, математика и информатика – дистанционно,
				на
				платформе Сириус.Курсы.
			</li>
		</ul>
		<h3 class="AllRussian-sub-title">
			МУНИЦИПАЛЬНЫЙ ЭТАП (ноябрь-декабрь)
		</h3>
		<ul class="AllRussian-dist-list">
			<li class="AllRussian-dist-items">
				для 7-11 классов;
			</li>
			<li class="AllRussian-dist-items">
				приглашаются:
			</li>
			<li class="AllRussian-dist-items">
				— участники школьного этапа текущего учебного года, набравшие необходимое для участия количество баллов,
				установленное министерством образования Иркутской области;
			</li>
			<li class="AllRussian-dist-items">
				— победители и призеры муниципального этапа олимпиады предыдущего учебного года.
			</li>
		</ul>
		<h3 class="AllRussian-sub-title">
			РЕГИОНАЛЬНЫЙ ЭТАП (январь-февраль)
		</h3>
		<ul class="AllRussian-dist-list">
			<li class="AllRussian-dist-items">
				для 9-11 классов;
			</li>
			<li class="AllRussian-dist-items">
				приглашаются:
			</li>
			<li class="AllRussian-dist-items">
				— участники муниципального этапа олимпиады текущего учебного года, набравшие необходимое для участия
				количество
				баллов, установленное министерством образования Иркутской области;
			</li>
			<li class="AllRussian-dist-items">
				— победители и призеры регионального этапа олимпиады предыдущего учебного года.
			</li>
		</ul>
		<h3 class="AllRussian-sub-title">
			ЗАКЛЮЧИТЕЛЬНЫЙ ЭТАП (март-апрель)
		</h3>
		<ul class="AllRussian-dist-list">
			<li class="AllRussian-dist-items">
				для 9-11 классов;
			</li>
			<li class="AllRussian-dist-items">
				приглашаются:
			</li>
			<li class="AllRussian-dist-items">
				— участники регионального этапа олимпиады текущего учебного года, набравшие необходимое для участия количество
				баллов, установленное Министерством Просвещения Российской Федерации;
			</li>
			<li class="AllRussian-dist-items">
				— победители и призеры заключительного олимпиады предыдущего учебного года.
			</li>
		</ul>
		<hr>

		<h2 class="AllRussian-title _cover-title">
			ЛЬГОТЫ ПОБЕДИТЕЛЯМ И ПРИЗЕРАМ ВСОШ
		</h2>
		<ul class="AllRussian-dist-list">
			<li class="AllRussian-dist-items">
				Главная льгота, которую предоставляет победа или призерство на заключительном этапе всероссийской олимпиады
				школьников, – это поступление в профильные вузы без экзаменов. Принимаются результаты с 9 по 11 класс. Если
				победитель поступает на непрофильную специальность, по решению вуза ему могут зачесть 100 баллов за ЕГЭ по
				предмету, соответствующему профилю олимпиады (соответствие направления и профиля олимпиады определяет сам
				вуз).
			</li>
			<li class="AllRussian-dist-items">
				Для победителей и призеров регионального этапа ВСОШ есть возможность получения баллов индивидуальных
				достижений,
				дополнительно к сумме баллов ЕГЭ. Порядок учета и перечень индивидуальных достижений устанавливается каждым
				вузом самостоятельно.
			</li>
		</ul>
	</div>
</template>