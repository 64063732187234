<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«География. Регионоведение»
		</h2>
		<p class="EventsPage-date">
			с 14 по 23 марта 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=148" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24648-programma-geografiya-regionovedenie"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «География. Регионоведение» для учащихся 9-11-х классов
			общеобразовательных организаций.
		</p>
		<hr>э

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Брюханова Юлия Михайловна, канд. филол. наук, заведующий кафедрой ФГБОУ ВО Иркутский государственный
				университет;
			</li>
			<li class="JuniorProfi-items">
				Буров Эдуард Евгеньевич, ассистент кафедры русского языка и общего языкознания ФГБОУ ВО Иркутский
				государственный университет;
			</li>
			<li class="JuniorProfi-items">
				Вербицкая Ольга Юрьевна, канд. филол. наук, доцент ФГБОУ ВО Иркутский государственный университет ;
			</li>
			<li class="JuniorProfi-items">
				Кузнецова Ольга Сергеевна, доцент, доцент, декан ФГБОУ ВО Иркутский государственный университет;
			</li>
			<li class="JuniorProfi-items">
				Саенко Ольга Анатольевна, канд. филол. наук, и.о. заведующего кафедрой, доцент ФГБОУ ВО Иркутский
				государственный университет;
			</li>
			<li class="JuniorProfi-items">
				Сумарокова Екатерина Валерьевич, старший преподаватель кафедры новейшей русской литературы, заместитель
				директора по молодёжной политике и воспитательной деятельности ИФИЯМ Иркутский государственный университет;
			</li>
			<li class="JuniorProfi-items">
				Чекмез Ульяна Эдуардовна, старший преподаватель ФГБОУ ВО Иркутский государственный университет.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету и выполнить задание (эссе на тему «Россия в мире») до 4 марта 2024 года на
				платформе Образовательного центра «Персей», системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Для тех участников, которые пройдут конкурсный отбор, необходимо будет записаться на программу «География.
				Регионоведение» на ресурсе «Навигатор дополнительного образования Иркутской области».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится с 14 марта 2024 года по 23 марта 2024 года на бесплатной основе в Образовательном
				центре «Персей» (Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>

		<p class="EventsPage-text">
			<b>Результаты отбора будут размещены 5 марта на сайте Образовательного центра «Персей».</b>
		</p>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 6 марта 2024 г. в 20.00, <a style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1708921820">ссылка на подключение</a>
		</p>
		<hr>

		<h2 class="JuniorProfi-sub-title">
			Списочный состав обучающихся, приглашённых на очную профильную смену:
		</h2>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Афонина Дарья Александровна</li>
			<li class="EventsPage-number">Баирова Арина Андреевна</li>
			<li class="EventsPage-number">Белова Дарья Петровна</li>
			<li class="EventsPage-number">Вихров Ярослав Игоревич</li>
			<li class="EventsPage-number">Власенко Виктория Александровна</li>
			<li class="EventsPage-number">Голяк Ирина Викторовна</li>
			<li class="EventsPage-number">Дмитриченко Анна Алексеевна</li>
			<li class="EventsPage-number">Жеребцова Мария Владимировна</li>
			<li class="EventsPage-number">Казакова Алина Андреевна</li>
			<li class="EventsPage-number">Климова Елизавета Валерьевна</li>
			<li class="EventsPage-number">Ковалев Евгений Максимович</li>
			<li class="EventsPage-number">Кузнецова Дарья Сергеевна</li>
			<li class="EventsPage-number">Лящевская Полина Макаровна</li>
			<li class="EventsPage-number">Мигунов Степан Викторович</li>
			<li class="EventsPage-number">Паздникова Юлия Владимировна</li>
			<li class="EventsPage-number">Радчук Яна Михайловна</li>
			<li class="EventsPage-number">Саприкова Полина Александровна</li>
			<li class="EventsPage-number">Силинская Лилия Павловна</li>
			<li class="EventsPage-number">Смолянинова Софья Михайловна</li>
			<li class="EventsPage-number">Текалова Елизавета Максимовна</li>
			<li class="EventsPage-number">Халтаева Юлиана Михайловна</li>
			<li class="EventsPage-number">Харина Вероника Сергеевна</li>
			<li class="EventsPage-number">Худякова Анна Сергеевна</li>
			<li class="EventsPage-number">Чубков Александр Сергеевич</li>
			<li class="EventsPage-number">Ялдынов Матвей Ринатович</li>
		</ol>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/tYkP/oGqsyVr9c" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/YexQVTxnatP4ug" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>