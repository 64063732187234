<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Школа безопасности»
		</h2>
		<p class="EventsPage-date">
			с 10 по 23 апреля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24714-programma-shkola-bezopasnosti"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Школа безопасности» для учащихся 5-11-х классов
			общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			По причине увлеченности детей современными информационными технологиями, ориентации на цифровизацию, они не имеют
			элементарных представлений о правилах поведения в природной среде, порядке действий в случае возникновения
			экстремальной ситуации. Программа ориентирована в основном на обучение школьников правильному поведению при
			различных ситуациях на природе, оказанию помощи и самопомощи в различных ситуациях в лесу.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Дронова Анастасия Александровна, учитель физического воспитания МБОУ СОШ NQ 45 МО г. Братск;
			</li>
			<li class="JuniorProfi-items">
				Морозова Елена Владимировна, педагог дополнительного образования МБУ ДО «Центр внешкольной работы
				Казачинско-Ленского района»;
			</li>
			<li class="JuniorProfi-items">
				Орлов Олег Петрович, педагог дополнительного образования «ДТДиМ» г. Ангарск;
			</li>
			<li class="JuniorProfi-items">
				Орлова Мария Константиновна, педагог дополнительного образования «ДТДиМ» г. Ангарск;
			</li>
			<li class="JuniorProfi-items">
				Сибирякова Оксана Владимировна, учитель МБОУ г. Иркутска СОШ № 24;
			</li>
			<li class="JuniorProfi-items">
				Форсайт Анита Андреевна, фельдшер кабинета медицинского освидетельствования ОГБУЗ ИО ПНД Ангарский филиал в г.
				Ангарск;
			</li>
			<li class="JuniorProfi-items">
				Хабеев Степан Аркадьевич, заместитель начальника отр. по ПСР АСС ОГБУ «Пожарно-спасательная служба Иркутской
				области»;
			</li>
			<li class="JuniorProfi-items">
				Ярунина Виктория Васильевна, педагог дополнительного образования «ДТДиМ» г. Ангарск;
			</li>
			<li class="JuniorProfi-items">
				Моргунов Олег Анатольевич, педагог дополнительного образования «ДТДиМ» г. Ангарск;
			</li>
			<li class="JuniorProfi-items">
				Федоров Дмитрий Александрович, пожарный 60 ПСЧ З ПСО ФПС ГПС ГУ МЧС России по Иркутской области;
			</li>
			<li class="JuniorProfi-items">
				Конторин Дмитрий Алексеевич, педагог дополнительного образования «ДТДиМ» г. Ангарск;
			</li>
			<li class="JuniorProfi-items">
				Филимонов Никита Валерьевич, консультант ИП Демаков А.В.;
			</li>
			<li class="JuniorProfi-items">
				Терентьева Лариса Аркадьевна, учитель географии МБОУ г. Иркутска СОШ с УИОП № 64.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо записаться на программу «Школа безопасности» на ресурсе «Навигатор дополнительного образования
				Иркутской области».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 2 апреля 2024 г. в 20.00 <a style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1709296297">ссылка на подключение</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/aRhh/2NF8XZcch" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/zJmC4gRHnlQzwA" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>