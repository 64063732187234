<template>
	<div class="ConferenceSeminars _cover">
		<h2 class="ConferenceSeminars-title _cover-title">
			Мероприятия ГАНУ ДО ИО «Региональный центр выявления и поддержки одаренных детей «Персей» в рамках региональной
			программы методической недели «августовского» педагогического совещания – 2024
		</h2>

		<h3 class="Documents-sub-title">
			«Сетевая образовательная программа с Образовательным центром Персей - новые возможности для школ
			региона»
		</h3>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%A1%D0%B5%D1%82%D0%B5%D0%B2%D1%8B%D0%B5-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B5-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D1%8B.pdf"
					target="_blank">
					Презентация
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://rutube.ru/video/private/3f11d52ea14ce15db99f63eae99d2d85/?p=BwBsV-Dba_LnhGVU2juGxg"
					target="_blank">
					Видеозапись мероприятия
				</a>
			</li>
		</ul>

		<h3 class="Documents-sub-title">
			«Формирование единых подходов к подготовке проектных и проектно-исследовательских работ школьников»
		</h3>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%95%D0%B4%D0%B8%D0%BD%D1%8B%D0%B5-%D0%BF%D0%BE%D0%B4%D1%85%D0%BE%D0%B4%D1%8B-%D0%BA-%D0%BE%D1%86%D0%B5%D0%BD%D0%BA%D0%B5-%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%BE%D0%B2.pdf"
					target="_blank">
					Презентация
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://rutube.ru/video/private/42994b29565bc48831736c41af57dc46/?p=7f0-We296RhoIZhPYchIxw"
					target="_blank">
					Видеозапись мероприятия
				</a>
			</li>
		</ul>

		<h3 class="Documents-sub-title">
			«Профориентация через партнёрство»
		</h3>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/3.-%D0%9F%D1%80%D0%BE%D1%84%D0%BE%D1%80%D0%B8%D0%B5%D0%BD%D1%82%D0%B0%D1%86%D0%B8%D1%8F-%D1%87%D0%B5%D1%80%D0%B5%D0%B7-%D0%BF%D0%B0%D1%80%D1%82%D0%BD%D1%91%D1%80%D1%81%D1%82%D0%B2%D0%BE.pdf"
					target="_blank">
					Профориентация через партнёрство
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/3.-%D0%97%D0%BE%D0%BB%D0%BE%D1%82%D0%BE%D0%B5-%D0%B1%D1%83%D0%B4%D1%83%D1%89%D0%B5%D0%B5-%D1%82%D0%B2%D0%BE%D0%B5%D0%B9-%D0%BA%D0%B0%D1%80%D1%8C%D0%B5%D1%80%D1%8B.pdf"
					target="_blank">
					Золотое будущее твоей карьеры
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://rutube.ru/video/private/6c85a0d5443a84cd9c571f2acf134717/?p=FKb7xbDkuGwjxBLzoSy6_g"
					target="_blank">
					Видеозапись мероприятия
				</a>
			</li>
		</ul>

		<h3 class="Documents-sub-title">
			«Проектные программы ОЦ Сириус. Как участвовать и побеждать?»
		</h3>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/4.-%D0%9F%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%BD%D1%8B%D0%B5-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D1%8B-%D0%A1%D0%B8%D1%80%D0%B8%D1%83%D1%81%D0%B0.pdf"
					target="_blank">
					Проектные программы Сириуса
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://rutube.ru/video/private/727a9a40cbc5a4da86959f44e6645a12/?p=tx0MNMlkPmCPVxFEofolPA"
					target="_blank">
					Видеозапись мероприятия
				</a>
			</li>
		</ul>

		<h3 class="Documents-sub-title">
			«Конкурсы и олимпиады регионального и федерального уровней для обучающихся Иркутской области. Подготовка
			школьников к участию»
		</h3>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D1%8B-%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D1%8B-%D0%B4%D0%BB%D1%8F-%D1%88%D0%BA%D0%BE%D0%BB%D1%8C%D0%BD%D0%B8%D0%BA%D0%BE%D0%B2-2024-25-%D1%83%D1%87%D0%B3.pdf"
					target="_blank">
					Олимпиады, конкурсы для школьников, 2024-25 учг
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%A0%D0%B5%D0%BA%D0%BE%D0%BC%D0%B5%D0%BD%D0%B4%D0%B0%D1%86%D0%B8%D0%B8-%D0%94%D0%BB%D1%8F-%D0%A8%D0%BA%D0%BE%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B8-%D0%BC%D1%83%D0%BD%D0%B8%D1%86.-%D1%8D%D1%82%D0%B0%D0%BF%D0%B0.pdf"
					target="_blank">
					Рекомендации Для Школьного и муниц.этапа
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%97%D0%A4%D0%A1_%D0%98%D1%80%D0%BA%D1%83%D1%82%D1%81%D0%BA%D0%BE%D0%B5-%D0%93%D0%90%D0%A3.pdf"
					target="_blank">
					ЗФС_Иркутское ГАУ
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%97%D0%A4%D0%A1_%D0%98%D0%93%D0%A3.pdf"
					target="_blank">
					ЗФС_ИГУ
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/08/%D0%97%D0%A4%D0%A1_%D0%91%D0%93%D0%A3.pdf"
					target="_blank">
					ЗФС_БГУ
				</a>
			</li>
		</ul>

	</div>
</template>