<template>
	<div class="DirectionBlock _cover">
		<div class="DirectionBlock-img-box">
			<img loading="lazy" class="DirectionBlock-img" src="../assets/img/Logo/СПОРТ.webp" alt="Спорт">
		</div>

		<div class="Intelligence-block-video-box">
			<div class="Intelligence-block-video">
				<iframe class="Intelligence-block-video-items"
					src="https://www.youtube.com/embed/rEKbLVe1ST0?si=tR9RGPgJ_4Ve0Bl9" title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen>
					Направление Спорт
				</iframe>
			</div>
		</div>

		<!-- Профильные смены -->
		<div class="Period">
			<h2 class="Period-title _cover-title">
				Профильные смены
			</h2>

			<ul class="grid-container">
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage197' })">
						<EventsCard197></EventsCard197>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage196' })">
						<EventsCard196></EventsCard196>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage179' })">
						<EventsCard179></EventsCard179>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage160' })">
						<EventsCard160></EventsCard160>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage152' })">
						<EventsCard152></EventsCard152>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage151' })">
						<EventsCard151></EventsCard151>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage150' })">
						<EventsCard150></EventsCard150>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage13' })">
						<EventsCard13></EventsCard13>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage5' })">
						<EventsCard5></EventsCard5>
					</a>
				</li>
			</ul>
		</div>

		<!-- Завершенные программы -->
		<div class="Period">
			<h2 class="Period-title _cover-title">
				Завершенные программы
			</h2>

			<details class="Ol_title-drop">
				<summary class="Completed-Period-hide-title">
					Подробнее
				</summary>
				<ul class="grid-container">
					<!-- <li class="grid-item grid-items">
							<a class="Period-link" href="#">
								
							</a>
						</li> -->
				</ul>
			</details>

		</div>
	</div>
</template>

<script>
import EventsCard5 from '../EventsCard/EventsCard5';
import EventsCard13 from '../EventsCard/EventsCard13';
import EventsCard150 from '../EventsCard/EventsCard150';
import EventsCard151 from '../EventsCard/EventsCard151';
import EventsCard152 from '../EventsCard/EventsCard152';
import EventsCard160 from '../EventsCard/EventsCard160';
import EventsCard179 from '../EventsCard/EventsCard179';
import EventsCard196 from '../EventsCard/EventsCard196';
import EventsCard197 from '../EventsCard/EventsCard197';



	export default {
		components: {
			EventsCard5,
			EventsCard13,
			EventsCard150,
			EventsCard151,
			EventsCard152,
			EventsCard160,
			EventsCard179,
			EventsCard196,
			EventsCard197,
		}
	}
</script>