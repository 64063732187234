<template>
	<div class="Logistics _cover">
		<h2 class="_cover-title">Материально-техническое обеспечение и оснащенность образовательного процесса. Доступная
			среда</h2>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Фотографии учебных кабинетов, объектов спорта и питания обучающихся
			</summary>
			<h3 class="Logistics-dist _cover-title">г. Иркутск, Угольный проезд 68/1</h3>
			<div class="grid-container">
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/1.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/2.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/3.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/4.webp" alt="Учебная аудитория" />
				</div>
			</div>
			<h3 class="Logistics-dist _cover-title">ОЦ «Персей», Ангарский район, 8,351 км автодороги Ангарск-Тальяны</h3>
			<div class="grid-container">
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/5.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/6.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/7.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/8.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/9.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/10.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/11.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/12.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/13.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/14.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/15.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/16.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/17.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/18.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/19.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/20.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/21.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/22.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/23.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/24.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/25.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/27.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/28.webp" alt="Учебная аудитория" />
				</div>
				<div class="Logistics-img-box grid-item">
					<img loading="lazy" class="Logistics-img" src="../assets/img/Logistics/29.webp" alt="Учебная аудитория" />
				</div>
			</div>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация об оборудованных учебных кабинетах</summary>
			<div class="StructureAndOrgans-table">
				<table>
					<tr>
						<th>Адрес</th>
						<th>Наименование оборудованного учебного кабинета</th>
						<th>Оснащенность оборудованного учебного кабинета</th>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Химическая лаборатория</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория микробиологии</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория разработки и эксплуатации беспилотных авиационных систем</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория рационального использования водных биоресурсов и экологии</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория по геоэкологии</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория малоглубинной геофизики</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Медицинский пункт</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория сейсмического мониторинга</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория по геологии</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория по гидрогеологии</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория по географии</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория спектрального анализа</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория физики</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория виртуальной и дополненной реальности</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Лаборатория анатомии</td>
						<td>Учебный кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения всех
							видов учебных занятий</td>
					</tr>
				</table>
			</div>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация об объектах для проведения практических занятий</summary>
			<div class="StructureAndOrgans-table">
				<table>
					<tbody>
						<tr>
							<td>Адрес</td>
							<td>Наименование объекта</td>
							<td>Оснащенность объекта</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Химическая лаборатория</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория микробиологии</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория разработки и эксплуатации беспилотных авиационных систем</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория рационального использования водных биоресурсов и экологии</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория по геоэкологии</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория малоглубинной геофизики</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория сейсмического мониторинга</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория по геологии</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория по гидрогеологии</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория по географии</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория спектрального анализа</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория физики</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория виртуальной и дополненной реальности</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Лаборатория анатомии</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, ул. Рабочего Штаба, 15</td>
							<td>Конференц-зал, лаборатория электродинамики и звуковые волны</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, ул. Рабочего Штаба, 15</td>
							<td>Компьютерный класс, лаборатория робототехники</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, ул. Рабочего Штаба, 15</td>
							<td>Компьютерный класс, лаборатория возобновляемой энергетики</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, Угольный проезд 68/1</td>
							<td>Лаборатория промышленных агротехнологий</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, Угольный проезд 68/1</td>
							<td>Цифровая лаборатория нейротехнологий</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, Угольный проезд 68/1</td>
							<td>Лаборатория «Интернет вещей в быту и производстве»</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, Угольный проезд 68/1</td>
							<td>Лаборатория электротехники и электроники</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, Угольный проезд 68/1</td>
							<td>Лаборатория «Умный город и безопасность»</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
						<tr>
							<td>г. Иркутск, Угольный проезд 68/1</td>
							<td>Лаборатория «Разработки виртуальной и дополненной реальности, 3D моделирования и графики»)</td>
							<td>Кабинет оснащен необходимым оборудованием и техническими средствами обучения для проведения
								практических занятий</td>
						</tr>
					</tbody>
				</table>
			</div>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация о библиотеке</summary>
			<p class="Logistics-details-dist">
				Слушателям и преподавателям обеспечен доступ к электронно-библиотечной системе <a class="Logistics-link"
					href="https://biblioclub.ru/index.php?page=book_blocks&view=main_ub">«Университетская библиотека online»</a>,
				которая насчитывает 130 тысяч экземпляров образовательной, научной, интеллектуальной, деловой литературы.
			</p>
			<p class="Logistics-details-dist">
				<a class="Logistics-link" href="https://biblioclub.ru/index.php?page=book_blocks&view=main_ub">Университетская
					библиотека online</a> – это электронная библиотека, обеспечивающая слушателям доступ к наиболее востребованным
				материалам по всем отраслям знаний от российских ведущих издательств, это собрание учебной, специальной и
				справочной литературы самого разнообразного профессионального профиля, необходимое каждому обучающемуся.
				Предлагается обширная подборка словарей, справочников и энциклопедий.
			</p>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация об объектах спорта</summary>
			<div class="StructureAndOrgans-table">
				<table>
					<tbody>
						<tr>
							<td>Адрес</td>
							<td>Наименование объекта спорта</td>
							<td>Оснащенность объекта</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей», 4 корпус</td>
							<td>Кабинет №10 (тренажерный зал)</td>
							<td>
								Дорожка беговая NordicTrack Commercial 245;<br>
								Велотренажер Freemotion u 10.2;<br>
								Прямая скамья для пресса;<br>
								Тренажер для мышц спины;<br>
								Комплект гантелей (20 шт.);<br>
								Стойка для приседаний (скамья для силовых тренировок) WEINDER PRO 7500;<br>
								Медболы разных весов;<br>
								Гиря «Титан», вес от 6 кг. до 32 кг.;<br>
								Силовой тренажер со встроенными весами (кроссовер со стеком 60 кг., силовая рама);<br>
								Гантели сборные;<br>
								Скамейка с регулируемым уровнем спинки<br>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация о наличии оборудованных средствах обучения и воспитания
			</summary>
			<h3 class="AccessibleEnvironment-title-h3">
				Информация о наличии оборудованных средствах обучения и воспитания
			</h3>
			<p class="AccessibleEnvironment-descr">
				Средства обучения и воспитания, используемые для обеспечения образовательной деятельности, рассматриваются как
				совокупность учебно-методических, материальных, дидактических ресурсов, обеспечивающих эффективное решение
				воспитательно-образовательных задач в оптимальных условиях.
			</p>
			<p class="AccessibleEnvironment-descr">
				В Образовательном центре «Персей» имеются следующие средства обучения и воспитания коллективного и
				индивидуального пользования:
			</p>
			<ul class="JuniorProfi-list">
				<li class="JuniorProfi-items">
					печатные (дидактические и учебные пособия, демонстрационный и раздаточный материал и т.д.);
				</li>
				<li class="JuniorProfi-items">
					электронные образовательные ресурсы (экраны и проекторы для выведения с компьютера/ноутбука текстов и
					фотографий, дидактические материалы к учебно-методическим комплексам, воспроизводимые с CD и флэш-носителей,
					сетевые образовательные ресурсы);
				</li>
				<li class="JuniorProfi-items">
					аудиовизуальные (слайды, слайд-фильмы, видеофильмы образовательные на цифровых носителях, мультимедийные
					установки (проектор, экран, ноутбук), акустические системы;
				</li>
				<li class="JuniorProfi-items">
					наглядные плоскостные (плакаты, карты настенные, иллюстрации настенные, магнитные доски);
				</li>
				<li class="JuniorProfi-items">
					демонстрационные (муляжи, макеты, стенды, модели демонстрационные);
				</li>
				<li class="JuniorProfi-items">
					спортивное оборудование (гимнастическое оборудование, тренажеры, спортивные снаряды, инвентарь).
				</li>
			</ul>
			<p class="AccessibleEnvironment-descr">
				Средства обучения и воспитания, используемые в Образовательном центре «Персей», частично приспособлены для
				использования лицами с ограниченными возможностями здоровья и инвалидов.
			</p>

		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация об условиях питания обучающихся</summary>

			<p class="AccessibleEnvironment-descr">
				Государственное автономное нетиповое учреждение дополнительного образования Иркутской области «Региональный
				центр выявления и поддержки одаренных детей «Персей» (далее – Образовательный центр «Персей») обеспечивает
				рациональное и сбалансированное питание обучающихся интенсивных профильных смен в соответствии с примерным
				цикличным (сезонным) разработанным по установленной форме для детей с 10 лет до 18 лет по адресу:
			</p>

			<div class="StructureAndOrgans-table">
				<table>
					<tbody>
						<tr>
							<td>Адрес</td>
							<td>Наименование помещения</td>
							<td>Характеристика помещения</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Столовая</td>
							<td>Площадь столовой – 1183,9 м², количество посадочных мест – 280</td>
						</tr>
					</tbody>
				</table>
			</div>

			<p class="AccessibleEnvironment-descr">
				Организация питания учащихся (получение, хранение и учёт продуктов питания, производство кулинарной продукции в
				столовой, создание условий для приёма пищи обучающимися и пр.) обеспечивается сотрудниками столовой
				Образовательного центра «Персей» в соответствии со штатным расписанием и функциональными обязанностями.
			</p>

		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация об условиях охраны здоровья обучающихся</summary>
			<div class="StructureAndOrgans-table">
				<table>
					<tbody>
						<tr>
							<td>Адрес</td>
							<td>Наименование помещения</td>
							<td>Характеристика помещения</td>
						</tr>
						<tr>
							<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
							<td>Медицинский пункт</td>
							<td>Площадь медицинского пункта – 266,2 м²</td>
						</tr>
					</tbody>
				</table>
			</div>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация о доступе к информационным системам и
				информационно-телекоммуникационным сетям</summary>
			<p class="Logistics-details-dist">
				Каждый обучающийся в течение всего периода обучения обеспечен индивидуальным неограниченным доступом к
				электронной информационно-образовательной среде образовательного центра из любой точки, в которой имеется доступ
				к информационно-телекоммуникационной сети «Интернет», как на территории образовательного центра, так и за его
				пределами.
			</p>
			<p class="Logistics-details-dist">
				Все виды учебных занятий и организация самостоятельной работы обучающихся предусматривают широкое применение в
				учебном процессе вычислительной техники и современных информационных технологий (мультимедийное оборудование,
				вычислительная техника, интерактивные доски, принтеры, сканеры, ноутбуки, графические планшеты, документ-камера
				и т.д.).
			</p>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">Информация об электронных образовательных ресурсах, к которым
				обеспечивается доступ обучающихся</summary>
			<p class="Logistics-details-dist">
				Информационные системы, используемые в ГАНУДО ИО Образовательный центр «Персей», позволяют автоматизировать
				процессы обучения.
			</p>
			<p class="Logistics-details-dist">
				Обучающимся доступны следующие электронные образовательные и информационные ресурсы:
			</p>

			<div class="StructureAndOrgans-table">
				<table width="100%">
					<tr>
						<th>№</th>
						<th>Наименование ресурса</th>
						<th>Порядок доступа</th>
						<th>Адрес ресурса</th>
					</tr>
					<tr>
						<td>1</td>
						<td>Официальный сайт Образовательного центра «Персей»</td>
						<td>Свободный доступ с любого устройства, подключенного к сети Интернет</td>
						<td><a href="https://perseusirk.ru/" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>2</td>
						<td>Электронная информационно -образовательная среда Образовательного центра «Персей»</td>
						<td>Свободный доступ с любого устройства, подключенного к сети Интернет</td>
						<td><a href="https://moodle.perseusirk.ru/" target="_blank" download>Ссылка</a></td>
					</tr>
				</table>
			</div>

		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">
				Доступная среда. Информация о специальных условиях для получения образования инвалидами и лицами с ограниченными
				возможностями здоровья
			</summary>

			<h3 class="AccessibleEnvironment-title-h3">
				Доступ к информационным системам, информационно-телекоммуникационным сетям, приспособленным для использования
				инвалидами и лицами с ограниченными возможностями здоровья
			</h3>
			<p class="AccessibleEnvironment-descr">
				Официальный сайт Образовательного центра «Персей» имеет адаптированную версию для лиц с ограниченными
				возможностями здоровья (версия для слабовидящих). Реализована возможность увеличения шрифтов, выбора более
				контрастной схемы, выделение области нахождения курсора.
			</p>
			<h3 class="AccessibleEnvironment-title-h3">
				Электронные образовательные ресурсы, к которым обеспечивается доступ инвалидов и лиц с ограниченными
				возможностями
				здоровья
			</h3>
			<p class="AccessibleEnvironment-descr">
				Электронные образовательные ресурсы, к которым обеспечивается доступ обучающихся, в том числе приспособленные
				для
				использования инвалидами и лицами с ограниченными возможностями здоровья – не предусматривается образовательной
				программой Образовательного центра «Персей»
			</p>
			<h3 class="AccessibleEnvironment-title-h3">
				Сведения об электронных образовательных ресурсах, к которым обеспечивается доступ обучающихся, в том числе
				приспособленных для использования инвалидами и лицами с ограниченными возможностями здоровья
			</h3>
			<div class="StructureAndOrgans-table">
				<table width="100%">
					<tr>
						<th>Наименование</th>
						<th>Ссылка</th>
					</tr>
					<tr>
						<td>Министерство образования и науки Российской Федерации</td>
						<td><a href="http://mon.gov.ru/" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Федеральная служба по надзору в сфере образования
							и науки (Рособрнадзор)
						</td>
						<td><a href="http://www.obrnadzor.gov.ru/" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Федеральный портал «Российское образование»</td>
						<td><a href="http://www.edu.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Единое окно доступа к образовательным ресурсам</td>
						<td><a href="http://window.edu.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Единая коллекция цифровых образовательных ресурсов</td>
						<td><a href="http://school-collection.edu.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Федеральный центр информационных образовательных ресурсов</td>
						<td><a href="http://fcior.edu.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Сайты библиотек</td>
						<td><a href="www.rsl.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Официальный сайт Российской государственной библиотеки</td>
						<td><a href="http://www.nlr.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Официальный сайт Российской национальной библиотеки</td>
						<td><a href="http://www.prlib.ru/" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Официальный сайт Президентской библиотека имени Б.Н. Ельцина</td>
						<td><a href="www.gopb.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Официальный сайт Государственной общественно-политической библиотеки</td>
						<td><a href="http://www.rasl.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Официальный сайт Библиотеки Российской академии наук</td>
						<td><a href="www.benran.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Официальный сайт библиотеки по естественным наукам Российской академии наук</td>
						<td><a href="http://www.gpntb.ru/" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Официальный сайт Государственной публичной научно-технической библиотеки</td>
						<td><a href="www.msu.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Официальный сайт научной библиотеки МГУ им. Ломоносова</td>
						<td><a href="http://ellib.gpntb.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Государственная публичная научно-техническая библиотека России</td>
						<td><a href="http://www.knigafund.ru/" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Электронная библиотечная система «Книгафонд»</td>
						<td><a href="http://www.gumfak.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Электронная гуманитарная библиотека</td>
						<td><a href="http://www.prlib.ru/" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Информационно-коммуникационные технологии в образовании</td>
						<td><a href="http://window.edu.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Федеральный институт педагогических измерений</td>
						<td><a href="http://www.en.edu.ru/" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Образовательный портал «Русский язык»</td>
						<td><a href="http://www.openclass.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Сетевые образовательные сообщества Открытый класс</td>
						<td><a href="http://it-n.ru/" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Обучение для будущего</td>
						<td><a href="http://fmcspo.ru/links" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Федеральный методический центр СПО и ПО лиц с инвалидностью и ОВЗ</td>
						<td><a href="http://spo.wil.ru" target="_blank" download>Ссылка</a></td>
					</tr>
					<tr>
						<td>Электронно-библиотечная система Znanium.com</td>
						<td><a href="http://www.nbchr.ru" target="_blank" download>Ссылка</a></td>
					</tr>
				</table>
			</div>
			<h3 class="AccessibleEnvironment-title-h3">
				Наличие специальных технических средств обучения коллективного и индивидуального пользования
			</h3>
			<p class="AccessibleEnvironment-descr">
				Во время проведения занятий, где есть обучаются инвалиды и обучающиеся с ОВЗ, применяются мультимедийные
				средства,
				оргтехника и иные средства для повышения уровня восприятия учебной информации обучающимися с различными
				нарушениями.
			</p>
			<p class="AccessibleEnvironment-descr">
				В Образовательном центре имеются в наличии учебные пособия и дидактические материалы в электронном формате,
				доступ
				к которым и просмотр которых возможен с помощью любого цифрового устройства (ПК, планшет, смартфон и др.),
				подключенного к сети Интернет и имеющих специальные возможности (увеличение шрифта, программа звукового
				воспроизведения текста).
			</p>

			<h3 class="AccessibleEnvironment-title-h3">
				Материально-техническое обеспечение и оснащенность образовательного процесса для лиц с ограниченными
				возможностями
				здоровья и инвалидов
			</h3>
			<p class="AccessibleEnvironment-descr">
				В учреждении созданы условия доступности образовательной деятельности для детей с особенностями развития и
				инвалидностью в соответствии с требованиями, установленными законодательными и иными нормативными правовыми
				актами. Проводиться работа по результатам обследования паспортов доступности, приобретено оборудование и
				материалы
				для создания условий пребывания на территории и помещениях Государственного автономного нетипового учреждения
				дополнительного образования Иркутской области «Региональный центр выявления и поддержки одаренных детей
				«Персей»».
			</p>

			<h3 class="AccessibleEnvironment-title-h3">
				Наличие оборудованных учебных помещений.
			</h3>
			<p class="AccessibleEnvironment-descr">
				Имеются приспособленные учебные кабинеты для использования инвалидами и лицами с ограниченными возможностями
				здоровья, расположенный по адресу:
			</p>
			<ul class="AccessibleEnvironment-list">
				<li class="AccessibleEnvironment-items">
					г. Иркутск, ул. Рабочего штаба, 15; (4, 9,10 аудитория)
				</li>
				<li class="AccessibleEnvironment-items">
					Иркутская обл, Ангарский р-н, 8.351 км автодороги Ангарск- Тальяны (4 корпус)
				</li>
			</ul>
			<p class="AccessibleEnvironment-descr">
				Для образовательного процесса имеются мобильные ученические столы и помещение для проведения занятий (фото №1,2)
			</p>
			<p class="AccessibleEnvironment-descr">
				Объектов спорта для использования инвалидами и лицами с ограниченными возможностями здоровья – нет. Лица с ОВЗ и
				инвалиды участвуют в образовательном процессе на общих условиях. Электронные образовательные ресурсы, к которым
				обеспечивается доступ обучающихся, в том числе приспособленные для использования инвалидами и лицами с
				ограниченными возможностями здоровья – не предусматривается. Официальный сайт учреждения имеет версию сайта для
				слабовидящих.
			</p>

			<h3 class="AccessibleEnvironment-title-h3">
				Обеспечение доступа в здание образовательной организации инвалидов и лиц с ограниченными возможностями здоровья.
			</h3>
			<p class="AccessibleEnvironment-descr">
				Все здания Образовательного центра «Персей» приспособленные помещения, год постройки 1972, капитальный ремонт
				проведен в 1989 году. Доступ к кабинетам администрации, учебным аудиториям, туалету обеспечен посредством
				предоставления сопровождающего лица.
			</p>
			<p class="AccessibleEnvironment-descr">
				На входных дверях установлена контрастная маркировка в соответствии с п. 6.1.6. Установлены противоскользящие
				полосы (фото 3). На фасадах зданий и в помещениях установлены кнопки вызова персонала (фото 4)
			</p>
			<p class="AccessibleEnvironment-descr">
				Туалетные комнаты оборудованы поручнями для инвалидов, имеется оборудованная душевая. (фото 5,6,7,8).
				Организована
				стоянка для Помещения, в которых находятся лица с ОВЗ и инвалиды оснащены специализированными
				информационно-тактильными табличками, мнемосхемы, которые размещены на входе и в помещении. Основная цель таких
				табличек – оповещение о
				предназначении помещения. Надписи выполнены рельефным способом, обязательно имеют дубляж шрифтом Брайля, поэтому
				такие таблички одинаково доступны для использования незрячими людьми, слабовидящими и людьми без инвалидности.
				(фото 9,10)
			</p>
			<p class="AccessibleEnvironment-descr">
				Установлены пандусы (перекатные, откидные) фото 11. Для самостоятельного передвижения по территории
				образовательного учреждения, в целях доступа к месту предоставления услуги, в том числе с помощью работников, в
				организации имеется сменная кресло-коляска (фото 12). Организованы парковочные места для инвалидов.
			</p>

			<h3 class="AccessibleEnvironment-title-h3">
				Условия охраны здоровья инвалидов и лиц с ОВЗ
			</h3>
			<p class="AccessibleEnvironment-descr">
				Педагоги дополнительного образования, работающие с инвалидами и лицами с ОВЗ, прошли повышение квалификации в
				ГАУ
				ДПО ИРО. Инвалиды и лица с ОВЗ небольшой и средней тяжести могут участвовать в образовательном процессе на общих
				основаниях, в том числе с имеющимся в учреждении оборудованием.
			</p>
			<p class="AccessibleEnvironment-descr">
				Санитарно-гигиеническое состояние учреждения соответствует требованиям СанПиН 2.4.4.3172 — 14: световой,
				воздушный
				и питьевой режимы поддерживаются в норме. С целью снижения заболеваемости имеются переносные бактерицидные
				лампы.
			</p>
			<p class="AccessibleEnvironment-descr">
				В здании учреждения имеется: пост охраны; наружное и внутреннее видеонаблюдение; охранно-пожарная сигнализация
				со
				световым и звуковым оповещением с дублированием сигнала в автоматическом режиме на пульт Пожарной части МЧС.
			</p>

			<div class="grid-container">
				<div class="grid-element">
					<figure class="AccessibleEnvironment-figure">
						<img loading="lazy" class="AccessibleEnvironment-img" src="../assets/img/jpg/AccessibleEnvironment1.webp"
							alt="Фото №1">
						<figcaption class="AccessibleEnvironment-figcaption">
							Фото №1
						</figcaption>
					</figure>
				</div>
				<div class="grid-element">
					<figure class="AccessibleEnvironment-figure">
						<img loading="lazy" class="AccessibleEnvironment-img" src="../assets/img/jpg/AccessibleEnvironment2.webp"
							alt="Фото №2">
						<figcaption class="AccessibleEnvironment-figcaption">
							Фото №2
						</figcaption>
					</figure>
				</div>
				<div class="grid-element">
					<figure class="AccessibleEnvironment-figure">
						<img loading="lazy" class="AccessibleEnvironment-img" src="../assets/img/jpg/AccessibleEnvironment3.webp"
							alt="Фото №3">
						<figcaption class="AccessibleEnvironment-figcaption">
							Фото №3
						</figcaption>
					</figure>
				</div>
				<div class="grid-element">
					<figure class="AccessibleEnvironment-figure">
						<img loading="lazy" class="AccessibleEnvironment-img" src="../assets/img/jpg/AccessibleEnvironment4.webp"
							alt="Фото №4">
						<figcaption class="AccessibleEnvironment-figcaption">
							Фото №4
						</figcaption>
					</figure>
				</div>
				<div class="grid-element">
					<figure class="AccessibleEnvironment-figure">
						<img loading="lazy" class="AccessibleEnvironment-img" src="../assets/img/jpg/AccessibleEnvironment5.webp"
							alt="Фото №5">
						<figcaption class="AccessibleEnvironment-figcaption">
							Фото №5
						</figcaption>
					</figure>
				</div>
				<div class="grid-element">
					<figure class="AccessibleEnvironment-figure">
						<img loading="lazy" class="AccessibleEnvironment-img" src="../assets/img/jpg/AccessibleEnvironment6.webp"
							alt="Фото №6">
						<figcaption class="AccessibleEnvironment-figcaption">
							Фото №6
						</figcaption>
					</figure>
				</div>
				<div class="grid-element">
					<figure class="AccessibleEnvironment-figure">
						<img loading="lazy" class="AccessibleEnvironment-img" src="../assets/img/jpg/AccessibleEnvironment7.webp"
							alt="Фото №7">
						<figcaption class="AccessibleEnvironment-figcaption">
							Фото №7
						</figcaption>
					</figure>
				</div>
				<div class="grid-element">
					<figure class="AccessibleEnvironment-figure">
						<img loading="lazy" class="AccessibleEnvironment-img" src="../assets/img/jpg/AccessibleEnvironment8.webp"
							alt="Фото №8">
						<figcaption class="AccessibleEnvironment-figcaption">
							Фото №8
						</figcaption>
					</figure>
				</div>
				<div class="grid-element">
					<figure class="AccessibleEnvironment-figure">
						<img loading="lazy" class="AccessibleEnvironment-img" src="../assets/img/jpg/AccessibleEnvironment9.webp"
							alt="Фото №9">
						<figcaption class="AccessibleEnvironment-figcaption">
							Фото №9
						</figcaption>
					</figure>
				</div>
				<div class="grid-element">
					<figure class="AccessibleEnvironment-figure">
						<img loading="lazy" class="AccessibleEnvironment-img" src="../assets/img/jpg/AccessibleEnvironment10.webp"
							alt="Фото №10">
						<figcaption class="AccessibleEnvironment-figcaption">
							Фото №10
						</figcaption>
					</figure>
				</div>
				<div class="grid-element">
					<figure class="AccessibleEnvironment-figure">
						<img loading="lazy" class="AccessibleEnvironment-img" src="../assets/img/jpg/AccessibleEnvironment11.webp"
							alt="Фото №11">
						<figcaption class="AccessibleEnvironment-figcaption">
							Фото №11
						</figcaption>
					</figure>
				</div>
				<div class="grid-element">
					<figure class="AccessibleEnvironment-figure">
						<img loading="lazy" class="AccessibleEnvironment-img" src="../assets/img/jpg/AccessibleEnvironment12.webp"
							alt="Фото №12">
						<figcaption class="AccessibleEnvironment-figcaption">
							Фото №12
						</figcaption>
					</figure>
				</div>
			</div>

			<ul class="AccessibleEnvironment-doc">
				<li class="AccessibleEnvironment-file">
					<a class="AccessibleEnvironment-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/pasport_dostupnostizhiloy_dom.pdf"
						target="_blank" download>
						Паспорт доступности объекта, Жилой дом: Иркутская область, Ангарский район, 8,351 км автодороги
						Ангарск-Тальяны, строение 4/4.
					</a>
				</li>
				<li class="AccessibleEnvironment-file">
					<a class="AccessibleEnvironment-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/pasport_dostupnostikorpus_1234.pdf"
						target="_blank" download>
						Паспорт доступности объекта, Спальные учебные корпуса 1,2,3,4: Иркутская область, Ангарский район, 8,351 км
						автодороги Ангарск-Тальяны, строение 4/13 и 4/15.
					</a>
				</li>
				<li class="AccessibleEnvironment-file">
					<a class="AccessibleEnvironment-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/pasport_dostupnostimedsanchast.pdf"
						target="_blank" download>
						Паспорт доступности объекта, Медсанчасть: Иркутская область, Ангарский район, 8,351 км автодороги
						Ангарск-Тальяны, строение 4/3.
					</a>
				</li>
				<li class="AccessibleEnvironment-file">
					<a class="AccessibleEnvironment-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/pasport_dostupnostistolovaya_persey.pdf"
						target="_blank" download>
						Паспорт доступности объекта, Столовая: Иркутская область, Ангарский район, 8,351 км автодороги
						Ангарск-Тальяны, строение 4/4.
					</a>
				</li>
				<li class="AccessibleEnvironment-file">
					<a class="AccessibleEnvironment-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/pasport_dostupnostiugolnyy_proezd.pdf"
						target="_blank" download>
						Паспорт доступности объекта, Нежилое здание: г. Иркутск, проезд Угольный, 68/1.
					</a>
				</li>
				<li class="AccessibleEnvironment-file">
					В организации обеспечены условия доступности, позволяющие инвалидам получать услуги наравне с другими, а
					именно
					инвалидам по слуху (слуху и зрению) предоставляются услуги сурдопереводчика (тифлосурдопереводчика) (
					<a class="AccessibleEnvironment-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9E%D0%9E%D0%9E%D0%98-%D0%92%D0%9E%D0%93.pdf"
						target="_blank" download>
						Договор ОООИ ВОГ
					</a>
					,
					<a class="AccessibleEnvironment-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%9E%D0%9E%D0%9E%D0%98-%D0%92%D0%9E%D0%A1.pdf"
						target="_blank" download>
						договор ОООИ ВОС
					</a>
					)
				</li>
			</ul>

		</details>
	</div>
</template>
