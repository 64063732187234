<template>
	<div class="NewsPage-container _cover">
		<p class="NewsPage-date">
			09.09.2024
		</p>
		<h2 class="NewsPage-title">
			В «Персее» завершился еженедельный курс по фотографии
		</h2>
		<div class="NewsPage-content">
			<div class="NewsPage-img-box">
				<img class="NewsPage-img" src="../assets/Newsimg/NewsPage80.webp" alt="Обложка новости" loading="lazy">
			</div>
			<p class="NewsPage-sub-title">
				В Образовательном центре «Персей» завершился еженедельный летний курс по фотографии, на котором участники
				изучали основы профессиональной деятельности коммерческого фотографа. Уникальность программы заключалась в том,
				что работа проходила в формате фотодней — одном из самых популярных способов обучения и работы фотографов.
			</p>
			<p class="NewsPage-sub-title">
				Фотодни предоставили участникам возможность глубже погрузиться в различные стили и техники съемки. Ребята не
				только изучили теоретические аспекты фотографии, но и сразу применяли полученные знания на практике,
				организовывая и проводя съемки в реальных условиях.
			</p>
			<p class="NewsPage-sub-title">
				По словам преподавателей, цель курса заключалась в том, чтобы каждый участник смог пройти весь процесс создания
				фотографии — от поиска идеи и взаимодействия с моделями до продвижения своих работ. Во время курса ребята
				получили знания о том, как находить вдохновение для фотосессий, продумывать концепцию съемок, подбирать
				референсы и взаимодействовать с клиентами. Преподаватели делали акцент на практических занятиях, чтобы участники
				развивали не только технические навыки, но и творческое видение.
			</p>
			<p class="NewsPage-sub-title">
				Работа проходила как на улице, так и в студии. На улице ребята отрабатывали навыки репортажной и пейзажной
				съемки, учились ловить интересные моменты и передавать атмосферу событий. В студии они самостоятельно
				настраивали освещение, выбирали оборудование и проводили съемочный процесс.
			</p>
			<p class="NewsPage-sub-title">
				Важно отметить, что каждый участник курса попробовал себя в роли арт-директора, организуя все этапы съемки
				самостоятельно: от идеи и планирования до проведения фотосессий, обработки и постпродакшена. Важным аспектом
				обучения стало освоение навыков продвижения своих работ в социальных сетях, что позволило участникам понять, как
				эффективно представить свои фотографии широкой аудитории и наладить коммуникацию с потенциальными клиентами. Как
				результат, все участники смогли пополнить свое портфолио уникальными работами, которые они смогут использовать
				для дальнейшего профессионального развития.
			</p>
			<p class="NewsPage-sub-title">
				– Этот курс помог ребятам не только освоить новые навыки, но и развить уверенность в своих силах, что является
				важным шагом на пути к профессиональному росту. Многие уже в процессе обучения ушли в коммерческую деятельность.
				Сейчас ребята занимаются фотографией профессионально, и к ним можно попасть на съемки, — отметила Анастасия
				Говорова, преподаватель Образовательного центра «Персей».
			</p>
			<p class="NewsPage-sub-title">
				В октябре в «Персее» стартует дистанционный курс по основам обработки фотографии. Программа включает в себя
				основные техники и методы редактирования изображений, работу с различными программами для постобработки.
				Участники курса смогут освоить цветокоррекцию, ретушь, а также научатся улучшать композицию и устранять дефекты
				на фотографиях. Регистрация <a class="NewsPage-link" href="https://perseusirk.ru/ProfileBlock/EventsPage187">по
					ссылке</a>
			</p>

		</div>
	</div>
</template>
