<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Еженедельный курс
		</h3>
		<h2 class="EventsPage-title">
			«Наследие монгольского народного танца»
		</h2>
		<p class="EventsPage-date">
			с 10 сентября по 19 октября 2024 года
		</p>
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Moodle
		</a> -->
		<a class="EventsPage-btn temp-btn"
			href="https://р38.навигатор.дети/program/25556-programma-nasledie-mongolskogo-narodnogo-tantsa" target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			К освоению программы допускаются учащиеся организаций дополнительного образования (ДШИ, самодеятельные
			танцевальные коллективы) в возрасте от 10 до 17 лет. Предварительная подготовка обучающихся заключается в базовом
			владении основами народного танца:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				постановка корпуса, рук, ног;
			</li>
			<li class="JuniorProfi-items">
				знание основных элементов народного танца.
			</li>
		</ul>

		<p class="EventsPage-text">
			Учебные занятия проводятся очно с периодичностью 3 раза в неделю на базе МКОУ Атагайская СОШ.
		</p>

		<p class="EventsPage-text">
			По итогам обучения выдаются сертификаты об освоении дополнительной общеразвивающей программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Намаконова Лариса Владимировна, главный балетмейстер ГБУ ДО ИО «Центр развития творчества детей и юношества
				«Узорочье»
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Учащимся необходимо обязательно зарегистрироваться до 9 сентября 2024 года в системе Навигатор
				дополнительного образования Иркутской области по ссылке:
				https://р38.навигатор.дети/program/25556-programma-nasledie-mongolskogo-narodnogo-tantsa
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>