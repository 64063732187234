<template>
	<div class="EventsCard-container">
		<h2 class="EventsCard-title-box col-2">
			Профильная смена
		</h2>
		<h3 class="EventsCard-title">
			«Спортивное ориентирование»
		</h3>
		<p class="EventsCard-sub-title">
			с 26 по 30 сентября 2024 года
		</p>
		<p class="EventsCard-sub-title">
			с 02 по 06 октября 2024 года
		</p>
		<p class="EventsCard-sub-title">
			с 08 по 12 октября 2024 года
		</p>
	</div>
</template>