<template>
	<div class="DirectionBlock _cover">
		<div class="DirectionBlock-img-box">
			<img loading="lazy" class="DirectionBlock-img" src="../assets/img/Logo/ИСКУССТВО.webp" alt="Искусство">
		</div>

		<div class="Intelligence-block-video-box">
			<div class="Intelligence-block-video">
				<iframe class="Intelligence-block-video-items"
					src="https://www.youtube.com/embed/R3AzMMIDLt8?si=FCVhjS34lrwfRsKG" title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen>
					Направление Искусство
				</iframe>
			</div>
		</div>

		<!-- Профильные смены -->
		<div class="Period">
			<h2 class="Period-title _cover-title">
				Профильные смены
			</h2>

			<ul class="grid-container">
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage192' })">
						<EventsCard192></EventsCard192>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage178' })">
						<EventsCard178></EventsCard178>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage177' })">
						<EventsCard177></EventsCard177>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage154' })">
						<EventsCard154></EventsCard154>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage153' })">
						<EventsCard153></EventsCard153>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage145' })">
						<EventsCard145></EventsCard145>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage107' })">
						<EventsCard107></EventsCard107>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage105' })">
						<EventsCard105></EventsCard105>
					</a>
				</li>
				<li class="grid-item grid-itemss">
					<a class="Period-link" @click="$router.push({ name: 'EventsPage93' })">
						<EventsCard93></EventsCard93>
					</a>
				</li>

			</ul>
		</div>

		<!-- Завершенные программы -->
		<div class="Period">
			<h2 class="Period-title _cover-title">
				Завершенные программы
			</h2>

			<details class="Ol_title-drop">
				<summary class="Completed-Period-hide-title">
					Подробнее
				</summary>
				<ul class="grid-container">
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage18' })">
							<EventsCard18></EventsCard18>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage3' })">
							<EventsCard3></EventsCard3>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" @click="$router.push({ name: 'EventsPage4' })">
							<EventsCard4></EventsCard4>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/eksteryernaya_rospis/" target="_blank">
							<EventsCard64></EventsCard64>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-shcol-t-160423-300423/" target="_blank">
							<EventsCard63></EventsCard63>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-mif-10023-230323/" target="_blank">
							<EventsCard62></EventsCard62>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-tbnk-080223-210223/" target="_blank">
							<EventsCard61></EventsCard61>
						</a>
					</li>
					<li class="grid-item grid-itemss">
						<a class="Period-link" href="https://starsite.perseusirk.ru/ps-lths-180123-310123/" target="_blank">
							<EventsCard60></EventsCard60>
						</a>
					</li>
				</ul>
			</details>

		</div>
	</div>
</template>

<script>
import EventsCard3 from '../EventsCard/EventsCard3';
import EventsCard4 from '../EventsCard/EventsCard4';
import EventsCard18 from '../EventsCard/EventsCard18';
import EventsCard60 from '../EventsCard/EventsCard60';
import EventsCard61 from '../EventsCard/EventsCard61';
import EventsCard62 from '../EventsCard/EventsCard62';
import EventsCard63 from '../EventsCard/EventsCard63';
import EventsCard64 from '../EventsCard/EventsCard64';
import EventsCard93 from '../EventsCard/EventsCard93';
import EventsCard105 from '../EventsCard/EventsCard105';
import EventsCard107 from '../EventsCard/EventsCard107';
import EventsCard145 from '../EventsCard/EventsCard145';
import EventsCard153 from '../EventsCard/EventsCard153';
import EventsCard154 from '../EventsCard/EventsCard154';
import EventsCard177 from '../EventsCard/EventsCard177';
import EventsCard178 from '../EventsCard/EventsCard178';
import EventsCard192 from '../EventsCard/EventsCard192';



	export default {
		components: {
			EventsCard3,
			EventsCard4,
			EventsCard18,
			EventsCard60,
			EventsCard61,
			EventsCard62,
			EventsCard63,
			EventsCard64,
			EventsCard93,
			EventsCard105,
			EventsCard107,
			EventsCard145,
			EventsCard153,
			EventsCard154,
			EventsCard177,
			EventsCard178,
			EventsCard192,

		}
	}
</script>