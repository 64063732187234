<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Подготовка обучающихся к участию в региональном этапе ВсОШ по физике»
		</h2>
		<p class="EventsPage-date">
			С 17 января по 23 января 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=126" target="_blank">
			Регистрация
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://р38.навигатор.дети/program/24413-programma-podgotovka-k-uchastiyu-vo-vsosh-po-fizike"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Подготовка обучающихся к участию в региональном этапе
			ВсОШ по физике» для учащихся 9-11-х классов общеобразовательных организаций.
		</p>
		<p class="EventsPage-text">
			Отличительной особенностью программы считается совершенствование знаний в области подготовки к заданиям
			регионального этапа Всероссийской олимпиады школьников по физике. Учащиеся, в рамках данной программы, смогут
			ознакомиться с заданиями олимпиады прошлых лет, изучить их решение и типичные ошибки участников, возникающие при
			их выполнении.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Ларюнин Олег Альбертович, кандидат физико-математических наук, ФГБУН Ордена Трудового Красного Знамени Институт
				солнечно-земной физики СО РАН;
			</li>
			<li class="JuniorProfi-items">
				Мотык Илья Дмитриевич, инженер-программист 1-й категории ФГБУН институт солнечно-земной физики СО РАН.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 11 января 2024 г. в 20.00, <a
				href="https://pruffme.com/landing/Perseus/tmp1704772571">https://pruffme.com/landing/Perseus/tmp1704772571</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы:
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/4GkA/jamonMRLE" target="_blank">
			Скачать
		</a>
		<p class="JuniorProfi-sub-title">
			Документы:
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.ru/d/urQ71qeH095OOw" target="_blank">
			Сертификаты
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>