<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Астрономия»
		</h2>
		<p class="EventsPage-date">
			с 10 по 16 сентября 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=181" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/25595-programma-astronomiya"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Астрономия» для учащихся 8-11-х классов
			общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Целью профильной смены является развитие, расширение знаний и возможностей дополнительных возможностей для
			учащихся, имеющих особые достижения в изучении астрономии, а также для тех, кто начинает изучать астрономию для
			обеспечения высокого качества их подготовки к результативному участию в мероприятиях регионального,
			межрегионального, всероссийского и международного уровней.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Булыгин Игорь Викторович, лектор Большого Иркутского планетария;
			</li>
			<li class="JuniorProfi-items">
				Дворкина-Самарская Антонина Анатольевна, кандидат физико-математических наук, доцент кафедры физики
				Педагогического института Иркутского государственного университета;
			</li>
			<li class="JuniorProfi-items">
				Захаров Григорий Викторович ведущий инженер кафедры физики Педагогического института Иркутского государственного
				университета;
			</li>
			<li class="JuniorProfi-items">
				Меркулов Михаил Анатольевич директор Автономной некоммерческой организации «Звездный десант», популяризатор
				науки;
			</li>
			<li class="JuniorProfi-items">
				Семенов Дмитрий Владимирович, руководитель Большого иркутского планетария, ведущий инженер Астрономической
				обсерватории Иркутского государственного университета;
			</li>
			<li class="JuniorProfi-items">
				Язев Сергей Арктурович, профессор, доктор физико-математических наук. Иркутского государственного университета,
				директор Астрономической обсерватории Иркутского государственного университета, старший научный сотрудник
				Института солнечно-земной физики Сибирского отделения Российской академии наук.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету и выполнить задание (мотивационное письмо, тестирование) на платформе ОЦ
				«Персей», до 30 августа 2024 года на платформе обучения Образовательного центра «Персей» системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится с 10 по 16 сентября 2024 года на бесплатной основе в Образовательном центре «Персей»
				(Иркутская область, Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			<b>Результаты отбора будут размещены 2 сентября на сайте Образовательного центра «Персей».</b>
		</p>
		<hr>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 3 сентября 2024 г. в 12.30, <a style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1723610475">ссылка на подключение</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Авдеева Вероника Михайловна</li>
			<li class="EventsPage-number">Аниканов Семён Дмитриевич</li>
			<li class="EventsPage-number">Верхозина Светлана Михайловна</li>
			<li class="EventsPage-number">Дубровин Дмитрий Владимирович</li>
			<li class="EventsPage-number">Дубровина Ольга Владимировна</li>
			<li class="EventsPage-number">Ефременко Дарья Александровна</li>
			<li class="EventsPage-number">Заливанова Марина Алексеевна</li>
			<li class="EventsPage-number">Илевахина Екатерина Сергеевна</li>
			<li class="EventsPage-number">Карнеев Артём Максимович</li>
			<li class="EventsPage-number">Карпов роман Алексеевич</li>
			<li class="EventsPage-number">Котов Егор Константинович</li>
			<li class="EventsPage-number">Крюкова Юлия Владимировна</li>
			<li class="EventsPage-number">Кургузова Алиса Николаевна</li>
			<li class="EventsPage-number">Мельников Ярослав Владимирович</li>
			<li class="EventsPage-number">Печкина Ника Сергеевна</li>
			<li class="EventsPage-number">Плисенко Александр Максимович</li>
			<li class="EventsPage-number">Самычин Арсений Андреевич</li>
			<li class="EventsPage-number">Смирнов Константин Александрович</li>
			<li class="EventsPage-number">Соколова Светлана Михайловна</li>
			<li class="EventsPage-number">Сологубов Александр Дмитриевич</li>
			<li class="EventsPage-number">Халилов Артем Дмитриевич</li>
			<li class="EventsPage-number">Черненко Виктория Николаевна</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://drive.google.com/drive/folders/1ZwKfCQgVNvquBFNN78tTDv89HUjJUt3U"
			target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/EO_K6hziidgiSg" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>