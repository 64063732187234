<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Еженедельный курс
		</h3>
		<h2 class="EventsPage-title">
			Региональные сборы обучающихся по подготовке к программе «Сириус.ИИ»
		</h2>
		<p class="EventsPage-date">
			с 17 по 28 июня 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=165" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/25283-programma-regionalnye-sbory-po-podgotovke-k-siriusii"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Цель программы: подготовка учащихся к Всероссийской научно-технологической программе по решению проектных задач в
			области искусственного интеллекта и смежных дисциплин «Сириус.ИИ».
		</p>

		<p class="EventsPage-text">
			Программа состоит из двух уровней подготовки. Слушатели могу заявиться только на один из уровней: углублённый и
			базовый.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Общие (совместные) темы:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Диалоги о трендах в технологии ИИ от ведущих научных сотрудников Сколтеха и Университета 20.35 (г. Москва);
			</li>
			<li class="JuniorProfi-items">
				Рекомендации по эффективной подготовке к участию в программе «Сириус.ИИ» от методистов программы (ОЦ Сириус,
				г. Сочи);
			</li>
			<li class="JuniorProfi-items">
				Использование генеративного искусственного интеллекта в решение практических и учебных задач (г. Томск);
			</li>
			<li class="JuniorProfi-items">
				Цифровые инструменты с искусственным интеллектом (г. Иркутск);
			</li>
			<li class="JuniorProfi-items">
				Решение практических кейсов с использованием ИИ (ГК Форус, др. партнёры).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Основное содержание Программы углублённого уровня:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				разработка алгоритмов на языке Python;
			</li>
			<li class="JuniorProfi-items">
				анализ данных на Python;
			</li>
			<li class="JuniorProfi-items">
				методы машинного обучения.
			</li>
		</ul>
		<p class="EventsPage-text">
			Количество участников на углублённом уровне – 45 человек.
		</p>

		<p class="EventsPage-text">
			Время проведения занятий: с 9.00 до 12.30.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Основное содержание Программы базового уровня:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				программирование на языке Python;
			</li>
			<li class="JuniorProfi-items">
				введение алгоритмы: реализация на языке Python;
			</li>
			<li class="JuniorProfi-items">
				введение в машинное обучение.
			</li>
		</ul>
		<p class="EventsPage-text">
			Количество участников на базовом уровне – 45 человек.
		</p>

		<p class="EventsPage-text">
			Время проведения занятий: с 13.00 до 16.30.
		</p>
		<hr>

		<p class="EventsPage-text">
			Преподаватели программы: методисты программы «Сириус.ИИ» (ОЦ Сириус), преподаватели Байкальского государственного
			университета, Иркутского национального исследовательского технического университета, Иркутского государственного
			университета, Томского национального исследовательского государственного университета.
		</p>

		<p class="EventsPage-text">
			Объем программы: 40 учебных часов.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зайцев Илья Андреевич, функциональный лидер по направлению Искусственный интеллект ООО НПФ "Форус";
			</li>
			<li class="JuniorProfi-items">
				Кантер Андрей Николаевич, директор по инновациям и работе с учебными заведениями ООО НПФ "Форус";
			</li>
			<li class="JuniorProfi-items">
				Катаев Виктор Борисович, преподаватель-методист ООО «1Т»;
			</li>
			<li class="JuniorProfi-items">
				Кузнецова Елена Владимировна, старший преподаватель ИРНИТУ;
			</li>
			<li class="JuniorProfi-items">
				Перелыгина Александра Юрьевна, заведующий кафедрой инженерной и компьютерной графики ИРНИТУ;
			</li>
			<li class="JuniorProfi-items">
				Петрушин Иван Сергеевич, доцент ИГУ канд. техн. Наук;
			</li>
			<li class="JuniorProfi-items">
				Привалихин Сергей Валентинович, Инженер по лесной графике ООО "Сила Сибири";
			</li>
			<li class="JuniorProfi-items">
				Савченко Ульяна Анатольевна, специалист Томский государственный университет, отдел методического сопровождения
				электронного обучения ЦТИС;
			</li>
			<li class="JuniorProfi-items">
				Толстихин Антон Артемович, Младший научный сотрудник ИДСТУ СО РАН
			</li>
			<li class="JuniorProfi-items">
				Уразова Нина Геннадьевна доцент ИрНИТУ канд. экон. Наук;
			</li>
			<li class="JuniorProfi-items">
				Фокин Игорь Владимирович, руководитель направления «Лесопереработка» Фонд стратегического и инновационного
				развития Иркутской области;
			</li>
			<li class="JuniorProfi-items">
				Хранивский Сергей Дмитриевич, Заведующий отделом информационно-технического обеспечения Образовательный центр
				«Персей»;
			</li>
			<li class="JuniorProfi-items">
				Худышкина Мария Анатольевна, Специалист отдела методического сопровождения электронного обучения ЦТИС ИДО ТГУ;
			</li>
			<li class="JuniorProfi-items">
				Черепахин Антон Евгеньевич, Специалист по работе с гражданами ЦКДО ИДО ТГУ;
			</li>
			<li class="JuniorProfi-items">
				Шадрин Дмитрий Германович, руководитель направления обработки мультимодальных данных Сколковский институт науки
				и технологий, г. Москва канд. техн. Наук;
			</li>
			<li class="JuniorProfi-items">
				Шведина Светлана Александровна, Заведующий отделом развития образовательных программ по направлению "Спорт"
				Образовательный центр «Персей» канд. экон. Наук.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Целевая аудитория: обучающихся 8-10 информационно-технологических классов, имеющих базовые навыки
				программирования на Python.Форма обучения: очная с применением дистанционных образовательных технологий.
			</li>
			<li class="JuniorProfi-items">
				Подготовка будет проходить очно на базе Образовательного центра Персей по адресу: г. Иркутск, Ул. Рабочего
				Штаба, 19а с 17.06.2024 по 28.06.2024 года, кроме субботы и воскресенья.
			</li>
			<li class="JuniorProfi-items">
				Проживание и питание участники организуют и оплачивают самостоятельно.
			</li>
			<li class="JuniorProfi-items">
				По итогам обучения при условии успешного прохождения выдается сертификат о прохождении программы.
			</li>
			<li class="JuniorProfi-items">
				Обучение бесплатно для обучающихся.
			</li>
			<li class="JuniorProfi-items">
				Заявки на обучение принимаются до 5 июня 2024 года. Запись на курсы по ссылке:
				<a style="color:blue"
					href="https://moodle.perseusirk.ru/course/view.php?id=165">https://moodle.perseusirk.ru/course/view.php?id=165
				</a>
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/qoUnOvjgMr4mPA" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Шведина Светлана Александровна, заведующий отделом организации проектной деятельности.
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: s.shvedina@perseusirk.ru.
			</li>
		</ul>

	</div>
</template>