<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Страна железных дорог»
		</h2>
		<p class="EventsPage-date">
			с 14 по 23 марта 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=139" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24574-programma-strana-zheleznykh-dorog"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Страна железных дорог» для учащихся 9-11-х классов
			общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа направлена на формирование представлений о роли железнодорожной отрасли для современной экономики,
			актуализация процесса профессионального самоопределения учащихся за счет организации их деятельности, включающей
			знакомство с миром железнодорожных профессий.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Программа профильной смены включает в себя 3 модуля:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Робототехника;
			</li>
			<li class="JuniorProfi-items">
				Экологией;
			</li>
			<li class="JuniorProfi-items">
				Цифровые технологии вокруг нас.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Анардович Светлана Сергеевна, инженер, ассистент ФГБОУ ВО «Иркутский государственный университет путей
				сообщения»;
			</li>
			<li class="JuniorProfi-items">
				Инкижинова Светлана Антоновна, канд.социол.наук, доцент ФГБОУ ВО «Иркутский государственный университет путей
				сообщения»;
			</li>
			<li class="JuniorProfi-items">
				Исупов Станислав Андреевич, инженер, ассистент ФГБОУ ВО «Иркутский государственный университет путей сообщения»;
			</li>
			<li class="JuniorProfi-items">
				Михайлова Елена Анатольевна, старший преподаватель ФГБОУ ВО «Иркутский государственный университет путей
				сообщения»;
			</li>
			<li class="JuniorProfi-items">
				Руш Елена Анатольевна, доктор техн. наук, профессор, заведующий кафедрой ФГБОУ ВО «Иркутский государственный
				университет путей сообщения»;
			</li>
			<li class="JuniorProfi-items">
				Самарина Мария Владимировна, канд.экон.наук, доцент ФГБОУ ВО «Иркутский государственный университет путей
				сообщения»;
			</li>
			<li class="JuniorProfi-items">
				Самсонов Евгений Анатольевич, канд.экон.наук, доцент ФГБОУ ВО «Иркутский государственный университет путей
				сообщения»;
			</li>
			<li class="JuniorProfi-items">
				Шаванов Николай Дмитриевич, техник 1 категории, ассистент ФГБОУ ВО «Иркутский государственный университет путей
				сообщения».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области и необходимо
				выбрать один из модулей.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 1 марта 2024 года на платформе обучения Образовательного центра
				«Персей» системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 6 марта 2024 г. в 20.00, <a style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1708921820">ссылка на подключение</a>
		</p>
		<hr>

		<h2 class="JuniorProfi-sub-title">
			Списочный состав обучающихся, приглашённых на очную профильную смену:
		</h2>
		<p class="JuniorProfi-sub-title">
			Группа №1 «Робототехника»:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Альхеев Аполлон</li>
			<li class="EventsPage-number">Борисов Илья Евгеньевич</li>
			<li class="EventsPage-number">Васьков Сергей Дмитриевич</li>
			<li class="EventsPage-number">Величко Егор Русланович</li>
			<li class="EventsPage-number">Власов Иван Денисович</li>
			<li class="EventsPage-number">Голяк Ирина Викторовна</li>
			<li class="EventsPage-number">Горишняков Иван Романович</li>
			<li class="EventsPage-number">Иванов Антон Дмитриевич</li>
			<li class="EventsPage-number">Козырев Михаил Максимович</li>
			<li class="EventsPage-number">Непомнищий Макар Евгеньевич</li>
			<li class="EventsPage-number">Печкина Ника Сергеевна</li>
			<li class="EventsPage-number">Пирожкова Кристина Сергеевна</li>
			<li class="EventsPage-number">Подтаева Марго Александровна</li>
			<li class="EventsPage-number">Рожков Андрей Александрович</li>
			<li class="EventsPage-number">Сизых Алина Николаевна</li>
			<li class="EventsPage-number">Соколова Валерия Олеговна</li>
			<li class="EventsPage-number">Солоница Руслан Артемович</li>
			<li class="EventsPage-number">Татарников Вадим Олегович</li>
			<li class="EventsPage-number">Трунёв Андрей Русланович</li>
			<li class="EventsPage-number">Трунёв Андрей Русланович</li>
			<li class="EventsPage-number">Хлыстов Тимофей Дмитриевич</li>
			<li class="EventsPage-number">Шерешков Алексей Андреевич</li>
			<li class="EventsPage-number">Шилова Милана Денисовна</li>
			<li class="EventsPage-number">Щипицын Денис Вадимович</li>
			<li class="EventsPage-number">Юркевич Марк Иванович</li>
		</ol>

		<p class="JuniorProfi-sub-title">
			Группа №2 «Экология»
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Алексеева Татьяна Руслановна</li>
			<li class="EventsPage-number">Антипова Милена Олеговна</li>
			<li class="EventsPage-number">Баженова Анна Ивановна</li>
			<li class="EventsPage-number">Басюк Ксения Алексеевна</li>
			<li class="EventsPage-number">Батракова Раиса Петровна</li>
			<li class="EventsPage-number">Батюта Анастасия Алексеевна</li>
			<li class="EventsPage-number">Березовская Елизавета Максимовна</li>
			<li class="EventsPage-number">Воробьева Любовь Игоревна</li>
			<li class="EventsPage-number">Галдусова Екатерина Алексеевна</li>
			<li class="EventsPage-number">Гончаровская Александра Андреевна</li>
			<li class="EventsPage-number">Господарик Виктория Викторовна</li>
			<li class="EventsPage-number">Грицюк Виктория Максимовна</li>
			<li class="EventsPage-number">Демидович Мария Андреевна</li>
			<li class="EventsPage-number">Денисова Алевтина Александровна</li>
			<li class="EventsPage-number">Дураков Егор Юрьевич</li>
			<li class="EventsPage-number">Дьячук Виктория Сергеевна</li>
			<li class="EventsPage-number">Журавлева Алёна Сергеевна</li>
			<li class="EventsPage-number">Журебихина Валерия Алексеевна</li>
			<li class="EventsPage-number">Завьялов Даниил</li>
			<li class="EventsPage-number">Звягин Дмитрий Игоревич</li>
			<li class="EventsPage-number">Кальментьева Анастасия Алексеевна</li>
			<li class="EventsPage-number">Климова Марина Юрьевна</li>
			<li class="EventsPage-number">Колесникова Кристина Александровна</li>
			<li class="EventsPage-number">Красикова Елизавета Алексеевна</li>
			<li class="EventsPage-number">Куклина Юлия Андреевна</li>
			<li class="EventsPage-number">Любиншин Степан Андреевич</li>
			<li class="EventsPage-number">Любиншин Степан Андреевич</li>
			<li class="EventsPage-number">Мускатина Екатерина Юрьевна</li>
			<li class="EventsPage-number">Невидомская Снежана Артёмовна</li>
			<li class="EventsPage-number">Никита Федотов Алексеевич</li>
			<li class="EventsPage-number">Огнёва Эвелина Николаевна</li>
			<li class="EventsPage-number">Олзоева Кристина Олеговна</li>
			<li class="EventsPage-number">Подойницын Артём Андреевич</li>
			<li class="EventsPage-number">Репина Ангелина Николаевна</li>
			<li class="EventsPage-number">Рязановская Татьяна Александровна</li>
			<li class="EventsPage-number">Силина Анна Максимовна</li>
			<li class="EventsPage-number">Степанова Анастасия Александровна</li>
			<li class="EventsPage-number">Тетенькина Ксения</li>
			<li class="EventsPage-number">Толмачев Егор</li>
			<li class="EventsPage-number">Фильченко Анна Михайловна</li>
			<li class="EventsPage-number">Червов Захар Сергеевич</li>
			<li class="EventsPage-number">Черногородова Доминика Романовна</li>
			<li class="EventsPage-number">Шашутина Анастасия Андреевна</li>
		</ol>

		<p class="JuniorProfi-sub-title">
			Группа №3 «Цифровые технологии вокруг нас»
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Афанасьев Данил Викторович</li>
			<li class="EventsPage-number">Белошитская Арина Валерьевне</li>
			<li class="EventsPage-number">Васильева Елизавета Евгеньевна</li>
			<li class="EventsPage-number">Войченко Антон Дмитриевич</li>
			<li class="EventsPage-number">Гаврилова Софья Андреевна</li>
			<li class="EventsPage-number">Галиулин Ренат Минтагирович</li>
			<li class="EventsPage-number">Горяшин, Дмитрий Владимирович</li>
			<li class="EventsPage-number">Давыденко Валерия Дмитриевна</li>
			<li class="EventsPage-number">Елизавета Алексеевна Крупская</li>
			<li class="EventsPage-number">Завацкий Тимофей</li>
			<li class="EventsPage-number">Закаменных Софья Александровна</li>
			<li class="EventsPage-number">Зуева Таисия Романович</li>
			<li class="EventsPage-number">Иванов Георгий Александрович</li>
			<li class="EventsPage-number">Колесова Елизавета Александровна</li>
			<li class="EventsPage-number">Комин Алеесандр Александрович</li>
			<li class="EventsPage-number">Конакова Полина Денисовна</li>
			<li class="EventsPage-number">Конакова Полина Денисовна</li>
			<li class="EventsPage-number">Кондратьев Константин Владимирович</li>
			<li class="EventsPage-number">Кремнева Вера Александровна</li>
			<li class="EventsPage-number">Купцова Виктория Романовна</li>
			<li class="EventsPage-number">Лудцев Степан Антонович</li>
			<li class="EventsPage-number">Лупанова Вероника Сергеевна</li>
			<li class="EventsPage-number">Медведев Матвей Алексеев</li>
			<li class="EventsPage-number">Мишуров Данила Сергеевич</li>
			<li class="EventsPage-number">Мишуров Матвей Сергеевич</li>
			<li class="EventsPage-number">Мороз Артём Станиславович</li>
			<li class="EventsPage-number">Неумывакин Денис Валерьевич</li>
			<li class="EventsPage-number">Новичков Силантий Андреевич</li>
			<li class="EventsPage-number">Осипова Алина Ивановна</li>
			<li class="EventsPage-number">Павлюченко Кирилл Константинович</li>
			<li class="EventsPage-number">Пинчук Алексей Дмитриевич</li>
			<li class="EventsPage-number">Рудых Алиса Александровна</li>
			<li class="EventsPage-number">Селезнев Тимофей Алексеевич</li>
			<li class="EventsPage-number">Семенов Марк Сергеевич</li>
			<li class="EventsPage-number">Слименева Карина Михайловна</li>
			<li class="EventsPage-number">Страшинский Роман Александрович</li>
			<li class="EventsPage-number">Трофимов Александр Николаевич</li>
			<li class="EventsPage-number">Хмелева Анастасия Геннадьевна</li>
			<li class="EventsPage-number">Черепахин Семён Андреевич</li>
			<li class="EventsPage-number">Чеснокова Анна Аркадьевна</li>
			<li class="EventsPage-number">Щепин Евгений Данилович</li>
			<li class="EventsPage-number">Юргин Владислав Андреевич</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/k5rf/EFA2x7K5q" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/2BCfzATcblGV4A" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>