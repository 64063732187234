<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Основы обработки фотографии»
		</h2>
		<p class="EventsPage-date">
			30 сентября по 4 ноября 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=186" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/25551-programma-osnovy-obrabotki-fotografii" target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит дистанционный курс «Основы обработки фотографии» для учащихся 8-11-х
			классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа «Основы обработки фотографии» ориентирована на формирование у учащихся профессиональных знаний, умений и
			навыков, необходимых для работы на этапе продакшна и пост-продакшна в фотографии. Также данная программа поможет
			сформировать навык обработки фотографий и размещения в сети Интернет графических файлов.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Хранивский Сергей Дмитриевич, заведующий отдел информационно-технического обеспечения и связей с общественностью
				Государственного автономного нетипового учреждения дополнительного образования Иркутской области «Региональный
				центр выявления и	поддержки одаренных детей «Персей».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 30 сентября 2024 года на платформе Навигатор дополнительного образования детей Иркутской
				области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 30 сентября 2024 года на платформе Moodle, Образовательного центра
				«Персей»
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>