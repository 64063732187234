<template>
	<div class="TeachingStaff _cover">
		<h2 class="TeachingStaff-title _cover-title">
			Педагогический состав
		</h2>

		<div class="StructureAndOrgans-table">
			<table>
				<tr>
					<td>№</td>
					<td>Ф.И.О.</td>
					<td>Занимаемая должность</td>
					<td>Уровень образования</td>
					<td>Квалификация</td>
					<td>Наименование направленности подготовки и (или) специальности</td>
					<td>Учёная степень (при наличии)</td>
					<td>Учёное звание (при наличии)</td>
					<td>Повышение квалификации и (или) профессиональная переподготовка (при наличии)</td>
					<td>Общий стаж работы</td>
					<td>Стаж работы по специальности</td>
					<td>Преподаваемые учебные предметы, дисциплины (модули) </td>
				</tr>
				<tr>
					<td>1</td>
					<td>Бутакова Светлана Владимировна </td>
					<td>Старший методист </td>
					<td>Высшее</td>
					<td>1995 г., Учитель немецкого и английского языков</td>
					<td>Немецкий и английский язык</td>
					<td>нет </td>
					<td>нет </td>
					<td></td>
					<td>24 года </td>
					<td>10 лет </td>
					<td> </td>
				</tr>
				<tr>
					<td>2</td>
					<td>Говорова Анастасия Андреевна </td>
					<td>Специалист со связью с общественностью </td>
					<td>Высшее</td>
					<td>2018 г., Педагог дополнительного образования в области социально-педагогической деятельности 2022г.
						Журналистика</td>
					<td>Педагогика дополнительного образования</td>
					<td>нет </td>
					<td>нет </td>
					<td>2024 г. ДПП ПК «Эффективные приемы и инструменты для информирования об образовательных программах и
						мероприятиях»</td>
					<td>3 года </td>
					<td>8 мес. </td>
					<td>Журналистика. Искусство фотосъемки. </td>
				</tr>
				<tr>
					<td>3</td>
					<td>Горчаков Алексей Михайлович </td>
					<td>Старший методист </td>
					<td>Высшее</td>
					<td>1986 г., Инженер- электрик</td>
					<td>Авиационное оборудование</td>
					<td>Кандидат технических наук </td>
					<td>Доцент </td>
					<td></td>
					<td>42 года </td>
					<td>30 лет </td>
					<td> </td>
				</tr>
				<tr>
					<td>4</td>
					<td>Дума Наталья Леонидовна</td>
					<td>Педагог дополнительного образования </td>
					<td>Высшее</td>
					<td>2001 г., Преподаватель дошкольной педагогики психологии</td>
					<td>Дошкольная педагогика и психология</td>
					<td>нет </td>
					<td>нет </td>
					<td></td>
					<td>24 года </td>
					<td>24 года </td>
					<td> </td>
				</tr>
				<tr>
					<td>5</td>
					<td>Мельник Алина Владимировна </td>
					<td>Старший методист </td>
					<td>Высшее</td>
					<td>2006 г., Организатор- методист дошкольного образования</td>
					<td>Педагогика и методика дошкольного образования</td>
					<td>нет </td>
					<td>нет </td>
					<td></td>
					<td>22 года </td>
					<td>22 года </td>
					<td> </td>
				</tr>
				<tr>
					<td>6</td>
					<td>Милослова Светлана Николаевна </td>
					<td>Педагог- психолог </td>
					<td>Высшее</td>
					<td>1987 г., Учитель начальных классов 2008 г., Психолог. Преподаватель психологии</td>
					<td>Педагогика и методика начального обучения. Психология</td>
					<td>нет </td>
					<td>нет </td>
					<td>2023 г. ДПП ПК «Содержание и технологии деятельности педагога ДОО в соответствии с федеральной
						образовательной программой»; 2022 г.ДПП ПК «Применение игровых технологий в обучении детей дошкольного
						возраста (с учетом стандарта Ворлдскиллс по компетенциям «Дошкольное воспитание»)»</td>
					<td>27 лет </td>
					<td>27 лет </td>
					<td>Психология </td>
				</tr>
				<tr>
					<td>7</td>
					<td>Намаконова Лариса Владимировна</td>
					<td>Преподаватель (внешний совместитель) </td>
					<td>Высшее </td>
					<td>1998 г., Методист народного творчества, балетмейстер-педагог</td>
					<td>Народное художественное творчество (хореография) </td>
					<td>нет </td>
					<td>нет </td>
					<td></td>
					<td>28 лет </td>
					<td>28 лет </td>
					<td>Хореографическое искусство </td>
				</tr>
				<tr>
					<td>8</td>
					<td>Хасанов Тимур Баходирович</td>
					<td>Воспитатель </td>
					<td>Среднее профессиональное </td>
					<td>2023 г., Педагог по физической культуре и спорту</td>
					<td>Физическая культура </td>
					<td>нет </td>
					<td>нет </td>
					<td></td>
					<td>1 год </td>
					<td>1 год </td>
					<td> </td>
				</tr>
				<tr>
					<td>9</td>
					<td>Хранивский Сергей Дмитриевич</td>
					<td>Руководитель управления </td>
					<td>Высшее </td>
					<td>2016 г., Строитель</td>
					<td>Строительство </td>
					<td>нет </td>
					<td>нет </td>
					<td>2022 г. ДПП ПК «Специалист по безопасности компьютерных систем и сетей» 2024 г. ДПП ПП «Педагог
						дополнительного образования»</td>
					<td>5 лет </td>
					<td>7 мес. </td>
					<td>Искусство фотосъемки </td>
				</tr>
				<tr>
					<td>10</td>
					<td>Эрматов Шохжахон Баходирович</td>
					<td>Воспитатель </td>
					<td>Среднее профессиональное </td>
					<td>2024 г., Педагог по физической культуре и спорту</td>
					<td>Физическая культура </td>
					<td>нет </td>
					<td>нет </td>
					<td></td>
					<td>0</td>
					<td>0</td>
					<td> </td>
				</tr>
				<tr>
					<td>11</td>
					<td>Юрьева Ольга Алексеевна</td>
					<td>Преподаватель </td>
					<td>Высшее </td>
					<td>1985 г., Учитель русского языка и литературы</td>
					<td>Учитель русского языка и литературы </td>
					<td>нет </td>
					<td>нет </td>
					<td></td>
					<td>35 лет </td>
					<td>35 лет </td>
					<td>Литература. </td>
				</tr>
			</table>
		</div>

		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%A1%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%BE-%D0%BF%D0%B5%D0%B4%D0%B0%D0%B3%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D1%85-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BD%D0%B8%D0%BA%D0%B0%D1%85-10.09.2024.pdf"
					target="_blank">
					Сведения о педагогических работниках 10.09.2024
				</a>
			</li>
		</ul>

	</div>
</template>