<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Байкал - природная лаборатория»
		</h2>
		<p class="EventsPage-date">
			с 10 по 23 апреля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=149" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24672-programma-baikal-prirodnaya-laboratoriya"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Байкал - природная лаборатория» для учащихся 8-11-х
			классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа даст возможность учащимся сформировать практические знания о возникновении и функционировании уникальной
			байкальской природы, об особенностях видового разнообразия живых организмов Прибайкалья и озера Байкал, а также
			навыки самостоятельной научно- исследовательской деятельности по комплексному изучению водных экосистем.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Майор Татьяна Юрьевна, кандидат биологических наук, инженер Лимнологический институт СО РАН;
			</li>
			<li class="JuniorProfi-items">
				Наумова Елена Юрьевна, кандидат биологических наук, старший научный сотрудник Лимнологический институт СО РАН;
			</li>
			<li class="JuniorProfi-items">
				Перетолчина Татьяна Евгеньевна, кандидат биологических наук, старший научный сотрудник Лимнологический институт
				СОА РАН;
			</li>
			<li class="JuniorProfi-items">
				Суслова Мария Юрьевна, кандидат биологических наук, старший научный сотрудник Лимнологический институт СО РАН;
			</li>
			<li class="JuniorProfi-items">
				Тихонова Ирина Васильевна, кандидат биологических наук, старший научный сотрудник Лимнологический институт СО
				РАН.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету и выполнить задание до 24 марта 2024 года на платформе Moodle,
				Образовательного центра «Персей»
			</li>
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>

		<p class="EventsPage-text">
			<b>Результаты отбора будут размещены 27 марта на сайте Образовательного центра «Персей».</b>
		</p>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 2 апреля 2024 г. в 20.00 <a style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1709296297">ссылка на подключение</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Аверьянова Вероника Валерьевна</li>
			<li class="EventsPage-number">Бабаева Арюна Станиславовна</li>
			<li class="EventsPage-number">Бабашко Никита Николаевич</li>
			<li class="EventsPage-number">Баренцева Милана Владимировна</li>
			<li class="EventsPage-number">Белянская Юлия Александровна</li>
			<li class="EventsPage-number">Белянский Тимофей Александрович</li>
			<li class="EventsPage-number">Буйлов Тимофей Станиславович</li>
			<li class="EventsPage-number">Власов Иван Денисович</li>
			<li class="EventsPage-number">Грязнова Анна Владиславовна</li>
			<li class="EventsPage-number">Иванов Антон Дмитриевич</li>
			<li class="EventsPage-number">Ковалёва Екатерина Михайловна</li>
			<li class="EventsPage-number">Крюкова Юлия Владимировна</li>
			<li class="EventsPage-number">Лештаева Ксения Николаевна</li>
			<li class="EventsPage-number">Ли Иван Алексеевич</li>
			<li class="EventsPage-number">Лобанова Анастасия Алексеевна</li>
			<li class="EventsPage-number">Любославская Ольга Ивановна</li>
			<li class="EventsPage-number">Мальцева Ирина Андреевна</li>
			<li class="EventsPage-number">Михеева Полина Артемовна</li>
			<li class="EventsPage-number">Мошкирева Софья Юрьевна</li>
			<li class="EventsPage-number">Пивнёва София Дмитриевна</li>
			<li class="EventsPage-number">Плюснин Евгений Викторович</li>
			<li class="EventsPage-number">Попова Марианна Николаевна</li>
			<li class="EventsPage-number">Пылаева Яна Сергеевна</li>
			<li class="EventsPage-number">Решетников Илья Алексеевич</li>
			<li class="EventsPage-number">Рогожина Милана Алексеевна</li>
			<li class="EventsPage-number">Скотаренко Артемий Олегович</li>
			<li class="EventsPage-number">Соболева Алёна Денисовна</li>
			<li class="EventsPage-number">Степанова Раджана Сергеевна</li>
			<li class="EventsPage-number">Ташкаракова Светлана Алексеевна</li>
			<li class="EventsPage-number">Фидикова Дарья Евгеньевна</li>
			<li class="EventsPage-number">Черноусова Дарина Денисовна</li>
			<li class="EventsPage-number">Чистохина Софья Александровна</li>
			<li class="EventsPage-number">Шашлова Александра Евгеньевна</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/zBja/Q1XeJjqL8" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/lDGIQAixan83aQ" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>