<template>
	<div class="Events _cover">
		<h2 class="Events-title _cover-title">
			Актуальные программы
		</h2>

		<h4 class="MainNavigator-title">При выборе общеразвивающей программы или мероприятия Образовательного центра
			"Персей" необходимо пройти регистрацию на сайте: Навигатор дополнительного образования детей Иркутской области
			<a class="MainOlympics-link" href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/"
				target="_blank">38-навигатор.дети</a>
		</h4>
		<h4 class="MainNavigator-title">
			<a class="MainOlympics-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/07/%D0%98%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D1%8F-%D0%BF%D0%BE-%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B0%D1%86%D0%B8%D0%B8-%D0%BD%D0%B0%D0%B2%D0%B8%D0%B3%D0%B0%D1%82%D0%BE%D1%80.pdf"
				target="_blank">
				Инструкция по регистрации
			</a>
		</h4>
		<div class="grid-container">


			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage197' })">
						<events-card197></events-card197>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage196' })">
						<events-card196></events-card196>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage195' })">
						<events-card195></events-card195>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage194' })">
						<events-card194></events-card194>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage193' })">
						<events-card193></events-card193>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage192' })">
						<events-card192></events-card192>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage191' })">
						<events-card191></events-card191>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage190' })">
						<events-card190></events-card190>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage189' })">
						<events-card189></events-card189>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage188' })">
						<events-card188></events-card188>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage187' })">
						<events-card187></events-card187>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage186' })">
						<events-card186></events-card186>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage185' })">
						<events-card185></events-card185>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage184' })">
						<events-card184></events-card184>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage183' })">
						<events-card183></events-card183>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage174' })">
						<events-card174></events-card174>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage176' })">
						<events-card176></events-card176>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage175' })">
						<events-card175></events-card175>
					</a>
				</div>
			</div>
			<div class="grid-element">
				<div class="Events-direction">
					<a class="Events-direction-link" @click="$router.push({ name: 'EventsPage148' })">
						<events-card148></events-card148>
					</a>
				</div>
			</div>

		</div>

		<!-- <router-link to="/ProfileBlock" class="Events-btn temp-btn">
				Все программы
		</router-link> -->
	</div>
</template>

<script>
import EventsCard148 from '../EventsCard/EventsCard148';
import EventsCard174 from '../EventsCard/EventsCard174';
import EventsCard175 from '../EventsCard/EventsCard175';
import EventsCard176 from '../EventsCard/EventsCard176';
import EventsCard183 from '../EventsCard/EventsCard183';
import EventsCard184 from '../EventsCard/EventsCard184';
import EventsCard185 from '../EventsCard/EventsCard185';
import EventsCard186 from '../EventsCard/EventsCard186';
import EventsCard187 from '../EventsCard/EventsCard187';
import EventsCard188 from '../EventsCard/EventsCard188';
import EventsCard189 from '../EventsCard/EventsCard189';
import EventsCard190 from '../EventsCard/EventsCard190';
import EventsCard191 from '../EventsCard/EventsCard191';
import EventsCard192 from '../EventsCard/EventsCard192';
import EventsCard193 from '../EventsCard/EventsCard193';
import EventsCard194 from '../EventsCard/EventsCard194';
import EventsCard195 from '../EventsCard/EventsCard195';
import EventsCard196 from '../EventsCard/EventsCard196';
import EventsCard197 from '../EventsCard/EventsCard197';


	export default {
		components: {
			EventsCard148,
			EventsCard174,
			EventsCard175,
			EventsCard176,
			EventsCard183,
			EventsCard184,
			EventsCard185,
			EventsCard186,
			EventsCard187,
			EventsCard188,
			EventsCard189,
			EventsCard190,
			EventsCard191,
			EventsCard192,
			EventsCard193,
			EventsCard194,
			EventsCard195,
			EventsCard196,
			EventsCard197,
		}
	}
</script>
