<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционные курсы
		</h3>
		<h2 class="EventsPage-title">
			«Зарядка для ума. Нейробика»
		</h2>
		<p class="EventsPage-date">
			18 сентября 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=188" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://р38.навигатор.дети/program/25597-programma-zaryadka-dlya-uma-neirobika " target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребенка» проводит курсы с использованием дистанционных образовательных технологий «Зарядка
			для ума. Нейробика».
		</p>

		<p class="EventsPage-text">
			Программа «Зарядка для ума. Нейробика» направлена на развитие памяти, творческого мышления, решению проблем с
			ориентацией у детей, интеллектуальным развитием, способности с усвоением информации. Для учащихся, занимающихся в
			хореографических коллективах, занятия по программе будут способствовать развитию координации, умений управлять
			своим телом и мыслями, навыка регуляции напряжения-расслабления необходимых групп мышц в том или ином танцевальном
			движении.
		</p>

		<p class="EventsPage-text">
			В образовательной программе могут принять участие учащиеся общеобразовательных организаций Иркутской области в
			возрасте от 10 до 17 лет. Предварительной подготовки учащихся не требуется.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты об освоении дополнительной общеразвивающей
			программы.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватель:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Намаконова Лариса Владимировна, главный балетмейстер ГБУ ДО ИО ЦРДТЮ «Узорочье»
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться в системе Навигатор дополнительного образования Иркутской области по
				ссылке: https://р38.навигатор.дети/program/25597-programma-zaryadka-dlya-uma-neirobika
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться на сайте ОЦ «Персей» и обязательно заполнить АНКЕТУ по ссылке:
				https://moodle.perseusirk.ru/course/view.php?id=188
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Сыроватская Ангелина Геннадьевна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: a.syrovatskaya@perseusirk.ru
			</li>
		</ul>

	</div>
</template>