<template>
	<div class="AllRussian _cover">
		<h2 class="AllRussian-title _cover-title">
			«Всероссийская олимпиада школьников» 2023-2024г.
		</h2>
		<div class="AllRussian-img-box">
			<img loading="lazy" class="AllRussian-img" src="../assets/img/svg/ВСОШ.svg"
				alt="Всероссийская олимпиада школьников">
		</div>
		<div class="AllRussian-btn-box">
			<a class="AllRussian temp-btn" @click="$router.push({ name: 'Preparation' })">
				Подготовка к олимпиаде
			</a>
			<a class="AllRussian temp-btn" @click="$router.push({ name: 'ContactsofOlympiad' })">
				Контакты
			</a>
			<a class="AllRussian temp-btn" @click="$router.push({ name: 'SchoolStage' })">
				Школьный этап
			</a>
		</div>
		<hr>

		<ul class="AllRussian-list">
			<li class="AllRussian-items">
				<a class="AllRussian-items-link" href="https://edu.gov.ru/">
					<p class="AllRussian-items-dist">
						Организатор заключительного этапа олимпиады:
					</p>
					<img loading="lazy" class="AllRussian-items-img" src="../assets/img/svg/logo-1.webp"
						alt="Организатор заключительного этапа олимпиады">
					<p class="AllRussian-items-dist">
						Министерство образования и науки Российской Федерации
					</p>
				</a>
				<a class="AllRussian-items-link" href="https://irkobl.ru/sites/minobr/olimpiada/">
					<p class="AllRussian-items-dist">
						Организатор регионального этапа олимпиады:
					</p>
					<img loading="lazy" class="AllRussian-items-img" src="../assets/img/svg/лого-министерство.webp"
						alt="Организатор регионального этапа олимпиады">
					<p class="AllRussian-items-dist">
						Министерство образования Иркутской области
					</p>
				</a>
				<a class="AllRussian-items-link" @click="$router.push({ name: 'Main' })">
					<p class="AllRussian-items-dist">
						Оператор регионального этапа олимпиады:
					</p>
					<img loading="lazy" class="AllRussian-items-img" src="../assets/img/Logo/logo.webp"
						alt="Оператор регионального этапа олимпиады">
					<p class="AllRussian-items-dist">
						Региональный центр выявления и поддержки одаренных детей в Иркутской области "Образовательный центр Персей"
					</p>
				</a>
			</li>
		</ul>
		<hr>

		<h2 class="AllRussian-title _cover-title">
			Нормативно-правовые документы Министерства просвещения Российской Федерации
		</h2>
		<a class="AllRussian-doc"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%E2%84%96678-%D0%BE%D1%82-27.11.20-%D0%9E%D0%B1-%D1%83%D1%82%D0%B2%D0%B5%D1%80%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%9F%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D0%B0-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%92%D1%81%D0%9E%D0%A8.pdf"
			target="_blank">
			Приказ №678 от 27.11.20 Об утверждении Порядка проведения ВсОШ
		</a><br>
		<a class="AllRussian-doc"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/09/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%D0%9C%D0%B8%D0%BD%D0%B8%D1%81%D1%82%D0%B5%D1%80%D1%81%D1%82%D0%B2%D0%B0-%D0%BF%D1%80%D0%BE%D1%81%D0%B2%D0%B5%D1%89%D0%B5%D0%BD%D0%B8%D1%8F-%E2%84%96740-%D0%BE%D1%82-16.08.22-%D0%9E-%D0%B2%D0%BD%D0%B5%D1%81%D0%B5%D0%BD%D0%B8%D0%B8-%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B9-%D0%B2-%D1%81%D0%BE%D1%81%D1%82%D0%B0%D0%B2-%D0%A6%D0%9F%D0%9C%D0%9A-%D0%92%D0%A1%D0%9E%D0%A8.pdf"
			target="_blank">
			Приказ Министерства просвещения №740 от 16.08.22 О внесении изменений в состав ЦПМК ВСОШ
		</a><br>
		<a class="AllRussian-doc" href="https://vserosolimp.edsoo.ru/school_way" target="_blank">
			Школьный этап Методические рекомендации к школьному и муниципальному этапам ВсОШ 2023-24 (по предметам)
		</a><br>
		<a class="AllRussian-doc"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%A2%D1%80%D0%B5%D0%B1%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BA-%D0%BE%D1%80%D0%B3.-%D0%B8-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4.-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D1%8D%D1%82%D0%B0%D0%BF%D0%B0-%D0%92%D0%A1%D0%9E%D0%A8-%D0%B2-2023-2024-%D1%83%D1%87.%D0%B3%D0%BE%D0%B4%D1%83.pdf"
			target="_blank">
			Требования к организации и проведению регионального этапа ВсОШ в 2023/2024 учебном году
		</a><br>
		<hr>
		<br>

		<a class="AllRussian-doc" href="https://vserosolimp.edsoo.ru/" target="_blank">
			Сайт Всероссийской олимпиады школьников
		</a><br>
		<hr>

		<h2 class="AllRussian-title _cover-title">
			Документы заключительного этапа
		</h2>
		<a class="AllRussian-dist-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%E2%84%96127-%D0%BE%D1%82-27.02.2024-%D0%9F%D1%80%D0%BE%D1%85%D0%BE%D0%B4%D0%BD%D1%8B%D0%B5-%D0%B1%D0%B0%D0%BB%D0%BB%D1%8B-%D0%97%D0%AD-%D0%92%D1%81%D0%9E%D0%A8-2023-24-1-%D0%B3%D1%80%D1%83%D0%BF%D0%BF%D0%B0-1.pdf"
			target="_blank">
			Приказ №127 от 27.02.2024 Проходные баллы ЗЭ ВсОШ 2023-24 (1 группа)
		</a><br><br>
		<a class="AllRussian-dist-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%E2%84%9694-%D0%BE%D1%82-12.02.2024_%D1%81%D1%80%D0%BE%D0%BA%D0%B8-%D0%B8-%D0%BC%D0%B5%D1%81%D1%82%D0%B0-%D0%97%D0%AD-%D0%92%D1%81%D0%9E%D0%A8-2023-24_%D0%B8%D0%B7%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5.pdf"
			target="_blank">
			Приказ №94 от 12.02.2024_сроки и места ЗЭ ВсОШ 2023-24_изменение
		</a><br><br>
		<a class="AllRussian-dist-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%E2%84%96159-%D0%BE%D1%82-12.03.2024-%D0%BF%D1%80%D0%BE%D1%85%D0%BE%D0%B4%D0%BD%D0%BE%D0%B9-%D0%B1%D0%B0%D0%BB%D0%BB-1-%D0%B8-2-%D0%B3%D1%80%D1%83%D0%BF%D0%BF%D0%B0.pdf"
			target="_blank">
			Приказ №159 от 12.03.2024 проходной балл (1 и 2 группа)
		</a><br><br>
		<hr>

		<h2 class="AllRussian-title _cover-title">
			Документы регионального этапа
		</h2>
		<a class="AllRussian-dist-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9F%D1%80%D0%B8%D0%BA%D0%B0%D0%B7-%E2%84%96804-%D0%BE%D1%82-30.10.23-%D0%9E%D0%B1-%D1%83%D1%81%D1%82%D0%B0%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B8-%D1%81%D1%80%D0%BE%D0%BA%D0%BE%D0%B2-%D0%A0%D0%AD-%D0%92%D0%A1%D0%9E%D0%A8.pdf"
			target="_blank">
			Приказ №804 от 30.10.23 Об установлении сроков РЭ ВСОШ
		</a><br><br>
		<a class="AllRussian-dist-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9F%D0%B8%D1%81%D1%8C%D0%BC%D0%BE-%E2%84%9603-1758-%D0%BE%D1%82-31.10.23-%D0%9E-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%A0%D0%AD-%D0%92%D0%A1%D0%9E%D0%A8.pdf"
			target="_blank">
			Письмо №03-1758 от 31.10.23 О проведении РЭ ВСОШ
		</a><br><br>
		<a class="AllRussian-dist-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9F%D0%B8%D1%81%D1%8C%D0%BC%D0%BE-%E2%84%96-02-55-14424-23-%D0%BE%D1%82-4.12.23-%D0%9E-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%A0%D0%AD-%D0%92%D1%81%D0%9E%D0%A8.pdf"
			target="_blank">
			Письмо № 02-55-14424-23 от 4.12.23 О проведении РЭ ВсОШ
		</a><br><br>
		<a class="AllRussian-dist-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D0%BF%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D1%80%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D1%8D%D1%82%D0%B0%D0%BF%D0%B0-%D0%92%D0%A1%D0%9E%D0%A8-%D0%B2-23-24-%D0%B3%D0%BE%D0%B4%D1%83.pdf"
			target="_blank">
			Распоряжение о проведении регионального этапа ВСОШ в 23-24 году
		</a><br><br>
		<a class="AllRussian-dist-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9E-%D0%BF%D1%80%D0%BE%D1%85%D0%BE%D0%B4%D0%BD%D1%8B%D1%85-%D0%B1%D0%B0%D0%BB%D0%BB%D0%B0%D1%85-%D0%BD%D0%B0-%D0%A0%D0%AD-1-%D0%B3%D1%80%D1%83%D0%BF%D0%BF%D0%B0.pdf"
			target="_blank">
			О проходных баллах на РЭ 1 группа
		</a><br><br>
		<a class="AllRussian-dist-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9E-%D0%BF%D1%80%D0%BE%D1%85%D0%BE%D0%B4%D0%BD%D1%8B%D1%85-%D0%B1%D0%B0%D0%BB%D0%BB%D0%B0%D1%85-%D0%BD%D0%B0-%D0%A0%D0%AD-2-%D0%B3%D1%80%D1%83%D0%BF%D0%BF%D0%B0.pdf"
			target="_blank">
			О проходных баллах на РЭ 2 группа
		</a><br><br>
		<a class="AllRussian-dist-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%92%D1%80%D0%B5%D0%BC%D1%8F-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B8-%D0%BF%D0%BB%D0%BE%D1%89%D0%B0%D0%B4%D0%BA%D0%B8-%D0%A0%D0%AD-2024-%D0%B3%D0%BE%D0%B4-15.pdf"
			target="_blank">
			Время проведения и площадки РЭ 2024 год
		</a><br><br>
		<a class="AllRussian-dist-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-55-335-%D0%BC%D1%80-%D0%BE%D1%82-20-%D0%BC%D0%B0%D1%80%D1%82%D0%B0-2024-%D0%B3.-%D0%9E%D0%B1-%D0%B8%D1%82%D0%BE%D0%B3%D0%B0%D1%85-%D0%92%D1%81%D0%9E%D0%A8-23-24.pdf"
			target="_blank">
			Распоряжение 55-335-мр от 20 марта 2024 г. Об итогах ВсОШ 23-24
		</a><br><br>
		<hr>

		<h2 class="AllRussian-title _cover-title">
			Программы проведения регионального этапа ВСОШ
		</h2>
		<h4 class="AllRussian-title _cover-title">
			Уважаемые участники! Сроки подачи заявлений на просмотр работ и апелляцию в предварительных программах олимпиадных
			туров могут быть скорректированы. Информация о просмотре работ и апелляции будет объявлена на инструктаже перед
			началом олимпиадного тура.
		</h4>
		<br>

		<p class="AllRussian-dist">
			Уважаемые участники! Апелляция по <b>предмету "Английский язык"</b> состоится 6 марта в 11.35. Ссылка на
			конференцию будет направлена участникам на почту.
		</p>

		<a class="AllRussian-dist-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A4%D0%BE%D1%80%D0%BC%D0%B0-%D0%B7%D0%B0%D1%8F%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-%D0%BD%D0%B0-%D0%B0%D0%BF%D0%B5%D0%BB%D0%BB%D1%8F%D1%86%D0%B8%D1%8E.docx"
			target="_blank">
			Форма заявления на апелляцию
		</a><br>
		<br>

		<details class="Logistics-details">
			<summary>Английский язык</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.pdf"
				target="_blank">
				Программа Английский язык
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA-9-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Английский язык, 9-11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA-%D0%BF%D1%80%D0%B5%D0%B4%D0%B2.-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-9-11-%D0%BA%D0%BB.xls"
				target="_blank">
				Предварительный рейтинг, 9-11 класс
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://youtu.be/1KpHHHc9jEo" target="_blank">
				Разбор Listening and Reading
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://youtu.be/HfbUjXsjhu0" target="_blank">
				Use of English
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA-9-11-%D0%BA%D0%BB-%D0%B8%D1%82%D0%BE%D0%B3.xlsx"
				target="_blank">
				Итоговый рейтинг, 9-11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Астрономия</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F.pdf"
				target="_blank">
				Программа Астрономия
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Астрономия, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Астрономия, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Астрономия, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F-%D0%BA%D0%BB%D1%8E%D1%87%D0%B8.pdf"
				target="_blank">
				Разбор заданий (Критерии оценки конкурсных заданий)
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A2%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D0%B0-%D0%BA%D0%BE%D0%B4%D0%BE%D0%B2.-%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F..xlsx"
				target="_blank">
				Таблица кодов
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Итоговый рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Итоговый рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Итоговый рейтинг, 11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Биология</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F.pdf"
				target="_blank">
				Программа Биология
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Биология, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Биология, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Биология, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%B1%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-9-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Предварительный рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%B1%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Предварительный рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%B1%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Предварительный рейтинг, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9E%D1%82%D0%B2%D0%B5%D1%82%D1%8B-%D0%91%D0%B8%D0%BE_%D0%A0%D0%AD-24_9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B2%D0%B0%D1%801.pdf"
				target="_blank">
				Решение, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9E%D1%82%D0%B2%D0%B5%D1%82%D1%8B-%D0%91%D0%B8%D0%BE_%D0%A0%D0%AD-24_10_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B2%D0%B0%D1%801.pdf"
				target="_blank">
				Решение, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9E%D1%82%D0%B2%D0%B5%D1%82%D1%8B-%D0%91%D0%B8%D0%BE_%D0%A0%D0%AD-24_11_%D0%BA%D0%BB%D0%B0%D1%81%D1%81_%D0%B2%D0%B0%D1%801.pdf"
				target="_blank">
				Решение, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A0%D0%B5%D0%B0%D0%BA%D1%82%D0%B8%D0%B2%D1%8B-%D0%BA-%D0%BA%D0%B0%D0%B1%D0%B8%D0%BD%D0%B5%D1%82%D1%83-%D0%B1%D0%B8%D0%BE%D1%85%D0%B8%D0%BC%D0%B8%D0%B8-%D0%BD%D0%B0-%D0%9F%D0%A0%D0%90%D0%9A%D0%A2%D0%98%D0%A7%D0%95%D0%A1%D0%9A%D0%98%D0%99-%D0%A2%D0%A3%D0%A0.pdf"
				target="_blank">
				Реактивы к кабинету биохимии на 1 тур
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9A%D1%80%D0%B8%D1%82%D0%B5%D1%80%D0%B8%D0%B8-%D0%BE%D1%86%D0%B5%D0%BD%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D0%B9-2-%D1%82%D1%83%D1%80%D0%B0.pdf"
				target="_blank">
				Критерии оценивания заданий 2 тура
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%98%D0%A2%D0%93-%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-9-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%98%D0%A2%D0%93-%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-10-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%98%D0%A2%D0%93-%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-11-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>География</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F.pdf"
				target="_blank">
				Программа География
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				География, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F-10-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				География, 10-11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9A%D0%BE%D0%BF%D0%B8%D1%8F-%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-9-%D0%BA%D0%BB-%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F.xlsx"
				target="_blank">
				Предварительный рейтинг 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-10-11-%D0%BA%D0%BB-%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F.xlsx"
				target="_blank">
				Предварительный рейтинг 10-11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/07_-%D0%93%D0%95%D0%9E%D0%93%D0%A0%D0%90%D0%A4%D0%98%D0%AF_%D0%9E%D0%A2%D0%92%D0%95%D0%A2%D0%AB-_2023-24.pdf"
				target="_blank">
				Ответы
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%B8%D1%82%D0%BE%D0%B3-%D0%B3%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F-9-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%B8%D1%82%D0%BE%D0%B3-%D0%B3%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F-10-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Итоговый рейтинг, 10-11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Информатика</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0.pdf"
				target="_blank">
				Программа Информатика
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%98%D0%BA%D1%82-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Икт, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%98%D0%BA%D1%82-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Икт, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%98%D0%BA%D1%82-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Икт, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-9-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-10-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-11-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Искусство</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE.pdf"
				target="_blank">
				Программа Искусство
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Искусство, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Искусство, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Искусство, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A0%D0%AD__%D0%9A%D0%BB%D1%8E%D1%87%D0%B8__%D0%A2%D0%B5%D0%BE%D1%80%D0%B5%D1%82%D0%B8%D1%87_9_%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Разбор заданий (Критерии оценки конкурсных заданий) 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A0%D0%AD_%D0%9A%D0%BB%D1%8E%D1%87%D0%B8__%D0%91%D0%BE%D0%BB%D1%8C%D1%88%D0%BE%D0%B9-%D1%82%D0%B5%D0%B0%D1%82%D1%80_10_%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Разбор заданий (Критерии оценки конкурсных заданий) 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A0%D0%AD_%D0%9A%D0%BB%D1%8E%D1%87%D0%B8_11_%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Разбор заданий (Критерии оценки конкурсных заданий) 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A2%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D0%B0-%D0%BA%D0%BE%D0%B4%D0%BE%D0%B2.-%D0%9C%D0%A5%D0%9A.xlsx"
				target="_blank">
				Таблица кодов
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Итоговый рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Итоговый рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Итоговый рейтинг, 11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Испанский язык</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.pdf"
				target="_blank">
				Программа Испанский язык
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA-9-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Испанский язык, 9-11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D0%A1%D0%9F_%D0%90%D0%A3%D0%94%D0%98%D0%A0%D0%9E%D0%92%D0%90%D0%9D%D0%98%D0%95_%D0%92%D0%9E%D0%A8_%D0%A0%D0%AD_9-11.pdf"
				target="_blank">
				Аудирование
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D0%A1%D0%9F_%D0%9A%D0%9B%D0%AE%D0%A7%D0%98_%D0%92%D0%9E%D0%A8_%D0%A0%D0%AD_9-11.pdf"
				target="_blank">
				Ключи
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9A%D1%80%D0%B8%D1%82%D0%B5%D1%80%D0%B8%D0%B8-%D0%BE%D1%86%D0%B5%D0%BD%D0%BA%D0%B8-%D0%BF%D0%B8%D1%81%D1%8C%D0%BC%D0%B5%D0%BD%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F.pdf"
				target="_blank">
				Критерии оценки письменного задания
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA-%D0%98%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-9-11-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 9-11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>История</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F.pdf"
				target="_blank">
				Программа История
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				История, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				История, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				История, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%B8%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F-9-11-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Предварительный рейтинг, история, 9-11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/1-%D1%82%D1%83%D1%80.-9-11.-%D0%9A%D1%80%D0%B8%D1%82%D0%B5%D1%80%D0%B8%D0%B8-%D0%BE%D1%86%D0%B5%D0%BD%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F.pdf"
				target="_blank">
				Критерии оценивания 1 тур. 9-11
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/2-%D1%82%D1%83%D1%80.-9-11.-%D0%9A%D1%80%D0%B8%D1%82%D0%B5%D1%80%D0%B8%D0%B8-%D0%BE%D1%86%D0%B5%D0%BD%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F.pdf"
				target="_blank">
				Критерии оценивания 2 тур. 9-11
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-9-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				История, итоговый рейтинг, 9-11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Китайский язык</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.pdf"
				target="_blank">
				Программа Китайский язык
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA-9-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Китайский язык, 9-11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%BA%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA-%D0%B8%D1%82%D0%BE%D0%B3.xlsx"
				target="_blank">
				Итоговый рейтинг, 9-11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Литература</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0.pdf"
				target="_blank">
				Программа Литература
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Литература, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Литература, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Литература, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0-9-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Предварительный итог, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0-10-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Предварительный итог, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%BB%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0-11-%D0%BA%D0%BB.xls"
				target="_blank">
				Предварительный итог, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9A%D1%80%D0%B8%D1%82%D0%B5%D1%80_%D0%B8_-%D0%BC%D0%B5%D1%82%D0%BE%D0%B4_%D0%BE%D1%86%D0%B5%D0%BD_%D0%A0%D0%AD_%D0%9B%D0%B8%D1%82_2024_%D0%9E%D1%81%D0%BD.pdf"
				target="_blank">
				Критерии оценки
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A0%D0%AD_%D0%9B%D0%B8%D1%82_9-%D0%BA%D0%BB_2024_%D0%9E%D1%82%D0%B2%D0%B5%D1%82%D1%8B-1_3_%D0%9E%D1%81%D0%BD.pdf"
				target="_blank">
				Ответы 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A0%D0%AD_%D0%9B%D0%B8%D1%82_10-%D0%BA%D0%BB_2024_%D0%9E%D1%82%D0%B2%D0%B5%D1%82%D1%8B_1_3_%D0%9E%D1%81%D0%BD.pdf"
				target="_blank">
				Ответы 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A0%D0%AD_%D0%9B%D0%B8%D1%82_11-%D0%BA%D0%BB_2024_%D0%9E%D1%82%D0%B2%D0%B5%D1%82%D1%8B_1_3_%D0%9E%D1%81%D0%BD.pdf"
				target="_blank">
				Ответы 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%BB%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0-%D0%B8%D1%82%D0%BE%D0%B3-9.xlsx"
				target="_blank">
				Итоговый рейтинг 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%BB%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0-%D0%B8%D1%82%D0%BE%D0%B3-10-1.xlsx"
				target="_blank">
				Итоговый рейтинг 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%BB%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0-%D0%B8%D1%82%D0%BE%D0%B3-11.xlsx"
				target="_blank">
				Итоговый рейтинг 11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Математика</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0.pdf"
				target="_blank">
				Программа Математика
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%B0-%D0%AD%D0%B9%D0%BB%D0%B5%D1%80%D0%B0-8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81-.pdf"
				target="_blank">
				Математика, Олимпиада Эйлера, 8 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Математика, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Математика, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Математика, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%B0-%D0%AD%D0%B9%D0%BB%D0%B5%D1%80%D0%B0-8-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Предварительные итоги, 8 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0-9-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Предварительные итоги, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0-10-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Предварительные итоги, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0-11-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Предварительные итоги, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%B8%D1%82%D0%BE%D0%B3%D0%B8-%D0%BE%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%B0-%D0%AD%D0%B9%D0%BB%D0%B5%D1%80%D0%B0-8-%D0%BA%D0%BB-1.xlsx"
				target="_blank">
				Итоговый рейтинг, 8 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0-%D0%B8%D1%82%D0%BE%D0%B3-9-NEW.xlsx"
				target="_blank">
				Итоговый рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0-%D0%B8%D1%82%D0%BE%D0%B3-10.xlsx"
				target="_blank">
				Итоговый рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%BC%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0-11-%D0%B8%D1%82%D0%BE%D0%B3.xlsx"
				target="_blank">
				Итоговый рейтинг, 11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Немецкий язык</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.pdf"
				target="_blank">
				Программа Немецкий язык
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9-9-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Немецкий, 9-11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA-9-11.xls"
				target="_blank">
				Предварительный итог, 9-11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%BA%D1%80%D0%B8%D1%82%D0%B5%D1%80%D0%B8%D0%B8-%D0%BE%D1%86%D0%B5%D0%BD%D0%BA%D0%B8-%D0%BF%D0%B8%D1%81%D1%8C%D0%BC%D0%B5%D0%BD%D0%BD%D0%BE%D0%B3%D0%BE-%D0%B7%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F-1-%D1%82%D1%83%D1%80.pdf"
				target="_blank">
				критерии оценки письменного задания 1 тур
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A1%D0%BA%D1%80%D0%B8%D0%BF%D1%82-%D0%B0%D1%83%D0%B4%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F.-%D0%A0%D0%AD-223-2024.pdf"
				target="_blank">
				Скрипт аудирования. РЭ 223-2024
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9A%D0%BB%D1%8E%D1%87%D0%B8-%D0%A0%D0%AD-%D0%92%D0%A1%D0%9E%D0%A8-2023-2024.pdf"
				target="_blank">
				Ключи РЭ ВСОШ 2023-2024
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%BD%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-NEW.xlsx"
				target="_blank">
				Итоговый рейтинг, 9-11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>ОБЖ</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%BE%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D1%8B-%D0%9E%D0%91%D0%96-2024-1.doc"
				target="_blank">
				Программа ОБЖ
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9E%D0%91%D0%96-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				ОБЖ, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9E%D0%91%D0%96-10-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				ОБЖ, 10-11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9E%D0%91%D0%96-9-%D0%BA%D0%BB-%D0%B8%D1%82%D0%BE%D0%B3.xlsx"
				target="_blank">
				Итоговый рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9E%D0%91%D0%96-10-%D0%BA%D0%BB-%D0%B8%D1%82%D0%BE%D0%B3.xlsx"
				target="_blank">
				Итоговый рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9E%D0%91%D0%96-11-%D0%BA%D0%BB-%D0%B8%D1%82%D0%BE%D0%B3.xlsx"
				target="_blank">
				Итоговый рейтинг, 11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Обществознание</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5.pdf"
				target="_blank">
				Программа Обществознание
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Обществознание, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Обществознание, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Обществознание, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5-9.xlsx"
				target="_blank">
				Предварительный рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5-10.xlsx"
				target="_blank">
				Предварительный рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5-11.xlsx"
				target="_blank">
				Предварительный рейтинг, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%BE%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5-%D0%B8%D1%82%D0%BE%D0%B3-9-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%BE%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5-%D0%B8%D1%82%D0%BE%D0%B3-10-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%BE%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5-%D0%B8%D1%82%D0%BE%D0%B3-11-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Право</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D0%BB%D0%B0%D0%BD-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%BE%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D1%8B-%D0%92%D1%81%D0%9E%D0%A8_%D1%81%D0%B5%D0%BA%D1%86%D0%B8%D1%8F-%D0%9F%D1%80%D0%B0%D0%B2%D0%BE_-2024.pdf"
				target="_blank">
				Программа Право
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Право, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Право, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Право, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%BF%D1%80%D0%B0%D0%B2%D0%BE-%D0%B8%D1%82%D0%BE%D0%B3-9-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%BF%D1%80%D0%B0%D0%B2%D0%BE-%D0%B8%D1%82%D0%BE%D0%B3-10-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%BF%D1%80%D0%B0%D0%B2%D0%BE-%D0%B8%D1%82%D0%BE%D0%B3-11-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Русский язык</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.pdf"
				target="_blank">
				Программа Русский язык
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Русский, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Русский, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Русский, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A2%D0%B0%D0%B1%D0%BB%D0%B8%D1%86%D0%B0-%D0%BA%D0%BE%D0%B4%D0%BE%D0%B2.-%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.xlsx"
				target="_blank">
				Таблица кодов
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-%D0%9A%D1%80%D0%B8%D1%82%D0%B5%D1%80%D0%B8%D0%B8-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Критерии, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-%D0%9A%D1%80%D0%B8%D1%82%D0%B5%D1%80%D0%B8%D0%B8-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Критерии, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-%D0%9A%D1%80%D0%B8%D1%82%D0%B5%D1%80%D0%B8%D0%B8-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Критерии, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-9-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-10-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-11-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг 11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Технология</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F.pdf"
				target="_blank">
				Программа Технология
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-%D1%8E%D0%BD%D0%BE%D1%88%D0%B8-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Технология, юноши, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-%D1%8E%D0%BD%D0%BE%D1%88%D0%B8-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Технология, девушки, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-%D0%B4%D0%B5%D0%B2%D1%83%D1%88%D0%BA%D0%B8-10-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Технология, девушки, 10-11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-%D1%8E%D0%BD%D0%BE%D1%88%D0%B8-10-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Технология, юноши, 10-11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A2%D1%80%D0%B5%D0%B1%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BA-%D0%BE%D1%84%D0%BE%D1%80%D0%BC%D0%BB%D0%B5%D0%BD%D0%B8%D1%8E-%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%B0-%D0%BA%D1%80%D0%B8%D1%82%D0%B5%D1%80%D0%B8%D0%B8-%D0%BE%D1%86%D0%B5%D0%BD%D0%BA%D0%B8.docx"
				target="_blank">
				Требования к оформлению проекта, критерии оценки
			</a><br><br>
			<p class="AllRussian-dist">
				Пояснительную записку нужно отправить до 12 февраля 2024 года на эл. почту <b>Lerog1@mail.ru</b>
			</p><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-%D0%9A%D0%94%D0%94%D0%A2.xlsx"
				target="_blank">
				Предварительный рейтинг, технология, КДДТ
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-%D0%A2%D0%A2%D0%A2%D0%A2.xlsx"
				target="_blank">
				Предварительный рейтинг, технология, ТТТТ
			</a><br><br><br>

			<p class="AllRussian-dist">
				Информационная безопастность
			</p>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0-%D0%BE%D1%86%D0%B5%D0%BD%D0%BA%D0%B8-%D0%98%D0%91-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81-2023-2024.pdf"
				target="_blank">
				Методика оценки ИБ 9 класс 2023-2024
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0-%D0%BE%D1%86%D0%B5%D0%BD%D0%BA%D0%B8-%D0%98%D0%91-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81-2023-2024.pdf"
				target="_blank">
				Методика оценки ИБ 10 класс 2023-2024
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0-%D0%BE%D1%86%D0%B5%D0%BD%D0%BA%D0%B8-%D0%98%D0%91-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81-2023-2024.pdf"
				target="_blank">
				Методика оценки ИБ 11 класс 2023-2024
			</a><br><br>

			<p class="AllRussian-dist">
				КДДТ
			</p>
			<p class="AllRussian-dist">
				9 класс
			</p>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0-%D0%BE%D1%86%D0%B5%D0%BD%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BA%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D0%BE%D0%B5-%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5.pdf"
				target="_blank">
				Методика оценивания компьютерное моделирование
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D0%BE%D0%B5-%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D1%81-%D0%BE%D1%82%D0%B2%D0%B5%D1%82%D0%B0%D0%BC%D0%B8-9%D0%BA%D0%BB%D0%B0%D1%81%D1%81.svg"
				target="_blank">
				Компьютерное моделирование с ответами 9 ласс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A2%D0%B5%D0%BE%D1%80%D0%B8%D1%8F-%D0%B4%D0%BB%D1%8F-%D0%B6%D1%8E%D1%80%D0%B8-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Теория для жюри 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0-%D0%BE%D1%86%D0%B5%D0%BD%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%A1%D0%AA%D0%81%D0%9C%D0%9D%D0%AB%D0%99-%D0%9A%D0%90%D0%A0%D0%9C%D0%90%D0%9D-%D0%B8-%D0%92%D0%AB%D0%A8%D0%98%D0%92%D0%9A%D0%90.pdf"
				target="_blank">
				Методика оценивания СЪЁМНЫЙ КАРМАН и ВЫШИВКА
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0-%D0%BE%D1%86%D0%B5%D0%BD%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%9C%D0%BE%D0%B4%D0%B5%D0%BB%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5.pdf"
				target="_blank">
				Методика оценивания Моделирование
			</a><br><br>

			<p class="AllRussian-dist">
				10 класс
			</p>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0-%D0%BE%D1%86%D0%B5%D0%BD%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%9A%D0%90%D0%A0%D0%9C%D0%90%D0%9D-%D0%A1-%D0%9F%D0%90%D0%A2%D0%9E%D0%99-%D0%B8-%D0%92%D1%8B%D1%88%D0%B8%D0%B2%D0%BA%D0%B0-%D0%BD%D0%B0-%D0%BA%D0%B0%D1%80%D0%BC%D0%B0%D0%BD%D0%B5-%D1%81-%D0%BA%D0%BE%D1%81%D1%8B%D0%BC-%D0%B2%D1%85%D0%BE%D0%B4%D0%BE%D0%BC.pdf"
				target="_blank">
				Методика оценивания КАРМАН С ПАТОЙ и Вышивка на кармане с косым входом
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D0%BE%D0%B5-%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D1%81-%D0%BE%D1%82%D0%B2%D0%B5%D1%82%D0%B0%D0%BC%D0%B8-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.svg"
				target="_blank">
				Компьютерное моделирование с ответами 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0-%D0%BE%D1%86%D0%B5%D0%BD%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%BA%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D0%BE%D0%B5-%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-1.pdf"
				target="_blank">
				Методика оценивания компьютерное моделирование
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0-%D0%BE%D1%86%D0%B5%D0%BD%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%9C%D0%BE%D0%B4%D0%B5%D0%BB%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-1.pdf"
				target="_blank">
				Методика оценивания Моделирование
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A2%D0%B5%D0%BE%D1%80%D0%B8%D1%8F-%D0%9A%D0%94%D0%94%D0%A2-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Теория КДДТ 10 класс
			</a><br><br>

			<p class="AllRussian-dist">
				11 класс
			</p>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0-%D0%BE%D1%86%D0%B5%D0%BD%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%9A%D0%9E%D0%9B%D0%AC%D0%95-%D0%92%D0%9E%D0%A0%D0%9E%D0%A2%D0%9D%D0%98%D0%9A-%D0%B8-%D0%92%D0%AB%D0%A8%D0%98%D0%92%D0%9A%D0%90-%D0%9F%D1%80.-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%B0-%D0%BF%D0%BE-%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D0%B8-%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B8-%D1%88%D0%B2%D0%B5%D0%B9%D0%BD%D1%8B%D1%85-%D0%B8%D0%B7%D0%B4%D0%B5%D0%BB%D0%B8%D0%B9.pdf"
				target="_blank">
				Методика оценивания КОЛЬЕ-ВОРОТНИК и ВЫШИВКА Пр. работа по технологии обработки швейных изделий
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D0%BE%D0%B5-%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-%D1%81-%D0%BE%D1%82%D0%B2%D0%B5%D1%82%D0%B0%D0%BC%D0%B8-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.svg"
				target="_blank">
				Компьютерное моделирование с ответами 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0-%D0%BE%D1%86%D0%B5%D0%BD%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%9A%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D0%BE%D0%B5-%D0%BC%D0%BE%D0%B4%D0%B5%D0%BB%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5.pdf"
				target="_blank">
				Методика оценивания Компьютерное моделирование
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9C%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D0%BA%D0%B0-%D0%BE%D1%86%D0%B5%D0%BD%D0%B8%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F-%D0%9C%D0%BE%D0%B4%D0%B5%D0%BB%D0%B8%D1%80%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B5-2.pdf"
				target="_blank">
				Методика оценивания Моделирование
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A2%D0%B5%D0%BE%D1%80%D0%B8%D1%8F-%D0%9A%D0%94%D0%94%D0%A2-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Теория КДДТ 11 класс
			</a><br><br>

			<p class="AllRussian-dist">
				РТ
			</p>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A0%D0%AD_%D0%A0%D0%A2_%D0%BE%D1%86%D0%B5%D0%BD%D0%BA%D0%B0_%D0%BF%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B8_9-11_23-24.pdf"
				target="_blank">
				РЭ_РТ_оценка_практики_9-11_23-24
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A2%D0%B5%D0%BE%D1%80%D0%B8%D1%8F-%D0%A0%D0%A2-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Теория РТ 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A2%D0%B5%D0%BE%D1%80%D0%B8%D1%8F-%D0%A0%D0%A2-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Теория РТ 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A2%D0%B5%D0%BE%D1%80%D0%B8%D1%8F-%D0%A0%D0%A2-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Теория РТ 11 класс
			</a><br><br>

			<p class="AllRussian-dist">
				ТТТТ
			</p>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A2%D0%A2%D0%A2%D0%A2-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				ТТТТ 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A2%D0%A2%D0%A2%D0%A2-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				ТТТТ 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A2%D0%A2%D0%A2%D0%A2-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				ТТТТ 11 класс
			</a><br><br>

			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%98%D1%82%D0%BE%D0%B3-%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-%D0%A2%D0%A2%D0%A2%D0%A2.xlsx"
				target="_blank">
				Итоговый рейтинг ТТТТ
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%98%D1%82%D0%BE%D0%B3-%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-%D0%9A%D0%94%D0%94%D0%A2.xlsx"
				target="_blank">
				Итоговый рейтинг КДДТ
			</a><br><br>

		</details>
		<details class="Logistics-details">
			<summary>Физика</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0.pdf"
				target="_blank">
				Программа Физика
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%B0-%D0%9C%D0%B0%D0%BA%D1%81%D0%B2%D0%B5%D0%BB%D0%BB%D0%B0-7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Физика, Олимпиада Максвелла, 7 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF%D0%B8%D0%B0%D0%B4%D0%B0-%D0%9C%D0%B0%D0%BA%D1%81%D0%B2%D0%B5%D0%BB%D0%BB%D0%B0-8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Физика, Олимпиада Максвелла, 8 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Физика, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Физика, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Физика, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D1%84%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-7-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Предварительные итоги, 7 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D1%84%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-8-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Предварительные итоги, 8 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D1%84%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Предварительные итоги, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D1%84%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Предварительные итоги, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D1%84%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Предварительные итоги, 11 класс
			</a><br><br>
			<p class="AllRussian-dist">
				Ключи 1 тур
			</p>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/7T-MS.pdf"
				target="_blank">
				7T-MS
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/7T-s.pdf"
				target="_blank">
				7T-s
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/8T-MS.pdf"
				target="_blank">
				8T-MS
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/8T-s.pdf"
				target="_blank">
				8T-s
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/9T-MS.pdf"
				target="_blank">
				9T-MS
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/9T-s.pdf"
				target="_blank">
				9T-s
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/10T-MS.pdf"
				target="_blank">
				10T-MS
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/10T-s.pdf"
				target="_blank">
				10T-s
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/11T-MS.pdf"
				target="_blank">
				11T-MS
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/11T-s.pdf"
				target="_blank">
				11T-s
			</a><br><br>

			<p class="AllRussian-dist">
				Ключи 2 тур
			</p>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/7e1-s.pdf"
				target="_blank">
				7e1-s
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/7e2-s.pdf"
				target="_blank">
				7e2-s
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/8e1-s.pdf"
				target="_blank">
				8e1-s
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/8e2-s.pdf"
				target="_blank">
				8e2-s
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/9e1-s.pdf"
				target="_blank">
				9e1-s
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/9e2-s.pdf"
				target="_blank">
				9e2-s
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/10e1-s.pdf"
				target="_blank">
				10e1-s
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/10e2-s.pdf"
				target="_blank">
				10e2-s
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/11e1-s.pdf"
				target="_blank">
				11e1-s
			</a><br><br>
			<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/11e2-s.pdf"
				target="_blank">
				11e2-s
			</a><br><br>

			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D1%84%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-7-%D0%BA%D0%BB-%D0%B8%D1%82%D0%BE%D0%B3.xlsx"
				target="_blank">
				Итоговый рейтинг, 7 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D1%84%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-8-%D0%BA%D0%BB-%D0%B8%D1%82%D0%BE%D0%B3.xlsx"
				target="_blank">
				Итоговый рейтинг, 8 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D1%84%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-9-%D0%BA%D0%BB-%D0%B8%D1%82%D0%BE%D0%B3-Ud.xlsx"
				target="_blank">
				Итоговый рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D1%84%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-10-%D0%BA%D0%BB-%D0%B8%D1%82%D0%BE%D0%B3.xlsx"
				target="_blank">
				Итоговый рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D1%84%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0-11-%D0%BA%D0%BB-%D0%B8%D1%82%D0%BE%D0%B3-1.xlsx"
				target="_blank">
				Итоговый рейтинг, 11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Физическая культура</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F-%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0.pdf"
				target="_blank">
				Программа Физическая культура
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F-%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0-%D0%B4%D0%B5%D0%B2%D1%83%D1%88%D0%BA%D0%B8-9-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Физическая культура, девушки, 9-11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F-%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0-%D1%8E%D0%BD%D0%BE%D1%88%D0%B8-9-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Физическая культура, юноши, 9-11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%B8%D1%82%D0%BE%D0%B3%D0%B8-%D1%84%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F-%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0.xlsx"
				target="_blank">
				Итоговый рейтинг, 9-11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Французский язык</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA.pdf"
				target="_blank">
				Программа Французский язык
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9-%D1%8F%D0%B7%D1%8B%D0%BA-9-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Французский язык, 9-11 класс
			</a><br><br>
			<!-- <a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A4%D1%80_%D1%8F%D0%B7_%D0%A0%D0%AD_%D0%92%D1%81%D0%9E%D0%A8_2024.-%D0%BF%D1%80%D0%B5%D0%B4%D0%B2.-%D1%80%D0%B5%D0%B7.-12.01.xlsx" target="_blank">
						Предварительные итоги
					</a><br><br> -->
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D1%84%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-9-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Итоговый рейтинг
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Химия</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F.pdf"
				target="_blank">
				Программа Химия
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Химия, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Химия, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx"
				target="_blank">
				Химия, 11 класс
			</a><br><br>
			<!-- <a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx" target="_blank">
						Предварительные итоги, 9 класс
					</a><br><br>
					<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx" target="_blank">
						Предварительные итоги, 10 класс
					</a><br><br>
					<a class="AllRussian-dist-link" href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.xlsx" target="_blank">
						Предварительные итоги, 11 класс
					</a><br><br> -->
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F-2023-%D0%A0%D0%AD-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81-%D1%85%D0%B8%D0%BC%D0%B8%D1%8F.pdf"
				target="_blank">
				Решение, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F-2023-%D0%A0%D0%AD-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81-%D1%85%D0%B8%D0%BC%D0%B8%D1%8F.pdf"
				target="_blank">
				Решение, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F-2023-%D0%A0%D0%AD-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81-%D1%85%D0%B8%D0%BC%D0%B8%D1%8F.pdf"
				target="_blank">
				Решение, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F-9-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F-10-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%98%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F-11-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Экология</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/2024-%D0%A0%D0%B5%D0%B3%D0%AD%D1%82%D0%9E%D0%BB%D0%B8%D0%BC%D0%BF-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0.pdf"
				target="_blank">
				Программа Экология
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Экология, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Экология, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Экология, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-9-%D0%BA%D0%BB-%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F.xlsx"
				target="_blank">
				Предварительный рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-10-%D0%BA%D0%BB-%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F.xlsx"
				target="_blank">
				Предварительный рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-11-%D0%BA%D0%BB-%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F.xlsx"
				target="_blank">
				Предварительный рейтинг, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D1%8D%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-9-%D0%B8%D1%82%D0%BE%D0%B3.xlsx"
				target="_blank">
				Итоговый рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D1%8D%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-10-%D0%B8%D1%82%D0%BE%D0%B3.xlsx"
				target="_blank">
				Итоговый рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D1%8D%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F-11-%D0%B8%D1%82%D0%BE%D0%B3.xlsx"
				target="_blank">
				Итоговый рейтинг, 11 класс
			</a><br><br>
		</details>
		<details class="Logistics-details">
			<summary>Экономика</summary>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0-%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0.pdf"
				target="_blank">
				Программа Экономика
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Экономика, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Экономика, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2023/12/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Экономика, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D1%8D%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0-9-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Предварительный рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D1%8D%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0-10-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Предварительный рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%9F%D1%80%D0%B5%D0%B4%D0%B2%D0%B0%D1%80%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-%D1%8D%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0-11-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Предварительный рейтинг, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0-%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F-9-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Решение, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0-%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F-10-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Решение, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/01/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0-%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F-11-%D0%BA%D0%BB%D0%B0%D1%81%D1%81.pdf"
				target="_blank">
				Решение, 11 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D1%8D%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-9-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 9 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D1%8D%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-10-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 10 класс
			</a><br><br>
			<a class="AllRussian-dist-link"
				href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D1%8D%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D1%8B%D0%B9-%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3-11-%D0%BA%D0%BB.xlsx"
				target="_blank">
				Итоговый рейтинг, 11 класс
			</a><br><br>
		</details>

		<hr>

		<h2 class="AllRussian-title _cover-title">
			Документы муниципального этапа ВСОШ
		</h2>
		<a class="AllRussian-dist-link"
			href="https://starsite.perseusirk.ru/wp-content/uploads/2023/10/%D0%A0%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%9C%D0%AD-%D0%92%D0%A1%D0%9E%D0%A8-23-24.pdf"
			target="_blank">
			Об организации и проведении муниципального этапа всероссийской олимпиады школьников в 2023/2024 учебном году в
			Иркутской области
		</a><br><br><br>


		<h2 class="AllRussian-title _cover-title">
			Всероссийская олимпиада школьников — крупнейшее интеллектуальное соревнование, в котором ежегодно принимают
			участие более 6 млн школьников.
		</h2>
		<p class="AllRussian-dist">
			Олимпиада проводится по общеобразовательным предметам: математика, русский язык, иностранный язык (английский,
			немецкий, французский, испанский, китайский), информатика, физика, химия, биология, экология, география,
			астрономия, литература, история, обществознание, экономика, право, искусство, физическая культура, технология,
			основы безопасности жизнедеятельности.
		</p>
		<h3 class="AllRussian-sub-title">
			Регистрация на олимпиаду
		</h3>
		<p class="AllRussian-dist">
			К участию в школьном этапе Всероссийской олимпиады школьников приглашаются все желающие обучающиеся.<br>
			Для участия Вам необходимо пройти регистрацию и подать заявку на сайте <a class="AllRussian-dist-link"
				href="https://eor.vserosperseusirk.ru/">https://eor.vserosperseusirk.ru/.</a><br>
			Для получения графика олимпиады школьного этапа и инструкций обратитесь к координатору олимпиады Вашей школы.<br>
			<a class="AllRussian-dist-link" href="https://eor.vserosperseusirk.ru/">https://eor.vserosperseusirk.ru/</a><br>
		</p>
		<h3 class="AllRussian-sub-title">
			ШКОЛЬНЫЙ ЭТАП (сентябрь-октябрь)
		</h3>
		<ul class="AllRussian-dist-list">
			<li class="AllRussian-dist-items">
				для 5-11 классов (по всем предметам), для 4 класса (по предметам математика и русский язык);
			</li>
			<li class="AllRussian-dist-items">
				к участию приглашаются все желающие обучающиеся;
			</li>
			<li class="AllRussian-dist-items">
				формат проведения: по предметам физика, биология, химия, астрономия, математика и информатика – дистанционно, на
				платформе Сириус.Курсы.
			</li>
		</ul>
		<h3 class="AllRussian-sub-title">
			МУНИЦИПАЛЬНЫЙ ЭТАП (ноябрь-декабрь)
		</h3>
		<ul class="AllRussian-dist-list">
			<li class="AllRussian-dist-items">
				для 7-11 классов;
			</li>
			<li class="AllRussian-dist-items">
				приглашаются:
			</li>
			<li class="AllRussian-dist-items">
				— участники школьного этапа текущего учебного года, набравшие необходимое для участия количество баллов,
				установленное министерством образования Иркутской области;
			</li>
			<li class="AllRussian-dist-items">
				— победители и призеры муниципального этапа олимпиады предыдущего учебного года.
			</li>
		</ul>
		<h3 class="AllRussian-sub-title">
			РЕГИОНАЛЬНЫЙ ЭТАП (январь-февраль)
		</h3>
		<ul class="AllRussian-dist-list">
			<li class="AllRussian-dist-items">
				для 9-11 классов;
			</li>
			<li class="AllRussian-dist-items">
				приглашаются:
			</li>
			<li class="AllRussian-dist-items">
				— участники муниципального этапа олимпиады текущего учебного года, набравшие необходимое для участия количество
				баллов, установленное министерством образования Иркутской области;
			</li>
			<li class="AllRussian-dist-items">
				— победители и призеры регионального этапа олимпиады предыдущего учебного года.
			</li>
		</ul>
		<h3 class="AllRussian-sub-title">
			ЗАКЛЮЧИТЕЛЬНЫЙ ЭТАП (март-апрель)
		</h3>
		<ul class="AllRussian-dist-list">
			<li class="AllRussian-dist-items">
				для 9-11 классов;
			</li>
			<li class="AllRussian-dist-items">
				приглашаются:
			</li>
			<li class="AllRussian-dist-items">
				— участники регионального этапа олимпиады текущего учебного года, набравшие необходимое для участия количество
				баллов, установленное Министерством Просвещения Российской Федерации;
			</li>
			<li class="AllRussian-dist-items">
				— победители и призеры заключительного олимпиады предыдущего учебного года.
			</li>
		</ul>
		<hr>

		<h2 class="AllRussian-title _cover-title">
			ЛЬГОТЫ ПОБЕДИТЕЛЯМ И ПРИЗЕРАМ ВСОШ
		</h2>
		<ul class="AllRussian-dist-list">
			<li class="AllRussian-dist-items">
				Главная льгота, которую предоставляет победа или призерство на заключительном этапе всероссийской олимпиады
				школьников, – это поступление в профильные вузы без экзаменов. Принимаются результаты с 9 по 11 класс. Если
				победитель поступает на непрофильную специальность, по решению вуза ему могут зачесть 100 баллов за ЕГЭ по
				предмету, соответствующему профилю олимпиады (соответствие направления и профиля олимпиады определяет сам вуз).
			</li>
			<li class="AllRussian-dist-items">
				Для победителей и призеров регионального этапа ВСОШ есть возможность получения баллов индивидуальных достижений,
				дополнительно к сумме баллов ЕГЭ. Порядок учета и перечень индивидуальных достижений устанавливается каждым
				вузом самостоятельно.
			</li>
		</ul>
	</div>
</template>