<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Ракетостроение»
		</h2>
		<p class="EventsPage-date">
			с 10 по 23 апреля 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=156" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24690-programma-raketostroenie" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Ракетостроение» для учащихся 8-11-х классов
			общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа смены направлена на получение учащимися знаний в области промышленной робототехники, системной
			инженерии, навыков поиска оптимальных и научно-обоснованных подходов. Основанная цель – овладеть всесторонним
			научным подходом и инженерными навыками на примере создания собственного проекта, а также формирование
			персональной траектории роста. Главным результатом программы является повышение статуса, общественной значимости и
			привлекательности деятельности в сфере производства, техники и технологий, социально значимой творческой
			деятельности обучающихся.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Никифоров Павел Геннадьевич, генеральный директор Иркутский планетарий (ООО «Планетарий»);
			</li>
			<li class="JuniorProfi-items">
				Радаев Николай Юрьевич, учитель астрономии МБОУ СОШ N9 48 г. Нижнеудинск;
			</li>
			<li class="JuniorProfi-items">
				Чумаков Сергей Геннадьевич, педагог дополнительного образования МБОУ ДО ШР «Центр творчества».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться, заполнить анкету и выполнить задание (мотивационное письмо, награды) до 1 апреля 2024 года
				на платформе Moodle, Образовательного центра «Персей»
			</li>
			<li class="JuniorProfi-items">
				Для тех участников, которые пройдут конкурсный отбор, нужно будет записаться на программу «Ракетостроение» на
				ресурсе «Навигатор дополнительного образования Иркутской области».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="EventsPage-text">
			<b>Результаты отбора будут размещены 2 апреля на сайте Образовательного центра «Персей».</b>
		</p>
		<hr>

		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 2 апреля 2024 г. в 20.00 <a style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1709296297">ссылка на подключение</a>
		</p>
		<hr>

		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Аюшеев Артём Александрович</li>
			<li class="EventsPage-number">Бирюков Сергей Денисович</li>
			<li class="EventsPage-number">Бураков Матвей Юрьевич</li>
			<li class="EventsPage-number">Бурлаков Егор Андреевич</li>
			<li class="EventsPage-number">Гомелько Рома Владимирович</li>
			<li class="EventsPage-number">Горамьëва Екатерина Евгеньевна</li>
			<li class="EventsPage-number">Григорьев Николай Сергеевич</li>
			<li class="EventsPage-number">Демидов Егор Владимирович</li>
			<li class="EventsPage-number">Дергачев Сергей Владимирович</li>
			<li class="EventsPage-number">Жуковский Роман Яковлевич</li>
			<li class="EventsPage-number">Кистенев Никита Андреевич</li>
			<li class="EventsPage-number">Козлов Владислав Вячеславович</li>
			<li class="EventsPage-number">Котюкова Вероника Дмитриевна</li>
			<li class="EventsPage-number">Кудеев Александр Сергеевич</li>
			<li class="EventsPage-number">Парыгин Владислав Петрович</li>
			<li class="EventsPage-number">Побойкин Святослав Алексеевич</li>
			<li class="EventsPage-number">Подольский Савелий Анатольевич</li>
			<li class="EventsPage-number">Пятков Георгий Анатольевич</li>
			<li class="EventsPage-number">Пятков Григорий Анатольевич</li>
			<li class="EventsPage-number">Соколова Ксения Олеговна</li>
			<li class="EventsPage-number">Черных Всеволод Александрович</li>
			<li class="EventsPage-number">Щапов Вадим Михайлович</li>
		</ol>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/gKoS/P1waqKe6k" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/W3kWVI87Rfxojw" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>