<template>
	<div class="Contacts _cover">

		<h2 class="Contacts-title _cover-title">
			Контакты
		</h2>
		<h3 class="Contacts-sub-title _cover-title">
			ГОСУДАРСТВЕННОЕ АВТОНОМНОЕ НЕТИПОВОЕ УЧРЕЖДЕНИЕ ДОПОЛНИТЕЛЬНОГО ОБРАЗОВАНИЯ ИРКУТСКОЙ ОБЛАСТИ
			&laquo;РЕГИОНАЛЬНЫЙ ЦЕНТР ВЫЯВЛЕНИЯ И&nbsp;ПОДДЕРЖКИ ОДАРЕННЫХ ДЕТЕЙ &laquo;ПЕРСЕЙ&raquo;
		</h3>
		<div class="Contacts-map">
			<h2 class="Contacts-title _cover-title">
				Образовательный центр «Персей» (г. Иркутск, Угольный проезд, д. 68/1)
			</h2>
			<iframe class="Contacts-map-items"
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d862.9694598110138!2d104.34199791935282!3d52.2824936076572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5da83ba09dc4719b%3A0x9948a71c66ff303f!2z0J_QldCg0KHQldCZ!5e0!3m2!1sru!2sru!4v1688189139755!5m2!1sru!2sru"
				allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
				Местоположение на карте
			</iframe>
		</div>
		<div class="Contacts-map">
			<h2 class="Contacts-title _cover-title">
				Образовательный центр «Персей» (г. Иркутск, ул. Рабочего Штаба, д. 15)
			</h2>
			<iframe class="Contacts-map-items"
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2439.746898172815!2d104.2924610975051!3d52.302448180134874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5da83b2bdd2382e3%3A0xfc7147023a698cb1!2z0YPQuy4g0KDQsNCx0L7Rh9C10LPQviDQqNGC0LDQsdCwLCAxNSwg0JjRgNC60YPRgtGB0LosINCY0YDQutGD0YLRgdC60LDRjyDQvtCx0LsuLCA2NjQwMDE!5e0!3m2!1sru!2sru!4v1724912017604!5m2!1sru!2sru"
				allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
				Местоположение на карте
			</iframe>
		</div>
		<div class="Contacts-map">
			<h2 class="Contacts-title _cover-title">
				Кампус образовательного центр «Персей»
			</h2>
			<iframe class="Contacts-map-items"
				src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9731.175817609526!2d103.64268649336971!3d52.42853099924172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d07971f7a878d1f%3A0xa84c8c0320cabf2e!2z0J_QtdGA0YHQtdC5!5e0!3m2!1sru!2sru!4v1695783906624!5m2!1sru!2sru"
				allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
				Местоположение на карте
			</iframe>
		</div>
		<ul class="grid-container">
			<li class="grid-item">
				<p class="Contacts-address sub-title">
					Адрес:
				</p>
				<p class="Contacts-address-sub-title sub-title-text">
					664009, Иркутская область, г. Иркутск, Угольный проезд, 68/1
				</p>
			</li>
			<li class="grid-item">
				<p class="Contacts-email sub-title">
					E-mail:
				</p>
				<a href="mailto:perseus@perseusirk.ru">
					perseus@perseusirk.ru
				</a>
			</li>
			<li class="grid-item">
				<p class="Contacts-phone sub-title">
					Телефон:
				</p>
				<a href="tel:+73952546044">
					8 (3952) 54-60-44
				</a>
				<p class="Contacts-phone-sub-title sub-title-text">
					Контактное лицо: <br><b>Шестаков Алексей Александрович</b> директор Государственного автономного нетипового
					учреждения дополнительного образования Иркутской области &laquo;Региональный центр выявления
					и&nbsp;поддержки одаренных детей &laquo;Персей&raquo;
				</p>
			</li>
		</ul>
	</div>
</template>