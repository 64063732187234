<template>
	<footer class="footer">
		<div class="container">
			<div class="footer-column-all-list">

				<div class="footer-column">
					<router-link to="/" class="footer-signature-link">
						<div class="footer-signature-logo">
							<img class="footer-signature-logo-img" src="@/assets/img/Logo/logo.webp" alt="Логопит" loading="lazy">
						</div>
					</router-link>
					<p class="footer-signature-sub-title">
						Cоздан благодаря Федеральному проекту &laquo;Успех каждого ребенка&raquo;, который входит
						в&nbsp;Национальный
						проект &laquo;Образование&raquo;. В&nbsp;основе &laquo;Персея&raquo; лежит модель Образовательного центра
						&laquo;Сириус&raquo; в&nbsp;Сочи.
					</p>
					<p class="footer-signature-sub-title">
						&copy;2023 Образовательный центр &laquo;Персей&raquo;. Все права защищены.
					</p>
				</div>

				<div class="footer-column">
					<h3 class="footer-column-title">
						Сведения об образовательной организации
					</h3>
					<ul class="footer-column-list">
						<li class="footer-column-items">
							<router-link to="/Intelligence" class="footer-column-link">
								Сведения об&nbsp;Образовательном центре
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/BasicInformation" class="footer-column-link">
								Основные сведения
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/StructureAndOrgans" class="footer-column-link">
								Структура и органы управления образовательной организацией
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/Documentation" class="footer-column-link">
								Документы
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/Education" class="footer-column-link">
								Образование
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/Management" class="footer-column-link">
								Руководство
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/TeachingStaff" class="footer-column-link">
								Педагогический состав
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/Logistics" class="footer-column-link">
								Материально-техническое обеспечение и оснащенность образовательного процесса. Доступная среда
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/Scholarships" class="footer-column-link">
								Стипендии и меры поддержки обучающихся
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/Financial" class="footer-column-link">
								Финансово-хозяйственная деятельность
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/VacanciesForAdmission" class="footer-column-link">
								Вакантные места для приема (перевода) обучающихся
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/Trustees" class="footer-column-link">
								Попечительский совет
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/ExpertCouncil" class="footer-column-link">
								Экспертный совет
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/Partners" class="footer-column-link">
								Партнеры
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/Contacts" class="footer-column-link">
								Контакты
							</router-link>
						</li>
					</ul>
				</div>

				<div class="footer-column">
					<h3 class="footer-column-title">
						Образовательные программы
					</h3>
					<ul class="footer-column-list">
						<li class="footer-column-items">
							<router-link to="/ScienceBlock" class="footer-column-link">
								Наука
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/SportBlock" class="footer-column-link">
								Спорт
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/ArtBlock" class="footer-column-link">
								Искусство
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/WeeklyCoursesScience" class="footer-column-link">
								Еженедельные курсы Наука
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/WeeklyCoursesArt" class="footer-column-link">
								Еженедельные курсы Искусство
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/RemoteBlock" class="footer-column-link">
								Дистанционные курсы
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/OlyandCom" class="footer-column-link">
								Олимпиады и конкурсы
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/BestPrograms" class="footer-column-link">
								Реестр лучших программ
							</router-link>
						</li>
					</ul>
				</div>

				<div class="footer-column">
					<ul class="footer-column-list">
						<li class="footer-column-items">
							<router-link to="/GetIn" class="footer-column-link">
								Как попасть
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/HowToGet" class="footer-column-link">
								Стать участником программ
							</router-link>
						</li>
						<li class="footer-column-items">
							<router-link to="/News" class="footer-column-link">
								Новости
							</router-link>
						</li>
						<li class="footer-column-items">
							<a class="footer-column-link" href="https://sochisirius.ru/" target="_blank">
								Сириус
							</a>
						</li>
					</ul>
				</div>

				<div class="footer-column">
					<h3 class="footer-column-title">
						Социальные сети
					</h3>
					<ul class="footer-column-list">
						<li class="footer-column-items">
							<a class="footer-column-link" href="https://vk.com/perseusirk" target="_blank">
								Вконтакте
							</a>
						</li>
						<li class="footer-column-items">
							<a class="footer-column-link" href="https://t.me/perseiirk" target="_blank">
								Телеграм
							</a>
						</li>
					</ul>
				</div>

			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		
	}
</script>