<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Подготовка школьников к сдаче норм ГТО»
		</h2>
		<p class="EventsPage-date">
			с 26 сентября по 9 октября 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=194" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/25897-programma-podgotovke-shkolnikov-k-sdache-norm-gto"
			target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребенка» проводит профильную смену «Подготовка школьников к сдаче норм ГТО» для обучающихся
			5-11-х классов общеобразовательных организаций Иркутской области.
		</p>

		<p class="EventsPage-text">
			Программа направлена на улучшение физического состояния школьников, развивая у них выносливость, силу, гибкость и
			координацию. Способствует формированию у детей и подростков устойчивых привычек здорового образа жизни.
		</p>

		<p class="EventsPage-text">
			Процесс подготовки к сдаче норм ГТО требует от школьников регулярных тренировок, что способствует развитию
			дисциплины, самоконтроля и ответственности. Участие в программе мотивирует детей ставить перед собой цели и
			добиваться их, что полезно не только в спорте, но и в других сферах жизни. А регулярные занятия спортом помогают
			справляться со стрессом, улучшают настроение и способствуют развитию уверенности в себе, что особенно важно в
			подростковом возрасте.
		</p>

		<p class="EventsPage-text">
			Целью программы является: развитие физических качеств, подготовка учащихся к сдаче норм ГТО, оздоровление детей и
			подростков путем привлечения их к активным занятиям спортом и физической культуры.
		</p>

		<p class="EventsPage-text">
			По итогам освоения программы обучающимся выдаются сертификаты участников Смены
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Цветкова Елена Анатольевна, тренер-преподаватель Областного государственного казенного учреждения ДО «Спортивная
				школа олимпийского резерва «Приангарье». Достижения: заслуженный МС России по легкой атлетике
			</li>
			<li class="JuniorProfi-items">
				Пурас Андрей Валерьевич, педагог дополнительного образования ДДТ №3 г. Иркутска, тренер по вольной борьбе.
				Достижения: судья первой категории по спортивной борьбе
			</li>
			<li class="JuniorProfi-items">
				Корнилова Александра Александровна, тренер-преподаватель по легкой атлетике МКУ ДО ИРМО «Спортивная школа».
				Достижения: судья третьей категории по легкой атлетике
			</li>
			<li class="JuniorProfi-items">
				Щербакова Оксана Николаевна, инструктор- методист МКУ ДО ИРМО «Спортивная школа». Достижения: неоднократный
				победитель Международного конкурса «Нормы ГТО» разных групп населения
			</li>
			<li class="JuniorProfi-items">
				Журавлева Анастасия Михайловна, тренер-преподаватель МБУДО «Спортивная школа «Ермак»
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету участника на платформе электронной информационно-образовательной среды
				Образовательного центра «Персей» по ссылке: https://moodle.perseusirk.ru/course/view.php?id=194, где необходимо
				прикрепить до 20 сентября 2024 года включительно:<br><br>
				- Портфолио с дипломами, грамотами за участие в спортивных соревнованиях, чемпионатах регионального и
				всероссийского уровня за 2023 и 2024 год;<br><br>
				- Фотографии спортивных и творческих достижений (грамоты, дипломы, сертификаты, благодарственные письма за
				2023-2024 год);<br><br>
				- Мотивационное письмо на тему «Спорт в моей жизни», в котором написать о себе, о наиболее значимых спортивных и
				творческих мероприятиях, в которых вы принимали участие (не более 1000 символов).<br><br>
			</li>
			<li class="JuniorProfi-items">
				Участникам, прошедших на профильную смену необходимо подать заявку на платформе Навигатор дополнительного
				образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Капустина Екатерина Станиславна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: e.kapustina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>