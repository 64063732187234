<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Гибкие навыки в проектной деятельности»
		</h2>
		<p class="EventsPage-date">
			с 04 по 18 марта 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=152" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24624-programma-gibkie-navyki-v-proektnoi-deyatelnosti"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Государственное автономное нетиповое учреждение дополнительного образования Иркутской области «Региональный центр
			выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого
			ребёнка» проводит дистанционный курс «Гибкие навыки в проектной деятельности» с 04 марта по 18 марта 2024 года для
			учащихся 7-11-х классов общеобразовательных организаций Иркутской области.
		</p>

		<p class="EventsPage-text">
			Программа направлена на получение учащимися знаний, необходимых им для осуществления проектной деятельности в
			области научных технологий.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Коновалов Иван Алексеевич Инженер ООО «Байкальскийинжиниринг»;
			</li>
			<li class="JuniorProfi-items">
				Шагдыр Дарья Андреевна, Инженер 2 категории проектно-конструкторского отдела ООО «Байкальский инжиниринг».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Для прохождения обучения необходимо:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 04 марта 2024 года на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 04 марта 2024 года на платформе дистанционного обучения
				Образовательного центра «Персей» системе Moodle.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>