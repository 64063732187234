<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Дистанционный курс
		</h3>
		<h2 class="EventsPage-title">
			«Художественное слово. Подготовка к творческой деятельности по литературе»
		</h2>
		<p class="EventsPage-date">
			с 19 февраля по 18 марта 2024 года
		</p>
		<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=143)" target="_blank">
			Moodle
		</a>
		<a class="EventsPage-btn temp-btn"
			href="https://xn--38-kmc.xn--80aafey1amqq.xn--d1acj3b/program/24570-programma-khudozhestvennoe-slovo"
			target="_blank">
			Навигатор
		</a>
		<hr>

		<p class="EventsPage-text">
			Государственное автономное нетиповое учреждение дополнительного образования Иркутской области «Региональный центр
			выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального проекта «Успех каждого
			ребёнка» проводит дистанционный курс «Художественное слово. Подготовка к творческой деятельности по литературе» с
			19 февраля по 18 марта 2024 года для учащихся 8-11-х классов общеобразовательных организаций Иркутской области.
		</p>

		<p class="EventsPage-text">
			Программа направлена на ознакомление со спецификой литературного слова, узнают, чем отличается художественный
			текст от других видов текста, попрактикуются в написании текстов на разные темы, попробуют себя в литературной
			правке.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Власова Мария Александровна, старший преподаватель кафедры русской и зарубежной литературы, ФГБОУВО ИГУ.
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Для прохождения обучения необходимо:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Подать заявку до 19 февраля 2024 года на платформе Навигатор дополнительного образования детей Иркутской
				области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 19 февраля 2024 года на платформе дистанционного обучения
				Образовательного центра «Персей» системе Moodle.
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/1rn_0Lk1eYXFdw" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>