<template>
	<div class="EducationalStandards _cover">
		<h2 class="EducationalStandards-title _cover-title">
			Образовательные стандарты и требования
		</h2>

		<p class="JuniorProfi-sub-title">
			В настоящее время Федеральный государственный образовательный стандарт дополнительного образования детей и
			взрослых не разработан.
		</p>

		<p class="JuniorProfi-sub-title">
			Основным документом, регламентирующим деятельность учреждений дополнительного образования, является Федеральный
			закон от 29.12.2012 N 273-ФЗ (ред. от 08.08.2024) «Об образовании в Российской Федерации» (с изм. и доп., вступ. в
			силу с 01.09.2024)
		</p>

		<p class="JuniorProfi-sub-title">
			Статья 75. Дополнительное образование детей и взрослых
		</p>

		<p class="EventsPage-text">
			1. Дополнительное образование детей и взрослых направлено на формирование и развитие творческих способностей детей
			и взрослых, удовлетворение их индивидуальных потребностей в интеллектуальном, нравственном и физическом
			совершенствовании, формирование культуры здорового и безопасного образа жизни, укрепление здоровья, а также на
			организацию их свободного времени. Дополнительное образование детей обеспечивает их адаптацию к жизни в обществе,
			профессиональную ориентацию, а также выявление и поддержку детей, проявивших выдающиеся способности.
			Дополнительные общеобразовательные программы для детей должны учитывать возрастные и индивидуальные особенности
			детей.
		</p>

		<p class="EventsPage-text">
			2. Дополнительные общеобразовательные программы подразделяются на дополнительные общеразвивающие программы,
			дополнительные предпрофессиональные программы в области искусств и дополнительные образовательные программы
			спортивной подготовки. Дополнительные общеразвивающие программы реализуются для детей и для взрослых.
			Дополнительные предпрофессиональные программы в области искусств реализуются для детей. Дополнительные
			образовательные программы спортивной подготовки реализуются для детей и для взрослых.
		</p>

		<p class="EventsPage-text">
			3. К освоению дополнительных общеобразовательных программ допускаются любые лица без предъявления требований к
			уровню образования, если иное не обусловлено спецификой реализуемой образовательной программы.
		</p>

		<p class="EventsPage-text">
			4. Содержание дополнительных общеразвивающих программ и сроки обучения по ним определяются образовательной
			программой, разработанной и утвержденной организацией, осуществляющей образовательную деятельность. Содержание
			дополнительных предпрофессиональных программ в области искусств определяется образовательной программой,
			разработанной и утвержденной организацией, осуществляющей образовательную деятельность, в соответствии с
			федеральными государственными требованиями. Содержание дополнительных образовательных программ спортивной
			подготовки определяется соответствующей образовательной программой, разработанной и утвержденной организацией,
			реализующей дополнительные образовательные программы спортивной подготовки, с учетом примерных дополнительных
			образовательных программ спортивной подготовки.
		</p>

		<p class="EventsPage-text">
			5. Особенности реализации дополнительных предпрофессиональных программ в области искусств определяются в
			соответствии с частями 3 - 7 статьи 83 настоящего Федерального закона.
		</p>

		<p class="EventsPage-text">
			6. Особенности реализации дополнительных общеобразовательных программ в области физической культуры и спорта
			определяются статьей 84 настоящего Федерального закона.
		</p>
	</div>
</template>