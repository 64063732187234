<template>
	<div class="Sirius_Summer _cover">
		<h2 class="Sirius_Summer-title _cover-title">
			Сириус.Лето: Начни свой проект
		</h2>

		<div class="Sirius_Summer-logo">
			<img loading="lazy" class="Sirius_Summer-logo-img" src="../assets/img/svg/Sirius_Summer-logo-img.webp"
				alt="Логотип Уроки настоящего">
		</div>

		<h2 class="Sirius_Summer-title _cover-title">
			<a class="Sirius_Summer-link" href="https://siriusleto.ru/">
				О программе
			</a>
		</h2>

		<h4 class="Sirius_Summer-title _cover-title">
			Всероссийская образовательная инициатива по поиску и реализации научно-технологических проектов
		</h4>
		<p class="Sirius_Summer-text">
			Здесь школьники могут найти задачу для проектной работы, связанную с актуальными, современными проблемами науки,
			технологии, бизнеса, а также наставника, который поможет разобраться в задаче и будет сопровождать школьника в
			течение всего проекта.
		</p>

		<ul class="Sirius_Summer-list">
			<li class="Sirius_Summer-items">Постановка задач от партнеров — <b>до 10 сентября 2024 года</b></li>
			<li class="Sirius_Summer-items">Регистрация наставников, выбор проектных задач — <b>16-30 сентября 2024 года</b>
			</li>
			<li class="Sirius_Summer-items">Регистрация участников, выбор проектных задач — <b>7-31 октября 2024 года</b></li>
			<li class="Sirius_Summer-items">Работа над решением проектных задач — <b>ноябрь 2024 года -апрель 2025 года</b>
			</li>
			<li class="Sirius_Summer-items">Итоговые защиты проектов — <b>май 2025 года</b></li>
			<li class="Sirius_Summer-items">Загрузка отчетов о решении проектных задач для получения сертификатов — <b>15
					мая-15 июня 2025 года</b></li>
		</ul>


		<h3 class="Sirius_Summer-title _cover-title">
			<a class="Sirius_Summer-link" href="https://siriusleto.ru/regulations">Регламент</a>
		</h3>

		<div class="Sirius_Summer-box-img">
			<img loading="lazy" class="Sirius_Summer-img" src="../assets/img/BigChallenges/_1.webp" alt="Иконка">
			<p class="Sirius_Summer-list-text">
				<b>Школьникам</b>
			</p>
		</div>
		<ul class="Sirius_Summer-list">
			<li class="Sirius_Summer-items">работа над интересными и актуальными задачами развития страны</li>
			<li class="Sirius_Summer-items">возможность реализации себя в проектной деятельности и профессиональное
				самоопределение</li>
			<li class="Sirius_Summer-items">овладение востребованными на рынке труда компетенциями</li>
			<li class="Sirius_Summer-items">подготовка проекта для участия в знаковых всероссийских и международных конкурсах
			</li>
		</ul>

		<div class="Sirius_Summer-box-img">
			<img loading="lazy" class="Sirius_Summer-img" src="../assets/img/BigChallenges/_2.webp" alt="Иконка">
			<p class="Sirius_Summer-list-text">
				<b>Студентам</b>
			</p>
		</div>
		<ul class="Sirius_Summer-list">
			<li class="Sirius_Summer-items">опыт педагогической практики в качестве наставника</li>
			<li class="Sirius_Summer-items">приобретение навыка управления проектом</li>
			<li class="Sirius_Summer-items">возможность зарекомендовать себя перед потенциальным работодателем</li>
			<li class="Sirius_Summer-items">возможность трудоустройства</li>
		</ul>

		<div class="Sirius_Summer-box-img">
			<img loading="lazy" class="Sirius_Summer-img" src="../assets/img/BigChallenges/_3.webp" alt="Иконка">
			<p class="Sirius_Summer-list-text">
				<b>Партнерам</b>
			</p>
		</div>
		<ul class="Sirius_Summer-list">
			<li class="Sirius_Summer-items">привлечение внимания к своей отрасли и своим проектам</li>
			<li class="Sirius_Summer-items">знакомство с мотивированными кандидатами на трудоустройство, заинтересованными в
				проектах компании</li>
			<li class="Sirius_Summer-items">долгосрочный эффект привлечения самой талантливой молодежи (начиная со школьников)
			</li>
		</ul>

		<div class="Sirius_Summer-box-img">
			<img loading="lazy" class="Sirius_Summer-img" src="../assets/img/BigChallenges/_4.webp" alt="Иконка">
			<p class="Sirius_Summer-list-text">
				<b>Вузам</b>
			</p>
		</div>
		<ul class="Sirius_Summer-list">
			<li class="Sirius_Summer-items">привлечение к себе внимания сильных школьников региона</li>
			<li class="Sirius_Summer-items">установление более плотной и содержательной связи с партнерами в своем регионе
			</li>
			<li class="Sirius_Summer-items">возможности для трудоустройства своих студентов</li>
		</ul>

		<ul class="Sirius_Summer-list">
			<p class="Sirius_Summer-list-text">
				<b>Обмен опытом</b>
			</p>
			<li class="Sirius_Summer-items">Рекомендации наставников</li>
			<li class="Sirius_Summer-items">Отзывы участников</li>
			<li class="Sirius_Summer-items">Мнения партнёров</li>
		</ul>

		<ul class="Sirius_Summer-list">
			<p class="Sirius_Summer-list-text">
				<b>В помощь проектным командам</b>
			</p>
			<li class="Sirius_Summer-items">Обучающие курсы</li>
			<li class="Sirius_Summer-items">Видеролики</li>
			<li class="Sirius_Summer-items">Ссылки</li>
		</ul>

		<div class="Intelligence-block-video-box">
			<div class="Intelligence-block-video">
				<iframe class="Intelligence-block-video-items"
					src="https://www.youtube.com/embed/vfBy5iO0Uqc?si=Elh6eEi4w8bcMqcF" title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen>
					Финальная защита проектов Сириус.Лето
				</iframe>
			</div>
		</div>

		<h3 class="Sirius_Summer-title _cover-title">
			Как проходит программа сейчас
		</h3>

		<details class="AdditionalGneral-details" open>
			<summary class="AdditionalGneral-summary">
				<b>4 Сезон</b>
			</summary>

			<table class="Sirius_Summer-table">
				<tr>
					<td><b>Тема</b></td>
					<td><b>Заказчик</b></td>
					<td><b>ВУЗ координатор</b></td>
				</tr>
				<tr>
					<td>Развитие экотуризма в Иркутской области с использованием зеленой энергии
					</td>
					<td>ИРНИТУ</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Создание дрона-тележки для транспортировки тяжелых грузов</td>
					<td>ИРНИТУ</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Портативная система трекинга персонала</td>
					<td>ООО "ИНК"</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Разработка функциональных продуктов питания из растительного сырья Иркутской области</td>
					<td>ООО "ЭНОЛОГ"</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Разработка системы мониторинга микроклимата</td>
					<td>ООО "КВАНТУМ"</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Цифровая система учета и мониторинга выбросов CO2</td>
					<td>ИРНИТУ</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Выделение карбоната лития из пластовых рассолов (минерализованных подземных вод) Ковыктинского
						газоконденсатного месторождения</td>
					<td>ООО "ГАЗПРОМ ДОБЫЧА ИРКУТСК"</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Извлечение энергии из городской среды</td>
					<td>ИРНИТУ</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Создание чат-бота «ИнфоБГУ»</td>
					<td>БГУ</td>
					<td>БГУ</td>
				</tr>
				<tr>
					<td>Разработка профориентационной нейросети ProfSmart</td>
					<td>БГУ</td>
					<td>БГУ</td>
				</tr>
				<tr>
					<td>«СоцНавигатор» - информирование населения о льготах и социальных программах</td>
					<td>БГУ</td>
					<td>БГУ</td>
				</tr>
				<tr>
					<td>БизнесПомощь 2.0</td>
					<td>БГУ</td>
					<td>БГУ</td>
				</tr>
				<tr>
					<td>Создание комиксов-историй для решения социально-психологических проблем подростков</td>
					<td>ИГУ</td>
					<td>ИГУ</td>
				</tr>
			</table>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				<b>Архив 3 Сезона</b> 2022-2023 уч.г
			</summary>

			<table class="Sirius_Summer-table">
				<tr>
					<td><b>Тема</b></td>
					<td><b>Заказчик</b></td>
					<td><b>ВУЗ координатор</b></td>
				</tr>
				<tr>
					<td>Концепция устойчивого развития и энергоснабжения удаленных территорий и изолированных объектов</td>
					<td>ООО "ИЦ "Евросибэнерго"</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Электронная очередь для АЗС на месторождении</td>
					<td>ООО "ИНК"</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Создание доступной среды для детей-инвалидов через организацию внутренней визуальной навигации в
						учреждении</td>
					<td>ОГБУСО "Реабилитационный центр для детей и под-ростков с ограниченны-ми возможностями "Сосновая горка"
					</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Исследование и разработка цифровых моделей энергоснабжения изолированных районов на базе ВИЭ с
						использованием накопителей энергии</td>
					<td>ИРНИТУ</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Профориентационная нейросеть Prof.Smart</td>
					<td>БГУ</td>
					<td>БГУ</td>
				</tr>
			</table>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				<b>Архив 2 Сезона</b> 2021-2022 уч.г
			</summary>

			<table class="Sirius_Summer-table">
				<tr>
					<td><b>Тема</b></td>
					<td><b>Заказчик</b></td>
					<td><b>ВУЗ координатор</b></td>
				</tr>
				<tr>
					<td>Цифровой университет будущего</td>
					<td>БГУ</td>
					<td>БГУ</td>
				</tr>
				<tr>
					<td>Использование в АО «АНХК» природного газа в качестве топлива</td>
					<td>АО «АНХК»</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Разработка функциональных продуктов питания из растительного сырья Иркутской области</td>
					<td>ООО «Энолог»</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Повышение эффективности механической обработки моложестких деталей за счет выбора рационального способа
						гашения автоколебаний</td>
					<td>ИАЗ - филиал ПАО «Корпорация «Иркут»</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Разработка дорожной карты взаимодействия ООО «Газпром добыча Иркутск» и администрации Казачинско-Ленского
						района Иркутской области</td>
					<td>ОАО «Газпромдобыча Иркутск»</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Прибор для определения фракции щебня</td>
					<td>ООО «ИНК»</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Растворение гипса</td>
					<td>ООО «ИНК»</td>
					<td>ИРНИТУ</td>
				</tr>
			</table>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				<b>Архив 1 Сезона</b> 2020-2021 уч.г.
			</summary>

			<table class="Sirius_Summer-table">
				<tr>
					<td><b>Тема</b></td>
					<td><b>Заказчик</b></td>
					<td><b>ВУЗ координатор</b></td>
				</tr>
				<tr>
					<td>Магазин будущего</td>
					<td>ООО «СЛАТА»</td>
					<td>БГУ</td>
				</tr>
				<tr>
					<td>Концепция развития отрасли геодезии и картографии в далекой перспективе, ее место на рынке. Взгляд в
						будущее с учетом перспективы развития технологий</td>
					<td>АО «Восточно-Сибирское аэрогеодезическое предприятие</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Разработка противоскользящего покрытия для приставных, подвесных и маршевых лестниц, находящихся на
						производственных объектах</td>
					<td>ООО «ИНК»</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Внедрение технологий промышленной робототехники в сельскохозяйственное производство</td>
					<td>СХПАО
						«Белореченское»</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Растворение гипса</td>
					<td>ООО «ИНК»</td>
					<td>ИРНИТУ</td>
				</tr>
				<tr>
					<td>Галиты</td>
					<td>ООО «ИНК»</td>
					<td>ИРНИТУ</td>
				</tr>
			</table>
		</details>

		<details class="AdditionalGneral-details">
			<summary class="AdditionalGneral-summary">
				<b>Отзывы:</b>
			</summary>
			<div class="Sirius_Summer-reviews-box">

				<img loading="lazy" class="Sirius_Summer-reviews-box-img" src="../assets/img/SiriusSummerReviews/ПоповИлья.webp"
					alt="Аватар">
				<h5 class="Sirius_Summer-reviews-name">
					Автор: Попов Илья
				</h5>
				<div class="Sirius_Summer-reviews-text">
					<p class="Sirius_Summer-reviews-descr">
						Описание:
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Участник программы 2021-2022, выпускник МБОУ СОШ №67 г. Иркутска
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Я участвовал в конкурсах «Сириус.Лето» и «Большие вызовы» по направлению «Большие данные и искусственный
						интеллект». Для меня «Сириус.Лето» дал старт работы над проектом, который я продолжил продвигать на конкурсе
						«Большие вызовы». Будучи учеником 10 класса в процессе работы я научился работать в команде и открыл для
						себя новые навыки, которые мне пригодились уже в ВУЗе. Финал конкурса «Большие вызовы» проходил в Сириусе в
						Сочи, где я увидел огромное количество интересных ребят и их проекты. Этот уровень показал мне, что проект
						может быть действительно реализован, несмотря на масштабы, и быть действительно полезен нашей стране. Данный
						опыт подарил мне незабываемые эмоции и воспоминания, который я бы пожелал каждому. Очень надеюсь, что я
						смогу поучаствовать ещё раз в данном конкурсе, но уже в качестве наставника или даже руководителя проекта.
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Сейчас студент Иркутского политеха по направлению «Автоматизация технологических процессов и производств».
					</p>
				</div>

				<img loading="lazy" class="Sirius_Summer-reviews-box-img"
					src="../assets/img/SiriusSummerReviews/РудыхАнастасия.webp" alt="Аватар">
				<h5 class="Sirius_Summer-reviews-name">
					Автор: Рудых Анастасия
				</h5>
				<div class="Sirius_Summer-reviews-text">
					<p class="Sirius_Summer-reviews-descr">
						Описание:
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Участница программы Сириус.Лето 2021-2022, выпускница МБОУ г. Иркутска Гимназия №44
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Меня зовут Рудых Анастасия. В 2023 году я выпустилась из 11С класса с социально-экономическим профилем МБОУ
						Гимназии 44 г. Иркутск. Сейчас заканчиваю обучение на 1 курсе ИРНИТУ по направлению «Менеджмент».
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Программа "СириусЛето.Начни свой проект" - это интенсивный образовательный курс, проводимый Образовательным
						центром "Сириус" для одаренных школьников. Программа направлена на развитие исследовательских, проектных и
						презентационных навыков участников.
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Совершенно случайно мы стали участниками программы «СириусЛето. Начни свой проект». 8 февраля 2022г
						появилась официальная беседа с участниками и наставниками нашего кейса «Разработка дорожной карты
						взаимодействия Казачинского-Ленского и Жигаловского районов Иркутской области при содействии ООО «Газпром
						Добыча Иркутск». Сразу же появился вопрос: как девять десятиклассников и одна девятиклассница могут это
						сделать??? Но, можно попробовать.
					</p>
					<p class="Sirius_Summer-reviews-descr">
						На протяжении 4 месяцев мы упорно занимались проектом, каждую субботу собирались для обсуждений,
						созванивались и собирались кучу информации. На две недели ездили в образовательный центр «Персей», где
						только там стало ясно, что нам точно нужно сделать: создать план по развитию образования, газификации и
						инфраструктуры данных районов.
					</p>
					<p class="Sirius_Summer-reviews-descr">
						В мае мы успешно защитили наш проект.
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Благодаря данной программе мы получили опыт командной работы, развили исследовательские и проектные навыки,
						углубили знания в выбранной области и обрели новые знакомства с различными людьми!
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Программа "СириусЛето.Начни свой проект" - это выдающаяся образовательная инициатива, которая предоставляет
						одаренным школьникам исключительную возможность развить свои таланты в исследованиях и проектной
						деятельности. Высококвалифицированный преподавательский состав, интенсивная учебная среда и поддержка
						экспертов обеспечивают участникам незабываемые и трансформирующие впечатления. Программу настоятельно
						рекомендую любому школьнику, стремящемуся стать будущим лидером в области исследований и инноваций.
					</p>
				</div>

				<img loading="lazy" class="Sirius_Summer-reviews-box-img"
					src="../assets/img/SiriusSummerReviews/ЖижинВячеслав.webp" alt="Аватар">
				<h5 class="Sirius_Summer-reviews-name">
					Автор: Жижин Вячеслав
				</h5>
				<div class="Sirius_Summer-reviews-text">
					<p class="Sirius_Summer-reviews-descr">
						Описание:
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Участник программы 2020-2022 гг.. выпускник МБОУ СОШ №10 г. Ангарска
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Программа Сириус.Лето дала мне возможность получить новые навыки и реализовать свой потенциал. Пытаясь
						решить сложные задачи, поставленные заказчиком, многие участники применяли навыки критического и
						нестандартного мышления, тем самым развивали свои способности, учились рассматривать разные вещи под новыми
						углами и также расширяли свое мировоззрение и кругозор. Участвуя в программе, я познакомился не только с
						талантливыми студентами и их научными руководителями, но и с перспективными школьниками, с которыми всегда
						можно найти интересные темы для разговора. Два сезона программы Сириус.Лето позволили мне познакомится с
						интересными людьми и получить бесценные навыки в области проектной деятельности, используемые мной для
						участия в научных конкурсах будучи студентом.
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Сейчас студент 2 курса ИРНИТУ по направлению «Химическая технология»
					</p>
				</div>

				<img loading="lazy" class="Sirius_Summer-reviews-box-img"
					src="../assets/img/SiriusSummerReviews/БритюкТимофей.webp" alt="Аватар">
				<h5 class="Sirius_Summer-reviews-name">
					Автор: Бирюков Тимофей
				</h5>
				<div class="Sirius_Summer-reviews-text">
					<p class="Sirius_Summer-reviews-descr">
						Описание:
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Участник программы 2021-2022 гг., ученик МАОУ Лицей ИГУ г. Иркутска
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Участие в летней программе “Сириус” стало для меня одним из самых ярких и значимых событий. Она принесла мне
						ценные знания, новый опыт и полезные знакомства. В ходе участия я встретил много интересных и
						целеустремленных людей. Атмосфера программы просто насыщена энергией и стремлением к развитию, и это сильно
						мотивирует.
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Во время программы мы работали над проектом "Профориентационная нейросеть", что позволило мне получить
						углубленные знания в теме нейросетей и профориентации, а также стало отличной практикой публичных
						выступлений. Я узнал, как работают нейросети и как строится профориентация на основе типов темперамента,
						увлечений, предрасположенностей и других факторов. Кроме того, я работал и над другими проектами. Участие в
						программе дало мне ценный опыт работы в команде.
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Программа “Сириус Лето” послужила стимулом для изучения новой увлекательной информации. Я понял, что в нашем
						городе и стране много талантливых и целеустремленных ребят. Программа является местом высокой концентрации
						таких людей, что создает уникальную среду для развития.
					</p>
					<p class="Sirius_Summer-reviews-descr">
						В итоге, Сириус.Лето не только обогатила меня знаниями и опытом, но и дала уверенность в своих силах,
						вдохновила на дальнейшие достижения и новые проекты. Эта программа стала для меня важным этапом в личностном
						и профессиональном развитии.
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Спасибо за эту возможность!
					</p>
				</div>

				<img loading="lazy" class="Sirius_Summer-reviews-box-img"
					src="../assets/img/SiriusSummerReviews/ПляскинаКсения.webp" alt="Аватар">
				<h5 class="Sirius_Summer-reviews-name">
					Автор: Пляскина Ксения
				</h5>
				<div class="Sirius_Summer-reviews-text">
					<p class="Sirius_Summer-reviews-descr">
						Описание: Участница программы 2022-2024 г, ученица МАОУ Лицей ИГУ
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Мне очень понравилось участие в программе Сириус.Лето. Участвуя в программе, я нашла единомышленников,
						которым как и мне важно делать значимые для общества проекты. Благодаря Сириус.Лето я освоила навыки работы
						в различных сферах. Например, написания кода, а также улучшила свои навыки работы в команде.
					</p>
					<p class="Sirius_Summer-reviews-descr">
						Я благодарна всем наставникам и руководителям за то что верили в меня, и с радостью буду участвовать в
						следующих сезонах программы Сириус.Лето Начни свой проект.
					</p>
				</div>
			</div>
		</details>

		<h3 class="Sirius_Summer-title _cover-title">
			Контакты
		</h3>

		<div class="Sirius_Summer-contact-box">
			<ul class="Sirius_Summer-contact-list">
				<li class="Sirius_Summer-contact-items">
					Самодурова Вера Геннадьевна
				</li>
				<li class="Sirius_Summer-contact-items">
					Телефон: 8 (3952) 546-044
				</li>
				<li class="Sirius_Summer-contact-items">
					Эл. почта: v.samodurova@perseusirk.ru
				</li>
			</ul>

			<ul class="Sirius_Summer-contact-list">
				<li class="Sirius_Summer-contact-items">
					Шведина Светлана Александровна
				</li>
				<li class="Sirius_Summer-contact-items">
					Телефон: 8 (3952) 546-044
				</li>
				<li class="Sirius_Summer-contact-items">
					Эл. почта: s.shvedina@perseusirk.ru
				</li>
			</ul>
		</div>

	</div>
</template>