<template>
	<div class="MainNavigator _cover">
		<!-- VK -->
		<div class="MainNavigator-block-video-box">
			<div class="MainNavigator-iframe-box">
				<iframe class="MainNavigator-iframe" width="560" height="315"
					src="https://vk.com/video_ext.php?oid=-30558759&id=456247182&hd=2" title="YouTube video player"
					allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0"
					allowfullscreen>
					Поздравление Сергея Кравцова с Днем знаний!
				</iframe>
				<iframe class="MainNavigator-iframe" width="560" height="315"
					src="https://vk.com/video_ext.php?oid=-49034159&id=456239366&hd=2" title="YouTube video player"
					allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" frameborder="0"
					allowfullscreen>
					Поздравление министра образования Иркутской области Максима Парфенова с Днём знаний!
				</iframe>
			</div>
		</div>
	</div>
</template>

<style>
.MainNavigator-block-video-box {}

.MainNavigator-iframe-box {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
}

.MainNavigator-iframe {
	margin: 20px;
	border-radius: 10px;
}
</style>