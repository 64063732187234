<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Байкальские звезды «Абилимпикса»
		</h2>
		<p class="EventsPage-date">
			с 10 по 16 сентября 2024
		</p>
		<!-- <a class="EventsPage-btn temp-btn" href="" target="_blank">
			Moodle
		</a> -->
		<a class="EventsPage-btn temp-btn"
			href="https://р38.навигатор.дети/program/25475-programma-baikalskie-zvezdy-abilimpiksa" target="_blank">
			Навигатор
		</a>
		<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
			Условия заезда
		</a>
		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Байкальские звезды «Абилимпикса» для учащихся 5-11-х
			классов общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа ориентирована на создание мотивирующей образовательной среды, умение адаптироваться в различных
			ситуациях, эффективное взаимодействие со сверстниками и педагогами. Отличительной особенностью программы является
			то, что педагоги уделяют внимание не только интеллектуального потенциала обучающихся, но и формированию ключевых
			личностных навыков, к которым относятся – коммуникативные навыки, умение работать в команде.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо записаться на программу «Байкальские звезды «Абилимпикса» на ресурсе «Навигатор дополнительного
				образования Иркутской области».
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>
		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Антипин Захар Евгеньевич</li>
			<li class="EventsPage-number">Багдасарян Даниил Сергеевич</li>
			<li class="EventsPage-number">Борисов Николай Александрович</li>
			<li class="EventsPage-number">Виноградова Александра Романовна</li>
			<li class="EventsPage-number">Гаврилова Надежда Юрьевна</li>
			<li class="EventsPage-number">Гиззатуллина Ангелина Александровна</li>
			<li class="EventsPage-number">Гусельникова Кристина Владимировна</li>
			<li class="EventsPage-number">Димитрюк Максим Олегович</li>
			<li class="EventsPage-number">Еремеев Сергей Сергеевич</li>
			<li class="EventsPage-number">Иванова Елена Артуровна</li>
			<li class="EventsPage-number">Карелин Кирилл Владимирович</li>
			<li class="EventsPage-number">Карягин Александр Андреевич</li>
			<li class="EventsPage-number">Кодатенко Ольга Михайловна</li>
			<li class="EventsPage-number">Комаров Виталий Александрович</li>
			<li class="EventsPage-number">Королев Никита Владимирович</li>
			<li class="EventsPage-number">Красноштанов Андрей Евгеньевич</li>
			<li class="EventsPage-number">Луенко Надежда Леонидовна</li>
			<li class="EventsPage-number">Никитина Алиса Леонидовна</li>
			<li class="EventsPage-number">Носков Никита Александрович</li>
			<li class="EventsPage-number">Полоян Азиз Тенгизович</li>
			<li class="EventsPage-number">Соловьев Вячеслав Иванович</li>
			<li class="EventsPage-number">Тарасова Валерия Сергеевна</li>
			<li class="EventsPage-number">Торгашев Евгений Дмитриевич</li>
			<li class="EventsPage-number">Фаркова Ангелина Александровна</li>
			<li class="EventsPage-number">Федотов Станислав Алексеевич</li>
			<li class="EventsPage-number">Федяев Алексей Сергеевич</li>
			<li class="EventsPage-number">Фольмер Анатолий Александрович</li>
			<li class="EventsPage-number">Фунд Роман Алексеевич</li>
			<li class="EventsPage-number">Хващевскаа Алина Сергеевна</li>
			<li class="EventsPage-number">Худяков Илья Александрович</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://drive.google.com/drive/folders/1l0tjFMwWjzEv35lgfm_amL4FvaZV7gte"
			target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Сертификаты
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/bqjrUQUzDpSLmQ" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>