<template>
	<div class="Documents _cover">
		<h2 class="Documents-title _cover-title">
			Документы
		</h2>

		<h3 class="Documents-sub-title">
			Локальные акты, регламентирующие управление образовательной организацией:
		</h3>
		<ul class="Documents-list">
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%A3%D1%81%D1%82%D0%B0%D0%B2.pdf"
					target="_blank">
					Устав образовательной организации
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0-%D0%B2%D0%BD%D1%83%D1%82%D1%80%D0%B5%D0%BD%D0%BD%D0%B5%D0%B3%D0%BE-%D1%80%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D0%B0.pdf"
					target="_blank">
					Правила внутреннего распорядка обучающихся
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link"
					href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0-%D0%B2%D0%BD%D1%83%D1%82%D1%80%D0%B5%D0%BD%D0%B5%D0%B3%D0%BE-%D1%82%D1%80%D1%83%D0%B4%D0%BE%D0%B2%D0%BE%D0%B3%D0%BE-%D1%80%D0%B0%D1%81%D0%BF%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D0%B0-1.pdf"
					target="_blank">
					Правила внутреннего трудового распорядка
				</a>
			</li>
			<li class="Documents-list-items">
				<a class="Documents-list-items-link" href="#" target="_blank">
					Коллективный договор(на согласовании)
				</a>
			</li>
		</ul>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">
				Локальные нормативные акты, регламентирующие организационные аспекты деятельности:
			</summary>
			<ul class="Documents-list">
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BF%D0%B5%D0%B4%D1%81%D0%BE%D0%B2%D0%B5%D1%82%D0%B5.pdf"
						target="_blank">
						Положение о педсовете
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D0%BF%D0%BB%D0%B0%D1%82%D0%B5-%D1%82%D1%80%D1%83%D0%B4%D0%B0-NEW.pdf"
						target="_blank">
						Положение об оплате труда
					</a>
				</li>
			</ul>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">
				Локальные нормативные акты, регламентирующие открытость и доступность информации о деятельности ОЦ Персей:
			</summary>
			<ul class="Documents-list">
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%B2%D0%BD%D1%83%D1%82%D1%80%D0%B5%D0%BD%D0%BD%D0%B5%D0%B9-%D1%81%D0%B8%D1%81%D1%82%D0%B5%D0%BC%D0%B5-%D0%BA%D0%B0%D1%87%D0%B5%D1%81%D1%82%D0%B2%D0%B0-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D1%8F.pdf"
						target="_blank">
						Положение о внутренней системе качества образования
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D1%81%D0%B0%D0%BC%D0%BE%D0%BE%D0%B1%D1%81%D0%BB%D0%B5%D0%B4%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B8.pdf"
						target="_blank">
						Положение о самообследовании
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D1%81%D0%B0%D0%B9%D1%82%D0%B5.pdf"
						target="_blank">
						Положение о сайте
					</a>
				</li>
			</ul>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">
				Локальные нормативные акты, регламентирующие права, обязанности и ответственность работников Образовательного
				центра Персей:
			</summary>
			<ul class="Documents-list">
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BA%D0%BE%D0%BC%D0%B8%D1%81%D1%81%D0%B8%D0%B8-%D0%BF%D0%BE-%D1%8D%D1%82%D0%B8%D0%BA%D0%B5-%D0%B8-%D0%BF%D1%80%D0%BE%D1%82%D0%B8%D0%B2%D0%BE%D0%B4%D0%B5%D0%B9%D1%81%D1%82%D0%B2%D0%B8%D1%8E-%D0%BA%D0%BE%D1%80%D1%80%D1%83%D0%BF%D1%86%D0%B8%D0%B8.docx"
						target="_blank">
						Положение о комиссии по этике и противодействию коррупции
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%B0%D0%BD%D1%82%D0%B8%D0%BA%D0%BE%D1%80%D1%80%D1%83%D0%BF%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D0%BE%D0%B9-%D0%BF%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B5.docx"
						target="_blank">
						Положение об антикоррупционной политике
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/06/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BF%D1%80%D0%B5%D0%B4%D0%BE%D1%82%D0%B2%D1%80%D0%B0%D1%89%D0%B5%D0%BD%D0%B8%D0%B8-%D0%BA%D0%BE%D0%BD%D1%84%D0%BB%D0%B8%D0%BA%D1%82%D0%B0-%D0%B8%D0%BD%D1%82%D0%B5%D1%80%D0%B5%D1%81%D0%BE%D0%B2.docx"
						target="_blank">
						Положение о предотвращении конфликта интересов
					</a>
				</li>
			</ul>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">
				Локальные нормативные акты, регламентирующие организацию образовательного процесса по дополнительным
				общеразвивающим программам:
			</summary>
			<ul class="Documents-list">
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BF%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D0%B5-%D0%B8-%D0%BE%D1%81%D0%BD%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B8-%D0%BE%D1%82%D1%87%D0%B8%D1%81%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-%D1%83%D1%87%D0%B0%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BF%D0%BE-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%BC-%D0%BE%D0%B1%D1%89%D0%B5%D1%80%D0%B0%D0%B7%D0%B2%D0%B8%D0%B2%D0%B0%D1%8E%D1%89%D0%B8%D0%BC-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D0%BC.pdf"
						target="_blank">
						Положение о порядке и основании перевода, отчисления и восстановления учащихся по дополнительным
						общеразвивающим программам
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BF%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D0%B5-%D0%B8-%D0%BA%D1%80%D0%B8%D1%82%D0%B5%D1%80%D0%B8%D1%8F%D1%85-%D0%BE%D1%82%D0%B1%D0%BE%D1%80%D0%B0-%D1%83%D1%87%D0%B0%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%B8-%D0%BF%D0%B5%D0%B4%D0%B0%D0%B3%D0%BE%D0%B3%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B8%D1%85-%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BD%D0%B8%D0%BA%D0%BE%D0%B2.pdf"
						target="_blank">
						Положение о порядке и критериях отбора учащихся и педагогических работников
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/09/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%D1%85-%D0%BF%D1%80%D0%B8%D0%B5%D0%BC%D0%B0-%D0%BD%D0%B0-%D0%BE%D0%B1%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BF%D0%BE-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%BC-%D0%BE%D0%B1%D1%89%D0%B5%D1%80%D0%B0%D0%B7%D0%B2%D0%B8%D0%B2%D0%B0%D1%8E%D1%89%D0%B8%D0%BC-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D0%BC.pdf"
						target="_blank">
						Положение о правилах приема на обучение по дополнительным общеразвивающим программам
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BF%D1%80%D0%BE%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D0%B8-%D0%BF%D1%80%D0%BE%D1%84%D0%B8%D0%BB%D1%8C%D0%BD%D0%BE%D0%B9-%D1%81%D0%BC%D0%B5%D0%BD%D1%8B.pdf"
						target="_blank">
						Положение о проведении профильной смены
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D1%80%D0%B0%D0%B7%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B5-%D0%B8-%D1%80%D0%B5%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%BE%D0%B1%D1%89%D0%B5%D1%80%D0%B0%D0%B7%D0%B2%D0%B8%D0%B2%D0%B0%D1%8E%D1%89%D0%B8%D1%85-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC.pdf"
						target="_blank">
						Положение о разработке и реализации дополнительных общеразвивающих программ
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D1%80%D0%B5%D0%B6%D0%B8%D0%BC%D0%B5-%D1%83%D1%87%D0%B5%D0%B1%D0%BD%D1%8B%D1%85-%D0%B7%D0%B0%D0%BD%D1%8F%D1%82%D0%B8%D0%B9-%D1%83%D1%87%D0%B0%D1%89%D0%B8%D1%85%D1%81%D1%8F-%D0%BF%D0%BE-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%BC-%D0%BE%D0%B1%D1%89%D0%B5%D1%80%D0%B0%D0%B7%D0%B2%D0%B8%D0%B2%D0%B0%D1%8E%D1%89%D0%B8%D0%BC-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D0%BC.pdf"
						target="_blank">
						Положение о режиме учебных занятий учащихся по дополнительным общеразвивающим программам
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%82%D0%B1%D0%BE%D1%80%D0%BE%D1%87%D0%BD%D0%BE%D0%B9-%D0%B8-%D0%B0%D0%BF%D0%B5%D0%BB%D0%BB%D1%8F%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D0%BE%D0%B9-%D0%BA%D0%BE%D0%BC%D0%B8%D1%81%D1%81%D0%B8%D1%8F%D1%85-%D0%BF%D0%BE-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%BC-%D0%BE%D0%B1%D1%89%D0%B5%D1%80%D0%B0%D0%B7%D0%B2%D0%B8%D0%B2%D0%B0%D1%8E%D1%89%D0%B8%D0%BC-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D0%BC.pdf"
						target="_blank">
						Положение об отборочной и апелляционной комиссиях по дополнительным общеразвивающим программам
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D1%8D%D0%BA%D1%81%D0%BF%D0%B5%D1%80%D1%82%D0%BD%D0%BE%D0%BC-%D1%81%D0%BE%D0%B2%D0%B5%D1%82%D0%B5.pdf"
						target="_blank">
						Положение об экспертном совете
					</a>
				</li>
			</ul>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">
				Локальные нормативные акты, регламентирующие организацию образовательного процесса по дополнительным
				профессиональным программам:
			</summary>
			<ul class="Documents-list">
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/02/%D0%9E-%D0%BF%D1%80%D0%B8%D0%B5%D0%BC%D0%B5-%D0%BF%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B4%D0%B5-%D0%B8-%D0%BE%D1%82%D1%87%D0%B8%D1%81%D0%BB%D0%B5%D0%BD%D0%B8%D0%B8.pdf"
						target="_blank">
						Положение о периодичности и порядке контроля, аттестации учащихся по дополнительным профессиональным
						программам
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D1%80%D0%B5%D0%B6%D0%B8%D0%BC%D0%B5-%D0%B7%D0%B0%D0%BD%D1%8F%D1%82%D0%B8%D0%B9-%D1%81%D0%BB%D1%83%D1%88%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC.pdf"
						target="_blank">
						Положение о режиме занятий слушателей дополнительных профессиональных программ
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D1%81%D1%82%D0%B0%D0%B6%D0%B8%D1%80%D0%BE%D0%B2%D0%BA%D0%B5-%D1%81%D0%BB%D1%83%D1%88%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC.pdf"
						target="_blank">
						Положение о стажировке слушателей дополнительных профессиональных программ
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D1%82%D0%B5%D0%BA%D1%83%D1%89%D0%B5%D0%BC-%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%BE%D0%BB%D0%B5-%D0%B8-%D0%BF%D1%80%D0%BE%D0%BC%D0%B5%D0%B6%D1%83%D1%82%D0%BE%D1%87%D0%BD%D0%BE%D0%B9-%D0%B0%D1%82%D1%82%D0%B5%D1%81%D1%82%D0%B0%D1%86%D0%B8%D0%B8-%D1%81%D0%BB%D1%83%D1%88%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC.pdf"
						target="_blank">
						Положение о текущем контроле и промежуточной аттестации слушателей дополнительных профессиональных
						программ
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D0%B9-%D0%B0%D1%82%D1%82%D0%B5%D1%81%D1%82%D0%B0%D1%86%D0%B8%D0%B8-%D1%81%D0%BB%D1%83%D1%88%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC.pdf"
						target="_blank">
						Положение об итоговой аттестации слушателей дополнительных профессиональных программ
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8-%D0%B8-%D0%BE%D1%81%D1%83%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D0%B8-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D0%B9-%D0%B4%D0%B5%D1%8F%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8-%D0%BF%D0%BE-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%BC-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%BC-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D0%BC.pdf"
						target="_blank">
						Положение об организации и осуществлении образовательной деятельности по дополнительным профессиональным
						программам
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8-%D0%BE%D0%B1%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F-%D0%BF%D0%BE-%D0%B8%D0%BD%D0%B4%D0%B8%D0%B2%D0%B8%D0%B4%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC%D1%83-%D1%83%D1%87%D0%B5%D0%B1%D0%BD%D0%BE%D0%BC%D1%83-%D0%BF%D0%BB%D0%B0%D0%BD%D1%83-%D1%81%D0%BB%D1%83%D1%88%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC.pdf"
						target="_blank">
						Положение об организации обучения по индивидуальному учебному плану слушателей дополнительных
						профессиональных программ
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D1%83%D1%87%D0%B5%D0%B1%D0%BD%D0%BE-%D0%BC%D0%B5%D1%82%D0%BE%D0%B4%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%BC-%D0%BA%D0%BE%D0%BC%D0%BF%D0%BB%D0%B5%D0%BA%D1%81%D0%B5-%D0%BF%D0%BE-%D0%B4%D0%BE%D0%BF%D0%BE%D0%BB%D0%BD%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%BC-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%BC-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D0%BC.pdf"
						target="_blank">
						Положение об учебно-методическом комплексе по дополнительным профессиональным программам
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D1%80%D0%B5%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8-%D0%9E%D0%9F-%D1%81-%D0%BF%D1%80%D0%B8%D0%BC%D0%B5%D0%BD%D0%B5%D0%BD%D0%B8%D0%B5%D0%BC-%D1%8D%D0%BB%D0%B5%D0%BA%D1%82%D1%80%D0%BE%D0%BD%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BE%D0%B1%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B8-%D0%B4%D0%B8%D1%81%D1%82%D0%B0%D0%BD%D1%86%D0%B8%D0%BE%D0%BD%D0%BD%D1%8B%D1%85-%D0%BE%D0%B1%D1%80%D0%B0%D0%B7%D0%BE%D0%B2%D0%B0%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D1%85-%D1%82%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D0%B9-%D1%81%D0%BB%D1%83%D1%88%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%94%D0%9F%D0%9F.pdf"
						target="_blank">
						Положение о реализации образовательных программ с применением электронного обучения и дистанционных
						образовательных технологий слушателей дополнительных профессиональных программ
					</a>
				</li>
			</ul>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">
				Локальные нормативные акты, регламентирующие организацию образовательного процесса по основным программам
				профессионального обучения:
			</summary>
			<ul class="Documents-list">
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BF%D0%BE%D1%80%D1%8F%D0%B4%D0%BA%D0%B5-%D0%BF%D1%80%D0%B8%D0%B5%D0%BC%D0%B0-%D0%BF%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B4%D0%B0-%D0%BE%D1%82%D1%87%D0%B8%D1%81%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B8-%D0%B2%D0%BE%D1%81%D1%81%D1%82%D0%B0%D0%BD%D0%BE%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-%D1%81%D0%BB%D1%83%D1%88%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%BF%D0%BE-%D0%BE%D1%81%D0%BD%D0%BE%D0%B2%D0%BD%D1%8B%D0%BC-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D0%BC-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BE%D0%B1%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F.pdf"
						target="_blank">
						Положение о порядке приема, перевода, отчисления и восстановления слушателей по основным программам
						профессионального обучения
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D0%BF%D1%80%D0%B0%D0%BA%D1%82%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%BE%D0%B9-%D0%BF%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B5-%D1%81%D0%BB%D1%83%D1%88%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%BE%D1%81%D0%BD%D0%BE%D0%B2%D0%BD%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BE%D0%B1%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F.pdf"
						target="_blank">
						Положение о практической подготовке слушателей основных программ профессионального обучения
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D1%80%D0%B0%D0%B7%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D0%B5-%D0%B8-%D1%80%D0%B5%D0%B0%D0%BB%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D0%BC-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BE%D0%B1%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F.pdf"
						target="_blank">
						Положение о разработке и реализации программам профессионального обучения
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE-%D1%80%D0%B5%D0%B6%D0%B8%D0%BC%D0%B5-%D0%B7%D0%B0%D0%BD%D1%8F%D1%82%D0%B8%D0%B9-%D1%81%D0%BB%D1%83%D1%88%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%BE%D1%81%D0%BD%D0%BE%D0%B2%D0%BD%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BE%D0%B1%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F.pdf"
						target="_blank">
						Положение о режиме занятий слушателей основных программ профессионального обучения
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%B8%D1%82%D0%BE%D0%B3%D0%BE%D0%B2%D0%BE%D0%B9-%D0%B0%D1%82%D1%82%D0%B5%D1%81%D1%82%D0%B0%D1%86%D0%B8%D0%B8-%D1%81%D0%BB%D1%83%D1%88%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%BE%D1%81%D0%BD%D0%BE%D0%B2%D0%BD%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BE%D0%B1%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F.pdf"
						target="_blank">
						Положение об итоговой аттестации слушателей основных программ профессионального обучения
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8-%D0%BE%D0%B1%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F-%D0%BF%D0%BE-%D0%B8%D0%BD%D0%B4%D0%B8%D0%B2%D0%B8%D0%B4%D1%83%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%BC%D1%83-%D1%83%D1%87%D0%B5%D0%B1%D0%BD%D0%BE%D0%BC%D1%83-%D0%BF%D0%BB%D0%B0%D0%BD%D1%83-%D0%BF%D0%BE-%D0%BE%D1%81%D0%BD%D0%BE%D0%B2%D0%BD%D1%8B%D0%BC-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D0%BC-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BE%D0%B1%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F.pdf"
						target="_blank">
						Положение об организации обучения по индивидуальному учебному плану по основным программам
						профессионального обучения
					</a>
				</li>
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/03/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5-%D0%BE%D0%B1-%D0%BE%D1%80%D0%B3%D0%B0%D0%BD%D0%B8%D0%B7%D0%B0%D1%86%D0%B8%D0%B8-%D1%82%D0%B5%D0%BA%D1%83%D1%89%D0%B5%D0%B3%D0%BE-%D0%BA%D0%BE%D0%BD%D1%82%D1%80%D0%BE%D0%BB%D1%8F-%D0%B8-%D0%BF%D1%80%D0%BE%D0%BC%D0%B5%D0%B6%D1%83%D1%82%D0%BE%D1%87%D0%BD%D0%BE%D0%B9-%D0%B0%D1%82%D1%82%D0%B5%D1%81%D1%82%D0%B0%D1%86%D0%B8%D0%B8-%D1%81%D0%BB%D1%83%D1%88%D0%B0%D1%82%D0%B5%D0%BB%D0%B5%D0%B9-%D0%BE%D1%81%D0%BD%D0%BE%D0%B2%D0%BD%D1%8B%D1%85-%D0%BF%D1%80%D0%BE%D0%B3%D1%80%D0%B0%D0%BC%D0%BC-%D0%BF%D1%80%D0%BE%D1%84%D0%B5%D1%81%D1%81%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D0%B3%D0%BE-%D0%BE%D0%B1%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D1%8F.pdf"
						target="_blank">
						Положение об организации текущего контроля и промежуточной аттестации слушателей основных программ
						профессионального обучения
					</a>
				</li>
			</ul>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">
				Отчет о результатах самообследования
			</summary>
			<ul class="Documents-list">
				<li class="Documents-list-items">
					<a class="Documents-list-items-link"
						href="https://starsite.perseusirk.ru/wp-content/uploads/2024/04/%D0%9E%D1%82%D1%87%D0%B5%D1%82-%D0%BE-%D1%81%D0%B0%D0%BC%D0%BE%D0%BE%D0%B1%D1%81%D0%BB%D0%B5%D0%B4%D0%BE%D0%B2%D0%B0%D0%BD%D0%B8%D0%B8-%D0%B7%D0%B0-2023-.pdf"
						target="_blank">
						Отчет о результатах самообследования за 2023 год
					</a>
				</li>
			</ul>
		</details>

		<details class="Logistics-details">
			<summary class="Logistics-details-title">
				Предписания органов, осуществляющих государственный контроль (надзор) в сфере образования, отчеты об исполнении
				таких предписаний
			</summary>
			<div class="StructureAndOrgans-table">
				<table>
					<tr>
						<td>№</td>
						<td>Контрольно-надзорный орган</td>
						<td>Дата проведения проверки</td>
						<td>Вид проверки</td>
						<td>Документ </td>
						<td>Результат </td>
					</tr>
					<tr>
						<td>1</td>
						<td>Отдел надзорной деятельности и профилактической работы по Ангарскому городскому округу</td>
						<td>16.04.2024-27.04.2024</td>
						<td>внеплановая</td>
						<td>Решение о проведении выездной проверки № 2404/300-38/251-В/РВП</td>
						<td>Предписание об устранении нарушений обязательных требований </td>
					</tr>
				</table>
			</div>
		</details>
	</div>
</template>