<template>
	<div class="Nutrition _cover">
		<h2 class="Nutrition-title _cover-title">
			Организация питания в образовательной организации
		</h2>

		<p class="AccessibleEnvironment-descr">
			Государственное автономное нетиповое учреждение дополнительного образования Иркутской области «Региональный
			центр выявления и поддержки одаренных детей «Персей» (далее – Образовательный центр «Персей») обеспечивает
			рациональное и сбалансированное питание обучающихся интенсивных профильных смен в соответствии с примерным
			цикличным (сезонным) разработанным по установленной форме для детей с 10 лет до 18 лет по адресу:
		</p>

		<div class="StructureAndOrgans-table">
			<table>
				<tbody>
					<tr>
						<td>Адрес</td>
						<td>Наименование помещения</td>
						<td>Характеристика помещения</td>
					</tr>
					<tr>
						<td>Ангарский район, 8,351 км автодороги Ангарск-Тальяны, ОЦ «Персей»</td>
						<td>Столовая</td>
						<td>Площадь столовой – 1183,9 м², количество посадочных мест – 280</td>
					</tr>
				</tbody>
			</table>
		</div>

		<p class="AccessibleEnvironment-descr">
			Организация питания учащихся (получение, хранение и учёт продуктов питания, производство кулинарной продукции в
			столовой, создание условий для приёма пищи обучающимися и пр.) обеспечивается сотрудниками столовой
			Образовательного центра «Персей» в соответствии со штатным расписанием и функциональными обязанностями.
		</p>

	</div>
</template>