<template>
	<div class="EventsPage-container _cover">
		<h3 class="EventsPage-prof">
			Профильная смена
		</h3>
		<h2 class="EventsPage-title">
			«Анатомия человека»
		</h2>
		<p class="EventsPage-date">
			с 7 по 20 февраля 2024 года
		</p>

		<div class="colum1">
			<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/course/view.php?id=134" target="_blank">
				Анкета Moodle
			</a>
			<a class="EventsPage-btn temp-btn" href="https://moodle.perseusirk.ru/mod/quiz/view.php?id=882" target="_blank">
				Конкурсное задание
			</a>
			<a class="EventsPage-btn temp-btn" href="https://р38.навигатор.дети/program/24494-programma-anatomiya-cheloveka"
				target="_blank">
				Навигатор
			</a>
			<a class="EventsPage-btn temp-btn" @click="$router.push({ name: 'HowToGet' })">
				Условия заезда
			</a>
		</div>

		<hr>

		<p class="EventsPage-text">
			Региональный центр выявления и поддержки одаренных детей «Образовательный центр «Персей» в рамках Федерального
			проекта «Успех каждого ребёнка» проводит профильную смену «Анатомия человека» для учащихся 8-11-х классов
			общеобразовательных организаций.
		</p>

		<p class="EventsPage-text">
			Программа позволит участникам углубить знания о строении и функционировании организма. В рамках программы будут
			представлены интерактивные лекции, практические занятия и лабораторные работы, которые помогут участникам углубить
			свое понимание анатомии и функционирования органов и тканей.
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Преподаватели:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Томилова Альбина Сергеевна, старший лаборант, Образовательный центр «Персей».
			</li>
		</ul>

		<p class="JuniorProfi-sub-title">
			Условия и участие:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Необходимо подать заявку на платформе Навигатор дополнительного образования детей Иркутской области.
			</li>
			<li class="JuniorProfi-items">
				Зарегистрироваться и заполнить анкету до 29 января 2024 года на платформе дистанционного обучения
				Образовательного центра «Персей» системе Moodle.
			</li>
			<li class="JuniorProfi-items">
				Профильная смена состоится на бесплатной основе в кампусе «Образовательного центра «Персей» (Иркутская область,
				Ангарский район, 8,351 км. автодороги Ангарск-Тальяны).
			</li>
		</ul>
		<p class="EventsPage-text">
			Для участников смены будет проведено организационное собрание 1 февраля 2024 г. в 20.00, ссылка на подключение <a
				style="color:blue"
				href="https://pruffme.com/landing/Perseus/tmp1706081331">https://pruffme.com/landing/Perseus/tmp1706081331</a>
		</p>
		<hr>

		<p class="JuniorProfi-sub-title">
			Список учащихся, приглашенных на очную профильную смену:
		</p>

		<ol class="EventsPage-list-number">
			<li class="EventsPage-number">Барановская Милана;</li>
			<li class="EventsPage-number">Савватеева Юлия;</li>
			<li class="EventsPage-number">Шевцова Екатерина;</li>
			<li class="EventsPage-number">Лузина Алина;</li>
			<li class="EventsPage-number">Бахолдина Екатерина;</li>
			<li class="EventsPage-number">Разуваева Милана;</li>
			<li class="EventsPage-number">Гертнер Снежана;</li>
			<li class="EventsPage-number">Жуковский Роман;</li>
			<li class="EventsPage-number">Иванов Михаил;</li>
			<li class="EventsPage-number">Лопарева Фаина;</li>
			<li class="EventsPage-number">Новоселова Елена;</li>
			<li class="EventsPage-number">Окоряк Илья;</li>
			<li class="EventsPage-number">Новоселова Александра;</li>
			<li class="EventsPage-number">Полтавцева Диана;</li>
			<li class="EventsPage-number">Агапитова Катерина;</li>
			<li class="EventsPage-number">Харевич Антон;</li>
			<li class="EventsPage-number">Измайлова Анастасия;</li>
			<li class="EventsPage-number">Зырянов Николай;</li>
			<li class="EventsPage-number">Бурнашов Кирилл;</li>
			<li class="EventsPage-number">Леликов Ярополк;</li>
		</ol>
		<br>
		<hr>

		<p class="JuniorProfi-sub-title">
			Справки-вызовы
		</p>
		<a class="EventsPage-link" href="https://cloud.mail.ru/public/9YFN/PXCgpZh3V" target="_blank">
			Скачать
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Документы
		</p>
		<a class="EventsPage-link" href="https://disk.yandex.com/d/BPLhGPVJE5bGPQ" target="_blank">
			Сертификаты
		</a>
		<hr>

		<p class="JuniorProfi-sub-title">
			Контакты:
		</p>
		<ul class="JuniorProfi-list">
			<li class="JuniorProfi-items">
				Контактное лицо: Толстихина Татьяна Павловна
			</li>
			<li class="JuniorProfi-items">
				Телефон: 8 (3952) 546-044
			</li>
			<li class="JuniorProfi-items">
				Эл. почта: n.tolstihina@perseusirk.ru
			</li>
		</ul>

	</div>
</template>